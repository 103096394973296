export function getPricesByDistance(prices, distance) {
  return (
    prices &&
    prices.filter(x => x.minDistance <= distance && x.maxDistance >= distance)
  );
}

function getPricesWhereZipCodeIsNullOrUndefined(prices) {
  return prices && prices.filter(x => !x.zipCode);
}

function getPriceByZipCode(pricesByDistance, zipCode) {
  return pricesByDistance.find(x => x.zipCode === zipCode);
}

function getFlatRate(prices, distance, zipCode) {
  const pricesByDistance = getPricesByDistance(prices, distance);

  let price = null;

  if (pricesByDistance && pricesByDistance.length === 1) {
    [price] = pricesByDistance;
  } else if (pricesByDistance && pricesByDistance.length > 1) {
    price = getPriceByZipCode(pricesByDistance, zipCode);

    if (!price) {
      [price] = getPricesWhereZipCodeIsNullOrUndefined(pricesByDistance);
    }
  }

  let flatRate = null;

  if (price) {
    flatRate = price.flatRate;
  }

  return flatRate;
}

export function getPricePerKilometer(prices, distance, zipCode) {
  const flatRate = getFlatRate(prices, distance, zipCode);

  return flatRate / distance;
}

export function getStartPrice(prices, distance, zipCode) {
  const flatRate = getFlatRate(prices, distance, zipCode);

  return flatRate;
}
