import { gridStoreResults } from 'fox-react/dist/components/FoxGrid/actions';
import gridNames from 'common/gridNames';
import DefaultPricesManagementSrv from './DefaultPricesManagementSrv';

export function storeResults(results) {
  return gridStoreResults(gridNames.defaultPricesManagement)(results);
}

export function getForGrid(dataState) {
  return function(dispatch) {
    return DefaultPricesManagementSrv.getForGrid(dataState).then(results => {
      dispatch(storeResults(results));
    });
  };
}

export function addAsync(model) {
  return function() {
    return DefaultPricesManagementSrv.add(model);
    // TODO ppolak: toast?
  };
}

export function updateAsync(model) {
  return function() {
    return DefaultPricesManagementSrv.update(model);
    // TODO ppolak: toast
  };
}
