import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intersection from 'lodash/intersection';
import get from 'lodash/get';
import cx from 'classnames';

import permissions from 'common/permissions';

import OrderDashboardCard from './OrderDashboardCard';
import AuctionDashboardCard from './AuctionDashboardCard';
import { getData, getDiagram } from './actions';
import SpinnerContext from 'common/SpinnerContext';

const propTypes = {};

const defaultProps = {};

const DashboardPage = () => {
  const dispatch = useDispatch();
  const dashboardModel = useSelector(state => state.dashboard.dashboardModel);
  const [showSpinner, setShowSpinner] = useState(false);

  const userPermissions = useSelector(
    state => get(state, 'auth.currentUser.permissions') || [],
  );

  const hasOneOf = per => {
    return !!intersection(per, userPermissions).length;
  };

  const hasDepAdminEmployee = hasOneOf([
    permissions.DEP_ADMIN,
    permissions.EMPLOYEE,
  ]);

  useEffect(() => {
    setShowSpinner(true);
    dispatch(getData()).finally(() => setShowSpinner(false));
    dispatch(getDiagram());
  }, [dispatch]);

  return (
    <SpinnerContext.Provider value={{ setShowSpinner }}>
      <AuctionDashboardCard
        auctionsData={dashboardModel && dashboardModel.auctions}
        hasDepAdminEmployee={hasDepAdminEmployee}
        className={cx({ fox_spinner: showSpinner })}
      />
      <OrderDashboardCard
        ordersData={dashboardModel && dashboardModel.transportOrders}
        hasDepAdminEmployee={hasDepAdminEmployee}
        className={cx({ fox_spinner: showSpinner })}
      />
    </SpinnerContext.Provider>
  );
};

DashboardPage.propTypes = propTypes;
DashboardPage.defaultProps = defaultProps;

export default DashboardPage;
