import React from 'react';
import PropTypes from 'prop-types';

import lang from 'hh-shared/dist/language/services/languageService';
import auctionStatusesIds from 'hh-shared/dist/utils/auctionStatusesIds';
import icons from 'hh-shared/dist/consts/icons';
import Row from 'hh-shared/dist/components/layout/Row';
import PreviewButton from 'hh-shared/dist/components/commons/PreviewButton';
import FoxConfirmationButton from 'fox-react/dist/components/FoxConfirmationButton';

import styles from './ButtonContainer.module.scss';

const propTypes = {
  auctionId: PropTypes.string.isRequired,
  auctionStatus: PropTypes.string,
  onCancelConfirmed: PropTypes.func.isRequired,
  setCancelDialogIsOpen: PropTypes.func.isRequired,
  onDeleteConfirmed: PropTypes.func.isRequired,
};

const defaultProps = {
  auctionStatus: undefined,
};

const ButtonContainer = ({
  auctionId,
  auctionStatus,
  onCancelConfirmed,
  setCancelDialogIsOpen,
  onDeleteConfirmed,
}) => {
  return (
    <Row className={styles.container}>
      <PreviewButton
        label={lang.buttons.CloneAuction()}
        icon={icons.copy}
        link={`/auctions-management/add-based-on-auction/${auctionId}`}
      />

      {auctionStatus && auctionStatus.id === auctionStatusesIds.live && (
        <PreviewButton
          label={lang.labels.AuctionPage_CancelAuction()}
          icon={icons.sad}
          onCancelConfirmed={onCancelConfirmed}
          onClick={() => setCancelDialogIsOpen(true)}
          buttonColor="orange"
        />
      )}

      {auctionStatus &&
        auctionStatus.id === auctionStatusesIds.forPublication && (
          <FoxConfirmationButton
            buttonText={lang.labels.DeleteAuction()}
            onYesClick={() => onDeleteConfirmed()}
            renderButton={showDialogs => (
              <PreviewButton
                label={lang.labels.DeleteAuction()}
                icon={icons.trash}
                onClick={showDialogs}
                buttonColor="red"
              />
            )}
          />
        )}

      {auctionStatus && auctionStatus.id === auctionStatusesIds.closed && (
        <PreviewButton
          label={lang.buttons.CreateTransportOrder()}
          icon={icons.order}
          link={`/transport-orders-management/add-based-on-auction/${auctionId}`}
        />
      )}
    </Row>
  );
};

ButtonContainer.propTypes = propTypes;
ButtonContainer.defaultProps = defaultProps;

export default ButtonContainer;
