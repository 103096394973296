import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import createFoxGridContainer from 'fox-react/dist/components/FoxGrid/createFoxGridContainer';
import FoxUploadFiles from 'fox-react/dist/components/FoxUploadFiles';
import FoxGridColumn from 'fox-react/dist/components/FoxGrid/FoxGridColumn';

import gridNames from 'common/gridNames';
import columnNames from 'common/columnNames';
import InnerContentWrapper from 'hh-shared/dist/components/layout/InnerContentWrapper';
import lang from 'hh-shared/dist/language/services/languageService';

const propTypes = {
  onLoadData: PropTypes.func.isRequired,
  onImport: PropTypes.func.isRequired,
};

const defaultProps = {};

const FoxGridContainer = createFoxGridContainer(gridNames.pricesManagement);

class PricesManagementPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSpinner: false,
    };

    this.onImport = this.onImport.bind(this);
  }

  onImport(files) {
    this.setState({ showSpinner: true });
    this.props
      .onImport(files)
      .finally(() => this.setState({ showSpinner: false }));
  }

  render() {
    const { onLoadData } = this.props;
    const { showSpinner } = this.state;

    return (
      <InnerContentWrapper fullWidth>
        <div className={showSpinner ? 'fox_spinner' : ''}>
          <FoxUploadFiles
            autoUpload={false}
            multiple={false}
            onAdd={this.onImport}
          />
        </div>
        <FoxGridContainer
          onLoadData={onLoadData}
          defaultSortField={columnNames.pricesManagement.code}
        >
          <FoxGridColumn
            field={columnNames.pricesManagement.code}
            title={lang.labels.PricesManagement_Columns_Code()}
            filter="numeric"
          />
          <FoxGridColumn
            field={columnNames.pricesManagement.zoneNo}
            title={lang.labels.PricesManagement_Columns_ZoneNo()}
            filter="numeric"
          />
          <FoxGridColumn
            field={columnNames.pricesManagement.description}
            title={lang.labels.PricesManagement_Columns_Description()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.pricesManagement.shipmentMethod}
            title={lang.labels.PricesManagement_Columns_ShipmentMethod()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.pricesManagement.shippingAgentCode}
            title={lang.labels.PricesManagement_Columns_ShippingAgentCode()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.pricesManagement.shippingAgentServiceCode}
            title={lang.labels.PricesManagement_Columns_ShippingAgentServiceCode()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.pricesManagement.currencyCode}
            title={lang.labels.PricesManagement_Columns_CurrencyCode()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.pricesManagement.flatRate}
            title={lang.labels.PricesManagement_Columns_FlatRate()}
            filter="numeric"
          />
          <FoxGridColumn
            field={columnNames.pricesManagement.ratePerBaseUnit}
            title={lang.labels.PricesManagement_Columns_RatePerBaseUnit()}
            filter="numeric"
          />
          <FoxGridColumn
            field={columnNames.pricesManagement.minDistance}
            title={lang.labels.PricesManagement_Columns_MinDistance()}
            filter="numeric"
          />
          <FoxGridColumn
            field={columnNames.pricesManagement.maxDistance}
            title={lang.labels.PricesManagement_Columns_MaxDistance()}
            filter="numeric"
          />
          <FoxGridColumn
            field={columnNames.pricesManagement.minBasis}
            title={lang.labels.PricesManagement_Columns_MinBasis()}
            filter="numeric"
          />
          <FoxGridColumn
            field={columnNames.pricesManagement.maxBasis}
            title={lang.labels.PricesManagement_Columns_MaxBasis()}
            filter="numeric"
          />
          <FoxGridColumn
            field={columnNames.pricesManagement.type}
            title={lang.labels.PricesManagement_Columns_Type()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.pricesManagement.no}
            title={lang.labels.PricesManagement_Columns_No()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.pricesManagement.zipCode}
            title={lang.labels.PricesManagement_Columns_LocalizationCode()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.pricesManagement.buyerRebateGroup}
            title={lang.labels.PricesManagement_Columns_BuyerRebateGroup()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.pricesManagement.nip}
            title={lang.labels.PricesManagement_Columns_Nip()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.pricesManagement.company}
            title={lang.labels.PricesManagement_Columns_Company()}
            filter="text"
          />
        </FoxGridContainer>
      </InnerContentWrapper>
    );
  }
}

PricesManagementPage.propTypes = propTypes;
PricesManagementPage.defaultProps = defaultProps;

export default PricesManagementPage;
