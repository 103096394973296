import axios from 'config/axios';
import { downloadFile } from 'hh-shared/dist/utils/filesHelper';

const prefix = 'reports';

export default class ReportSrv {
  static downloadCurrentYearReport() {
    return axios
      .get(`${prefix}/currentYear/completed/excel`, { responseType: 'blob' })
      .then(response => {
        const type = response.headers['content-type'];
        const blob = new Blob([response.data], { type, encoding: 'UTF-8' });

        return downloadFile(blob, 'Raport.xlsx');
      });
  }
}
