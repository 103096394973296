import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  reduxForm,
  getFormSyncErrors,
  formValueSelector,
  FieldArray,
  formValues,
  change,
} from 'redux-form';
import { connect, useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import Form from 'fox-react/dist/components/Form';
import FoxInputTextField from 'fox-react/dist/reduxFormFields/FoxInputTextField';
import FoxSwitchField from 'fox-react/dist/reduxFormFields/FoxSwitchField';
import FoxUploadFiles from 'fox-react/dist/components/FoxUploadFiles';
import FoxButton from 'fox-react/dist/components/FoxButton';
import goBackAfterSuccess from 'fox-react/dist/utils/goBackAfterSuccess';
import lang from 'hh-shared/dist/language/services/languageService';
import {
  scrollToTheFirstError,
  displayCompletionToasts,
} from 'hh-shared/dist/consts/reduxFormMethods';
import icons from 'hh-shared/dist/consts/icons';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';
import InformationList from 'hh-shared/dist/components/commons/InformationList';
import FormSectionButtonRow from 'hh-shared/dist/components/layout/FormSectionButtonRow';
import permissions from 'common/permissions';
import fieldNames from 'common/fieldNames';
import formNames from 'common/formNames';
import {
  isRequiredFieldValidator,
  isNipFieldValidator,
  isRegonFieldValidator,
} from 'hh-shared/dist/utils/formValidators';
import ZipCodeAutocompleteField from 'commons/ZipCodeAutocompleteField';
import phoneMask from 'hh-shared/dist/utils/phoneMask';
import FoxMaskedInputTextField from 'fox-react/dist/reduxFormFields/FoxMaskedInputTextField';

import CompanyInformationFormDriver from './CompanyInformationFormDriver';
import CompanyInformationFormCar from './CompanyInformationFormCar';
import CompanyAttachment from './CompanyAttachment';
import { getFromGusByRegonAsync, getFromGusByNipAsync } from './actions';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  onSubmitAsNew: PropTypes.func,
  onGetFromGusByNip: PropTypes.func.isRequired,
  onGetFromGusByRegon: PropTypes.func.isRequired,
  nipHasError: PropTypes.bool.isRequired,
  regonHasError: PropTypes.bool.isRequired,
  carTypes: PropTypes.array.isRequired,
  onUploadAttachments: PropTypes.func,
  companyAttachments: PropTypes.array,
};

const defaultProps = {
  onSubmitAsNew: undefined,
  onUploadAttachments: () => {},
  companyAttachments: [],
};

function CompanyInformationForm({
  handleSubmit,
  submitting,
  onSubmitAsNew,
  onGetFromGusByNip,
  onGetFromGusByRegon,
  nipHasError,
  regonHasError,
  carTypes,
  onUploadAttachments,
  companyAttachments,
}) {
  const submitAsNew = () => {
    handleSubmit(onSubmitAsNew)();
  };

  const userPermissions = useSelector(
    state => get(state, 'auth.currentUser.permissions') || [],
  );

  const hasOneOf = per => {
    return !!intersection(per, userPermissions).length;
  };

  const canActivateCompany = hasOneOf([permissions.ACTIVATE_COMPANY]);

  const [showSpinner, setShowSpinner] = useState(false);
  const dispatch = useDispatch();

  const uploadAttachments = newCompanyAttachments => {
    setShowSpinner(true);
    onUploadAttachments(newCompanyAttachments)
      .then(() => {
        dispatch(
          change(
            formNames.companyInformationForm,
            fieldNames.companyInformationForm.companyAttachments,
            [
              ...companyAttachments,
              ...newCompanyAttachments.affectedFiles.map(x => {
                return {
                  id: x.uid,
                  name: x.name,
                  content: x.getRawFile(),
                  isNew: true,
                };
              }),
            ],
          ),
        );
      })
      .finally(() => setShowSpinner(false));
  };

  return (
    <Form handleSubmit={handleSubmit}>
      <fieldset>
        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.CompanyInformations()}
            titleIcon={icons.company}
          >
            <FormSectionButtonRow>
              <FoxInputTextField
                label={lang.labels.Nip()}
                placeholder={lang.labels.Nip()}
                name={fieldNames.companyInformationForm.nip}
                required
                validate={[isRequiredFieldValidator, isNipFieldValidator]}
              />

              <FoxButton
                type="button"
                disabled={nipHasError}
                onClick={onGetFromGusByNip}
                className="k-button k-primary"
              >
                {lang.buttons.GetFromGus()}
              </FoxButton>
            </FormSectionButtonRow>
            <FormSectionButtonRow>
              <FoxInputTextField
                label={lang.labels.Regon()}
                placeholder={lang.labels.Regon()}
                name={fieldNames.companyInformationForm.regon}
                required
                validate={[isRequiredFieldValidator, isRegonFieldValidator]}
              />

              <FoxButton
                type="button"
                disabled={regonHasError}
                onClick={onGetFromGusByRegon}
                className="k-button k-primary"
              >
                {lang.buttons.GetFromGus()}
              </FoxButton>
            </FormSectionButtonRow>

            <FoxInputTextField
              label={lang.labels.Name()}
              placeholder={lang.labels.Name()}
              name={fieldNames.companyInformationForm.name}
              required
              validate={isRequiredFieldValidator}
            />

            <FoxInputTextField
              label={lang.labels.Address()}
              placeholder={lang.labels.Address()}
              name={fieldNames.companyInformationForm.address}
              required
              validate={isRequiredFieldValidator}
            />

            <ZipCodeAutocompleteField
              required
              validate={isRequiredFieldValidator}
            />

            <FoxInputTextField
              label={lang.labels.City()}
              placeholder={lang.labels.City()}
              name={fieldNames.companyInformationForm.city}
              required
              validate={isRequiredFieldValidator}
            />

            <FoxMaskedInputTextField
              {...phoneMask}
              label={lang.labels.PhoneNumber()}
              placeholder={lang.labels.PhoneNumber()}
              name={fieldNames.companyInformationForm.phoneNumber}
              required
              validate={isRequiredFieldValidator}
            />
          </FormSectionCard>
        </SectionWrapper>

        <SectionWrapper bottomSpace>
          <FormSectionCard
            title={lang.labels.Drivers()}
            titleIcon={icons.driver}
            fullContentWidth
          >
            <FieldArray
              name="drivers"
              component={CompanyInformationFormDriver}
            />
          </FormSectionCard>
        </SectionWrapper>

        <SectionWrapper bottomSpace>
          <FormSectionCard
            title={lang.labels.Cars()}
            titleIcon={icons.vehicle}
            fullContentWidth
          >
            <FieldArray
              name="cars"
              carTypes={carTypes}
              component={CompanyInformationFormCar}
            />
          </FormSectionCard>
        </SectionWrapper>

        <SectionWrapper bottomSpace>
          <FormSectionCard
            title={lang.labels.BlockingCompany()}
            titleIcon={icons.password}
          >
            <FoxSwitchField
              labels={lang.labels.Blocked()}
              name={fieldNames.companyInformationForm.isBlocked}
            />
          </FormSectionCard>
        </SectionWrapper>

        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.BidingByHHAllowed()}
            titleIcon={icons.approval}
          >
            <FoxSwitchField
              labels={lang.labels.Blocked()}
              name={fieldNames.companyInformationForm.bidingByHHAllowed}
            />
          </FormSectionCard>
        </SectionWrapper>

        {canActivateCompany && (
          <SectionWrapper bottomSpace>
            <FormSectionCard
              title={lang.labels.IsActive()}
              titleIcon={icons.company}
            >
              <FoxSwitchField
                labels={lang.labels.IsActive()}
                name={fieldNames.companyInformationForm.isActive}
              />
            </FormSectionCard>
          </SectionWrapper>
        )}

        {canActivateCompany && (
          <SectionWrapper>
            <FormSectionCard
              title={lang.labels.Attachments()}
              titleIcon={icons.fileData}
            >
              <InformationList>
                <li>
                  {lang.labels.AddCompany_AttachmentInformation_License()}
                </li>
                <li>
                  {lang.labels.AddCompany_AttachmentInformation_Permission()}
                </li>
                <li>
                  {lang.labels.AddCompany_AttachmentInformation_CarrierInsurance()}
                </li>
                <li>
                  {lang.labels.AddCompany_AttachmentInformation_ForwarderInsurance()}
                </li>
              </InformationList>
              <div className={cx({ fox_spinner: showSpinner })}>
                <FoxUploadFiles batch={false} onAdd={uploadAttachments} />
                <FieldArray
                  name={fieldNames.companyInformationForm.companyAttachments}
                  companyAttachments={companyAttachments}
                  component={CompanyAttachment}
                />
              </div>
            </FormSectionCard>
          </SectionWrapper>
        )}
      </fieldset>
      <FoxButton primary disabled={submitting}>
        {lang.buttons.Save()}
      </FoxButton>
      {onSubmitAsNew && (
        <FoxButton
          type="button"
          primary
          disabled={submitting}
          onClick={submitAsNew}
        >
          {lang.buttons.SaveAsNew()}
        </FoxButton>
      )}
    </Form>
  );
}

CompanyInformationForm.propTypes = propTypes;
CompanyInformationForm.defaultProps = defaultProps;

const formSelector = formValueSelector(formNames.companyInformationForm);

const mapStateToProps = state => {
  const errors = getFormSyncErrors(formNames.companyInformationForm)(state);
  return {
    nipHasError: !!errors[fieldNames.companyInformationForm.nip],
    regonHasError: !!errors[fieldNames.companyInformationForm.regon],
    nipValue: formSelector(state, fieldNames.companyInformationForm.nip),
    regonValue: formSelector(state, fieldNames.companyInformationForm.regon),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetFromGusByNip: nip => dispatch(getFromGusByNipAsync(nip)),
    onGetFromGusByRegon: regon => dispatch(getFromGusByRegonAsync(regon)),
  };
};

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
  return {
    ...propsFromState,
    ...ownProps,
    ...propsFromDispatch,
    onGetFromGusByNip: () =>
      propsFromDispatch.onGetFromGusByNip(propsFromState.nipValue),
    onGetFromGusByRegon: () =>
      propsFromDispatch.onGetFromGusByRegon(propsFromState.regonValue),
  };
};

const connectedCompanyInformationForm = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(CompanyInformationForm);

const onSubmitSuccess = (result, dispatch, props) =>
  displayCompletionToasts(
    dispatch,
    !!props.isEditForm,
    lang.labels.SuccessfullyModificatedCompany(),
    lang.labels.SuccessfullyCreatedCompany(),
  );

export default reduxForm({
  form: formNames.companyInformationForm,
  enableReinitialize: true,
  onSubmitFail: scrollToTheFirstError,
  onSubmitSuccess,
})(
  formValues('companyAttachments')(
    goBackAfterSuccess(connectedCompanyInformationForm),
  ),
);
