import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import {
  isRequiredArray,
  isEmail,
  areEquals,
  isValidPasswordArray,
} from 'fox-react/dist/utils/formValidators';
import Form from 'fox-react/dist/components/Form';
import FoxInputEmailField from 'fox-react/dist/reduxFormFields/FoxInputEmailField';
import FoxInputPasswordField from 'fox-react/dist/reduxFormFields/FoxInputPasswordField';
import FoxButton from 'fox-react/dist/components/FoxButton';
import lang from 'hh-shared/dist/language/services/languageService';
import fieldNames from 'common/fieldNames';
import formNames from 'common/formNames';
import styles from './SetNewPasswordForm.module.scss';
import './SetNewPasswordFormTheming.scss';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
};

const defaultProps = {};

function SetNewPasswordForm({ handleSubmit, submitting, submitSucceeded }) {
  return (
    <div className={`${styles.form_container} reset_form_container`}>
      {submitSucceeded ? (
        <div className={styles.succeded_reset}>
          <p>{lang.labels.YourPasswordWasChanged()}</p>
          <p>
            <Link to="/login">{lang.labels.LoginPage()}</Link>
          </p>
        </div>
      ) : (
        <Form handleSubmit={handleSubmit}>
          <FoxInputEmailField
            label={lang.labels.Email()}
            placeholder={lang.labels.Email()}
            name={fieldNames.setNewPasswordForm.email}
          />

          <FoxInputPasswordField
            label={lang.labels.NewPassword()}
            placeholder={lang.labels.NewPassword()}
            name={fieldNames.setNewPasswordForm.newPassword}
          />

          <FoxInputPasswordField
            label={lang.labels.RepeatPassword()}
            placeholder={lang.labels.RepeatPassword()}
            name={fieldNames.setNewPasswordForm.repeatPassword}
          />

          <FoxButton primary disabled={submitting}>
            {lang.buttons.SetNewPassword()}
          </FoxButton>
        </Form>
      )}
    </div>
  );
}

SetNewPasswordForm.propTypes = propTypes;
SetNewPasswordForm.defaultProps = defaultProps;

const validate = values => {
  const errors = {};

  isRequiredArray(
    errors,
    values,
    [
      fieldNames.setNewPasswordForm.email,
      fieldNames.setNewPasswordForm.newPassword,
      fieldNames.setNewPasswordForm.repeatPassword,
    ],
    lang.validationMessages.FieldRequired(),
  );

  isEmail(
    errors,
    values,
    fieldNames.setNewPasswordForm.email,
    lang.validationMessages.FieldMustBeValidEmail(),
  );

  areEquals(
    errors,
    values,
    [
      fieldNames.setNewPasswordForm.newPassword,
      fieldNames.setNewPasswordForm.repeatPassword,
    ],
    lang.validationMessages.PasswordsNotEquals(),
  );

  isValidPasswordArray(
    errors,
    values,
    [
      fieldNames.setNewPasswordForm.newPassword,
      fieldNames.setNewPasswordForm.repeatPassword,
    ],
    lang.validationMessages.NotValidPassword(),
  );

  return errors;
};

export default reduxForm({
  form: formNames.setNewPasswordForm,
  validate,
})(SetNewPasswordForm);
