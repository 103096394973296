import actionTypes from './actionTypes';

const initialState = {
  defaultPrices: [],
};

export default function defaultPricesReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.STORE_DEFAULT_PRICES:
      return action.data;
    default:
      return state;
  }
}
