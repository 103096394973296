import keyBy from 'lodash/keyBy';
import auctionActionTypes from './actionTypes';

const initialState = {
  isLoading: true,
  auctionMetric: undefined,
  bids: [],
  participantsUsers: {},
  participantCompanies: {},
  invitedCompanies: [],
  loadingParticipants: true,
  isAuctionStarted: undefined,
};

const actionReducer = (state = initialState, action) => {
  switch (action.type) {
    case auctionActionTypes.ACTION_SET_ACTION_DATA:
      return {
        ...state,
        isLoading: false,
        auctionMetric: action.payload.auctionMetric,
        bids: action.payload.bids,
        participantsUsers: keyBy(
          action.payload.participantsUsers,
          'instanceId',
        ),
        participantCompanies: keyBy(
          action.payload.participantCompanies,
          'instanceId',
        ),
        invitedCompanies: action.payload.invitedCompanies,
      };
    case auctionActionTypes.SET_LOADING_USER_PARTICIPANT:
      return {
        ...state,
        participantsUsers: {
          ...state.participantsUsers,
          [action.payload]: { isLoading: true },
        },
      };
    case auctionActionTypes.SET_USER_PARTICIPANT_DATA:
      return {
        ...state,
        participantsUsers: {
          ...state.participantsUsers,
          [action.payload.instanceId]: { ...action.payload },
        },
      };
    case auctionActionTypes.SET_LOADING_COMPANY_PARTICIPANT:
      return {
        ...state,
        participantCompanies: {
          ...state.participantCompanies,
          [action.payload]: { isLoading: true },
        },
      };
    case auctionActionTypes.SET_COMPANY_PARTICIPANT_DATA:
      return {
        ...state,
        participantCompanies: {
          ...state.participantCompanies,
          [action.payload.instanceId]: { ...action.payload },
        },
      };
    case auctionActionTypes.ACTION_ADD_ACTION_BID:
      const newAuctionBids = [action.payload, ...state.bids];
      return {
        ...state,
        bids: newAuctionBids,
        auctionMetric: {
          ...state.auctionMetric,
          currentPrice: newAuctionBids[0].price,
        },
      };
    case auctionActionTypes.AUCTION_SET_BIDS_PARTICIPANTS:
      return {
        ...state,
        loadingParticipants: false,
        participants: action.payload,
      };
    case auctionActionTypes.AUCTION_CHANGE_IS_OBSERVED:
      return {
        ...state,
        auctionMetric: {
          ...state.auctionMetric,
          isObserved: action.payload,
        },
      };
    case auctionActionTypes.AUCTION_SET_AUCTION_IS_STARTED:
      return {
        ...state,
        isAuctionStarted: action.payload,
      };
    case auctionActionTypes.CLEAR_AUCTION_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default actionReducer;
