import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import icons from 'hh-shared/dist/consts/icons';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import CompanyInformationForm from 'company-management/CompanyInformationForm';
import FoxDropDownList from 'fox-react/dist/components/FoxDropDownList';
import CompanyManagementSrv from './CompanyManagementSrv';
import { updateAsync, uploadAttachments } from './actions';
import CompanyManagementPagePreview from './CompanyManagementPagePreview';
import CarsManagementSrv from '../cars-management/CarsManagementSrv';

const propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

function CompanyManagementEditPage({ match, history }) {
  const [versions, setVersions] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);
  const [carTypes, setCarTypes] = useState([]);

  useEffect(() => {
    setShowSpinner(true);
    CarsManagementSrv.getCarTypesAsSelectItems().then(res => setCarTypes(res));
    CompanyManagementSrv.get(match.params.instanceId)
      .then(results => {
        const newVersions = sortBy(results.versions, x => x.versionNumber);
        setVersions(newVersions);
        setInitialValues({
          ...last(newVersions),
          drivers: results.drivers,
          cars: results.cars,
          isBlocked: results.isBlocked,
          bidingByHHAllowed: results.bidingByHHAllowed,
          isActive: results.isActive,
          companyAttachments: results.companyAttachments,
        });
      })
      .finally(() => setShowSpinner(false));
  }, [match]);

  const onChangeVersion = e => {
    const newVersionNumber = e.target.value;
    setInitialValues(versions.find(x => x.versionNumber === newVersionNumber));
  };

  const submitHandle = model => {
    return updateAsync({
      ...model,
      id: initialValues.id,
      instanceId: match.params.instanceId,
    });
  };

  const onSubmitAsNew = model => {
    return updateAsync({
      ...model,
      id: undefined,
      instanceId: match.params.instanceId,
    });
  };

  const versionNumbers = versions.map(x => x.versionNumber);

  return (
    <>
      <BreadcrumbsContainer
        links={[
          {
            routePath: '/',
            label: lang.labels.HomePage(),
          },
          {
            routePath: '/companies-management',
            label: lang.labels.Company(),
          },
          {
            routePath: '/companies-management/edit',
            label: lang.labels.EditingCompany(),
          },
        ]}
      />
      <FormPreviewSectionContainer>
        <FormCardWrapper>
          <SectionWrapper bottomSpace>
            <FormSectionCard
              title={lang.labels.Version()}
              titleIcon={icons.editedDocument}
            >
              <FoxDropDownList
                items={versionNumbers}
                value={initialValues.versionNumber}
                onChange={onChangeVersion}
                dataItemKey=""
                textField=""
              />
            </FormSectionCard>
          </SectionWrapper>

          <div className={showSpinner ? 'fox_spinner' : undefined}>
            <CompanyInformationForm
              onSubmit={submitHandle}
              onSubmitAsNew={onSubmitAsNew}
              initialValues={initialValues}
              carTypes={carTypes}
              onUploadAttachments={uploadAttachments}
              history={history}
              isEditForm
            />
          </div>
        </FormCardWrapper>
        <CompanyManagementPagePreview editForm />
      </FormPreviewSectionContainer>
    </>
  );
}

CompanyManagementEditPage.propTypes = propTypes;
CompanyManagementEditPage.defaultProps = defaultProps;

export default CompanyManagementEditPage;
