import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import FoxInputTextField from 'fox-react/dist/reduxFormFields/FoxInputTextField';
import FoxButton from 'fox-react/dist/components/FoxButton';
import FormSection from 'fox-react/dist/components/FormSection';
import { isRequiredFieldValidator } from 'hh-shared/dist/utils/formValidators';
import FontAwesomeIcon from 'hh-shared/dist/components/commons/FontAwesomeIcon/FontAwesomeIcon';
import icons from 'hh-shared/dist/components/commons/FontAwesomeIcon/icons';

import styles from './StreetList.module.scss';

const propTypes = {
  fields: PropTypes.object.isRequired,
};

const defaultProps = {};

function StreetList({ fields }) {
  const handleAddNewStreet = useCallback(() => {
    fields.push();
  }, [fields]);

  const handleRemoveStreet = useCallback(
    fieldIndex => () => {
      fields.remove(fieldIndex);
    },
    [fields],
  );

  const labels = useMemo(
    () =>
      fields.length
        ? {
            sectionTitle: 'Ulice:',
            addButton: 'Dodaj następną ulicę',
          }
        : {
            sectionTitle: undefined,
            addButton: 'Dodaj ulicę',
          },
    [fields],
  );

  return (
    <FormSection label={labels.sectionTitle}>
      {fields.map((member, index) => (
        <div className={styles.rowWrapper}>
          <FoxInputTextField
            key={index}
            name={member}
            validate={[isRequiredFieldValidator]}
            // TODO: trzeba dostarczyć do poniższego propa, informacje o tym, czy field ma błąd,
            // withExtraErrorSpace={fieldMaBłąd}
          />
          <FontAwesomeIcon
            icon={icons.trash}
            onClick={handleRemoveStreet(index)}
            className={styles.deleteIconWrapper}
            title="Usuń ulicę"
            tabIndex={0}
          />
        </div>
      ))}
      <FoxButton icon="add" type="button" primary onClick={handleAddNewStreet}>
        {labels.addButton}
      </FoxButton>
    </FormSection>
  );
}

StreetList.propTypes = propTypes;
StreetList.defaultProps = defaultProps;

export default memo(StreetList);
