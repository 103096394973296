import axios from 'config/axios';

const prefix = 'companiesManagement';

export default class CompanyManagementSrv {
  static getForGrid(dataState) {
    return axios
      .post(`${prefix}/getForGrid`, dataState)
      .then(result => result.data);
  }

  static get(instanceId) {
    return axios.get(`${prefix}/${instanceId}`).then(result => result.data);
  }

  static add(model) {
    return axios.post(prefix, model);
  }

  static update(model) {
    return axios.put(prefix, model);
  }

  static uploadAttachments(model) {
    return axios.post(`${prefix}/uploadAttachments`, model, {
      headers: { 'content-type': 'multipart/form-data' },
    });
  }
}
