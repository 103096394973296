import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import CurrencyInformationForm from './CurrencyInformationForm';
import CurrenciesManagementSrv from './CurrenciesManagementSrv';
import CurrenciesManagementPagePreview from './CurrenciesManagementPagePreview';

const propTypes = {
  match: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

class CurrenciesManagementEditPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { initialValues: {}, showSpinner: false };
  }

  componentDidMount() {
    this.setState({ showSpinner: true });

    CurrenciesManagementSrv.get(this.props.match.params.id)
      .then(results => {
        this.setState({ initialValues: results });
      })
      .finally(() => this.setState({ showSpinner: false }));
  }

  render() {
    const { onSubmit, history } = this.props;
    const { initialValues, showSpinner } = this.state;

    return (
      <>
        <BreadcrumbsContainer
          links={[
            {
              routePath: '/',
              label: lang.labels.HomePage(),
            },
            {
              routePath: '/currencies-management',
              label: lang.labels.Currencies(),
            },
            {
              routePath: '/currencies-management/edit',
              label: lang.labels.EditingCurrency(),
            },
          ]}
        />
        <FormPreviewSectionContainer>
          <FormCardWrapper>
            <div className={showSpinner ? 'fox_spinner' : undefined}>
              <CurrencyInformationForm
                onSubmit={onSubmit}
                initialValues={initialValues}
                history={history}
                isEditForm
              />
            </div>
          </FormCardWrapper>
          <CurrenciesManagementPagePreview />
        </FormPreviewSectionContainer>
      </>
    );
  }
}

CurrenciesManagementEditPage.propTypes = propTypes;
CurrenciesManagementEditPage.defaultProps = defaultProps;

export default CurrenciesManagementEditPage;
