import axios from 'config/axios';

export default class DictionarySrv {
  static getStaticDictionaries() {
    return axios
      .get('dictionary/getStaticDictionaries')
      .then(result => result.data);
  }

  static getBigStaticDictionaries() {
    return axios
      .get('dictionary/getBigStaticDictionaries')
      .then(result => result.data);
  }
}
