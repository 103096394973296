import { connect } from 'react-redux';
import AuctionsManagementPagePreview from './AuctionsManagementPagePreview';

const mapStateToProps = state => {
  return {
    loadingMethods: state.dictionary.static.loadingMethods,
    unloadingMethods: state.dictionary.static.unloadingMethods,
    auctionTypes: state.dictionary.static.auctionTypes,
  };
};

const AuctionsManagementPagePreviewContainer = connect(
  mapStateToProps,
  null,
)(AuctionsManagementPagePreview);

export default AuctionsManagementPagePreviewContainer;
