import { connect } from 'react-redux';
import { getTransportOrderPreviewLink } from 'common/links';
import TransportOrdersManagementAddBasedOnAuctionPage from './TransportOrdersManagementAddBasedOnAuctionPage';
import { addAsync } from './actions';

const mapDispatchToProps = (_dispatch, ownProps) => {
  return {
    onSubmit: model =>
      addAsync({
        ...model,
        auctionId: ownProps.match.params.auctionId,
      }).then(res =>
        ownProps.history.push(getTransportOrderPreviewLink(res.data)),
      ),
  };
};

const TransportOrdersManagementAddBasedOnAuctionPageContainer = connect(
  undefined,
  mapDispatchToProps,
)(TransportOrdersManagementAddBasedOnAuctionPage);

export default TransportOrdersManagementAddBasedOnAuctionPageContainer;
