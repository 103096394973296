import { connect } from 'react-redux';
import DefaultPricesManagementPage from './DefaultPricesManagementPage';
import { getForGrid } from './actions';

const mapDispatchToProps = dispatch => {
  return {
    onLoadData: dataState => dispatch(getForGrid(dataState)),
  };
};

const DefaultPricesManagementPageContainer = connect(
  undefined,
  mapDispatchToProps,
)(DefaultPricesManagementPage);

export default DefaultPricesManagementPageContainer;
