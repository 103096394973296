import { connect } from 'react-redux';
import OnlyMyFacilitySwitch from './OnlyMyFacilitySwitch';
import userPreferencesActionTypes from './actionTypes';

const mapStateToProps = state => {
  return {
    onlyMyFacility: state.userPreferences.onlyMyFacility,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleOnlyMyFacility: newOnlyMyFacility =>
      dispatch({
        type: userPreferencesActionTypes.USER_PREFERENCES_SET_VALUE,
        payload: {
          onlyMyFacility: newOnlyMyFacility,
        },
      }),
  };
};

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
  return {
    toggleOnlyMyFacility: () =>
      propsFromDispatch.toggleOnlyMyFacility(!propsFromState.onlyMyFacility),
    ...propsFromState,
    ...ownProps,
  };
};

const OnlyMyFacilitySwitchContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(OnlyMyFacilitySwitch);

export default OnlyMyFacilitySwitchContainer;
