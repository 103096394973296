"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var htmlTemplateIds = {
  ResetPassword: '9eb6434d-e087-4441-b0fd-3ee1d6be9984',
  SetNewPassword: 'ee4185ac-c0d9-4b45-a5c6-34d4bb9ba40b',
  AuctionTookToRealization: 'ee4115ac-a0d9-4b45-a555-24d4bb9ba43a',
  AuctionBidOfferSet: 'ee4185ac-c0d9-4b45-a511-34d4bb9ba40b',
  AuctionPublished: 'ee4185ac-c0d9-4b45-a522-34d4bb9ba40b',
  AuctionBiddedByAnotherCompany: 'ee4185ac-c0d9-4b45-a533-34d4bb9ba40b',
  AuctionCloseToClose: 'ee4185ac-c0d9-4b45-a544-34d4bb9ba40b',
  AuctionCancelled: 'ee4185ac-c0d9-4b45-a555-24d4bb9ba40b',
  AuctionClosed: 'ee4185ac-c0d9-4b45-a555-34d4bb9ba40b',
  TransportOrderChangeOnAcceptForImplementation: '14ab65a9-1dec-4318-a5f7-d8bb6f7c5975',
  TransportOrderCreated: 'ee4185ac-c0d9-4b45-a531-24d4bb9ba43b',
  TransportOrderAdditionalRequirements: '35ab58d3-70ae-4faf-a058-2a20659e3e65',
  TransportOrder: 'e240e98a-fc1e-476e-8ea2-acef5bd1977e',
  UserAccountBanned: 'de0b60dd-c923-44cb-b2de-4564023d5daa',
  TransportOrderCanceled: 'afb17765-9b6a-4235-a67e-8bb36f817cb9',
  TransportOrderRealized: '296e60ba-ccee-479d-bbbc-c30d1e0a83e2',
  TransportOrderLoaded: '914efcff-6718-4a18-bc8c-87d50386ab5f',
  InviteToPlatform: '1e07aa03-db06-440d-8d3a-cf2606d70fea',
  NewCompanyRequest: '78ddbdbb-c5e1-43d0-a558-90fa9bfedcc0',
  CompanyActivated: '5b9e81af-9e2d-4672-9084-9f2750885c44',
  CompanyInformation: '21a1ed19-80fd-42a2-ae8a-cfd7e118b0ab'
};
var _default = htmlTemplateIds;
exports.default = _default;