import axios from 'config/axios';
import store from 'store/store';
import get from 'lodash/get';

const prefix = 'auctionsManagement';

export default class AuctionsManagementSrv {
  static getForGrid(dataState) {
    return axios
      .post(`${prefix}/getForGrid`, {
        ...dataState,
        userPreferences: get(store.getState(), 'userPreferences'),
      })
      .then(result => result.data);
  }

  static get(id) {
    return axios.get(`${prefix}/${id}`).then(result => result.data);
  }

  static addAndPublish(model) {
    return axios.post(`${prefix}/addAndPublish`, model);
  }

  static add(model) {
    return axios.post(`${prefix}/add`, model);
  }

  static update(model) {
    return axios.put(prefix, model);
  }

  static updateAndPublish(model) {
    return axios.put(`${prefix}/updateAndPublish`, model);
  }

  static delete(id) {
    return axios.delete(`${prefix}/${id}`);
  }

  static cancel(id) {
    return axios.put(`${prefix}/${id}/cancel`);
  }

  static getFacilitiesAsSelectItems() {
    return axios
      .get(`${prefix}/getFacilitiesAsSelectItems`)
      .then(result => result.data);
  }

  static getAuctionSubjectsAsSelectItems() {
    return axios
      .get(`${prefix}/getAuctionSubjectsAsSelectItems`)
      .then(result => result.data);
  }

  static getPrincipalPersonsAsSelectItems() {
    return axios
      .get(`${prefix}/getPrincipalPersonsAsSelectItems`)
      .then(result => result.data);
  }

  static getAddressesAsSelectItem() {
    return axios
      .get(`${prefix}/getAddressesAsSelectItem`)
      .then(result => result.data);
  }

  static getCurrenciesAsSelectItem() {
    return axios
      .get(`${prefix}/getCurrenciesAsSelectItem`)
      .then(result => result.data);
  }

  static getDefaultPrices() {
    return axios.get(`${prefix}/getDefaultPrices`).then(result => result.data);
  }

  static isObservedChange(id, isObserved) {
    return axios.put(`${prefix}/isObservedChange`, {
      auctionId: id,
      isObserved,
    });
  }

  static getForAddBasedOnAuction(auctionId) {
    return axios
      .get(`${prefix}/getForAddBasedOnAuction/${auctionId}`)
      .then(result => result.data);
  }
}
