"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertArrayToObjOfBools = convertArrayToObjOfBools;

function convertArrayToObjOfBools(array) {
  var ret = {};
  array.forEach(function (val) {
    ret[val] = true;
  });
  return ret;
}