import React from 'react';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';

import formNames from 'common/formNames';
import previewGetters from 'common/previewGetters';
import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import numberFormating from 'hh-shared/dist/consts/numberFormating';
import FormPreviewPane from 'hh-shared/dist/components/layout/FormPreview/FormPreviewPaneContainer';
import FormPreviewSection from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSection';
import FormPreviewItem from 'hh-shared/dist/components/layout/FormPreview/FormPreviewItem';

const propTypes = {};

const defaultProps = {};

const RoutesManagementPagePreview = () => {
  const { insertThousandsSeparator } = numberFormating;
  const { getCurrentAddress } = previewGetters;
  const formValues = useSelector(
    state => getFormValues(formNames.routeInformationForm)(state) || {},
  );

  return (
    <FormPreviewPane>
      <FormPreviewSection
        title={lang.labels.RouteInformations()}
        icon={icons.route}
      >
        <FormPreviewItem
          label={lang.labels.ZipCodeFrom()}
          value={getCurrentAddress(
            formValues,
            'isNewZipCodeFrom',
            'newZipCodeFrom',
            'zipCodeFrom',
          )}
          isNew={formValues.isNewZipCodeFrom}
        />
        <FormPreviewItem
          label={lang.labels.ZipCodeTo()}
          value={getCurrentAddress(
            formValues,
            'isNewZipCodeTo',
            'newZipCodeTo',
            'zipCodeTo',
          )}
          isNew={formValues.isNewZipCodeTo}
        />
        <FormPreviewItem
          label={lang.labels.Distance()}
          value={
            formValues.distance
              ? `${insertThousandsSeparator(formValues.distance)} km`
              : undefined
          }
        />
      </FormPreviewSection>
    </FormPreviewPane>
  );
};

RoutesManagementPagePreview.propTypes = propTypes;
RoutesManagementPagePreview.defaultProps = defaultProps;

export default RoutesManagementPagePreview;
