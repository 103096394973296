import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import createFoxGridContainer from 'fox-react/dist/components/FoxGrid/createFoxGridContainer';
import FoxGridColumn from 'fox-react/dist/components/FoxGrid/FoxGridColumn';
import FoxButton from 'fox-react/dist/components/FoxButton';

import gridNames from 'common/gridNames';
import columnNames from 'common/columnNames';
import InnerContentWrapper from 'hh-shared/dist/components/layout/InnerContentWrapper';
import lang from 'hh-shared/dist/language/services/languageService';
import DateTimeColumnCell from 'hh-shared/dist/components/grid/DateTimeColumnCell';
import NumericCell from 'hh-shared/dist/components/grid/NumericCell';
import AddressCell from 'hh-shared/dist/components/grid/AddressCell';
import OrderStatusClickableCell from 'commons/OrderStatusClickableCell';
import DropdownLinkCell from 'hh-shared/dist/components/commons/Dropdown/DropdownLinkCell';
import { downloadOrderPDF } from 'hh-shared/dist/utils/downloadMethods';

import OnlyMyFacilitySwitchContainer from 'user-preferences/OnlyMyFacilitySwitchContainer';
import { generateButtons } from './functions';
import TransportOrdersManagementSrv from './TransportOrdersManagementSrv';

const propTypes = {
  onLoadData: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  userHasFacilityDefined: PropTypes.bool.isRequired,
  dataState: PropTypes.object.isRequired,
};

const defaultProps = {};

const FoxGridContainer = createFoxGridContainer(
  gridNames.transportOrdersManagement,
);

class TransportOrdersManagementPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false,
    };
    this.downloadPdf = this.downloadPdf.bind(this);
    this.refresGrid = this.refresGrid.bind(this);
  }

  downloadPdf(id, orderNumber) {
    this.setState({ showSpinner: true });

    TransportOrdersManagementSrv.getCompletedTransportOrderPdf(id)
      .then(orderPDF => downloadOrderPDF(orderPDF, orderNumber))
      .finally(() => this.setState({ showSpinner: false }));
  }

  refresGrid() {
    const { onLoadData, dataState } = this.props;
    onLoadData(dataState);
  }

  render() {
    const { onLoadData, match, userHasFacilityDefined } = this.props;

    return (
      <InnerContentWrapper fullWidth>
        <div className={this.state.showSpinner ? 'fox_spinner' : undefined}>
          <FoxGridContainer
            onLoadData={onLoadData}
            loadOnStart={!userHasFacilityDefined} // its triggered via OnlyMyFacilitySwitchContainer by callback
            defaultSortField={columnNames.transportOrdersManagement.status}
            renderButtons={
              <Link to={`${match.url}/add`}>
                <FoxButton icon="add" primary>
                  {lang.buttons.Add()}
                </FoxButton>
              </Link>
            }
            renderRightButtons={
              userHasFacilityDefined && (
                <OnlyMyFacilitySwitchContainer
                  onChangeCallback={this.refresGrid}
                />
              )
            }
          >
            <FoxGridColumn
              field={columnNames.transportOrdersManagement.number}
              title={lang.labels.TransportOrdersManagement_Columns_Number()}
              filter="text"
              locked
              cell={({ dataItem, className }) => (
                <DropdownLinkCell
                  linkTitle={lang.labels.Preview()}
                  text={dataItem.number}
                  link={`${match.url}/preview/${dataItem.id}`}
                  className={className}
                  buttons={generateButtons(dataItem, match, this.downloadPdf)}
                />
              )}
            />
            <FoxGridColumn
              field={columnNames.transportOrdersManagement.status}
              title={lang.labels.TransportOrdersManagement_Columns_Status()}
              filter="text"
              cell={({ dataItem }) => (
                <OrderStatusClickableCell
                  text={dataItem.status}
                  statusId={dataItem.statusId}
                />
              )}
            />
            <FoxGridColumn
              field={columnNames.transportOrdersManagement.company}
              title={lang.labels.TransportOrdersManagement_Columns_Company()}
              filter="text"
            />
            <FoxGridColumn
              field={columnNames.transportOrdersManagement.value}
              title={lang.labels.TransportOrdersManagement_Columns_Value()}
              filter="numeric"
              cell={({ dataItem }) => <NumericCell value={dataItem.value} />}
            />
            <FoxGridColumn
              field={columnNames.transportOrdersManagement.loadingTime}
              title={lang.labels.TransportOrdersManagement_Columns_LoadingTime()}
              filter="date"
              filterable={false}
              cell={DateTimeColumnCell}
            />
            <FoxGridColumn
              field={columnNames.transportOrdersManagement.facility}
              title={lang.labels.TransportOrdersManagement_Columns_Facility()}
              filter="text"
            />
            <FoxGridColumn
              field={columnNames.transportOrdersManagement.loadingAddress}
              title={lang.labels.TransportOrdersManagement_Columns_LoadingAddress()}
              filter="text"
              cell={AddressCell}
            />
            <FoxGridColumn
              field={columnNames.transportOrdersManagement.unloadingTime}
              title={lang.labels.TransportOrdersManagement_Columns_UnloadingTime()}
              filter="date"
              filterable={false}
              cell={DateTimeColumnCell}
            />
            <FoxGridColumn
              field={columnNames.transportOrdersManagement.unloadingAddress}
              title={lang.labels.TransportOrdersManagement_Columns_UnloadingAddress()}
              filter="text"
              cell={AddressCell}
            />
            <FoxGridColumn
              field={columnNames.transportOrdersManagement.driver}
              title={lang.labels.TransportOrdersManagement_Columns_Driver()}
              filter="text"
            />
            <FoxGridColumn
              field={
                columnNames.transportOrdersManagement.authorizedPickupPerson
              }
              title={lang.labels.TransportOrdersManagement_Columns_AuthorizedPickupPerson()}
              filter="text"
              hidden
            />
            <FoxGridColumn
              field={
                columnNames.transportOrdersManagement
                  .authorizedPickupPersonPhoneNumber
              }
              title={lang.labels.TransportOrdersManagement_Columns_AuthorizedPickupPersonPhoneNumber()}
              filter="text"
              hidden
            />
            <FoxGridColumn
              field={columnNames.transportOrdersManagement.distance}
              title={lang.labels.TransportOrdersManagement_Columns_Distance()}
              filter="numeric"
              hidden
            />
            <FoxGridColumn
              field={columnNames.transportOrdersManagement.car}
              title={lang.labels.TransportOrdersManagement_Columns_Car()}
              filter="text"
              hidden
            />
            <FoxGridColumn
              field={columnNames.transportOrdersManagement.capacity}
              title={lang.labels.TransportOrdersManagement_Columns_Capacity()}
              filter="numeric"
              hidden
            />
            <FoxGridColumn
              field={columnNames.transportOrdersManagement.loadingMethod}
              title={lang.labels.TransportOrdersManagement_Columns_LoadingMethod()}
              filter="text"
              hidden
            />
            <FoxGridColumn
              field={columnNames.transportOrdersManagement.unloadingMethod}
              title={lang.labels.TransportOrdersManagement_Columns_UnloadingMethod()}
              filter="text"
              hidden
            />
            <FoxGridColumn
              field={columnNames.transportOrdersManagement.transportCode}
              title={lang.labels.TransportCode()}
            />
            <FoxGridColumn
              field={
                columnNames.transportOrdersManagement.additionalRequirements
              }
              title={lang.labels.TransportOrdersManagement_Columns_AdditionalRequirements()}
              filter="text"
              hidden
            />
            <FoxGridColumn
              field={columnNames.transportOrdersManagement.auctionNumber}
              title={lang.labels.TransportOrdersManagement_Columns_AuctionNumber()}
              filter="text"
              cell={({ dataItem, className }) => (
                <DropdownLinkCell
                  linkTitle={lang.labels.Auction()}
                  text={dataItem.auctionNumber}
                  link={`/auctions-management/details/${dataItem.auctionId}`}
                  className={className}
                />
              )}
            />
            <FoxGridColumn
              field={columnNames.transportOrdersManagement.exportNavNumber}
              title={lang.labels.ExportNav()}
              filter="text"
            />
          </FoxGridContainer>
        </div>
      </InnerContentWrapper>
    );
  }
}

TransportOrdersManagementPage.propTypes = propTypes;
TransportOrdersManagementPage.defaultProps = defaultProps;

export default TransportOrdersManagementPage;
