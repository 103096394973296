import React from 'react';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import ZipCodeForm from './ZipCodeForm';
import ZipCodesManagementSrv from './ZipCodesManagementSrv';
import ZipCodesManagementPreviewPage from './ZipCodesManagementPreviewPage';

const propTypes = {
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

function ZipCodesManagementAddPage({ history }) {
  const onSubmit = model => ZipCodesManagementSrv.add(model);

  return (
    <>
      <BreadcrumbsContainer
        links={[
          {
            routePath: '/',
            label: lang.labels.HomePage(), // lang.labels.MainPage(),
          },
          {
            routePath: '/zip-codes-management',
            label: lang.labels.ZipCodes(),
          },
          {
            routePath: '/zip-codes-management/add',
            label: lang.labels.AddingZipCode(),
          },
        ]}
      />
      <FormPreviewSectionContainer>
        <FormCardWrapper>
          <ZipCodeForm onSubmit={onSubmit} history={history} />
        </FormCardWrapper>
        <ZipCodesManagementPreviewPage />
      </FormPreviewSectionContainer>
    </>
  );
}

ZipCodesManagementAddPage.propTypes = propTypes;
ZipCodesManagementAddPage.defaultProps = defaultProps;

export default ZipCodesManagementAddPage;
