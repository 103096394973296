import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import createFoxGridContainer from 'fox-react/dist/components/FoxGrid/createFoxGridContainer';
import FoxButton from 'fox-react/dist/components/FoxButton';
import FoxGridColumn from 'fox-react/dist/components/FoxGrid/FoxGridColumn';

import lang from 'hh-shared/dist/language/services/languageService';
import InnerContentWrapper from 'hh-shared/dist/components/layout/InnerContentWrapper';

import gridNames from 'common/gridNames';
import columnNames from 'common/columnNames';
import actionsColumnGridDefaults from 'hh-shared/dist/consts/actionsColumnGridDefaults';
import PhoneNumberCell from 'hh-shared/dist/components/grid/PhoneNumberCell';

import SendInvitationDialog from './SendInvitationDialog';

const propTypes = {
  results: PropTypes.shape({
    items: PropTypes.array,
    totalCount: PropTypes.number,
  }),
  onLoadData: PropTypes.func.isRequired,
  onSendInvitation: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const defaultProps = {
  results: {
    items: [],
    totalCount: 0,
  },
};

const FoxGridContainer = createFoxGridContainer(gridNames.usersManagement);

class UsersManagementPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sendInvitationDialogIsOpen: false,
    };

    this.showInvitationDialog = this.showInvitationDialog.bind(this);
    this.closeInvitationDialog = this.closeInvitationDialog.bind(this);
    this.sendInvitation = this.sendInvitation.bind(this);
  }

  showInvitationDialog() {
    this.setState({ sendInvitationDialogIsOpen: true });
  }

  closeInvitationDialog() {
    this.setState({ sendInvitationDialogIsOpen: false });
  }

  sendInvitation(values) {
    const { onSendInvitation } = this.props;
    return onSendInvitation(values).then(() => this.closeInvitationDialog());
  }

  render() {
    const { onLoadData, match } = this.props;
    const { sendInvitationDialogIsOpen } = this.state;

    return (
      <InnerContentWrapper fullWidth>
        <FoxGridContainer
          onLoadData={onLoadData}
          defaultSortField={columnNames.usersManagement.login}
          renderButtons={
            <>
              <Link to={`${match.url}/add`}>
                <FoxButton icon="add" primary>
                  {lang.buttons.Add()}
                </FoxButton>
              </Link>
              <FoxButton primary onClick={this.showInvitationDialog}>
                {lang.buttons.SendInvitationToPlatform()}
              </FoxButton>
            </>
          }
        >
          <FoxGridColumn
            {...actionsColumnGridDefaults}
            cell={({ dataItem }) => (
              <td>
                <Link to={`${match.url}/edit/${dataItem.instanceId}`}>
                  <FoxButton icon="edit">{lang.buttons.Edit()}</FoxButton>
                </Link>
              </td>
            )}
          />
          <FoxGridColumn
            field={columnNames.usersManagement.login}
            title={lang.labels.UsersManagement_Columns_Login()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.usersManagement.email}
            title={lang.labels.UsersManagement_Columns_Email()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.usersManagement.firstName}
            title={lang.labels.UsersManagement_Columns_FirstName()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.usersManagement.lastName}
            title={lang.labels.UsersManagement_Columns_LastName()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.usersManagement.phoneNumber}
            title={lang.labels.UsersManagement_Columns_PhoneNumber()}
            filter="text"
            cell={({ dataItem }) => (
              <PhoneNumberCell phoneNumber={dataItem.phoneNumber} />
            )}
          />
          <FoxGridColumn
            field={columnNames.usersManagement.facility}
            title={lang.labels.UsersManagement_Columns_Facility()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.usersManagement.company}
            title={lang.labels.UsersManagement_Columns_Company()}
            filter="text"
          />
        </FoxGridContainer>

        <SendInvitationDialog
          isOpen={sendInvitationDialogIsOpen}
          onSendInvitationConfirmed={this.sendInvitation}
          onCloseClick={this.closeInvitationDialog}
        />
      </InnerContentWrapper>
    );
  }
}

UsersManagementPage.propTypes = propTypes;
UsersManagementPage.defaultProps = defaultProps;

export default UsersManagementPage;
