"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _kendoReactDropdowns = require("@progress/kendo-react-dropdowns");

var _kendoReactIntl = require("@progress/kendo-react-intl");

var _pl = _interopRequireDefault(require("./pl.json"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  label: _propTypes.default.string,
  value: _propTypes.default.any,
  onChange: _propTypes.default.func.isRequired,
  error: _propTypes.default.string,
  invalid: _propTypes.default.bool,
  items: _propTypes.default.array.isRequired,
  dataItemKey: _propTypes.default.string,
  textField: _propTypes.default.string,
  name: _propTypes.default.string
};
var defaultProps = {
  label: '',
  value: undefined,
  error: undefined,
  invalid: false,
  dataItemKey: 'id',
  textField: 'name',
  name: undefined
};

function FoxDropDownList(_ref) {
  var label = _ref.label,
      value = _ref.value,
      onChange = _ref.onChange,
      error = _ref.error,
      invalid = _ref.invalid,
      items = _ref.items,
      dataItemKey = _ref.dataItemKey,
      textField = _ref.textField,
      name = _ref.name;
  (0, _kendoReactIntl.loadMessages)(_pl.default, 'pl-PL');
  return _react.default.createElement(_kendoReactIntl.LocalizationProvider, {
    language: "pl-PL"
  }, _react.default.createElement(_kendoReactIntl.IntlProvider, {
    locale: "pl"
  }, _react.default.createElement("div", {
    "data-name": name
  }, _react.default.createElement(_kendoReactDropdowns.DropDownList, {
    label: label,
    onChange: onChange,
    validationMessage: error,
    valid: !invalid,
    data: items,
    dataItemKey: dataItemKey,
    textField: textField,
    value: value
  }))));
}

FoxDropDownList.propTypes = propTypes;
FoxDropDownList.defaultProps = defaultProps;
var _default = FoxDropDownList;
exports.default = _default;