import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import get from 'lodash/get';

import formNames from 'common/formNames';
import previewGetters from 'common/previewGetters';
import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import FormPreviewPane from 'hh-shared/dist/components/layout/FormPreview/FormPreviewPaneContainer';
import FormPreviewSection from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSection';
import FormPreviewItem from 'hh-shared/dist/components/layout/FormPreview/FormPreviewItem';
import { phoneMaskedDisplay } from 'hh-shared/dist/utils/phoneMask';

const propTypes = {
  editForm: PropTypes.bool,
};

const defaultProps = {
  editForm: false,
};

const FacilitiesManagementPagePreview = ({ editForm }) => {
  const { getCurrentAddress } = previewGetters;
  const formValues = useSelector(
    state => getFormValues(formNames.facilityInformationForm)(state) || {},
  );

  return (
    <FormPreviewPane>
      {editForm && (
        <FormPreviewSection
          title={lang.labels.Version()}
          icon={icons.editedDocument}
        >
          <FormPreviewItem
            label={lang.labels.Version()}
            value={formValues.versionNumber}
          />
        </FormPreviewSection>
      )}
      <FormPreviewSection
        title={lang.labels.FacilityInformations()}
        icon={icons.facility}
      >
        <FormPreviewItem label={lang.labels.Name()} value={formValues.name} />

        <FormPreviewItem
          label={lang.labels.Address()}
          value={getCurrentAddress(
            formValues,
            'isNewAddress',
            'newAddress',
            'address',
          )}
          isNew={formValues.isNewAddress}
        />

        <FormPreviewItem label={lang.labels.Email()} value={formValues.email} />

        <FormPreviewItem
          label={lang.labels.PhoneNumber()}
          value={phoneMaskedDisplay(formValues.phoneNumber)}
        />

        <FormPreviewItem
          label={lang.labels.Department()}
          value={get(formValues, 'department.name')}
        />
      </FormPreviewSection>
    </FormPreviewPane>
  );
};

FacilitiesManagementPagePreview.propTypes = propTypes;
FacilitiesManagementPagePreview.defaultProps = defaultProps;

export default FacilitiesManagementPagePreview;
