import React, { memo } from 'react';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import cx from 'classnames';

import styles from './ButtonWithDropdown.module.scss';
import './ButtonWithDropdown.theming.scss';

const propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
};

const defaultProps = {
  isActive: false,
};

const ButtonWithDropdown = ({ isActive, children, onClick, count }) => {
  const wrapperClassName = cx(styles.wrapper, {
    [styles.wrapper_active]: isActive,
  });

  const sizeWrapperClassName = cx(styles.sizeWrapper, 'themingWrapper');

  return (
    <div className={sizeWrapperClassName}>
      <div
        title={lang.labels.ClickToDisplayDataFromThisPeriod()}
        className={wrapperClassName}
        onClick={onClick}
        /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
        tabIndex={0}
      >
        <div className={styles.count}>{count}</div>
        <div className={styles.description}>
          {lang.labels.ConfigurableDiagramCompletedTransportOrders()}
        </div>
      </div>
      <div className={styles.dropdownWrapper}>{children}</div>
    </div>
  );
};

ButtonWithDropdown.propTypes = propTypes;
ButtonWithDropdown.defaultProps = defaultProps;

export default memo(ButtonWithDropdown);
