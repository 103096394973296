import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import fieldNames from 'common/fieldNames';
import loadingMethodsIds from 'common/loadingMethodsIds';
import unloadingMethodsIds from 'common/unloadingMethodsIds';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import TransportOrderInformationFormContainer from './TransportOrderInformationForm/TransportOrderInformationFormContainer';
import TransportOrdersManagementSrv from './TransportOrdersManagementSrv';
import TransportOrdersManagementPagePreviewContainer from './TransportOrdersManagementPagePreviewContainer';
import transportCodeIds from 'hh-shared/src/lib/utils/transportCodeIds';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  userFacilityInstanceId: PropTypes.string,
  onGetDefaultPrices: PropTypes.func.isRequired,
};

const defaultProps = {
  userFacilityInstanceId: undefined,
};

class TransportOrdersManagementAddPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false,
      facilities: [],
      companies: [],
      users: [],
      cars: [],
      addresses: [],
      carTypes: [],
      initialValues: {},
      currencies: [],
    };
  }

  componentDidMount() {
    this.setState({ showSpinner: true });

    this.props.onGetDefaultPrices();

    Promise.all([
      TransportOrdersManagementSrv.getFacilitiesAsSelectItems(),
      TransportOrdersManagementSrv.getCompaniesAsSelectItems(),
      TransportOrdersManagementSrv.getAddressesAsSelectItem(),
      TransportOrdersManagementSrv.getCarTypesAsSelectItems(),
      TransportOrdersManagementSrv.getCurrenciesAsSelectItems(),
      TransportOrdersManagementSrv.getAdditionalRequirementsInitValue(),
    ])
      .then(
        ([
          facilities,
          companies,
          addresses,
          carTypes,
          currencies,
          additionalRequirementsInitValue,
        ]) => {
          let initialValues = {};
          if (!this.props.userFacilityInstanceId) {
            const userFacility = facilities.find(
              x => x.instanceId === this.props.userFacilityInstanceId,
            );

            initialValues = {
              [fieldNames.transportOrderInformationForm.facility]: userFacility,
              [fieldNames.transportOrderInformationForm.loadingMethodId]:
                loadingMethodsIds.sideId,
              [fieldNames.transportOrderInformationForm.unloadingMethodId]:
                unloadingMethodsIds.sideId,
              transportCode: this.props.transportCodes.find(x => x.id === transportCodeIds.transportBlockToConsumer)
            };
          }

          const defaultCurrency = currencies.find(x => x.isDefault);

          initialValues[
            fieldNames.transportOrderInformationForm.currency
          ] = defaultCurrency;
          initialValues[
            fieldNames.transportOrderInformationForm.capacity
          ] = 24000;
          initialValues[
            fieldNames.transportOrderInformationForm.additionalRequirements
          ] = additionalRequirementsInitValue;

          this.setState({
            facilities,
            companies,
            addresses,
            carTypes,
            currencies,
            initialValues,
          });
        },
      )
      .finally(() => this.setState({ showSpinner: false }));
  }

  render() {
    const { onSubmit, history } = this.props;
    const {
      showSpinner,
      facilities,
      companies,
      users,
      cars,
      addresses,
      carTypes,
      initialValues,
      currencies,
    } = this.state;

    return (
      <>
        <BreadcrumbsContainer
          links={[
            {
              routePath: '/',
              label: lang.labels.MainPage(),
            },
            {
              routePath: '/transport-orders-management',
              label: lang.labels.TransportOrders(),
            },
            {
              routePath: '/transport-orders-management/add',
              label: lang.labels.AddingNewTransportOrder(),
            },
          ]}
        />
        <FormPreviewSectionContainer>
          <FormCardWrapper>
            <div className={showSpinner ? 'fox_spinner' : undefined}>
              <TransportOrderInformationFormContainer
                onSubmit={onSubmit}
                history={history}
                facilities={facilities}
                companies={companies}
                users={users}
                cars={cars}
                addresses={addresses}
                carTypes={carTypes}
                initialValues={initialValues}
                currencies={currencies}
              />
            </div>
          </FormCardWrapper>
          <TransportOrdersManagementPagePreviewContainer />
        </FormPreviewSectionContainer>
      </>
    );
  }
}

TransportOrdersManagementAddPage.propTypes = propTypes;
TransportOrdersManagementAddPage.defaultProps = defaultProps;

export default TransportOrdersManagementAddPage;
