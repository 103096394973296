import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './GreyTitle.module.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: undefined,
};

const GreyTitle = ({ children, className }) => {
  const titleClassName = cx(styles.title, className);

  return <div className={titleClassName}>{children}</div>;
};

GreyTitle.propTypes = propTypes;
GreyTitle.defaultProps = defaultProps;

export default memo(GreyTitle);
