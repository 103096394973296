import React from 'react';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';

import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import { useDispatch, useSelector } from 'react-redux';
import AddressInformationForm from './AddressInformationForm';
import AddressesManagementPagePreview from './AddressesManagementPagePreview';
import { addAsync } from './actions';

const propTypes = {
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

function AddressesManagementAddPage({ history }) {
  const dispatch = useDispatch();
  const onSubmit = model => dispatch(addAsync(model));
  const zipCodes = useSelector(state => state.dictionary.static.zipCodes);

  return (
    <>
      <BreadcrumbsContainer
        links={[
          {
            routePath: '/',
            label: lang.labels.HomePage(),
          },
          {
            routePath: '/addresses-management',
            label: lang.labels.Addresses(),
          },
          {
            routePath: '/addresses-management/add',
            label: lang.labels.AddingNewAddress(),
          },
        ]}
      />
      <FormPreviewSectionContainer>
        <FormCardWrapper>
          <AddressInformationForm
            onSubmit={onSubmit}
            history={history}
            zipCodes={zipCodes}
          />
        </FormCardWrapper>
        <AddressesManagementPagePreview />
      </FormPreviewSectionContainer>
    </>
  );
}

AddressesManagementAddPage.propTypes = propTypes;
AddressesManagementAddPage.defaultProps = defaultProps;

export default AddressesManagementAddPage;
