import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ChangeStatusButton from 'hh-shared/dist/components/transport-order/ChangeStatusButton';
import { useSelector } from 'react-redux';
import { isSubmitting } from 'redux-form';
import get from 'lodash/get';
import transportOrderStatusesIds from 'hh-shared/dist/consts/transportOrderStatusesIds';
import lang from 'hh-shared/dist/language/services/languageService';
import useFormDialogLogic from 'hh-shared/dist/components/transport-order/useFormDialogLogic';

import TransportOrdersManagementSrv from 'transport-orders-management/TransportOrdersManagementSrv';
import RealizedDialog from './RealizedDialog';

const formName = 'transportFormPreview';

const propTypes = {
  data: PropTypes.object.isRequired,
  orderId: PropTypes.string.isRequired,
  onRefreshPage: PropTypes.func.isRequired,
  submitAcceptForImplementation: PropTypes.func.isRequired,
  submitLoaded: PropTypes.func.isRequired,
};

const defaultProps = {};

function ActionButtons({
  data,
  orderId,
  onRefreshPage,
  submitAcceptForImplementation,
  submitLoaded,
}) {
  const [
    realizedDialogProps,
    openRealized,
    setRealizedInitialValues,
  ] = useFormDialogLogic();

  const isFormSubmitting = useSelector(state => isSubmitting(formName)(state));

  const openRealizedDialog = () => {
    setRealizedInitialValues({ unloadingTimeTo: data.unloadingTimeTo });
    openRealized();
  };

  const submitRealized = model => {
    TransportOrdersManagementSrv.realized({ ...model, id: orderId }).then(() =>
      onRefreshPage(),
    );
  };

  const statusId = get(data, 'status.id');
  const showAcceptForImplementationButton =
    statusId === transportOrderStatusesIds.placed ||
    statusId === transportOrderStatusesIds.accepted;
  const showLoadedButton = statusId === transportOrderStatusesIds.accepted;
  const showRealizedButton = statusId === transportOrderStatusesIds.loaded;

  const acceptImplementationLabels =
    statusId === transportOrderStatusesIds.placed
      ? {
          button: lang.buttons.AcceptForImplementation(),
          description: lang.labels.ChangeStatus(),
        }
      : {
          button: lang.buttons.UpdateImplementation(),
          description: lang.labels.ImplementationDetails(),
        };

  return (
    <>
      {showAcceptForImplementationButton && (
        <ChangeStatusButton
          onClickButton={submitAcceptForImplementation}
          orderStatusId={statusId}
          disabled={isFormSubmitting}
          renderButtonContent={acceptImplementationLabels.button}
          description={acceptImplementationLabels.description}
          color={statusId === transportOrderStatusesIds.accepted && 'info'}
        />
      )}
      {showLoadedButton && (
        <ChangeStatusButton
          onClickButton={submitLoaded}
          orderStatusId={statusId}
          renderButtonContent={lang.buttons.Loaded()}
        />
      )}
      {showRealizedButton && (
        <>
          <ChangeStatusButton
            onClickButton={openRealizedDialog}
            orderStatusId={statusId}
            renderButtonContent={lang.buttons.Realized()}
          />
          <RealizedDialog
            {...realizedDialogProps}
            onSubmit={submitRealized}
            transportOrderData={data}
          />
        </>
      )}
    </>
  );
}

ActionButtons.propTypes = propTypes;
ActionButtons.defaultProps = defaultProps;

export default memo(ActionButtons);
