import { connect } from 'react-redux';
import FacilitiesManagementAddPage from './FacilitiesManagementAddPage';
import { addAsync } from './actions';

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: model => dispatch(addAsync(model)),
  };
};

const FacilitiesManagementAddPageContainer = connect(
  undefined,
  mapDispatchToProps,
)(FacilitiesManagementAddPage);

export default FacilitiesManagementAddPageContainer;
