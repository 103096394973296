import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import intersection from 'lodash/intersection';

const propTypes = { oneOfPermission: PropTypes.array };

const defaultProps = {
  oneOfPermission: [],
};

function PermissionWrapper({ oneOfPermissions, children }) {
  const userPermissions =
    useSelector(state => get(state, 'auth.currentUser.permissions')) || [];

  const hasAnyPermission = !!intersection(userPermissions, oneOfPermissions)
    .length;

  return hasAnyPermission && children;
}

PermissionWrapper.propTypes = propTypes;
PermissionWrapper.defaultProps = defaultProps;

export default PermissionWrapper;
