import { connect } from 'react-redux';
import get from 'lodash/get';
import UserWidget from './UserWidget';
import { logout } from './actions';

const mapStateToProps = store => ({
  userName: get(store, 'auth.currentUser.email'),
  lastName: get(store, 'auth.currentUser.lastName'),
  firstName: get(store, 'auth.currentUser.firstName'),
  avatar: get(store, 'auth.currentUser.avatar'),
});

const mapDispatchToProps = dispatch => ({
  onLogOut: () => dispatch(logout()),
});

const UserWidgetContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserWidget);

export default UserWidgetContainer;
