import { connect } from 'react-redux';
import CarsManagementPage from './CarsManagementPage';
import { getForGrid } from './actions';

const mapDispatchToProps = dispatch => {
  return {
    onLoadData: dataState => dispatch(getForGrid(dataState)),
  };
};

const CarsManagementPageContainer = connect(
  undefined,
  mapDispatchToProps,
)(CarsManagementPage);

export default CarsManagementPageContainer;
