const gridNames = {
  usersManagement: 'usersManagement',
  htmlTemplatesManagement: 'htmlTemplatesManagement',
  departmentsManagement: 'departmentsManagement',
  companiesManagement: 'companiesManagement',
  carTypesManagement: 'carTypesManagement',
  carsManagement: 'carsManagement',
  facilitiesManagement: 'facilitiesManagement',
  routesManagement: 'routesManagement',
  currenciesManagement: 'currenciesManagement',
  auctionsManagement: 'auctionsManagement',
  transportOrdersManagement: 'transportOrdersManagement',
  addressesManagement: 'addressesManagement',
  pricesManagement: 'pricesManagement',
  defaultPricesManagement: 'defaultPricesManagement',
  zipCodesManagement: 'zipCodesManagement',
  exportNav: 'exportNav',
};

export default gridNames;
