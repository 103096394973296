import { connect } from 'react-redux';
import TransportOrderInformationForm from './TransportOrderInformationForm';

import {
  commonMapStateToProps,
  commonMapDispatchToProps,
  commonMergeProps,
} from './commonTransportOrderInformationFormContainer';

const mapStateToProps = state => {
  return {
    ...commonMapStateToProps(state),
    routes: state.dictionary.static.routes,
    zipCodes: state.dictionary.static.zipCodes,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...commonMapDispatchToProps(dispatch),
  };
};

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
  return {
    ...commonMergeProps(propsFromState, propsFromDispatch, ownProps),
  };
};

const TransportOrderInformationFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(TransportOrderInformationForm);

export default TransportOrderInformationFormContainer;
