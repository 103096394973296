import React from 'react';
import PropTypes from 'prop-types';

import Column from 'hh-shared/dist/components/layout/Column';
import PageWrapper from 'hh-shared/dist/components/layout/PageWrapper';

import MainInfo from './MainInfo/MainInfo';
import TransportInfo from './TransportInfo/TransportInfo';
import PriceSection from './PriceSection/PriceSection';
import ButtonContainer from './ButtonContainer';
import styles from './AuctionPageLeftPane.module.scss';

const propTypes = {
  isObservedChange: PropTypes.func.isRequired,
  reloadPage: PropTypes.func.isRequired,
  auctionId: PropTypes.string.isRequired,
  auctioneers: PropTypes.array.isRequired,
  displayPriceSection: PropTypes.bool.isRequired,
  auctionStatus: PropTypes.object,
  onCancelConfirmed: PropTypes.func.isRequired,
  setCancelDialogIsOpen: PropTypes.func.isRequired,
  onDeleteConfirmed: PropTypes.func.isRequired,
};

const defaultProps = {
  auctionStatus: undefined,
};

const AuctionPageLeftPane = ({
  isObservedChange,
  reloadPage,
  auctionId,
  auctioneers,
  displayPriceSection,
  auctionStatus,
  onCancelConfirmed,
  setCancelDialogIsOpen,
  onDeleteConfirmed,
}) => {
  return (
    <Column bigger>
      <ButtonContainer
        {...{
          auctionId,
          auctionStatus,
          onCancelConfirmed,
          setCancelDialogIsOpen,
          onDeleteConfirmed,
        }}
      />
      <PageWrapper roundedLeft additionalClassNames={styles.section_container}>
        <MainInfo {...{ isObservedChange, reloadPage }} />
        <TransportInfo />
        {displayPriceSection && (
          <PriceSection {...{ auctionId, auctioneers }} />
        )}
      </PageWrapper>
    </Column>
  );
};

AuctionPageLeftPane.propTypes = propTypes;
AuctionPageLeftPane.defaultProps = defaultProps;

export default AuctionPageLeftPane;
