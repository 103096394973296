import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  currentMonthItem,
  currentWeekItem,
  currentYearItem,
} from './pickerSource';
import RangeSelector from './RangeSelector';
import { PropTypes } from 'prop-types';

const propTypes = {
  selectedRangeValues: PropTypes.object,
};

const defaultProps = {
  selectedRangeValues: {
    yearCount: 0,
    monthCount: 0,
    weekCount: 0,
  },
};

function RangeConfigurationButtons({ selectedRangeValues }) {
  return (
    <Field
      name="type"
      component={RangeSelector}
      selectedRangeValues={selectedRangeValues}
    />
  );
}

RangeConfigurationButtons.propTypes = propTypes;
RangeConfigurationButtons.defaultProps = defaultProps;

const initialValues = {
  year: currentYearItem,
  month: currentMonthItem,
  week: currentWeekItem,
  type: 'week',
};

export default reduxForm({
  form: 'diagramPicker',
  enableReinitialize: true,
  initialValues,
})(RangeConfigurationButtons);
