"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _kendoReactDialogs = require("@progress/kendo-react-dialogs");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  title: _propTypes.default.string,
  body: _propTypes.default.string,
  onCloseClick: _propTypes.default.func,
  children: _propTypes.default.any
};
var defaultProps = {
  title: 'Potwierdź',
  body: 'Jesteś pewien?',
  onCloseClick: undefined,
  children: undefined
};

function FoxDialog(_ref) {
  var title = _ref.title,
      body = _ref.body,
      onCloseClick = _ref.onCloseClick,
      children = _ref.children;
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_kendoReactDialogs.Dialog, {
    title: title,
    onClose: onCloseClick
  }, _react.default.createElement("p", null, body), children));
}

FoxDialog.propTypes = propTypes;
FoxDialog.defaultProps = defaultProps;
var _default = FoxDialog;
exports.default = _default;