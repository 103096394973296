import axios from 'config/axios';

const prefix = 'facilitiesManagement';

export default class FacilitiesManagementSrv {
  static getForGrid(dataState) {
    return axios
      .post(`${prefix}/getForGrid`, dataState)
      .then(result => result.data);
  }

  static get(instanceId) {
    return axios.get(`${prefix}/${instanceId}`).then(result => result.data);
  }

  static add(model) {
    return axios.post(prefix, model);
  }

  static update(model) {
    return axios.put(prefix, model);
  }

  static getDepartmentsAsSelectItems() {
    return axios
      .get(`${prefix}/getDepartmentsAsSelectItems`)
      .then(result => result.data);
  }

  static getAddressesAsSelectItems() {
    return axios
      .get(`${prefix}/getAddressesAsSelectItems`)
      .then(result => result.data);
  }

  static delete(id) {
    return axios.delete(`${prefix}/${id}`);
  }
}
