import axios from 'config/axios';

export default class AuthSrv {
  static login(model) {
    return axios.post('auth/login', model).then(result => result.data);
  }

  static resetPassword(model) {
    model.origin = window.location.origin + process.env.PUBLIC_URL;
    return axios.post('auth/resetPassword', model);
  }

  static setNewPasswordAsync(model) {
    return axios.post('auth/setNewPassword', model);
  }

  static updateUserInformation(model) {
    return axios
      .post('auth/updateUserInformation', model)
      .then(result => result.data);
  }

  static changePassword(model) {
    return axios.put('auth/changePassword', model);
  }
}
