import { connect } from 'react-redux';
import CarsManagementEditPage from './CarsManagementEditPage';
import { updateAsync } from './actions';

const mapDispatchToProps = () => {
  return {
    onSubmit: model => updateAsync(model),
  };
};

const CarsManagementEditPageContainer = connect(
  undefined,
  mapDispatchToProps,
)(CarsManagementEditPage);

export default CarsManagementEditPageContainer;
