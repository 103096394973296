import React, { memo } from 'react';
import PropTypes from 'prop-types';
import FoxDropDownListField from 'fox-react/dist/reduxFormFields/FoxDropDownListField';
import Row from 'hh-shared/dist/components/layout/Row';
import { monthItems, yearItems, weeksItems } from './pickerSource';
import ButtonWithDropdown from './ButtonWithDropdown';

const propTypes = {
  input: PropTypes.object.isRequired,
  selectedRangeValues: PropTypes.object.isRequired,
};

const defaultProps = {};

function RangeSelector({
  input: { value, onChange },
  selectedRangeValues: { yearCount, monthCount, weekCount },
}) {
  const handleYearSelect = () => {
    onChange('year');
  };

  const handleMonthSelect = () => {
    onChange('month');
  };

  const handleWeekSelect = () => {
    onChange('week');
  };

  return (
    <Row>
      <ButtonWithDropdown
        onClick={handleWeekSelect}
        isActive={value === 'week'}
        count={weekCount}
      >
        <FoxDropDownListField
          items={weeksItems}
          name="week"
          onChange={handleWeekSelect}
        />
      </ButtonWithDropdown>
      <ButtonWithDropdown
        onClick={handleMonthSelect}
        isActive={value === 'month'}
        count={monthCount}
      >
        <FoxDropDownListField
          items={monthItems}
          name="month"
          onChange={handleMonthSelect}
        />
      </ButtonWithDropdown>
      <ButtonWithDropdown
        onClick={handleYearSelect}
        isActive={value === 'year'}
        count={yearCount}
      >
        <FoxDropDownListField
          items={yearItems}
          name="year"
          onChange={handleYearSelect}
        />
      </ButtonWithDropdown>
    </Row>
  );
}

RangeSelector.propTypes = propTypes;
RangeSelector.defaultProps = defaultProps;

export default memo(RangeSelector);
