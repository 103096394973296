import { connect } from 'react-redux';
import DefaultPricesManagementAddPage from './DefaultPricesManagementAddPage';
import { addAsync } from './actions';

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: model => dispatch(addAsync(model)),
  };
};

const DefaultPricesManagementAddPageContainer = connect(
  undefined,
  mapDispatchToProps,
)(DefaultPricesManagementAddPage);

export default DefaultPricesManagementAddPageContainer;
