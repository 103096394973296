import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import createFoxGridContainer from 'fox-react/dist/components/FoxGrid/createFoxGridContainer';
import FoxButton from 'fox-react/dist/components/FoxButton';
import FoxGridColumn from 'fox-react/dist/components/FoxGrid/FoxGridColumn';

import gridNames from 'common/gridNames';
import columnNames from 'common/columnNames';
import InnerContentWrapper from 'hh-shared/dist/components/layout/InnerContentWrapper';
import lang from 'hh-shared/dist/language/services/languageService';
import actionsColumnGridDefaults from 'hh-shared/dist/consts/actionsColumnGridDefaults';

const propTypes = {
  onLoadData: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const defaultProps = {};

const FoxGridContainer = createFoxGridContainer(gridNames.carTypesManagement);

class CarTypesManagementPage extends PureComponent {
  render() {
    const { onLoadData, match } = this.props;

    return (
      <InnerContentWrapper fullWidth>
        <FoxGridContainer
          onLoadData={onLoadData}
          defaultSortField={columnNames.carTypesManagement.name}
          renderButtons={
            <Link to={`${match.url}/add`}>
              <FoxButton icon="add" primary>
                {lang.buttons.Add()}
              </FoxButton>
            </Link>
          }
        >
          <FoxGridColumn
            {...actionsColumnGridDefaults}
            cell={({ dataItem }) => (
              <td>
                <Link to={`${match.url}/edit/${dataItem.id}`}>
                  <FoxButton icon="edit">{lang.buttons.Edit()}</FoxButton>
                </Link>
              </td>
            )}
          />
          <FoxGridColumn
            field={columnNames.carTypesManagement.name}
            title={lang.labels.CarTypesManagement_Columns_Name()}
            filter="text"
          />
        </FoxGridContainer>
      </InnerContentWrapper>
    );
  }
}

CarTypesManagementPage.propTypes = propTypes;
CarTypesManagementPage.defaultProps = defaultProps;

export default CarTypesManagementPage;
