import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import UsersManagementSrv from './UsersManagementSrv';
import EditUserInformationForm from './EditUserInformationForm';
import UsersManagementPagePreview from './UsersManagementPagePreview';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currentFormValues: PropTypes.object.isRequired,
};

const defaultProps = {};

class UsersManagementEditPage extends PureComponent {
  constructor(props) {
    super(props);
    this.onChangeVersion = this.onChangeVersion.bind(this);
    this.onSubmitAsNew = this.onSubmitAsNew.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      initialValues: {},
      showSpinner: false,
      versionNumbers: [],
      versionNumber: undefined,
      facilities: [],
      departments: [],
    };
  }

  componentDidMount() {
    this.setState({ showSpinner: true });

    Promise.all([
      UsersManagementSrv.get(this.props.match.params.instanceId),
      UsersManagementSrv.getFacilitiesAsSelectItem(),
      UsersManagementSrv.getCompaniesAsSelectItems(),
      UsersManagementSrv.getDepartmentsAsSelectItems(),
    ])
      .then(([userForEdit, facilities, companies, departments]) => {
        const instanceData = {
          login: userForEdit.login,
          email: userForEdit.email,
          permissions: userForEdit.permissions,
          isBanned: userForEdit.isBanned,
        };
        const lastVersion = last(userForEdit.userDetailsVersions);
        const versionNumbers = sortBy(
          userForEdit.userDetailsVersions,
          x => x.versionNumber,
        ).map(x => x.versionNumber);
        const initialValues = {
          ...instanceData,
          ...lastVersion,
        };

        this.setState({
          initialValues,
          versions: userForEdit.userDetailsVersions,
          versionNumbers,
          versionNumber: last(versionNumbers),
          facilities,
          companies,
          departments,
        });
      })
      .finally(() => this.setState({ showSpinner: false }));
  }

  onChangeVersion(e) {
    const newVersionNumber = e.target.value;
    const { currentFormValues } = this.props;
    this.setState(prevState => {
      const version = prevState.versions.find(
        x => x.versionNumber === newVersionNumber,
      );

      return {
        initialValues: {
          ...currentFormValues,
          ...version,
        },
        versionNumber: newVersionNumber,
      };
    });
  }

  onSubmit(model) {
    return this.props.onSubmit({ ...model, id: this.state.initialValues.id });
  }

  onSubmitAsNew(model) {
    return this.props.onSubmit({
      ...model,
      id: undefined,
      instanceId: this.props.match.params.instanceId,
    });
  }

  render() {
    const { history } = this.props;
    const {
      initialValues,
      showSpinner,
      versionNumbers,
      versionNumber,
      facilities,
      companies,
      departments,
    } = this.state;

    return (
      <>
        <BreadcrumbsContainer
          links={[
            {
              routePath: '/',
              label: lang.labels.HomePage(),
            },
            {
              routePath: '/users-management',
              label: lang.labels.Users(),
            },
            {
              routePath: '/users-management/edit',
              label: lang.labels.EditingUser(),
            },
          ]}
        />
        <FormPreviewSectionContainer>
          <FormCardWrapper>
            <div className={showSpinner ? 'fox_spinner' : undefined}>
              <EditUserInformationForm
                versionNumbers={versionNumbers}
                versionNumber={versionNumber}
                onSubmit={this.onSubmit}
                onSubmitAsNew={this.onSubmitAsNew}
                initialValues={initialValues}
                history={history}
                onChangeVersion={this.onChangeVersion}
                facilities={facilities}
                companies={companies}
                departments={departments}
              />
            </div>
          </FormCardWrapper>
          <UsersManagementPagePreview editForm />
        </FormPreviewSectionContainer>
      </>
    );
  }
}

UsersManagementEditPage.propTypes = propTypes;
UsersManagementEditPage.defaultProps = defaultProps;

export default UsersManagementEditPage;
