import { weekDays, months } from 'common';
import moment from 'moment';
import actionTypes from './actionTypes';
import { yearFrom, yearTo } from './config';

const initialState = {
  diagram: {
    yearWeeks: {},
    yearMonths: {},
    yearMonthDays: {},
    fullDays: [],
    countPerYear: {},
    countPerMonth: {},
    countPerWeek: {},
    from: '',
    to: '',
    keys: [],
  },
};

function generateData(realized, keys) {
  const from = moment([yearFrom]);
  const to = moment([yearTo]).endOf('year');

  const fullDays = [];
  const yearWeeks = {};
  const yearMonths = {};
  const yearMonthDays = {};
  const countPerYear = {};
  const countPerMonth = {};
  const countPerWeek = {};

  // create structure
  [...Array(yearTo - yearFrom + 1).keys()].forEach(x => {
    const yearSelector = `${x + yearFrom}`;

    yearWeeks[yearSelector] = [];
    yearMonths[yearSelector] = [];
    yearMonthDays[yearSelector] = [];
    countPerYear[yearSelector] = 0;
    countPerMonth[yearSelector] = [];
    countPerWeek[yearSelector] = [];

    for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
      yearMonths[yearSelector][monthIndex] = {
        day: months[monthIndex],
        ...keys.reduce((obj, item) => ({ ...obj, [item]: 0 }), {}),
      };
      yearMonthDays[yearSelector][monthIndex] = [];
      countPerMonth[yearSelector][monthIndex] = 0;
    }

    for (let weekIndex = 1; weekIndex <= 54; weekIndex++) {
      yearWeeks[yearSelector][weekIndex] = [];
      countPerWeek[yearSelector][weekIndex] = 0;
    }
  });

  // fill structure
  let iDay = from;
  do {
    const day = iDay.format('yyyy-MM-DD');
    const yearSelector = `${iDay.year()}`;
    const week = iDay.isoWeek();
    const month = iDay.month();
    const dayOfWeekIndex = iDay.isoWeekday() - 1;
    const dayOfMonth = iDay.date();

    const existing = realized.find(x => x.day === day);
    if (existing) {
      fullDays.push(existing);
      yearWeeks[yearSelector][week].push({
        day: weekDays[dayOfWeekIndex],
        ...existing.perFacility,
      });
      Object.keys(existing.perFacility).forEach(prop => {
        yearMonths[yearSelector][month][`${prop}`] +=
          existing.perFacility[`${prop}`];
      });
      yearMonthDays[yearSelector][month].push({
        day: dayOfMonth,
        ...existing.perFacility,
      });
      countPerYear[yearSelector] += existing.value;
      countPerMonth[yearSelector][month] += existing.value;
      countPerWeek[yearSelector][week] += existing.value;
    } else {
      const val = {
        day,
      };
      fullDays.push(val);
      yearWeeks[yearSelector][week].push({
        day: weekDays[dayOfWeekIndex],
        ...val.perFacility,
      });
      yearMonthDays[yearSelector][month].push({
        day: dayOfMonth,
        ...val.perFacility,
      });
    }

    iDay = iDay.add(1, 'days');
  } while (iDay.isBefore(to));

  return {
    yearWeeks,
    yearMonths,
    yearMonthDays,
    fullDays,
    countPerYear,
    countPerMonth,
    countPerWeek,
    from: from.format('yyyy-MM-DD'),
    to: to.format('yyyy-MM-DD'),
  };
}

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.STORE_DASHBOARD_DATA:
      return {
        ...state,
        dashboardModel: action.data,
      };
    case actionTypes.STORE_DASHBOARD_DIAGRAM:
      return {
        ...state,
        diagram: {
          ...action.data,
          ...generateData(action.data.realizedDays, action.data.allFacilities),
          keys: action.data.allFacilities,
        },
      };
    default:
      return state;
  }
}
