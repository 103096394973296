import React from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from 'hh-shared/dist/components/commons/FontAwesomeIcon/FontAwesomeIcon';
import icons from 'hh-shared/dist/consts/icons';
import styles from './EditPagePreviewButton.module.scss';

const propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const EditPagePreviewButton = ({ label, onClick, className }) => {
  return (
    <button
      onClick={onClick}
      title={label}
      className={`${styles.button} ${className}`}
      type="button"
    >
      {label}
      <FontAwesomeIcon
        icon={icons.plus}
        title={label}
        className={styles.icon}
      />
    </button>
  );
};

EditPagePreviewButton.propTypes = propTypes;
EditPagePreviewButton.defaultProps = defaultProps;

export default EditPagePreviewButton;
