import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import createFoxGridContainer from 'fox-react/dist/components/FoxGrid/createFoxGridContainer';
import FoxButton from 'fox-react/dist/components/FoxButton';
import FoxGridColumn from 'fox-react/dist/components/FoxGrid/FoxGridColumn';
import icons from 'hh-shared/dist/consts/icons';

import lang from 'hh-shared/dist/language/services/languageService';
import CurrentPriceCell from 'commons/CurrentPriceCell';
import gridNames from 'common/gridNames';
import columnNames from 'common/columnNames';
import moment from 'moment';
import InnerContentWrapper from 'hh-shared/dist/components/layout/InnerContentWrapper';
import DateTimeColumnCell from 'hh-shared/dist/components/grid/DateTimeColumnCell';
import NumericCell from 'hh-shared/dist/components/grid/NumericCell';
import TimeTriggerLabelCell from 'hh-shared/dist/components/grid/TimeTriggerLabelCell';
import AddressCell from 'hh-shared/dist/components/grid/AddressCell';
import StarHeaderCell from 'hh-shared/dist/components/grid/StarHeaderCell';
import StarCell from 'hh-shared/dist/components/grid/StarCell';
import DropdownLinkCell from 'hh-shared/dist/components/commons/Dropdown/DropdownLinkCell';
import auctionStatusIds from 'hh-shared/dist/utils/auctionStatusesIds';
import PhoneNumberCell from 'hh-shared/dist/components/grid/PhoneNumberCell';

import OnlyMyFacilitySwitchContainer from 'user-preferences/OnlyMyFacilitySwitchContainer';
import get from 'lodash/get';
import { getForGrid, isObservedChange } from './actions';
import AuctionStatusCell from './AuctionStatusCell';

const propTypes = {
  match: PropTypes.object.isRequired,
};

const defaultProps = {};

const FoxGridContainer = createFoxGridContainer(gridNames.auctionsManagement);

function showEditLink(item) {
  if (item.statusId === auctionStatusIds.draft) {
    return true;
  }

  if (item.statusId === auctionStatusIds.live) {
    return moment().isBefore(item.startTime);
  }
  return false;
}

function showLinkToDetails(item) {
  return item.statusId !== auctionStatusIds.draft;
}

function AuctionsManagementPage({ match }) {
  const dispatch = useDispatch();
  const onLoadData = dataState => dispatch(getForGrid(dataState));
  const dataState = useSelector(
    state => state[gridNames.auctionsManagement].dataState,
  );
  const onIsObservedChange = (id, isObserved) => {
    dispatch(isObservedChange(id, isObserved));
  };
  const userHasFacilityDefined = useSelector(
    state => !!get(state, 'auth.currentUser.facilityInstanceId'),
  );

  const refresGrid = () => {
    onLoadData(dataState);
  };

  return (
    <InnerContentWrapper fullWidth>
      <FoxGridContainer
        onLoadData={onLoadData}
        loadOnStart={!userHasFacilityDefined} // its triggered via OnlyMyFacilitySwitchContainer by callback
        defaultSortField={columnNames.auctionsManagement.status}
        renderButtons={
          <Link to={`${match.url}/add`}>
            <FoxButton icon="add" primary>
              {lang.buttons.Add()}
            </FoxButton>
          </Link>
        }
        renderRightButtons={
          userHasFacilityDefined && (
            <OnlyMyFacilitySwitchContainer onChangeCallback={refresGrid} />
          )
        }
      >
        <FoxGridColumn
          field={columnNames.auctionsManagement.isObserved}
          filter="boolean"
          columnMenu={false}
          cell={({ dataItem }) => (
            <StarCell
              value={dataItem.isObserved}
              onChange={() =>
                onIsObservedChange(dataItem.id, !dataItem.isObserved)
              }
            />
          )}
          headerCell={StarHeaderCell}
        />
        <FoxGridColumn
          field={columnNames.auctionsManagement.number}
          title={lang.labels.AuctionNumber()}
          filter="text"
          locked
          cell={({ dataItem, className }) => (
            <DropdownLinkCell
              link={
                showLinkToDetails(dataItem)
                  ? `${match.url}/details/${dataItem.id}`
                  : undefined
              }
              linkTitle={lang.labels.Preview()}
              text={dataItem.number}
              className={className}
              displayAsLink={
                showLinkToDetails(dataItem) || showEditLink(dataItem)
              }
              buttons={
                showEditLink(dataItem)
                  ? [
                      {
                        icon: icons.solidEdit,
                        label: lang.buttons.Edit(),
                        link: `${match.url}/edit/${dataItem.id}`,
                      },
                    ]
                  : undefined
              }
            />
          )}
        />
        <FoxGridColumn
          field={columnNames.auctionsManagement.status}
          title={lang.labels.CurrentAuctionStatus()}
          filter="text"
          cell={AuctionStatusCell}
        />
        <FoxGridColumn
          field={columnNames.auctionsManagement.currentPrice}
          title={lang.labels.CurrentPrice()}
          filter="text"
          cell={({ dataItem }) => (
            <CurrentPriceCell
              wantToDisplay={
                dataItem.currentPriceWithoutOffer &&
                dataItem.statusId === auctionStatusIds.live
              }
              value={dataItem.currentPrice}
              auctionId={dataItem.id}
              displayAsNumericValue
            >
              <TimeTriggerLabelCell
                value={dataItem.currentPrice}
                label={lang.labels.NoOffer()}
                orange
                displayAsNumericValue
                timeFrom={moment(dataItem.endTime).subtract(2, 'hours')}
                timeTo={moment(dataItem.endTime)}
              />
            </CurrentPriceCell>
          )}
        />
        <FoxGridColumn
          field={columnNames.auctionsManagement.startPrice}
          title={lang.labels.StartPrice()}
          filter="numeric"
          cell={({ dataItem }) => <NumericCell value={dataItem.startPrice} />}
        />
        <FoxGridColumn
          field={columnNames.auctionsManagement.distance}
          title={lang.labels.Distance()}
          filter="numeric"
          cell={({ dataItem }) => <NumericCell value={dataItem.distance} />}
        />
        <FoxGridColumn
          field={columnNames.auctionsManagement.facility}
          title={lang.labels.Facility()}
          filter="text"
        />
        <FoxGridColumn
          field={columnNames.auctionsManagement.pricePerKilometer}
          title={lang.labels.PricePerKilometer()}
          filter="numeric"
          cell={({ dataItem }) => (
            <NumericCell value={dataItem.pricePerKilometer} />
          )}
        />
        <FoxGridColumn
          field={columnNames.auctionsManagement.auctionType}
          title={lang.labels.AuctionType()}
          filter="text"
        />
        <FoxGridColumn
          field={columnNames.auctionsManagement.principalPerson}
          title={lang.labels.PrincipalPerson()}
          filter="text"
        />
        <FoxGridColumn
          field={columnNames.auctionsManagement.loadingTime}
          title={lang.labels.LoadingDateTime()}
          cell={DateTimeColumnCell}
          filter="date"
          filterable={false}
        />
        <FoxGridColumn
          field={columnNames.auctionsManagement.loadingAddress}
          title={lang.labels.LoadingAddress()}
          filter="text"
          cell={AddressCell}
        />
        <FoxGridColumn
          field={columnNames.auctionsManagement.loadingMethod}
          title={lang.labels.LoadingMethod()}
          filter="text"
        />
        <FoxGridColumn
          field={columnNames.auctionsManagement.capacity}
          title={`${lang.labels.Capacity()} (kg)`}
          filter="numeric"
          cell={({ dataItem }) => <NumericCell value={dataItem.capacity} />}
        />
        <FoxGridColumn
          field={columnNames.auctionsManagement.unloadingMethod}
          title={lang.labels.UnloadingMethod()}
          filter="text"
        />
        <FoxGridColumn
          field={columnNames.auctionsManagement.unloadingAddress}
          title={lang.labels.UnloadingAddress()}
          filter="text"
          cell={AddressCell}
        />
        <FoxGridColumn
          field={columnNames.auctionsManagement.unloadingTime}
          title={lang.labels.UnloadingTime()}
          filter="date"
          filterable={false}
          cell={DateTimeColumnCell}
        />
        <FoxGridColumn
          field={columnNames.auctionsManagement.authorizedPickupPerson}
          title={lang.labels.AuthorizedPickupPerson()}
          filter="text"
        />
        <FoxGridColumn
          field={
            columnNames.auctionsManagement.authorizedPickupPersonPhoneNumber
          }
          title={lang.labels.AuthorizedPickupPersonPhoneNumber()}
          filter="text"
          cell={({ dataItem }) => (
            <PhoneNumberCell
              phoneNumber={dataItem.authorizedPickupPersonPhoneNumber}
            />
          )}
        />
        <FoxGridColumn
          field={columnNames.auctionsManagement.startTime}
          title={lang.labels.StartDateTime()}
          filter="date"
          filterable={false}
          cell={DateTimeColumnCell}
        />
        <FoxGridColumn
          field={columnNames.auctionsManagement.endTime}
          title={lang.labels.EndDateTime()}
          filter="date"
          filterable={false}
          cell={DateTimeColumnCell}
        />
        <FoxGridColumn
          field={columnNames.auctionsManagement.transportCode}
          title={lang.labels.TransportCode()}
        />
      </FoxGridContainer>
    </InnerContentWrapper>
  );
}

AuctionsManagementPage.propTypes = propTypes;
AuctionsManagementPage.defaultProps = defaultProps;

export default AuctionsManagementPage;
