import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchParticipantData, fetchCompanyData } from 'auction/actions';
import BidsTableSupplier from './BidsTableSupplier';

const propTypes = {
  user: PropTypes.object,
  loadUserData: PropTypes.func.isRequired,
  company: PropTypes.object,
  loadCompanyData: PropTypes.func.isRequired,
};

const defaultProps = {
  user: undefined,
  company: undefined,
};

function BidsTableSupplierContainer({
  company,
  user,
  loadUserData,
  loadCompanyData,
}) {
  const userIsAvailable = user && !user.isLoading;

  useEffect(() => {
    if (!user) {
      loadUserData();
    }
    if (!company) {
      loadCompanyData();
    }
    // eslint-disable-next-line
  }, []);

  const companyName = company ? company.name : '';

  let userProp;
  if (userIsAvailable) {
    userProp = user;
  }

  return <BidsTableSupplier companyName={companyName} user={userProp} />;
}

BidsTableSupplierContainer.propTypes = propTypes;
BidsTableSupplierContainer.defaultProps = defaultProps;

const mapStateToProps = (
  state,
  { companyInstanceId, initiatorUserInstanceId },
) => {
  return {
    company: state.auction.participantCompanies[companyInstanceId],
    user: state.auction.participantsUsers[initiatorUserInstanceId],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadUserData: () =>
    dispatch(fetchParticipantData(ownProps.initiatorUserInstanceId)),
  loadCompanyData: () => dispatch(fetchCompanyData(ownProps.companyInstanceId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(BidsTableSupplierContainer));
