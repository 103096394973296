import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';

import AuctionPreviewPageLoader from 'hh-shared/dist/components/auction/AuctionPreviewPageLoader';
import auctionStatusesIds from 'hh-shared/dist/utils/auctionStatusesIds';
import Row from 'hh-shared/dist/components/layout/Row';

import AuctionsManagementSrv from 'auctions-management/AuctionsManagementSrv';
import AuctionsSrv from './AuctionsSrv';
import CancelDialog from './CancelDialog/CancelDialog';
import auctionActionTypes from './actionTypes';
import './AuctionPage.scss';
import AuctionPageLeftPane from './AuctionPageLeftPane';
import AuctionPageRightPane from './AuctionPageRightPane';
import { getAuctionData, clearAuctionData } from './actions';

const propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const defaultProps = {};

const AuctionDetailsPage = ({ match, history }) => {
  const dispatch = useDispatch();

  const [auctioneers, setAuctioneers] = useState([]);
  const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);
  const [displayPriceSection, setDisplayPriceSection] = useState(false);
  const auctionState = useSelector(state => state.auction);
  const { isLoading, auctionMetric } = auctionState;

  const reloadPage = useCallback(() => {
    dispatch(getAuctionData(match.params.id));
    AuctionsSrv.getAuctioneers(match.params.id).then(res =>
      setAuctioneers(res),
    );
  }, [match.params.id, dispatch]);

  useEffect(() => {
    if (match.params.id && dispatch) {
      reloadPage();
    }
    return () => {
      dispatch(clearAuctionData());
    };
  }, [match.params.id, dispatch, reloadPage]);

  useEffect(() => {
    setDisplayPriceSection(
      auctionMetric &&
        auctionMetric.status &&
        auctionMetric.status.id === auctionStatusesIds.live,
    );
  }, [auctionMetric]);

  const isObservedChange = isObserved => {
    dispatch({
      type: auctionActionTypes.AUCTION_CHANGE_IS_OBSERVED,
      payload: isObserved,
    });
    AuctionsManagementSrv.isObservedChange(match.params.id, isObserved);
  };

  const onCancelConfirmed = reason => {
    !!reason &&
      AuctionsSrv.cancel(match.params.id, reason).then(() => {
        setCancelDialogIsOpen(false);
        reloadPage();
      });
  };

  const onDeleteConfirmed = () => {
    AuctionsSrv.delete(match.params.id).then(() => {
      history.push('/auctions-management');
    });
  };
  return (
    <>
      {isLoading ? (
        <AuctionPreviewPageLoader />
      ) : (
        <Row className="auction_page">
          <AuctionPageLeftPane
            isObservedChange={isObservedChange}
            reloadPage={reloadPage}
            auctionId={match.params.id}
            auctioneers={auctioneers}
            displayPriceSection={displayPriceSection}
            auctionStatus={auctionMetric.status}
            onCancelConfirmed={onCancelConfirmed}
            onDeleteConfirmed={onDeleteConfirmed}
            setCancelDialogIsOpen={setCancelDialogIsOpen}
          />
          <AuctionPageRightPane auctionId={match.params.id} />
        </Row>
      )}

      <CancelDialog
        isOpen={cancelDialogIsOpen}
        onCancelConfirmed={onCancelConfirmed}
        onCloseClick={() => setCancelDialogIsOpen(false)}
      />
    </>
  );
};

AuctionDetailsPage.propTypes = propTypes;
AuctionDetailsPage.defaultProps = defaultProps;

export default AuctionDetailsPage;
