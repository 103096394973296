import axios from 'config/axios';
import { downloadFile, getFileName } from 'hh-shared/dist/utils/filesHelper';

const prefix = 'reports/nav';

export default class ExportNavSrv {
  static getForGrid(dataState) {
    return axios
      .post(`${prefix}/getForGrid`,
        dataState,
      )
      .then(result => result.data);
  }

  static generate() {
    return axios.get(`${prefix}/generate`, { responseType: 'blob' }).then(response => {
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], { type, encoding: 'UTF-8' });
      const fileName = getFileName(response);
      return downloadFile(blob, fileName);
    });
  }

  static download(id) {
    return axios.get(`${prefix}/download/${id}`, { responseType: 'blob' }).then(response => {
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], { type, encoding: 'UTF-8' });
      const fileName = getFileName(response);
      return downloadFile(blob, fileName);
    });
  }
}
