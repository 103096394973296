import React, { memo } from 'react';
import gridNames from 'common/gridNames';
import createFoxGridContainer from 'fox-react/dist/components/FoxGrid/createFoxGridContainer';
import FoxButton from 'fox-react/dist/components/FoxButton';
import lang from 'hh-shared/dist/language/services/languageService';
import InnerContentWrapper from 'hh-shared/dist/components/layout/InnerContentWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { getForGrid } from './actions';
import FoxGridColumn from 'fox-react/dist/components/FoxGrid/FoxGridColumn';
import DateTimeColumnCell from 'hh-shared/dist/components/grid/DateTimeColumnCell';
import ExportNavSrv from './ExportNavSrv';

const FoxGridContainer = createFoxGridContainer(gridNames.exportNav);

function ExportNavPage() {
  const dispatch = useDispatch();
  const dataState = useSelector(
    state => state[gridNames.exportNav].dataState,
  );
  const onLoadData = ds => dispatch(getForGrid(ds));
  const refreshGrid = () => {
    onLoadData(dataState);
  };

  const generate = () => ExportNavSrv.generate().then(x => refreshGrid());
  const download = (id) => ExportNavSrv.download(id);

  return (
    <InnerContentWrapper fullWidth>
      <FoxGridContainer
        onLoadData={onLoadData}
        defaultSortField='number'
        defaultSortDir='desc'
        renderButtons={
          <FoxButton icon='add' primary onClick={generate}>
            {lang.buttons.Generate()}
          </FoxButton>
        }
      >
        <FoxGridColumn
          filterable={false}
          sortable={false}
          cell={({ dataItem }) => <FoxButton icon="download" onClick={() => download(dataItem.id)}>{lang.buttons.Download()}</FoxButton>}
        />
        <FoxGridColumn
          field='number'
          title={lang.labels.Number()}
          filter='text'
        />
        <FoxGridColumn
          field='createdBy'
          title={lang.labels.CreatedBy()}
          filter='text'
        />
        <FoxGridColumn
          field='createdOn'
          title={lang.labels.CreatedOn()}
          filter='date'
          filterable={false}
          cell={DateTimeColumnCell}
        />
      </FoxGridContainer>
    </InnerContentWrapper>
  );
}

export default memo(ExportNavPage);
