import React, { memo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { scrollToGrid } from 'hh-shared/dist/utils/scrollMethods';

import MenuSublink from './MenuSublink';

const propTypes = {
  stateFilterPath: PropTypes.string.isRequired,
  routePath: PropTypes.string.isRequired,
  filter: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  count: PropTypes.number,
};

const defaultProps = {
  filter: undefined,
  count: undefined,
};

function MenuSublinkGridFilter({
  stateFilterPath,
  routePath,
  filter,
  onClick,
  location,
  count,
  ...others
}) {
  const currentFilter = useSelector(state => get(state, stateFilterPath));
  const isFilterSet = isEqual(filter, currentFilter);
  const isActiveRoute = () => location.pathname.includes(routePath);

  const handleOnClick = () => {
    onClick();
    scrollToGrid();
  };

  return (
    <MenuSublink
      {...others}
      onClick={handleOnClick}
      routePath={routePath}
      isActive={isFilterSet && isActiveRoute()}
      count={count}
    />
  );
}

MenuSublinkGridFilter.propTypes = propTypes;
MenuSublinkGridFilter.defaultProps = defaultProps;

export default withRouter(memo(MenuSublinkGridFilter));
