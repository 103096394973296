import { gridStoreResults } from 'fox-react/dist/components/FoxGrid/actions';
import { prepareFormData } from 'hh-shared/dist/utils/filesHelper';
import gridNames from 'common/gridNames';
import PricesManagementSrv from './PricesManagementSrv';

export function storeResults(results) {
  return gridStoreResults(gridNames.pricesManagement)(results);
}

export function getForGrid(dataState) {
  return function(dispatch) {
    return PricesManagementSrv.getForGrid(dataState).then(results => {
      dispatch(storeResults(results));
    });
  };
}

export function importPrices(files) {
  return function() {
    return PricesManagementSrv.import(prepareFormData(files)).then(() =>
      getForGrid(),
    );
  };
}
