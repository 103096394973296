import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import FoxDropDownList from 'fox-react/dist/components/FoxDropDownList';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import icons from 'hh-shared/dist/consts/icons';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import AddressInformationForm from './AddressInformationForm';
import AddressesManagementSrv from './AddressesManagementSrv';
import AddressesManagementPagePreview from './AddressesManagementPagePreview';

const propTypes = {
  match: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  zipCodes: PropTypes.array.isRequired,
};

const defaultProps = {};

class AddressesManagementEditPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      versions: [],
      initialValues: {},
      showSpinner: false,
    };
    this.onChangeVersion = this.onChangeVersion.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitAsNew = this.onSubmitAsNew.bind(this);
  }

  componentDidMount() {
    this.setState({ showSpinner: true });

    Promise.all([
      AddressesManagementSrv.get(this.props.match.params.instanceId),
    ])
      .then(results => {
        const newVersions = sortBy(results[0], x => x.versionNumber);

        this.setState({
          versions: newVersions,
          initialValues: last(newVersions),
        });
      })
      .finally(() => this.setState({ showSpinner: false }));
  }

  onChangeVersion(e) {
    const newVersionNumber = e.target.value;
    this.setState(prevState => ({
      initialValues: prevState.versions.find(
        x => x.versionNumber === newVersionNumber,
      ),
    }));
  }

  onSubmit(model) {
    // TODO: sprawdzić czy działa w container (tak jak w innych miejscach), jeśli tak to usunąć stąd, poprawić wywołanie onSubmit i ujednolicić w całej aplikacji
    this.props.onSubmit({ ...model, id: this.state.initialValues.id });
  }

  onSubmitAsNew(model) {
    this.props.onSubmit({
      ...model,
      id: undefined,
      instanceId: this.props.match.params.instanceId,
    });
  }

  render() {
    const { history, zipCodes } = this.props;
    const { versions, initialValues, showSpinner } = this.state;
    const versionNumbers = versions.map(x => x.versionNumber);

    return (
      <>
        <BreadcrumbsContainer
          links={[
            {
              routePath: '/',
              label: lang.labels.HomePage(),
            },
            {
              routePath: '/addresses-management',
              label: lang.labels.Addresses(),
            },
            {
              routePath: '/addresses-management/add',
              label: lang.labels.EditingAddress(),
            },
          ]}
        />
        <FormPreviewSectionContainer>
          <FormCardWrapper>
            <SectionWrapper bottomSpace>
              <FormSectionCard
                title={lang.labels.Version()}
                titleIcon={icons.editedDocument}
              >
                <FoxDropDownList
                  items={versionNumbers}
                  value={initialValues.versionNumber}
                  onChange={this.onChangeVersion}
                  dataItemKey=""
                  textField=""
                />
              </FormSectionCard>
            </SectionWrapper>
            <div className={showSpinner ? 'fox_spinner' : undefined}>
              <AddressInformationForm
                onSubmitAsNew={this.onSubmitAsNew}
                onSubmit={this.onSubmit}
                initialValues={initialValues}
                history={history}
                zipCodes={zipCodes}
                isEditForm
              />
            </div>
          </FormCardWrapper>
          <AddressesManagementPagePreview editForm />
        </FormPreviewSectionContainer>
      </>
    );
  }
}

AddressesManagementEditPage.propTypes = propTypes;
AddressesManagementEditPage.defaultProps = defaultProps;

export default AddressesManagementEditPage;
