import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';

import formNames from 'common/formNames';
import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import FormPreviewPane from 'hh-shared/dist/components/layout/FormPreview/FormPreviewPaneContainer';
import FormPreviewSection from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSection';
import FormPreviewItem from 'hh-shared/dist/components/layout/FormPreview/FormPreviewItem';

const propTypes = {
  editForm: PropTypes.bool,
};

const defaultProps = {
  editForm: false,
};

const AddressesManagementPagePreview = ({ editForm }) => {
  const formValues = useSelector(
    state => getFormValues(formNames.addressInformationForm)(state) || {},
  );

  return (
    <FormPreviewPane>
      {editForm && (
        <FormPreviewSection
          title={lang.labels.Version()}
          icon={icons.editedDocument}
        >
          <FormPreviewItem
            label={lang.labels.Version()}
            value={formValues.versionNumber || undefined}
          />
        </FormPreviewSection>
      )}
      <FormPreviewSection
        title={lang.labels.AddressInformations()}
        icon={icons.address}
      >
        <FormPreviewItem
          label={lang.labels.ZipCode()}
          value={formValues.zipCode}
        />
        <FormPreviewItem
          label={lang.labels.City()}
          value={formValues.city || undefined}
        />
        <FormPreviewItem
          label={lang.labels.Street()}
          value={formValues.street || undefined}
        />
      </FormPreviewSection>
    </FormPreviewPane>
  );
};

AddressesManagementPagePreview.propTypes = propTypes;
AddressesManagementPagePreview.defaultProps = defaultProps;

export default AddressesManagementPagePreview;
