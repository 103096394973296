import { fieldChanged } from 'fox-react/dist/utils/formHelpers';
import get from 'lodash/get';
import { change } from 'redux-form';

function calculateDistance(
  props,
  loadingAddress,
  unloadingAddress,
  dispatch,
  formName,
) {
  if (
    !loadingAddress ||
    !unloadingAddress ||
    (loadingAddress.zipCode && loadingAddress.zipCode.length !== 6) ||
    (unloadingAddress.zipCode && unloadingAddress.zipCode.length !== 6)
  ) {
    return;
  }

  const route = props.routes.find(
    x =>
      (x.codeFrom === loadingAddress.zipCode ||
        x.codeTo === loadingAddress.zipCode) &&
      (x.codeFrom === unloadingAddress.zipCode ||
        x.codeTo === unloadingAddress.zipCode),
  );

  dispatch(change(formName, 'distance', route ? route.distance : null));
}

export function calculateCity(values, fieldName, zipCodes, formName, dispatch) {
  const zipCodeValue = get(values, `${fieldName}.zipCode`);
  if (!zipCodeValue || zipCodeValue.length !== 6) {
    return;
  }

  const zipCode = zipCodes.find(x => x.code === zipCodeValue);
  if (zipCode) {
    dispatch(change(formName, `${fieldName}.city`, zipCode.city));
  }
}

export function onChangeZipCodeFactory(formName) {
  return function onChangeZipCode(values, prevValues, props, dispatch) {
    if (!props.dirty) {
      return;
    }

    let loadingAddressLocation = 'loadingAddress';
    let unloadingAddressLocation = 'unloadingAddress';
    if (values.isNewLoadingAddress) {
      loadingAddressLocation = 'newLoadingAddress';

      if (fieldChanged(values, prevValues, loadingAddressLocation)) {
        calculateCity(
          values,
          loadingAddressLocation,
          props.zipCodes,
          formName,
          dispatch,
        );
      }
    }

    if (values.isNewUnloadingAddress) {
      unloadingAddressLocation = 'newUnloadingAddress';
      if (fieldChanged(values, prevValues, unloadingAddressLocation)) {
        calculateCity(
          values,
          unloadingAddressLocation,
          props.zipCodes,
          formName,
          dispatch,
        );
      }
    }

    if (
      fieldChanged(values, prevValues, loadingAddressLocation) ||
      fieldChanged(values, prevValues, unloadingAddressLocation)
    ) {
      calculateDistance(
        props,
        get(values, loadingAddressLocation),
        get(values, unloadingAddressLocation),
        dispatch,
        formName,
      );
    }
  };
}
