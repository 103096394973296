const columnNames = {
  usersManagement: {
    login: 'login',
    email: 'email',
    firstName: 'firstName',
    lastName: 'lastName',
    phoneNumber: 'phoneNumber',
    facility: 'facility',
    company: 'company',
  },
  htmlTemplatesManagement: {
    name: 'name',
    description: 'description',
    area: 'area',
  },
  departmentsManagement: {
    name: 'name',
    code: 'code',
  },
  companiesManagement: {
    name: 'name',
    address: 'address',
    zipCode: 'zipCode',
    city: 'city',
    nip: 'nip',
    regon: 'regon',
    phoneNumber: 'phoneNumber',
    isActive: 'isActive',
    participatedAuctionsNumber: 'participatedAuctionsNumber',
    winAuctionsNumber: 'winAuctionsNumber',
    acceptedTransportOrdersNumber: 'acceptedTransportOrdersNumber',
    orderedTransportOrdersNumber: 'orderedTransportOrdersNumber',
  },
  zipCodesManagement: {
    zipCode: 'zipCode',
    city: 'city',
  },
  carTypesManagement: {
    name: 'name',
    cars: 'cars',
  },
  carsManagement: {
    registerNumber: 'registerNumber',
    comment: 'comment',
    carType: 'carType',
    company: 'company',
  },
  facilitiesManagement: {
    name: 'name',
    street: 'street',
    zipCode: 'zipCode',
    city: 'city',
    email: 'email',
    phoneNumber: 'phoneNumber',
    department: 'department',
    code: 'code',
  },
  routesManagement: {
    zipCodeFrom: 'zipCodeFrom',
    zipCodeTo: 'zipCodeTo',
    distance: 'distance',
  },
  currenciesManagement: {
    name: 'name',
    isDefault: 'isDefault',
  },
  auctionsManagement: {
    number: 'number',
    auctionType: 'auctionType',
    startTime: 'startTime',
    endTime: 'endTime',
    principalPerson: 'principalPerson',
    facility: 'facility',
    loadingTime: 'loadingTime',
    loadingAddress: 'loadingAddress',
    unloadingTime: 'unloadingTime',
    unloadingAddress: 'unloadingAddress',
    authorizedPickupPerson: 'authorizedPickupPerson',
    authorizedPickupPersonPhoneNumber: 'authorizedPickupPersonPhoneNumber',
    distance: 'distance',
    pricePerKilometer: 'pricePerKilometer',
    startPrice: 'startPrice',
    capacity: 'capacity',
    loadingMethod: 'loadingMethod',
    unloadingMethod: 'unloadingMethod',
    isObserved: 'isObserved',
    timeLeft: 'timeLeft',
    currentPrice: 'currentPrice',
    status: 'status',
    transportCode: 'transportCode',
  },
  transportOrdersManagement: {
    number: 'number',
    facility: 'facility',
    company: 'company',
    driver: 'driver',
    loadingTime: 'loadingTime',
    loadingAddress: 'loadingAddress',
    unloadingTime: 'unloadingTime',
    unloadingAddress: 'unloadingAddress',
    authorizedPickupPerson: 'authorizedPickupPerson',
    authorizedPickupPersonPhoneNumber: 'authorizedPickupPersonPhoneNumber',
    distance: 'distance',
    value: 'value',
    car: 'car',
    capacity: 'capacity',
    loadingMethod: 'loadingMethod',
    unloadingMethod: 'unloadingMethod',
    additionalRequirements: 'additionalRequirements',
    auctionNumber: 'auctionNumber',
    status: 'status',
    transportCode: 'transportCode',
    exportNavNumber: 'exportNavNumber',
  },
  addressesManagement: {
    street: 'street',
    zipCode: 'zipCode',
    city: 'city',
  },
  pricesManagement: {
    code: 'code',
    zoneNo: 'zoneNo',
    description: 'description',
    shipmentMethod: 'shipmentMethod',
    shippingAgentCode: 'shippingAgentCode',
    shippingAgentServiceCode: 'shippingAgentServiceCode',
    currencyCode: 'currencyCode',
    flatRate: 'flatRate',
    ratePerBaseUnit: 'ratePerBaseUnit',
    minDistance: 'minDistance',
    maxDistance: 'maxDistance',
    minBasis: 'minBasis',
    maxBasis: 'maxBasis',
    type: 'type',
    no: 'no',
    zipCode: 'zipCode',
    buyerRebateGroup: 'buyerRebateGroup',
    nip: 'nip',
    company: 'company',
  },
  defaultPricesManagement: {
    flatRate: 'flatRate',
    minDistance: 'minDistance',
    maxDistance: 'maxDistance',
    facilityVersionName: 'facilityVersionName',
  },
};

export default columnNames;
