import React from 'react';
import moment from 'moment';
import cx from 'classnames';
import PropTypes from 'prop-types';

import lang from 'hh-shared/dist/language/services/languageService';
import numberFormating from 'hh-shared/dist/consts/numberFormating';
import TableRowRevealer from 'hh-shared/dist/components/commons/TableRowRevealer';
import BidsTableSupplierContainer from './BidsTableSupplierContainer';

import styles from './BidsTable.module.scss';

const propTypes = {
  bids: PropTypes.arrayOf(PropTypes.object).isRequired,
  priceStep: PropTypes.number.isRequired,
};

const defaultProps = {};

const BidsTable = ({ bids, priceStep }) => {
  const { toMoneyFormat } = numberFormating;

  const getPriceDifference = bidIndex =>
    bids[bidIndex].price - bids[bidIndex + 1].price;

  const hoursLabelClassName = cx(
    'text',
    'text_info',
    'display-block',
    styles.hours_label,
  );

  return (
    <table className={styles.bids_table}>
      <thead>
        <tr>
          <th className={styles.header_index}>{lang.labels.OrdinalNumber()}</th>
          <th className={styles.header_offerts}>{lang.labels.Offerts()}</th>
          <th className={styles.header_time}>{lang.labels.Time()}</th>
          <th className={styles.header_suppliers}>{lang.labels.Suppliers()}</th>
        </tr>
      </thead>
      <tbody>
        {bids.map((bid, bidIndex) => (
          // NOTE: nie używam tutaj jako key -> bidIndex, bo wtedy re-renderuje się element z listy na samym dole, czyli ten, którego user nie widzi. Gdy wykorzystuje inne dane do zrobienia key, to re-renderowany jest element na samej górze, bo jego dane jeszcze nie pojawiły się w tabeli :D
          <TableRowRevealer key={`${bid.price}${bid.creationDate}`}>
            <td
              data-table-header={lang.labels.OrdinalNumber()}
              className={styles.cell_index}
            >
              <span className={styles.index_cell}>
                {bids.length - bidIndex}
              </span>
            </td>
            <td
              data-table-header={lang.labels.Offerts()}
              className={styles.cell_offert}
            >
              <div>{toMoneyFormat(bid.price)}</div>
              {bidIndex !== bids.length - 1 && (
                <div
                  className={`${styles.difference_label} ${
                    getPriceDifference(bidIndex) !== -priceStep
                      ? styles.difference_label_accent
                      : ''
                  }`}
                >
                  {toMoneyFormat(getPriceDifference(bidIndex))}
                </div>
              )}
            </td>
            <td
              data-table-header={lang.labels.Time()}
              className={styles.cell_time}
            >
              {moment(bid.createdDate).format('DD.MM.YYYY')}
              <span className={hoursLabelClassName}>
                {moment(bid.createdDate).format('HH:mm:ss')}
              </span>
            </td>
            <td
              data-table-header={lang.labels.Suppliers()}
              className={styles.cell_company_name}
            >
              <BidsTableSupplierContainer
                companyInstanceId={bid.companyInstanceId}
                initiatorUserInstanceId={bid.initiatorUserInstanceId}
              />
              {/* <span>{bid.companyName}</span>
              <Row className={badgeRowClassName}>
                <PersonDataBadge
                  fullName={bid.userName}
                  avatar={bid.userAvatar}
                  phoneNumber={bid.userPhone || undefined}
                  className={styles.bid_creator_label}
                />
              </Row> */}
            </td>
          </TableRowRevealer>
        ))}
      </tbody>
    </table>
  );
};

BidsTable.propTypes = propTypes;
BidsTable.defaultProps = defaultProps;

export default BidsTable;
