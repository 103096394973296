import { change, formValueSelector } from 'redux-form';
import get from 'lodash/get';

import formNames from 'common/formNames';
import fieldNames from 'common/fieldNames';
import auctionTypesIds from 'common/auctionTypesIds';
import {
  getPricePerKilometer,
  getStartPrice,
  getPricesByDistance,
} from 'common/pricesCalucator';
import { getDefaultPrices } from './actions';

const formSelector = formValueSelector(formNames.auctionInformationForm);

function clearPrices(dispatch) {
  dispatch(
    change(
      formNames.auctionInformationForm,
      fieldNames.auctionInformationForm.pricePerKilometer,
      null,
    ),
  );
  dispatch(
    change(
      formNames.auctionInformationForm,
      fieldNames.auctionInformationForm.startPrice,
      null,
    ),
  );
}

function calculatePrices(prices, distance, zipCode, dispatch) {
  const pricePerKilometer = getPricePerKilometer(prices, distance, zipCode);
  const startPrice = getStartPrice(prices, distance, zipCode);

  dispatch(
    change(
      formNames.auctionInformationForm,
      fieldNames.auctionInformationForm.pricePerKilometer,
      pricePerKilometer,
    ),
  );
  dispatch(
    change(
      formNames.auctionInformationForm,
      fieldNames.auctionInformationForm.startPrice,
      startPrice,
    ),
  );
}

export function changePrices(
  subjects,
  distance,
  defaultPrices,
  zipCode,
  dispatch,
) {
  if (!distance) {
    clearPrices(dispatch);
  } else if (
    subjects &&
    subjects.length === 1 &&
    subjects[0] &&
    subjects[0].prices &&
    subjects[0].prices.length &&
    getPricesByDistance(subjects[0].prices, distance).length
  ) {
    calculatePrices(subjects[0].prices, distance, zipCode, dispatch);
  } else {
    calculatePrices(defaultPrices, distance, zipCode, dispatch);
  }
}

export const commonMapStateToProps = state => {
  return {
    showSubjects:
      formSelector(state, fieldNames.auctionInformationForm.auctionType) ===
      auctionTypesIds.privateId,
    auctionTypes: state.dictionary.static.auctionTypes,
    loadingMethods: state.dictionary.static.loadingMethods,
    unloadingMethods: state.dictionary.static.unloadingMethods,
    currentUserId: get(state, 'auth.currentUser.unique_name'),
    subjects: formSelector(state, fieldNames.auctionInformationForm.subjects),
    distance: formSelector(state, fieldNames.auctionInformationForm.distance),
    startPrice: formSelector(
      state,
      fieldNames.auctionInformationForm.startPrice,
    ),
    loadingAddressZipCode: get(
      state,
      'form.auctionInformationForm.values.loadingAddress.zipCode',
    ),
    newLoadingAddressZipCode: get(
      state,
      'form.auctionInformationForm.values.newLoadingAddress.zipCode',
    ),
    isNewLoadingAddress: formSelector(
      state,
      fieldNames.auctionInformationForm.isNewLoadingAddress,
    ),
    defaultPrices: state.defaultPrices,
    routes: state.dictionary.static.routes,
  };
};

export const commonMapDispatchToProps = dispatch => {
  return {
    onGetDefaultPrices: () => dispatch(getDefaultPrices()),
    onSubjectsChange: (subjects, distance, defaultPrices, zipCode) => {
      changePrices(subjects, distance, defaultPrices, zipCode, dispatch);
    },
    onDistanceChange: (distance, subjects, defaultPrices, zipCode) => {
      changePrices(subjects, distance, defaultPrices, zipCode, dispatch);
    },
    onPricePerKilometerChange: (pricePerKilometer, distance) => {
      if (pricePerKilometer && distance) {
        dispatch(
          change(
            formNames.auctionInformationForm,
            fieldNames.auctionInformationForm.startPrice,
            distance * pricePerKilometer,
          ),
        );
      }
    },
    onStartPriceChange: (startPrice, distance) => {
      if (startPrice && distance) {
        dispatch(
          change(
            formNames.auctionInformationForm,
            fieldNames.auctionInformationForm.pricePerKilometer,
            startPrice / distance,
          ),
        );
      }
    },
    onResetPrices: (subjects, distance, defaultPrices, zipCode) => {
      changePrices(subjects, distance, defaultPrices, zipCode, dispatch);
    },
  };
};

export const commonMergeProps = (
  propsFromState,
  propsFromDispatch,
  ownProps,
) => {
  return {
    ...propsFromState,
    ...propsFromDispatch,
    onSubjectsChange: subjects =>
      propsFromDispatch.onSubjectsChange(
        subjects,
        propsFromState.distance,
        propsFromState.defaultPrices,
        propsFromState.isNewLoadingAddress
          ? propsFromState.newLoadingAddressZipCode
          : propsFromState.loadingAddressZipCode,
      ),
    onDistanceChange: distance =>
      propsFromDispatch.onDistanceChange(
        distance,
        propsFromState.subjects,
        propsFromState.defaultPrices,
        propsFromState.isNewLoadingAddress
          ? propsFromState.newLoadingAddressZipCode
          : propsFromState.loadingAddressZipCode,
      ),
    onPricePerKilometerChange: pricePerKilometer =>
      propsFromDispatch.onPricePerKilometerChange(
        pricePerKilometer,
        propsFromState.distance,
      ),
    onStartPriceChange: startPrice =>
      propsFromDispatch.onStartPriceChange(startPrice, propsFromState.distance),
    onResetPrices: () =>
      propsFromDispatch.onResetPrices(
        propsFromState.subjects,
        propsFromState.distance,
        propsFromState.defaultPrices,
        propsFromState.isNewLoadingAddress
          ? propsFromState.newLoadingAddressZipCode
          : propsFromState.loadingAddressZipCode,
      ),
    ...ownProps,
  };
};

export function onChangeAddresses(values, prevValues, props, dispatch) {
  if (!props.dirty) {
    return;
  }

  const { loadingAddress, unloadingAddress } = values;

  if (
    loadingAddress !== prevValues.loadingAddress ||
    unloadingAddress !== prevValues.unloadingAddress
  ) {
    if (!loadingAddress || !unloadingAddress) {
      return;
    }

    const route = props.routes.find(
      x =>
        (x.codeFrom === loadingAddress.zipCode ||
          x.codeTo === loadingAddress.zipCode) &&
        (x.codeFrom === unloadingAddress.zipCode ||
          x.codeTo === unloadingAddress.zipCode),
    );
    if (route) {
      dispatch(
        change(
          formNames.auctionInformationForm,
          fieldNames.auctionInformationForm.distance,
          route.distance,
        ),
      );
    }
  }
}
