import fieldNames from 'common/fieldNames';
import {
  isRequiredArray,
  isDateFromTo,
  isRequired,
  areNotEquals,
} from 'fox-react/dist/utils/formValidators';
import lang from 'hh-shared/dist/language/services/languageService';

const fnames = fieldNames.auctionInformationForm;

export function auctionValidationAddresses(errors, values) {
  if (!values.isNewLoadingAddress) {
    isRequired(
      errors,
      values,
      fnames.loadingAddress,
      lang.validationMessages.FieldRequired(),
    );
  } else {
    isRequiredArray(
      errors,
      values,
      [
        'newLoadingAddress.zipCode',
        'newLoadingAddress.city',
        'newLoadingAddress.street',
      ],
      lang.validationMessages.FieldRequired(),
    );
  }

  if (!values.isNewUnloadingAddress) {
    isRequired(
      errors,
      values,
      fnames.unloadingAddress,
      lang.validationMessages.FieldRequired(),
    );
  } else {
    isRequiredArray(
      errors,
      values,
      [
        'newUnloadingAddress.zipCode',
        'newUnloadingAddress.city',
        'newUnloadingAddress.street',
      ],
      lang.validationMessages.FieldRequired(),
    );
  }

  if (!values.isNewUnloadingAddress && !values.isNewLoadingAddress) {
    areNotEquals(
      errors,
      values,
      [fnames.unloadingAddress, fnames.loadingAddress],
      lang.validationMessages.LoadingUnloadingAddressesCannotBeTheSame(),
    );
  }
}

export const validate = values => {
  const errors = {};

  isRequiredArray(
    errors,
    values,
    [
      fnames.startTime,
      fnames.endTime,
      fnames.principalPerson,
      fnames.facility,
      fnames.loadingTime,
      fnames.unloadingTime,
      fnames.distance,
      fnames.pricePerKilometer,
      fnames.startPrice,
      fnames.capacity,
      fnames.loadingMethod,
      fnames.unloadingMethod,
      fnames.auctionType,
      fnames.loadingTimeTo,
      fnames.unloadingTimeTo,
      fnames.transportCode,
    ],
    lang.validationMessages.FieldRequired(),
  );

  if (!values.loadingTimeFix) {
    isRequired(
      errors,
      values,
      fnames.loadingTimeFrom,
      lang.validationMessages.FieldRequired(),
    );
  }

  if (!values.unloadingTimeFix) {
    isRequired(
      errors,
      values,
      fnames.unloadingTimeFrom,
      lang.validationMessages.FieldRequired(),
    );
  }

  auctionValidationAddresses(errors, values);

  isDateFromTo(
    errors,
    values,
    fnames.startTime,
    fnames.endTime,
    lang.validationMessages.TimeFromCannotBeLaterThanTimeTo(),
  );

  isDateFromTo(
    errors,
    values,
    fnames.loadingTimeTo,
    fnames.unloadingTimeTo,
    lang.validationMessages.LoadingTimeCannotBeLaterThanUnloadingTime(),
  );

  if (!values.loadingTimeFix) {
    isRequired(
      errors,
      values,
      fnames.loadingTimeFrom,
      lang.validationMessages.FieldRequired(),
    );
  }

  if (!values.unloadingTimeFix) {
    isRequired(
      errors,
      values,
      fnames.unloadingTimeFrom,
      lang.validationMessages.FieldRequired(),
    );
  }

  return errors;
};
