import lang from 'hh-shared/dist/language/services/languageService';
import moment from 'moment';

export const transportOrderAllFilter = undefined;

const generateStatusFilter = value => ({
  field: 'status',
  operator: 'eqs',
  value,
});

const realizedOrLoadedFilter = {
  filters: [
    generateStatusFilter(lang.labels.Realized()),
    generateStatusFilter(lang.labels.LoadedTransportOrderStatus()),
  ],
  logic: 'or',
};

export const transportOrderAcceptedFilter = {
  logic: 'and',
  filters: [generateStatusFilter(lang.labels.AcceptedTransportOrderStatus())],
};

export const transportOrderCanceledFilter = {
  logic: 'and',
  filters: [generateStatusFilter(lang.labels.Canceled())],
};

export const transportOrderLoadedFilter = {
  logic: 'and',
  filters: [generateStatusFilter(lang.labels.LoadedTransportOrderStatus())],
};

export const transportOrderCompletedFilter = {
  logic: 'and',
  filters: [generateStatusFilter(lang.labels.CompletedTransportOrderStatus())],
};

export const transportOrderPlacedFilter = {
  logic: 'and',
  filters: [generateStatusFilter(lang.labels.Placed())],
};

const generateLastFilterRange = (field, rangeName) => {
  return [
    {
      field,
      operator: 'dgt',
      value: moment()
        .subtract(1, rangeName)
        .startOf(rangeName)
        .toJSON(),
    },
    {
      field,
      operator: 'dlt',
      value: moment()
        .subtract(1, rangeName)
        .endOf(rangeName)
        .toJSON(),
    },
  ];
};

export const transportOrderLastWeekFilter = {
  logic: 'and',
  filters: [
    ...generateLastFilterRange('loadingTime', 'isoWeek'),
    realizedOrLoadedFilter,
  ],
};

export const transportOrderLastMonthFilter = {
  logic: 'and',
  filters: [
    ...generateLastFilterRange('loadingTime', 'month'),
    realizedOrLoadedFilter,
  ],
};

export const transportOrderLastYearFilter = {
  logic: 'and',
  filters: [
    ...generateLastFilterRange('loadingTime', 'year'),
    realizedOrLoadedFilter,
  ],
};
