import { connect } from 'react-redux';
import formNames from 'common/formNames';
import { formValueSelector } from 'redux-form';
import fieldNames from 'common/fieldNames';
import { updateUserInformationAsync } from '../actions';
import UserInformationForm from './UserInformationForm';

const selector = formValueSelector(formNames.myProfileForm);

const mapStateToProps = state => {
  return {
    initialValues: {
      login: state.auth.currentUser.login,
      avatar: state.auth.currentUser.avatar,
    },
    firstName: state.auth.currentUser.firstName,
    avatar: selector(state, fieldNames.myProfileUserInformationForm.avatar),
  };
};

const mapDispathToProps = dispatch => {
  return {
    onSubmit: model => dispatch(updateUserInformationAsync(model)),
  };
};

const UserInformationFormContainer = connect(
  mapStateToProps,
  mapDispathToProps,
)(UserInformationForm);

export default UserInformationFormContainer;
