import React, { useEffect, memo } from 'react';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import { useCountUp } from 'react-countup';
import PropTypes from 'prop-types';
import Column from 'layout/Column';
import styles from './DashboardCardBox.module.scss';

const propTypes = {
  quantity: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  delay: PropTypes.number,
  onClick: PropTypes.func,
  history: PropTypes.object.isRequired,
  link: PropTypes.string,
  color: PropTypes.oneOf(['blue', 'red']),
};

const defaultProps = {
  delay: 0.5,
  onClick: () => {},
  link: '/',
  color: undefined,
};

const DashboardCardBox = ({
  quantity,
  label,
  delay,
  onClick,
  history,
  link,
  color,
}) => {
  const boxClassName = cx(styles.box, {
    [styles[color]]: color,
  });

  const { countUp, update: updateValue } = useCountUp({
    start: 0,
    end: quantity,
    duration: 3,
    delay,
  });

  useEffect(() => {
    updateValue(quantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  const handleOnClick = () => {
    onClick();
    history.push(link);
  };

  return (
    <div className={styles.box_wrapper}>
      <Column className={boxClassName} onClick={handleOnClick}>
        <div className={styles.box_quantity}>{countUp}</div>
        <div className={styles.box_label}>{label}</div>
      </Column>
    </div>
  );
};

DashboardCardBox.propTypes = propTypes;
DashboardCardBox.defaultProps = defaultProps;

export default memo(withRouter(DashboardCardBox));
