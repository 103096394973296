"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _FoxButton = _interopRequireDefault(require("./FoxButton"));

var _FoxConfirmationDialog = _interopRequireDefault(require("./FoxConfirmationDialog"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var propTypes = {
  buttonText: _propTypes.default.string,
  title: _propTypes.default.string,
  body: _propTypes.default.string,
  noLabel: _propTypes.default.string,
  yesLabel: _propTypes.default.string,
  onCloseClick: _propTypes.default.func,
  onNoClick: _propTypes.default.func,
  onYesClick: _propTypes.default.func,
  className: _propTypes.default.string,
  renderButton: _propTypes.default.func
};
var defaultProps = {
  buttonText: 'Usuń',
  title: undefined,
  body: undefined,
  noLabel: undefined,
  yesLabel: undefined,
  onCloseClick: undefined,
  onNoClick: undefined,
  onYesClick: undefined,
  className: '',
  renderButton: undefined
};

function FoxConfirmationButton(_ref) {
  var buttonText = _ref.buttonText,
      title = _ref.title,
      body = _ref.body,
      noLabel = _ref.noLabel,
      yesLabel = _ref.yesLabel,
      onCloseClick = _ref.onCloseClick,
      onNoClick = _ref.onNoClick,
      onYesClick = _ref.onYesClick,
      className = _ref.className,
      renderButton = _ref.renderButton;

  var _useState = (0, _react.useState)(false),
      _useState2 = _slicedToArray(_useState, 2),
      isVisible = _useState2[0],
      setIsVisible = _useState2[1];

  var showDialog = function showDialog() {
    setIsVisible(true);
  };

  var onClose = function onClose() {
    onCloseClick && onCloseClick();
    setIsVisible(false);
  };

  var onNo = function onNo() {
    onNoClick && onNoClick();
    setIsVisible(false);
  };

  var onYes = function onYes() {
    onYesClick && onYesClick();
    setIsVisible(false);
  };

  return _react.default.createElement(_react.default.Fragment, null, renderButton ? renderButton(showDialog) : _react.default.createElement(_FoxButton.default, {
    type: "button",
    onClick: showDialog,
    className: className
  }, buttonText), isVisible && _react.default.createElement(_FoxConfirmationDialog.default, {
    title: title,
    body: body,
    noLabel: noLabel,
    yesLabel: yesLabel,
    onCloseClick: onClose,
    onNoClick: onNo,
    onYesClick: onYes
  }));
}

FoxConfirmationButton.propTypes = propTypes;
FoxConfirmationButton.defaultProps = defaultProps;
var _default = FoxConfirmationButton;
exports.default = _default;