import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import FontAwesomeIcon from 'hh-shared/dist/components/commons/FontAwesomeIcon/FontAwesomeIcon';

import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import Column from 'hh-shared/dist/components/layout/Column';
import Row from 'hh-shared/dist/components/layout/Row';

import DashboardButton from './DashboardButton';
import OrderCardBoxes from './OrderCardBoxes';
import GreyTitle from './GreyTitle';
import styles from './DashboardCard.module.scss';
import { RaportDiagrams } from './Reports';

const propTypes = {
  ordersData: PropTypes.shape({
    placedCount: PropTypes.number,
    acceptedCount: PropTypes.number,
    loadedCount: PropTypes.number,
    realizedCount: PropTypes.number,
  }),
  hasDepAdminEmployee: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  ordersData: undefined,
  hasDepAdminEmployee: false,
  className: '',
};

const OrdersDashboardCard = ({
  ordersData,
  hasDepAdminEmployee,
  className,
}) => {
  const cardContainerClassName = cx(styles.card_container, className);

  return (
    <Column className={cardContainerClassName}>
      <Row className="align-items-center justify-content-space-between">
        <Row className="align-items-center width-fit-content">
          <FontAwesomeIcon
            icon={icons.order}
            title={lang.labels.Orders()}
            className={styles.icon}
          />
          <div className={styles.title}>{lang.labels.Orders()}</div>
        </Row>
        {hasDepAdminEmployee && (
          <DashboardButton
            tag={Link}
            title={lang.labels.AddNewOrder()}
            to="/transport-orders-management/add"
            icon={icons.plus}
            withIconAnimation
          >
            {lang.labels.AddNew_Masculine()}
          </DashboardButton>
        )}
      </Row>
      <Column>
        <GreyTitle>{lang.labels.CurrentlyYouHave()}:</GreyTitle>
        <OrderCardBoxes {...ordersData} />
      </Column>
      <RaportDiagrams />
    </Column>
  );
};

OrdersDashboardCard.propTypes = propTypes;
OrdersDashboardCard.defaultProps = defaultProps;

export default OrdersDashboardCard;
