import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import createFoxGridContainer from 'fox-react/dist/components/FoxGrid/createFoxGridContainer';
import FoxButton from 'fox-react/dist/components/FoxButton';
import FoxGridColumn from 'fox-react/dist/components/FoxGrid/FoxGridColumn';

import lang from 'hh-shared/dist/language/services/languageService';
import PermissionWrapper from 'commons/PermissionWrapper';
import gridNames from 'common/gridNames';
import columnNames from 'common/columnNames';
import permissions from 'common/permissions';
import InnerContentWrapper from 'hh-shared/dist/components/layout/InnerContentWrapper';
import { useDispatch } from 'react-redux';
import actionsColumnGridDefaults from 'hh-shared/dist/consts/actionsColumnGridDefaults';
import { getForGrid } from './actions';

const propTypes = {
  match: PropTypes.object.isRequired,
};

const defaultProps = {};

const FoxGridContainer = createFoxGridContainer(
  gridNames.departmentsManagement,
);

function DepartmentsManagementPage({ match }) {
  const dispatch = useDispatch();
  const onLoadData = dataState => dispatch(getForGrid(dataState));

  return (
    <InnerContentWrapper fullWidth>
      <FoxGridContainer
        onLoadData={onLoadData}
        defaultSortField={columnNames.departmentsManagement.name}
        renderButtons={
          <PermissionWrapper oneOfPermissions={[permissions.ADMIN]}>
            <Link to={`${match.url}/add`} className="add-button">
              <FoxButton icon="add" primary>
                {lang.buttons.Add()}
              </FoxButton>
            </Link>
          </PermissionWrapper>
        }
      >
        <FoxGridColumn
          {...actionsColumnGridDefaults}
          cell={({ dataItem }) => (
            <td>
              <Link to={`${match.url}/edit/${dataItem.id}`}>
                <FoxButton icon="edit">{lang.buttons.Edit()}</FoxButton>
              </Link>
            </td>
          )}
        />
        <FoxGridColumn
          field={columnNames.departmentsManagement.name}
          title={lang.labels.UserManagement_Columns_Name()}
          filter="text"
        />
        <FoxGridColumn
          field={columnNames.departmentsManagement.code}
          title={lang.labels.Code()}
          filter="text"
        />
      </FoxGridContainer>
    </InnerContentWrapper>
  );
}

DepartmentsManagementPage.propTypes = propTypes;
DepartmentsManagementPage.defaultProps = defaultProps;

export default DepartmentsManagementPage;
