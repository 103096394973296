import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import Form from 'fox-react/dist/components/Form';
import FoxInputTextField from 'fox-react/dist/reduxFormFields/FoxInputTextField';
import FoxComboBoxField from 'fox-react/dist/reduxFormFields/FoxComboBoxField';
import FoxButton from 'fox-react/dist/components/FoxButton';
import { isRequiredArray, isNip } from 'fox-react/dist/utils/formValidators';
import goBackAfterSuccess from 'fox-react/dist/utils/goBackAfterSuccess';
import lang from 'hh-shared/dist/language/services/languageService';
import {
  scrollToTheFirstError,
  displayCompletionToasts,
} from 'hh-shared/dist/consts/reduxFormMethods';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';
import fieldNames from 'common/fieldNames';
import formNames from 'common/formNames';
import icons from 'hh-shared/dist/consts/icons';
import { toUpper } from 'fox-react/dist/utils/reduxFormUtils';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  onSubmitAsNew: PropTypes.func,
  carTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const defaultProps = {
  onSubmitAsNew: undefined,
};

function CarInformationForm({
  handleSubmit,
  submitting,
  onSubmitAsNew,
  carTypes,
  companies,
}) {
  const submitAsNew = () => {
    handleSubmit(onSubmitAsNew)();
  };

  return (
    <div className="form-container">
      <Form handleSubmit={handleSubmit}>
        <fieldset>
          <SectionWrapper>
            <FormSectionCard
              title={lang.labels.CarInformations()}
              titleIcon={icons.vehicle}
            >
              <FoxInputTextField
                label={lang.labels.RegisterNumber()}
                placeholder={lang.labels.RegisterNumber()}
                name={fieldNames.carInformationForm.registerNumber}
                format={toUpper}
                required
              />

              <FoxComboBoxField
                label={lang.labels.CarType()}
                name={fieldNames.carInformationForm.carType}
                items={carTypes}
                required
              />

              <FoxInputTextField
                label={lang.labels.Comment()}
                placeholder={lang.labels.Comment()}
                name={fieldNames.carInformationForm.comment}
              />

              <FoxComboBoxField
                label={lang.labels.Company()}
                name={fieldNames.carInformationForm.company}
                items={companies}
                required
              />
            </FormSectionCard>
          </SectionWrapper>
        </fieldset>
        <FoxButton primary disabled={submitting}>
          {lang.buttons.Save()}
        </FoxButton>
        {onSubmitAsNew && (
          <FoxButton
            type="button"
            primary
            disabled={submitting}
            onClick={submitAsNew}
          >
            {lang.buttons.SaveAsNew()}
          </FoxButton>
        )}
      </Form>
    </div>
  );
}

CarInformationForm.propTypes = propTypes;
CarInformationForm.defaultProps = defaultProps;

const validate = values => {
  const errors = {};

  isRequiredArray(
    errors,
    values,
    [
      fieldNames.carInformationForm.registerNumber,
      fieldNames.carInformationForm.carType,
      fieldNames.carInformationForm.company,
    ],
    lang.validationMessages.FieldRequired(),
  );

  isNip(
    errors,
    values,
    fieldNames.carInformationForm.nip,
    lang.validationMessages.InvalidNip(),
  );

  return errors;
};

const onSubmitSuccess = (result, dispatch, props) =>
  displayCompletionToasts(
    dispatch,
    !!props.isEditForm,
    lang.labels.SuccessfullyModificatedVehicle(),
    lang.labels.SuccessfullyCreatedVehicle(),
  );

export default reduxForm({
  form: formNames.carInformationForm,
  validate,
  enableReinitialize: true,
  onSubmitFail: scrollToTheFirstError,
  onSubmitSuccess,
})(goBackAfterSuccess(CarInformationForm));
