import React from 'react';
import FoxInputTextField from 'fox-react/dist/reduxFormFields/FoxInputTextField';
import lang from 'hh-shared/dist/language/services/languageService';
import fieldNames from 'common/fieldNames';
import ZipCodeAutocompleteField from 'commons/ZipCodeAutocompleteField';
import { isRequiredFieldValidator } from 'hh-shared/dist/utils/formValidators';

const propTypes = {};

const defaultProps = {};

function NewZipCodeForm() {
  return (
    <>
      <ZipCodeAutocompleteField required validate={isRequiredFieldValidator} />

      <FoxInputTextField
        label={lang.labels.City()}
        placeholder={lang.labels.City()}
        name={fieldNames.newZipCodeInformationForm.city}
        required
        validate={isRequiredFieldValidator}
      />
    </>
  );
}

NewZipCodeForm.propTypes = propTypes;
NewZipCodeForm.defaultProps = defaultProps;

export default NewZipCodeForm;
