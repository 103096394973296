import {useDispatch} from 'react-redux';
import {displayToastr} from 'hh-shared/dist/toastr/toastrActions';
import ToastrTypes from 'hh-shared/src/lib/toastr/ToastrTypes';

/**
 * Toastr notifications
 * @returns {{success: (function(message: string): *), info: (function(message: string): *)}}
 */
export function useToastr() {
  const dispatch = useDispatch();

  const success = (message) => dispatch(displayToastr(message, ToastrTypes.success));
  const info = (message) => dispatch(displayToastr(message, ToastrTypes.info));

  return {
    success,
    info
  };
}
