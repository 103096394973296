"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Row = _interopRequireDefault(require("layout/Row"));

var _Column = _interopRequireDefault(require("layout/Column"));

var _FormPreviewItemListModule = _interopRequireDefault(require("./FormPreviewItemList.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  label: _propTypes.default.string.isRequired,
  values: _propTypes.default.arrayOf(_propTypes.default.string).isRequired
};
var defaultProps = {};

var FormPreviewItemList = function FormPreviewItemList(_ref) {
  var label = _ref.label,
      values = _ref.values;
  return _react.default.createElement(_Row.default, {
    className: _FormPreviewItemListModule.default.list_container
  }, _react.default.createElement("div", {
    className: _FormPreviewItemListModule.default.label
  }, "".concat(label, ": ")), _react.default.createElement(_Column.default, {
    className: _FormPreviewItemListModule.default.value_list
  }, values.map(function (value, valueIndex) {
    return _react.default.createElement("span", {
      className: _FormPreviewItemListModule.default.value,
      key: valueIndex
    }, "".concat(values.length > 1 ? '-' : '', " ").concat(value));
  }), values.length < 1 && '-'));
};

FormPreviewItemList.propTypes = propTypes;
FormPreviewItemList.defaultProps = defaultProps;
var _default = FormPreviewItemList;
exports.default = _default;