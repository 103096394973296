export default {
  ADMIN: 'ADMIN',
  DEP_ADMIN: 'DEP_ADMIN',
  EMPLOYEE: 'EMPLOYEE',
  LOGISTICIAN: 'LOGISTICIAN',
  DRIVER: 'DRIVER',
  PRICES_MANAGEMENT: 'PRICES_MANAGEMENT',
  ACTIVATE_COMPANY: 'ACTIVATE_COMPANY',
  EXPORT_NAV: 'EXPORT_NAV'
};
