import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import FoxCustomDialog from 'fox-react/dist/components/FoxCustomDialog';
import FoxInputTextarea from 'fox-react/dist/components/FoxInputTextarea';
import lang from 'hh-shared/dist/language/services/languageService';

const propTypes = {
  isOpen: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onCancelConfirmed: PropTypes.func.isRequired,
};

const defaultProps = {};

const CancelDialog = ({ isOpen, onCloseClick, onCancelConfirmed }) => {
  const [reason, setReason] = useState('');

  return (
    <FoxCustomDialog
      title={lang.labels.TransportOrderPage_OrderCancelation()}
      isOpen={isOpen}
      onCloseClick={onCloseClick}
      onConfirmClick={() => onCancelConfirmed(reason.trim())}
      yesLabel={lang.labels.Yes()}
      noLabel={lang.labels.No()}
      className="orange"
    >
      <p className="bold_medium">
        {lang.labels.TransportOrderPage_CancelTransportOrderDialogDecription()}
      </p>
      <FoxInputTextarea
        placeholder={lang.labels.TransportOrderPage_ProviceReason()}
        value={reason}
        onChange={e => setReason(e.target.value)}
        className="full-width margin-top"
      />
      {!reason.trim() && (
        <div className="invalid-message">
          {lang.labels.TransportOrderPage_CancelOrderDialogErrorMessage()}
        </div>
      )}
    </FoxCustomDialog>
  );
};

CancelDialog.propTypes = propTypes;
CancelDialog.defaultProps = defaultProps;

export default CancelDialog;
