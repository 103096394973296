import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import FoxAutocompleteField from 'fox-react/dist/reduxFormFields/FoxAutocompleteField';
import lang from 'hh-shared/dist/language/services/languageService';
import { zipCodeMask } from 'fox-react/dist/utils/reduxFormUtils';

const propTypes = {
  name: PropTypes.string,
};

const defaultProps = {
  name: 'zipCode',
};

function ZipCodeAutocompleteField({ name, ...others }) {
  const zipCodeCodes = useSelector(state => state.dictionary.static.zipCodes);

  const zipCodeCustomItemRender = (li, itemProps) => {
    const itemChildren = (
      <span>
        {li.props.children} {itemProps.dataItem.city}
      </span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <FoxAutocompleteField
      label={lang.labels.ZipCode()}
      placeholder={lang.labels.ZipCode()}
      name={name}
      items={zipCodeCodes}
      {...zipCodeMask}
      itemRender={zipCodeCustomItemRender}
      textField="code"
      {...others}
    />
  );
}

ZipCodeAutocompleteField.propTypes = propTypes;
ZipCodeAutocompleteField.defaultProps = defaultProps;

export default memo(ZipCodeAutocompleteField);
