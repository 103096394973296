import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { initialize, getFormValues } from 'redux-form';

import transportOrderStatusesIds from 'hh-shared/dist/consts/transportOrderStatusesIds';
import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import Row from 'hh-shared/dist/components/layout/Row';
import Column from 'hh-shared/dist/components/layout/Column';
import PageWrapper from 'hh-shared/dist/components/layout/PageWrapper';
import MainInfo from 'hh-shared/dist/components/transport-order/MainInfo';
import TransportInfo from 'hh-shared/dist/components/transport-order/TransportInfo/TransportInfo';
import TransportForm from 'hh-shared/dist/components/transport-order/TransportForm';
import BottomSection from 'hh-shared/dist/components/transport-order/BottomSection';
import IconArea from 'hh-shared/dist/components/commons/IconArea';
import SummarySection from 'hh-shared/dist/components/transport-order/SummarySection';
import ButtonContainer from 'hh-shared/dist/components/transport-order/ButtonContainer';
import TransportOrderPageLoader from 'hh-shared/dist/components/transport-order/TransportOrderPageLoader';
import PreviewButton from 'hh-shared/dist/components/commons/PreviewButton';
import { downloadOrderPDF } from 'hh-shared/dist/utils/downloadMethods';
import { scrollTo } from 'hh-shared/dist/utils/scrollMethods';
import TransportOrdersManagementSrv from 'transport-orders-management/TransportOrdersManagementSrv';
import CancelDialog from 'transport-orders-management/common/CancelDialog/CancelDialog';
import actionTypes from './actionTypes';
import ActionButtons from './ActionButtons';
import CommentOnOrderForm from './CommentOnOrderForm';
import TransportOrderAddMessageDialog from '../TransportOrderAddMessageDialog/TransportOrderAddMessageDialog';

import './TransportOrderPreviewPage.scss';
import './TransportOrderPreviewPageTheming.scss';
import store from 'store/store';
import ImplementationDialog from 'hh-shared/dist/components/transport-order/ImplementationDialog';
import LoadedDialog from './LoadedDialog';

const formName = 'transportFormPreview';

const propTypes = {
  match: PropTypes.object.isRequired,
};

const defaultProps = {};

const transformPreviewFormValueSelector = getFormValues('transportFormPreview');

function TransportOrderPreviewPage({ match }) {
  const [showSpinner, setShowSpinner] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [cars, setCars] = useState([]);
  const [carTypes, setCarTypes] = useState([]);
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
  const [messageDialogIsOpen, setMessageDialogIsOpen] = useState(false);
  const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);
  const [implementationDialogIsOpen, setImplementationDialogIsOpen] = useState(
    false,
  );
  const [
    implementationDetailsInitialValues,
    setImplementationDetailsInitialValues,
  ] = useState({});
  const [loadedDetailsInitialValues, setLoadedDetailsInitialValues] = useState(
    {},
  );
  const [loadedDialogIsOpen, setLoadedDialogIsOpen] = useState(false);

  const transportOrderPreviewPage = useSelector(
    state => state.transportOrderPreviewPage,
  );
  const { transportOrderData } = transportOrderPreviewPage;

  const dispatch = useDispatch();

  const onSendClick = message => {
    message &&
      TransportOrdersManagementSrv.sendMessage(match.params.id, message).then(
        () => {
          setMessageDialogIsOpen(false);
        },
      );
  };

  const refreshPage = () => {
    setShowSpinner(true);
    TransportOrdersManagementSrv.getPreview(match.params.id)
      .then(orderData => {
        dispatch({
          type: actionTypes.SET_TRANSPORT_ORDER_DATA,
          payload: orderData,
        });
        dispatch(
          initialize(formName, {
            car: orderData.car,
            driver: orderData.driver,
          }),
        );
      })
      .finally(() => setShowSpinner(false));
  };

  const onCancelConfirmed = reason => {
    setShowSpinner(true);
    return TransportOrdersManagementSrv.cancel(match.params.id, reason)
      .then(() => {
        setCancelDialogIsOpen(false);
        refreshPage();
      })
      .finally(() => setShowSpinner(false));
  };

  const submitLoaded = () => {
    const { loadingTimeTo } = transportOrderData;
    const values = transformPreviewFormValueSelector(store.getState());
    setLoadedDetailsInitialValues({ ...values, loadingTimeTo });
    setLoadedDialogIsOpen(true);
  };

  const onSubmitLoaded = model => {
    setLoadedDialogIsOpen(false);
    return TransportOrdersManagementSrv.loaded({
      ...model,
      id: match.params.id,
    }).then(() => refreshPage());
  };

  const submitAcceptForImplementation = () => {
    const { loadingTimeTo } = transportOrderData;
    const values = transformPreviewFormValueSelector(store.getState());
    setImplementationDetailsInitialValues({ ...values, loadingTimeTo });
    setImplementationDialogIsOpen(true);
  };

  const onSubmitAcceptForImplementation = model => {
    setImplementationDialogIsOpen(false);
    return TransportOrdersManagementSrv.acceptForImplementation({
      ...model,
      id: match.params.id,
    }).then(() => {
      refreshPage();
    });
  };

  const updateComment = model =>
    TransportOrdersManagementSrv.updateComment(model, match.params.id);

  useEffect(() => {
    setShowSpinner(true);

    Promise.all([
      TransportOrdersManagementSrv.getPreview(match.params.id),
      TransportOrdersManagementSrv.getDrivers(match.params.id),
      TransportOrdersManagementSrv.getCars(match.params.id),
      TransportOrdersManagementSrv.getCarTypesAsSelectItems(),
    ])
      .then(([orderData, driversRes, carsRes, carTypesRes]) => {
        setCars(carsRes);
        setDrivers(driversRes);
        setCarTypes(carTypesRes);
        dispatch({
          type: actionTypes.SET_TRANSPORT_ORDER_DATA,
          payload: orderData,
        });
        dispatch(
          initialize(formName, {
            car: orderData.car,
            driver: orderData.driver,
          }),
        );
      })
      .finally(() => setShowSpinner(false));
    // TODO -> ogarnąć czemu brakuje hook-owi dispatcha w array-u argumentów ;o
    // eslint-disable-next-line
  }, [match.params.id]);

  useEffect(() => {
    const pageContainer = document.querySelector('.transport_order_page');
    pageContainer && scrollTo(pageContainer);
  }, [showSpinner]);

  const downloadPDF = () => {
    setIsDownloadingPdf(true);

    TransportOrdersManagementSrv.getCompletedTransportOrderPdf(match.params.id)
      .then(orderPDF => downloadOrderPDF(orderPDF, transportOrderData.number))
      .finally(() => setIsDownloadingPdf(false));
  };

  const showInputs =
    get(transportOrderData, 'status.id') === transportOrderStatusesIds.placed;

  const disabledComment =
    get(transportOrderData, 'status.id') === transportOrderStatusesIds.realized;

  return (
    <>
      {showSpinner || !transportOrderData ? (
        <TransportOrderPageLoader />
      ) : (
        <Row className="transport_order_page">
          <Column rounded bigger className="lifted lifted-left">
            <PageWrapper rounded additionalClassNames="order_card">
              <MainInfo
                data={transportOrderData}
                auctionPreviewUrl="/auctions-management/details"
              />
              <TransportInfo
                data={transportOrderData}
                renderForm={
                  <TransportForm
                    drivers={drivers}
                    cars={cars}
                    showInputs={showInputs}
                    carTypes={carTypes}
                    transportOrderData={transportOrderData}
                  />
                }
              />
              <BottomSection
                data={transportOrderData}
                renderButtons={
                  <ActionButtons
                    data={transportOrderData}
                    orderId={match.params.id}
                    onRefreshPage={refreshPage}
                    submitLoaded={submitLoaded}
                    submitAcceptForImplementation={
                      submitAcceptForImplementation
                    }
                  />
                }
              />
            </PageWrapper>
          </Column>
          <Column rounded smaller>
            <ButtonContainer
              renderButtons={
                <>
                  <PreviewButton
                    label={lang.labels.DownloadPdf()}
                    icon={icons.download}
                    onClick={downloadPDF}
                    isProcessing={isDownloadingPdf}
                  />

                  {transportOrderData.status.id !==
                    transportOrderStatusesIds.canceled && (
                    <PreviewButton
                      label={lang.buttons.CancelOrder()}
                      icon={icons.order}
                      onClick={() => setCancelDialogIsOpen(true)}
                    />
                  )}
                </>
              }
            />
            <PageWrapper
              additionalClassNames="flex-size display-flex"
              roundedRight
            >
              <SummarySection
                data={transportOrderData}
                onAddMessageClick={() => setMessageDialogIsOpen(true)}
                renderAdditionalContent={
                  <Row>
                    <IconArea
                      icon={icons.comment}
                      iconTitle={lang.labels.InternalNote()}
                      className="full-width"
                      alignTop
                    >
                      <CommentOnOrderForm
                        onSubmit={updateComment}
                        initialValues={{ comment: transportOrderData.comment }}
                        disabled={disabledComment}
                      />
                    </IconArea>
                  </Row>
                }
              />
            </PageWrapper>
          </Column>
        </Row>
      )}
      <TransportOrderAddMessageDialog
        isOpen={messageDialogIsOpen}
        onSendClick={message => onSendClick(message)}
        onCloseClick={() => setMessageDialogIsOpen(false)}
      />
      <ImplementationDialog
        drivers={drivers}
        cars={cars}
        carTypes={carTypes}
        onCloseClick={() => setImplementationDialogIsOpen(false)}
        isOpen={implementationDialogIsOpen}
        transportOrderData={transportOrderData}
        initialValues={implementationDetailsInitialValues}
        onSubmit={onSubmitAcceptForImplementation}
      />
      <LoadedDialog
        drivers={drivers}
        cars={cars}
        carTypes={carTypes}
        onCloseClick={() => setLoadedDialogIsOpen(false)}
        isOpen={loadedDialogIsOpen}
        transportOrderData={transportOrderData}
        initialValues={loadedDetailsInitialValues}
        onSubmit={onSubmitLoaded}
      />
      <CancelDialog
        isOpen={cancelDialogIsOpen}
        onCancelConfirmed={onCancelConfirmed}
        onCloseClick={() => setCancelDialogIsOpen(false)}
      />
    </>
  );
}

TransportOrderPreviewPage.propTypes = propTypes;
TransportOrderPreviewPage.defaultProps = defaultProps;

export default TransportOrderPreviewPage;
