import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import HtmlTemplatesManagementSrv from '../HtmlTemplatesManagementSrv';
import TemplateEditForm from './TemplateEditForm';
import EditPagePreview from './EditPagePreview';
import { calculateEditType } from './functions';

const propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const EditPage = ({ history, match }) => {
  const [editorType, setEditorType] = useState(undefined);
  const [initialValues, setInitialValues] = useState({});
  const [showSpinner, setShowSpinner] = useState();

  useEffect(() => {
    setEditorType(calculateEditType(match.path));

    setShowSpinner(true);
    HtmlTemplatesManagementSrv.get(match.params.id)
      .then(result => setInitialValues(result))
      .finally(() => setShowSpinner(false));
  }, [match.params.id, match.path]);

  const onSubmitHandler = model => HtmlTemplatesManagementSrv.update(model);

  return (
    <>
      <BreadcrumbsContainer
        links={[
          {
            routePath: '/',
            label: lang.labels.HomePage(),
          },
          {
            routePath: '/html-templates-management',
            label: lang.labels.HtmlTemplates(),
          },
          {
            routePath: '/html-templates-management/edit',
            label: lang.labels.EditingHtmlTemplates(),
          },
        ]}
      />
      <FormPreviewSectionContainer>
        <FormCardWrapper>
          <div className={showSpinner ? 'fox_spinner' : undefined}>
            <TemplateEditForm
              onSubmit={onSubmitHandler}
              initialValues={initialValues}
              editorType={editorType}
              history={history}
            />
          </div>
        </FormCardWrapper>
        <EditPagePreview smsPage={editorType === 'smsContent'} />
      </FormPreviewSectionContainer>
    </>
  );
};

EditPage.propTypes = propTypes;

export default EditPage;
