import { connect } from 'react-redux';
import CarTypesManagementEditPage from './CarTypesManagementEditPage';
import { updateAsync } from './actions';

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: model => dispatch(updateAsync(model)),
  };
};

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
  return {
    onSubmit: model =>
      propsFromDispatch.onSubmit({
        ...model,
        id: ownProps.match.params.id,
      }),
    ...ownProps,
  };
};

const CarTypesManagementEditPageContainer = connect(
  undefined,
  mapDispatchToProps,
  mergeProps,
)(CarTypesManagementEditPage);

export default CarTypesManagementEditPageContainer;
