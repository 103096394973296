import axios from 'config/axios';
import { convertArrayToObjOfBools } from 'fox-react/dist/utils/array';
import { convertObjOfBoolsToArray } from 'fox-react/dist/utils/object';

const prefix = 'usersManagement';

export default class UsersManagementSrv {
  static getForGrid(dataState) {
    return axios
      .post(`${prefix}/getForGrid`, dataState)
      .then(result => result.data);
  }

  static get(instanceId) {
    return axios.get(`${prefix}/${instanceId}`).then(result => {
      return {
        ...result.data,
        permissions: convertArrayToObjOfBools(result.data.permissions),
      };
    });
  }

  static add(model) {
    model.origin = window.location.origin + process.env.PUBLIC_URL;
    model.permissions = convertObjOfBoolsToArray(model.permissions);
    return axios.post(prefix, model);
  }

  static update(model) {
    model.permissions = convertObjOfBoolsToArray(model.permissions);
    return axios.put(prefix, model);
  }

  static getFacilitiesAsSelectItem() {
    return axios
      .get(`${prefix}/getFacilitiesAsSelectItem`)
      .then(result => result.data);
  }

  static getCompaniesAsSelectItems() {
    return axios
      .get(`${prefix}/getCompaniesAsSelectItems`)
      .then(result => result.data);
  }

  static getDepartmentsAsSelectItems() {
    return axios
      .get(`${prefix}/getDepartmentsAsSelectItems`)
      .then(result => result.data);
  }

  static sendInvitation(model) {
    return axios.post(`${prefix}/sendInvitation`, model);
  }
}
