import lang from 'hh-shared/dist/language/services/languageService';

export const auctionObservedFilter = {
  logic: 'and',
  filters: [
    {
      field: 'isObserved',
      operator: 'eq',
      value: true,
    },
  ],
};

export const auctionDraftFilter = {
  filters: [
    {
      logic: 'or',
      filters: [
        {
          field: 'status',
          operator: 'contains',
          value: lang.labels.Draft(),
        },
        {
          field: 'status',
          operator: 'contains',
          value: lang.labels.ForPublication(),
        },
      ],
    },
  ],
  logic: 'and',
};

export const auctionOngoingFilter = {
  logic: 'and',
  filters: [
    {
      field: 'status',
      operator: 'contains',
      value: lang.labels.LiveAuctionStatus(),
    },
  ],
};

export const auctionCanceledFilter = {
  logic: 'and',
  filters: [
    {
      field: 'status',
      operator: 'contains',
      value: lang.labels.CanceledAuctionStatus(),
    },
  ],
};

export const auctionAllFilter = undefined;

export const auctionWithSuccessFilter = {
  logic: 'and',
  filters: [
    {
      field: 'currentPrice',
      operator: 'neq',
      value: lang.labels.WithoutOffer(),
    },
    {
      field: 'status',
      operator: 'contains',
      value: lang.labels.ClosedAuctionStatus(),
    },
  ],
};

export const auctionWithoutSuccessFilter = {
  logic: 'and',
  filters: [
    {
      field: 'currentPrice',
      operator: 'contains',
      value: lang.labels.WithoutOffer(),
    },
    {
      field: 'status',
      operator: 'contains',
      value: lang.labels.ClosedAuctionStatus(),
    },
  ],
};

export const auctionWithoutOfferFilter = {
  logic: 'and',
  filters: [
    {
      field: 'currentPrice',
      operator: 'contains',
      value: lang.labels.WithoutOffer(),
    },
    {
      field: 'status',
      operator: 'contains',
      value: lang.labels.LiveAuctionStatus(),
    },
  ],
};
