import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import gridNames from 'common/gridNames';
import createFoxGridContainer from 'fox-react/dist/components/FoxGrid/createFoxGridContainer';
import { Link } from 'react-router-dom';
import FoxButton from 'fox-react/dist/components/FoxButton';
import FoxGridColumn from 'fox-react/dist/components/FoxGrid/FoxGridColumn';
import lang from 'hh-shared/dist/language/services/languageService';
import columnNames from 'common/columnNames';
import InnerContentWrapper from 'hh-shared/dist/components/layout/InnerContentWrapper';
import NumericCell from 'hh-shared/dist/components/grid/NumericCell';
import PhoneNumberCell from 'hh-shared/dist/components/grid/PhoneNumberCell';
import actionsColumnGridDefaults from 'hh-shared/dist/consts/actionsColumnGridDefaults';

const propTypes = {
  onLoadData: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const defaultProps = {};

const FoxGridContainer = createFoxGridContainer(gridNames.companiesManagement);

class CompanyManagementPage extends PureComponent {
  render() {
    const { onLoadData, match } = this.props;

    return (
      <InnerContentWrapper fullWidth>
        <FoxGridContainer
          onLoadData={onLoadData}
          defaultSortField={columnNames.companiesManagement.name}
          renderButtons={
            <Link to={`${match.url}/add`}>
              <FoxButton icon="add" primary>
                {lang.buttons.Add()}
              </FoxButton>
            </Link>
          }
        >
          <FoxGridColumn
            {...actionsColumnGridDefaults}
            locked
            cell={({ dataItem, className }) => (
              <td className={className}>
                <Link to={`${match.url}/edit/${dataItem.instanceId}`}>
                  <FoxButton icon="edit">{lang.buttons.Edit()}</FoxButton>
                </Link>
              </td>
            )}
          />
          <FoxGridColumn
            field={columnNames.companiesManagement.name}
            title={lang.labels.Name()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.companiesManagement.address}
            title={lang.labels.Address()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.companiesManagement.zipCode}
            title={lang.labels.ZipCode()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.companiesManagement.city}
            title={lang.labels.City()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.companiesManagement.nip}
            title={lang.labels.Nip()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.companiesManagement.regon}
            title={lang.labels.Regon()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.companiesManagement.phoneNumber}
            title={lang.labels.PhoneNumber()}
            filter="text"
            cell={({ dataItem }) => (
              <PhoneNumberCell phoneNumber={dataItem.phoneNumber} />
            )}
          />
          <FoxGridColumn
            field={columnNames.companiesManagement.isActive}
            title={lang.labels.IsActive()}
            filter="boolean"
            columnMenu={false}
            cell={({ dataItem }) => (
              <td colSpan="1">
                {dataItem.isActive ? lang.labels.Yes() : lang.labels.No()}
              </td>
            )}
          />
          <FoxGridColumn
            field={columnNames.companiesManagement.participatedAuctionsNumber}
            title={lang.labels.ParticipatedAuctionsNumber()}
            filter="numeric"
            cell={({ dataItem }) => (
              <NumericCell
                value={dataItem.participatedAuctionsNumber}
                withoutDecimalSeparator
              />
            )}
          />
          <FoxGridColumn
            field={columnNames.companiesManagement.winAuctionsNumber}
            title={lang.labels.WinAuctionsNumber()}
            filter="numeric"
            cell={({ dataItem }) => (
              <NumericCell
                value={dataItem.winAuctionsNumber}
                withoutDecimalSeparator
              />
            )}
          />
          <FoxGridColumn
            field={
              columnNames.companiesManagement.acceptedTransportOrdersNumber
            }
            title={lang.labels.AcceptedTransportOrdersNumber()}
            filter="numeric"
            cell={({ dataItem }) => (
              <NumericCell
                value={dataItem.acceptedTransportOrdersNumber}
                withoutDecimalSeparator
              />
            )}
          />
          <FoxGridColumn
            field={columnNames.companiesManagement.orderedTransportOrdersNumber}
            title={lang.labels.OrderedTransportOrdersNumber()}
            filter="numeric"
            cell={({ dataItem }) => (
              <NumericCell
                value={dataItem.orderedTransportOrdersNumber}
                withoutDecimalSeparator
              />
            )}
          />
        </FoxGridContainer>
      </InnerContentWrapper>
    );
  }
}

CompanyManagementPage.propTypes = propTypes;
CompanyManagementPage.defaultProps = defaultProps;

export default CompanyManagementPage;
