import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getFormValues } from 'redux-form';

import lang from 'hh-shared/dist/language/services/languageService';
import formNames from 'common/formNames';
import auctionTypesIds from 'common/auctionTypesIds';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';

import auctionStatusesIds from 'hh-shared/dist/utils/auctionStatusesIds';
import { getAuctionPreviewLink } from 'common/links';
import AuctionsManagementSrv from './AuctionsManagementSrv';
import AuctionsManagementPagePreviewContainer from './AuctionsManagementPagePreviewContainer';
import AuctionInformationEditForm from './Forms/AuctionInformationEditForm';

const propTypes = {
  history: PropTypes.object.isRequired,
  loadingMethods: PropTypes.array.isRequired,
  unloadingMethods: PropTypes.array.isRequired,
  match: PropTypes.any.isRequired,
  onSubjectsChange: PropTypes.func.isRequired,
  onDistanceChange: PropTypes.func.isRequired,
  onPricePerKilometerChange: PropTypes.func.isRequired,
  onStartPriceChange: PropTypes.func.isRequired,
  onResetPrices: PropTypes.func.isRequired,
  onGetDefaultPrices: PropTypes.func.isRequired,
  routes: PropTypes.array.isRequired,
  zipCodes: PropTypes.array.isRequired,
};

const AuctionsManagementEditPage = ({
  history,
  loadingMethods,
  unloadingMethods,
  match,
  onSubjectsChange,
  onDistanceChange,
  onPricePerKilometerChange,
  onStartPriceChange,
  onResetPrices,
  onGetDefaultPrices,
  routes,
  zipCodes,
}) => {
  const [showSpinner, setShowSpinner] = useState(true);
  const [showSubjects, setShowSubjects] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [facilities, setFacilities] = useState([]);
  const [auctionSubjects, setAuctionSubjects] = useState([]);
  const [principalPersons, setPrincipalPersons] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [isDraft, setIsDraft] = useState(false);

  const values = useSelector(state =>
    getFormValues(formNames.auctionInformationForm)(state),
  );

  const auctionTypes = useSelector(
    state => state.dictionary.static.auctionTypes,
  );

  useEffect(() => {
    if (values)
      setShowSubjects(auctionTypesIds.privateId === values.auctionType);
  }, [values]);

  useEffect(() => {
    onGetDefaultPrices();

    Promise.all([
      AuctionsManagementSrv.getFacilitiesAsSelectItems(),
      AuctionsManagementSrv.getAuctionSubjectsAsSelectItems(),
      AuctionsManagementSrv.getPrincipalPersonsAsSelectItems(),
      AuctionsManagementSrv.getAddressesAsSelectItem(),
      AuctionsManagementSrv.getCurrenciesAsSelectItem(),
      AuctionsManagementSrv.get(match.params.id),
    ])
      .then(
        ([
          resFacilities,
          resAuctionSubjects,
          resPrincipalPersons,
          resAddresses,
          resCurrencies,
          resDataToEdit,
        ]) => {
          setFacilities(resFacilities);
          setAuctionSubjects(resAuctionSubjects);
          setPrincipalPersons(resPrincipalPersons);
          setAddresses(resAddresses);
          setCurrencies(resCurrencies);
          setInitialValues(resDataToEdit);
          setIsDraft(resDataToEdit.status.id === auctionStatusesIds.draft);
        },
      )
      .finally(() => setShowSpinner(false));
  }, [match.params.id, onGetDefaultPrices]);

  const onSubmit = model => {
    model.id = match.params.id;
    return AuctionsManagementSrv.update(model).then(() =>
      history.push(getAuctionPreviewLink(match.params.id)),
    );
  };

  const onPublish = model => {
    model.id = match.params.id;
    return AuctionsManagementSrv.updateAndPublish(model).then(() =>
      history.push(getAuctionPreviewLink(match.params.id)),
    );
  };

  const onRemove = () => {
    return AuctionsManagementSrv.delete(match.params.id).then(() =>
      history.goBack(),
    );
  };

  return (
    <>
      <BreadcrumbsContainer
        links={[
          {
            routePath: '/',
            label: lang.labels.HomePage(),
          },
          {
            routePath: '/auctions-management',
            label: lang.labels.Auctions(),
          },
          {
            routePath: '/auctions-management/edit',
            label: `${lang.labels.EditingAuction()} ${initialValues.number}`,
          },
        ]}
      />
      <FormPreviewSectionContainer>
        <FormCardWrapper>
          <div className={showSpinner ? 'fox_spinner' : ''}>
            <AuctionInformationEditForm
              onSubmit={onSubmit}
              history={history}
              facilities={facilities}
              currencies={currencies}
              loadingMethods={loadingMethods}
              unloadingMethods={unloadingMethods}
              auctionTypes={auctionTypes}
              auctionSubjects={auctionSubjects}
              showSubjects={showSubjects}
              principalPersons={principalPersons}
              initialValues={initialValues}
              addresses={addresses}
              onSubjectsChange={onSubjectsChange}
              onDistanceChange={onDistanceChange}
              onPricePerKilometerChange={onPricePerKilometerChange}
              onStartPriceChange={onStartPriceChange}
              onResetPrices={onResetPrices}
              routes={routes}
              zipCodes={zipCodes}
              auctionId={match.params.id}
              isDraft={isDraft}
              onPublish={onPublish}
              onRemove={onRemove}
            />
          </div>
        </FormCardWrapper>
        <AuctionsManagementPagePreviewContainer />
      </FormPreviewSectionContainer>
    </>
  );
};

AuctionsManagementEditPage.propTypes = propTypes;

export default AuctionsManagementEditPage;
