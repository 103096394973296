import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { formValues } from 'redux-form';
import { useSelector } from 'react-redux';
import FoxAutocompleteField from 'fox-react/dist/reduxFormFields/FoxAutocompleteField';
import lang from 'hh-shared/dist/language/services/languageService';
import fieldNames from 'common/fieldNames';
import { isRequiredFieldValidator } from 'hh-shared/dist/utils/formValidators';

const propTypes = {
  zipCode: PropTypes.string,
};

const defaultProps = {
  zipCode: '',
};

function StreetAutocompleteField({ zipCode }) {
  const [streetAutocomplete, setStreetAutocomplete] = useState([]);
  const zipCodes = useSelector(state => state.dictionary.static.zipCodes);

  useEffect(() => {
    if (zipCode && zipCode.length === 6) {
      const zipCodeObj = zipCodes.find(x => x.code === zipCode);
      if (zipCodeObj) {
        setStreetAutocomplete(zipCodeObj.streets || []);
      } else {
        setStreetAutocomplete([]);
      }
    }
    // eslint-disable-next-line
  }, [zipCode]);

  return (
    <FoxAutocompleteField
      items={streetAutocomplete}
      label={lang.labels.Street()}
      placeholder={lang.labels.Street()}
      name={fieldNames.newAddressInformationForm.street}
      required
      validate={isRequiredFieldValidator}
    />
  );
}

StreetAutocompleteField.propTypes = propTypes;
StreetAutocompleteField.defaultProps = defaultProps;

export default compose(formValues('zipCode'), memo)(StreetAutocompleteField);
