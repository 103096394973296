import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import icons from 'hh-shared/dist/consts/icons';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import FoxDropDownList from 'fox-react/dist/components/FoxDropDownList';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';
import CarsManagementPagePreview from './CarsManagementPagePreview';
import CarInformationForm from './CarInformationForm';
import CarsManagementSrv from './CarsManagementSrv';

const propTypes = {
  match: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

class CarsManagementEditPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      versions: [],
      initialValues: {},
      showSpinner: false,
      carTypes: [],
      companies: [],
    };
    this.onChangeVersion = this.onChangeVersion.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitAsNew = this.onSubmitAsNew.bind(this);
  }

  componentDidMount() {
    this.setState({ showSpinner: true });

    Promise.all([
      CarsManagementSrv.get(this.props.match.params.instanceId),
      CarsManagementSrv.getCarTypesAsSelectItems(),
      CarsManagementSrv.getCompaniesAsSelectItems(),
    ])
      .then(results => {
        const newVersions = sortBy(results[0], x => x.versionNumber);

        this.setState({
          versions: newVersions,
          initialValues: last(newVersions),
          carTypes: results[1],
          companies: results[2],
        });
      })
      .finally(() => this.setState({ showSpinner: false }));
  }

  onChangeVersion(e) {
    const newVersionNumber = e.target.value;
    this.setState(prevState => ({
      initialValues: prevState.versions.find(
        x => x.versionNumber === newVersionNumber,
      ),
    }));
  }

  onSubmit(model) {
    // TODO: sprawdzić czy działa w container (tak jak w innych miejscach), jeśli tak to usunąć stąd, poprawić wywołanie onSubmit i ujednolicić w całej aplikacji
    return this.props.onSubmit({ ...model, id: this.state.initialValues.id });
  }

  onSubmitAsNew(model) {
    return this.props.onSubmit({
      ...model,
      id: undefined,
      instanceId: this.props.match.params.instanceId,
    });
  }

  render() {
    const { history } = this.props;
    const {
      versions,
      initialValues,
      showSpinner,
      carTypes,
      companies,
    } = this.state;
    const versionNumbers = versions.map(x => x.versionNumber);

    return (
      <>
        <BreadcrumbsContainer
          links={[
            {
              routePath: '/',
              label: lang.labels.HomePage(),
            },
            {
              routePath: '/cars-management',
              label: lang.labels.Cars(),
            },
            {
              routePath: '/cars-management/edit',
              label: lang.labels.EditingCar(),
            },
          ]}
        />
        <FormPreviewSectionContainer>
          <FormCardWrapper>
            <SectionWrapper bottomSpace>
              <FormSectionCard
                title={lang.labels.Version()}
                titleIcon={icons.editedDocument}
              >
                <FoxDropDownList
                  items={versionNumbers}
                  value={initialValues.versionNumber}
                  onChange={this.onChangeVersion}
                  dataItemKey=""
                  textField=""
                />
              </FormSectionCard>
            </SectionWrapper>
            <div className={showSpinner ? 'fox_spinner' : undefined}>
              <CarInformationForm
                onSubmitAsNew={this.onSubmitAsNew}
                onSubmit={this.onSubmit}
                initialValues={initialValues}
                history={history}
                carTypes={carTypes}
                companies={companies}
                isEditForm
              />
            </div>
          </FormCardWrapper>
          <CarsManagementPagePreview editForm />
        </FormPreviewSectionContainer>
      </>
    );
  }
}

CarsManagementEditPage.propTypes = propTypes;
CarsManagementEditPage.defaultProps = defaultProps;

export default CarsManagementEditPage;
