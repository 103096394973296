import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import CarTypeInformationForm from './CarTypeInformationForm';
import CarTypesManagementSrv from './CarTypesManagementSrv';
import CarTypesManagementPagePreview from './CarTypesManagementPagePreview';

const propTypes = {
  match: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

class CarTypesManagementEditPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { initialValues: {}, showSpinner: false };
  }

  componentDidMount() {
    this.setState({ showSpinner: true });

    CarTypesManagementSrv.get(this.props.match.params.id)
      .then(results => {
        this.setState({ initialValues: results });
      })
      .finally(() => this.setState({ showSpinner: false }));
  }

  render() {
    const { onSubmit, history } = this.props;
    const { initialValues, showSpinner } = this.state;

    return (
      <>
        <BreadcrumbsContainer
          links={[
            {
              routePath: '/',
              label: lang.labels.HomePage(),
            },
            {
              routePath: '/car-types-management',
              label: lang.labels.CarTypes(),
            },
            {
              routePath: '/car-types-management/edit',
              label: lang.labels.EditingCarType(),
            },
          ]}
        />
        <FormPreviewSectionContainer>
          <FormCardWrapper>
            <div className={showSpinner ? 'fox_spinner' : undefined}>
              <CarTypeInformationForm
                onSubmit={onSubmit}
                initialValues={initialValues}
                history={history}
                isEditForm
              />
            </div>
          </FormCardWrapper>
          <CarTypesManagementPagePreview />
        </FormPreviewSectionContainer>
      </>
    );
  }
}

CarTypesManagementEditPage.propTypes = propTypes;
CarTypesManagementEditPage.defaultProps = defaultProps;

export default CarTypesManagementEditPage;
