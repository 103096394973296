import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { reduxForm, formValues } from 'redux-form';

import lang from 'hh-shared/dist/language/services/languageService';
import FoxButton from 'fox-react/dist/components/FoxButton';
import { displayCompletionToast } from 'hh-shared/dist/consts/reduxFormMethods';
import Column from 'hh-shared/dist/components/layout/Column';
import FoxInputTextareaField from 'fox-react/dist/reduxFormFields/FoxInputTextareaField';

import themingStyles from './CommentOnOrderFormTheming.module.scss';
import styles from './CommentOnOrderForm.module.scss';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  comment: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

const defaultProps = {
  comment: undefined,
};

const formName = 'commentOnOrder';

function CommentOnOrderForm({
  handleSubmit,
  submitting,
  initialValues,
  comment,
  disabled,
}) {
  const [initialComment, setInitialComment] = useState(initialValues.comment);

  const formContentContainerClassName = cx(styles.content_container, {
    [styles.content_container_filled]: !!comment,
  });

  const buttonClassName = cx(
    'align-self-end',
    'width-fit-content',
    styles.comment_button,
    themingStyles.comment_button,
  );

  const updateInitialComment = () => setInitialComment(comment);

  const onSubmit = args => {
    handleSubmit(args);
    updateInitialComment();
  };

  return (
    <form onSubmit={onSubmit}>
      <Column className={formContentContainerClassName}>
        <FoxInputTextareaField
          name="comment"
          label={lang.labels.InternalNote()}
          placeholder={lang.labels.WriteInternalNote()}
          className={styles.textarea}
          disabled={disabled}
        />
        {!disabled && (
          <FoxButton
            disabled={
              submitting ||
              initialComment === comment ||
              (!initialComment && comment && comment.length === 0)
            }
            className={buttonClassName}
          >
            {lang.buttons.Update()}
          </FoxButton>
        )}
      </Column>
    </form>
  );
}

CommentOnOrderForm.propTypes = propTypes;
CommentOnOrderForm.defaultProps = defaultProps;

const onSubmitSuccess = (_, dispatch) =>
  displayCompletionToast(
    dispatch,
    lang.validationMessages.SuccessUpdatedCommentOnTransportOrder(),
  );

export default reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitSuccess,
})(formValues({ comment: 'comment' })(memo(CommentOnOrderForm)));
