import icons from 'hh-shared/dist/consts/icons';
import lang from 'hh-shared/dist/language/services/languageService';
import transportOrderStatusesIds from 'hh-shared/dist/consts/transportOrderStatusesIds';

export function generateButtons(dataItem, match, downloadPdf) {
  const ret = [
    {
      icon: icons.solidDownload,
      label: lang.labels.DownloadPdf(),
      onClick: () => downloadPdf(dataItem.id, dataItem.number),
    },
  ];

  if (
    ![
      transportOrderStatusesIds.accepted,
      transportOrderStatusesIds.canceled,
      transportOrderStatusesIds.realized,
      transportOrderStatusesIds.loaded,
    ].includes(dataItem.statusId)
  ) {
    ret.unshift({
      icon: icons.solidEdit,
      label: lang.labels.Edit(),
      link: `${match.url}/edit/${dataItem.id}`,
    });
  }

  return ret;
}
