import axios from 'config/axios';

const prefix = 'dashboard';

export default class DashboardSrv {
  static get() {
    return axios.get(prefix).then(result => result.data);
  }

  static getDiagram() {
    return axios.get(`${prefix}/diagram`).then(result => result.data);
  }
}
