import React from 'react';
import { PropTypes } from 'prop-types';
import ResetPasswordForm from './ResetPasswordForm';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const defaultProps = {};

const ResetPasswordPage = ({ onSubmit }) => {
  return <ResetPasswordForm onSubmit={onSubmit} />;
};

ResetPasswordPage.propTypes = propTypes;
ResetPasswordPage.defaultProps = defaultProps;

export default ResetPasswordPage;
