import { gridStoreResults } from 'fox-react/dist/components/FoxGrid/actions';
import gridNames from 'common/gridNames';
import UsersManagementSrv from './UsersManagementSrv';

export function storeResults(results) {
  return gridStoreResults(gridNames.usersManagement)(results);
}

export function getForGrid(dataState) {
  return function(dispatch) {
    return UsersManagementSrv.getForGrid(dataState).then(results => {
      dispatch(storeResults(results));
    });
  };
}

export function addAsync(model) {
  return UsersManagementSrv.add(model);
}

export function updateAsync(model) {
  return UsersManagementSrv.update(model);
}

export function sendInvitation(model) {
  return UsersManagementSrv.sendInvitation(model);
}
