import React, {memo, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import lang from 'hh-shared/dist/language/services/languageService';
import Column from 'hh-shared/dist/components/layout/Column';
import icons from 'hh-shared/dist/consts/icons';
import permissions from 'common/permissions';

import MenuLink from '../MenuLink';
import MenuSublink from '../MenuSublink';
import styles from './NavigationMenu.module.scss';
import AuctionsMenuPosition from './AuctionsMenuPosition';
import TransportOrderMenuPosition from './TransportOrderMenuPosition';
import {useCurrentUser} from 'common/useCurrentUser';
import ReportSrv from 'dashboard/ReportsSrv';
import {useToastr} from 'common/useToastr';

const propTypes = {
  isOpened: PropTypes.bool,
  openMenu: PropTypes.func.isRequired,
};

const defaultProps = {
  isOpened: true,
};

function NavigationMenu({isOpened, openMenu}) {

  const {hasOneOf} = useCurrentUser();
  const {success,info} = useToastr();

  const hasDepAdminEmployee = hasOneOf([
    permissions.DEP_ADMIN,
    permissions.EMPLOYEE,
  ]);

  const hasDepAdminAdmin = hasOneOf([permissions.DEP_ADMIN, permissions.ADMIN]);
  const hasPricesManagement = hasOneOf([permissions.PRICES_MANAGEMENT]);
  const hasAdmin = hasOneOf([permissions.ADMIN]);

  const handleMenuOpen = () => openMenu();

  const [auctionMenuIsOpen, setAuctionMenuIsOpen] = useState(true);
  const [orderMenuIsOpen, setOrderMenuIsOpen] = useState(true);
  const [priceListMenuIsOpen, setPriceListMenuIsOpen] = useState(false);
  const [reportMenuIsOpen, setReportMenuIsOpen] = useState(false);
  const [templateMenuIsOpen, setTemplateMenuIsOpen] = useState(false);
  const [dictionariesMenuIsOpen, setDictionariesMenuIsOpen] = useState(false);

  const togglePriceListMenu = () =>
    setPriceListMenuIsOpen(!priceListMenuIsOpen);
  const toggleTemplateMenu = () => setTemplateMenuIsOpen(!templateMenuIsOpen);
  const toggleDictionariesMenu = () =>
    setDictionariesMenuIsOpen(!dictionariesMenuIsOpen);
  const toggleReportMenuOpen = () => setReportMenuIsOpen(!reportMenuIsOpen);

  const closeAllMenuItems = () => {
    setAuctionMenuIsOpen(false);
    setOrderMenuIsOpen(false);
    setPriceListMenuIsOpen(false);
    setTemplateMenuIsOpen(false);
    setDictionariesMenuIsOpen(false);
    setReportMenuIsOpen(false);
  };

  const menuClassName = cx('navigation_menu_container', styles.menu_container, {
    [styles.menu_container_toggled]: !isOpened,
  });

  const onDownloadReport = () => {
    info('Generaowanie raportu...');
    return ReportSrv.downloadCurrentYearReport().finally(() =>
      success('Wygenerowano raport')
    );
  };

  useEffect(() => {
    !isOpened && closeAllMenuItems();
  }, [isOpened]);

  return (
    <Column className={menuClassName}>
      <MenuLink
        icon={icons.dashboard}
        label={lang.labels.Dashboard()}
        routePath="/"
        checkExactPath
      />

      {hasDepAdminEmployee && (
        <AuctionsMenuPosition
          handleMenuOpen={handleMenuOpen}
          auctionMenuIsOpen={auctionMenuIsOpen}
          setAuctionMenuIsOpen={setAuctionMenuIsOpen}
        />
      )}

      {hasDepAdminEmployee && (
        <TransportOrderMenuPosition
          handleMenuOpen={handleMenuOpen}
          orderMenuIsOpen={orderMenuIsOpen}
          setOrderMenuIsOpen={setOrderMenuIsOpen}
        />
      )}

      <MenuLink
        icon={icons.departmentAdmin}
        label={lang.labels.Users()}
        routePath="/users-management"
      />

      {hasOneOf([permissions.ADMIN, permissions.EXPORT_NAV]) && (
        <MenuLink
          icon={icons.dictionary}
          label={lang.labels.Reports()}
          handleMenuOpen={handleMenuOpen}
          submenuIsOpen={reportMenuIsOpen}
          handleMenuItemToggle={toggleReportMenuOpen}
        >
          {hasOneOf([permissions.EXPORT_NAV]) && (
            <MenuSublink
              routePath="/export-nav"
              label={lang.labels.ExportNav()}
            />
          )}
          {hasAdmin && (
            <MenuSublink
              onClick={onDownloadReport}
              label={lang.labels.TransportOrders()}
            />
          )}
        </MenuLink>
      )}

      {hasDepAdminAdmin && (
        <MenuLink
          icon={icons.department}
          label={lang.labels.Departments()}
          routePath="/departments"
        />
      )}

      {hasDepAdminAdmin && (
        <MenuLink
          icon={icons.facility}
          label={lang.labels.Facilities()}
          routePath="/facilities-management"
        />
      )}

      <MenuLink
        icon={icons.company}
        label={lang.labels.Companies()}
        routePath="/companies-management"
      />

      {hasDepAdminAdmin && (
        <MenuLink
          icon={icons.vehicle}
          label={lang.labels.Cars()}
          routePath="/cars-management"
        />
      )}

      <MenuLink
        icon={icons.zipCode}
        label={lang.labels.ZipCodes()}
        routePath="/zip-codes-management"
      />

      <MenuLink
        icon={icons.address}
        label={lang.labels.Addresses()}
        routePath="/addresses-management"
      />

      {hasDepAdminAdmin && (
        <MenuLink
          icon={icons.route}
          label={lang.labels.Routes()}
          routePath="/routes-management"
        />
      )}

      {hasPricesManagement && (
        <>
          <MenuLink
            icon={icons.currencies}
            label={lang.labels.PriceLists()}
            handleMenuOpen={handleMenuOpen}
            submenuIsOpen={priceListMenuIsOpen}
            handleMenuItemToggle={togglePriceListMenu}
          >
            <MenuSublink
              routePath="/default-prices-management"
              label={lang.labels.DefaultPrices()}
            />

            <MenuSublink
              routePath="/prices-management"
              label={lang.labels.Prices()}
            />
          </MenuLink>
        </>
      )}

      {hasDepAdminAdmin && (
        <>
          <MenuLink
            icon={icons.template}
            label={lang.labels.Templates()}
            handleMenuOpen={handleMenuOpen}
            submenuIsOpen={templateMenuIsOpen}
            handleMenuItemToggle={toggleTemplateMenu}
          >
            <MenuSublink
              routePath="/html-templates-management"
              label={lang.labels.HtmlTemplates()}
            />
          </MenuLink>
        </>
      )}

      {(hasDepAdminAdmin || hasAdmin) && (
        <>
          <MenuLink
            icon={icons.dictionary}
            label={lang.labels.Dictionaries()}
            handleMenuOpen={handleMenuOpen}
            submenuIsOpen={dictionariesMenuIsOpen}
            handleMenuItemToggle={toggleDictionariesMenu}
          >
            {hasDepAdminAdmin && (
              <MenuSublink
                routePath="/car-types-management"
                label={lang.labels.CarTypes()}
              />
            )}
            {hasAdmin && (
              <MenuSublink
                routePath="/currencies-management"
                label={lang.labels.Currencies()}
              />
            )}
          </MenuLink>
        </>
      )}
    </Column>
  );
}

NavigationMenu.propTypes = propTypes;
NavigationMenu.defaultProps = defaultProps;

export default memo(NavigationMenu);
