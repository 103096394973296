import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import FacilityInformationForm from './FacilityInformationForm';
import FacilitiesManagementSrv from './FacilitiesManagementSrv';
import FacilitiesManagementPagePreview from './FacilitiesManagementPagePreview';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

class FacilitiesManagementAddPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false,
      departments: [],
      addresses: [],
    };
  }

  componentDidMount() {
    this.setState({ showSpinner: true });

    Promise.all([
      FacilitiesManagementSrv.getDepartmentsAsSelectItems(),
      FacilitiesManagementSrv.getAddressesAsSelectItems(),
    ])
      .then(([departments, addresses]) => {
        this.setState({ departments, addresses });
      })
      .finally(() => this.setState({ showSpinner: false }));
  }

  render() {
    const { onSubmit, history } = this.props;
    const { showSpinner, departments, addresses } = this.state;

    return (
      <>
        <BreadcrumbsContainer
          links={[
            {
              routePath: '/',
              label: lang.labels.HomePage(),
            },
            {
              routePath: '/facilities-management',
              label: lang.labels.Facilities(),
            },
            {
              routePath: '/facilities-management/add',
              label: lang.labels.AddingNewFacility(),
            },
          ]}
        />
        <FormPreviewSectionContainer>
          <FormCardWrapper>
            <div className={showSpinner ? 'fox_spinner' : undefined}>
              <FacilityInformationForm
                onSubmit={onSubmit}
                history={history}
                departments={departments}
                addresses={addresses}
              />
            </div>
          </FormCardWrapper>
          <FacilitiesManagementPagePreview />
        </FormPreviewSectionContainer>
      </>
    );
  }
}

FacilitiesManagementAddPage.propTypes = propTypes;
FacilitiesManagementAddPage.defaultProps = defaultProps;

export default FacilitiesManagementAddPage;
