import React from 'react';
import PropTypes from 'prop-types';
import auctionActionTypes from 'auctions-management/actionTypes';
import { getForGrid as getForGridAuctionsManagement } from 'auctions-management/actions';
import {
  auctionOngoingFilter,
  auctionWithSuccessFilter,
  auctionWithoutSuccessFilter,
} from 'auctions-management/filters';
import { useSelector, useDispatch } from 'react-redux';

import lang from 'hh-shared/dist/language/services/languageService';
import Row from 'hh-shared/dist/components/layout/Row';

import DashboardCardBox from './DashboardCardBox';
import styles from './DashboardCardBoxes.module.scss';

const propTypes = {
  liveCount: PropTypes.number,
  endedSuccessfulCount: PropTypes.number,
  endedWithoutSuccessCount: PropTypes.number,
};

const defaultProps = {
  liveCount: 0,
  endedSuccessfulCount: 0,
  endedWithoutSuccessCount: 0,
};

const AuctionCardBoxes = ({
  liveCount,
  endedSuccessfulCount,
  endedWithoutSuccessCount,
}) => {
  const dispatch = useDispatch();
  const dataState = useSelector(state => state.auctionsManagement.dataState);

  const changeFilter = filter => {
    dispatch(
      getForGridAuctionsManagement({
        ...dataState,
        filter,
        skip: 0,
      }),
    );
    dispatch({
      type: auctionActionTypes.AUCTIONS_MANAGEMENT_SET_FILTER,
      payload: filter,
    });
  };

  const dispatchOngoing = () => changeFilter(auctionOngoingFilter);
  const dispatchWithSuccess = () => changeFilter(auctionWithSuccessFilter);
  const dispatchWithoutSuccess = () =>
    changeFilter(auctionWithoutSuccessFilter);

  return (
    <Row className={styles.box_container}>
      <DashboardCardBox
        quantity={liveCount}
        label={lang.labels.DashboardOngoing()}
        link="/auctions-management"
        onClick={dispatchOngoing}
        color="blue"
      />
      <DashboardCardBox
        quantity={endedSuccessfulCount}
        label={lang.labels.DashboardFinishedWithSuccess()}
        link="/auctions-management"
        onClick={dispatchWithSuccess}
      />
      <DashboardCardBox
        quantity={endedWithoutSuccessCount}
        label={lang.labels.DashboardFinishedWithoutSuccess()}
        link="/auctions-management"
        onClick={dispatchWithoutSuccess}
      />
    </Row>
  );
};

AuctionCardBoxes.propTypes = propTypes;
AuctionCardBoxes.defaultProps = defaultProps;

export default AuctionCardBoxes;
