import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import FoxDropDownList from 'fox-react/dist/components/FoxDropDownList';

import FacilityInformationForm from './FacilityInformationForm';
import FacilitiesManagementSrv from './FacilitiesManagementSrv';
import FacilitiesManagementPagePreview from './FacilitiesManagementPagePreview';

const propTypes = {
  match: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

class FacilitiesManagementEditPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      versions: [],
      initialValues: {},
      showSpinner: false,
      departments: [],
      addresses: [],
    };
    this.onChangeVersion = this.onChangeVersion.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitAsNew = this.onSubmitAsNew.bind(this);
    this.remove = this.remove.bind(this);
  }

  componentDidMount() {
    this.setState({ showSpinner: true });

    Promise.all([
      FacilitiesManagementSrv.get(this.props.match.params.instanceId),
      FacilitiesManagementSrv.getDepartmentsAsSelectItems(),
      FacilitiesManagementSrv.getAddressesAsSelectItems(),
    ])
      .then(([forEdit, departments, addresses]) => {
        const newVersions = sortBy(forEdit, x => x.versionNumber);

        this.setState({
          versions: newVersions,
          initialValues: last(newVersions),
          departments,
          addresses,
        });
      })
      .finally(() => this.setState({ showSpinner: false }));
  }

  onChangeVersion(e) {
    const newVersionNumber = e.target.value;
    this.setState(prevState => ({
      initialValues: prevState.versions.find(
        x => x.versionNumber === newVersionNumber,
      ),
    }));
  }

  onSubmit(model) {
    this.props.onSubmit({ ...model, id: this.state.initialValues.id });
  }

  onSubmitAsNew(model) {
    this.props.onSubmit({
      ...model,
      id: undefined,
      instanceId: this.props.match.params.instanceId,
    });
  }

  remove() {
    FacilitiesManagementSrv.delete(
      this.props.match.params.instanceId,
    ).then(() => this.props.history.goBack());
  }

  render() {
    const { history } = this.props;
    const {
      versions,
      initialValues,
      showSpinner,
      departments,
      addresses,
    } = this.state;
    const versionNumbers = versions.map(x => x.versionNumber);

    return (
      <>
        <BreadcrumbsContainer
          links={[
            {
              routePath: '/',
              label: lang.labels.HomePage(),
            },
            {
              routePath: '/facilities-management',
              label: lang.labels.Facilities(),
            },
            {
              routePath: '/facilities-management/edit',
              label: lang.labels.EditingFacilities(),
            },
          ]}
        />
        <FormPreviewSectionContainer>
          <FormCardWrapper>
            <SectionWrapper bottomSpace>
              <FormSectionCard
                title={lang.labels.Version()}
                titleIcon={icons.editedDocument}
              >
                <FoxDropDownList
                  items={versionNumbers}
                  value={initialValues.versionNumber}
                  onChange={this.onChangeVersion}
                  dataItemKey=""
                  textField=""
                />
              </FormSectionCard>
            </SectionWrapper>
            <div className={showSpinner ? 'fox_spinner' : undefined}>
              <FacilityInformationForm
                onSubmit={this.onSubmit}
                onSubmitAsNew={this.onSubmitAsNew}
                initialValues={initialValues}
                history={history}
                departments={departments}
                addresses={addresses}
                handleRemove={this.remove}
                isEditForm
              />
            </div>
          </FormCardWrapper>
          <FacilitiesManagementPagePreview editForm />
        </FormPreviewSectionContainer>
      </>
    );
  }
}

FacilitiesManagementEditPage.propTypes = propTypes;
FacilitiesManagementEditPage.defaultProps = defaultProps;

export default FacilitiesManagementEditPage;
