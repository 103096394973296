import { toDataFormat } from 'common/textFormating';

// TODO vorbert -> Popracować nad optymalizacją

export const getCurrentAddress = (
  formValues,
  fieldBoolName,
  newFieldName,
  currentFieldName,
) => {
  if (formValues) {
    if (formValues[fieldBoolName]) {
      if (formValues[newFieldName]) {
        const loadginZipCodes = formValues[newFieldName];
        const zipCode = loadginZipCodes.zipCode || '';
        const street = loadginZipCodes.street || '';
        const city = loadginZipCodes.city || '';
        return `${zipCode} ${city} ${street}`;
      }
      return undefined;
    }
    if (!formValues[fieldBoolName] && formValues[currentFieldName]) {
      return formValues[currentFieldName].name || undefined;
    }
  }
  return undefined;
};

const previewGetters = {
  getCurrentAddress,
};

export const constructFixDate = (fix, from, to) => {
  const firstPart = from ? toDataFormat(from) : '';
  const secondPart = to ? toDataFormat(to) : '';

  if (!fix) {
    return `${firstPart} - ${secondPart}`;
  }

  return secondPart;
};

export default previewGetters;
