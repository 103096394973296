import React from 'react';
import fieldNames from 'common/fieldNames';
import lang from 'hh-shared/dist/language/services/languageService';
import FoxInputTextField from 'fox-react/dist/reduxFormFields/FoxInputTextField';
import FoxMaskedInputTextField from 'fox-react/dist/reduxFormFields/FoxMaskedInputTextField';
import phoneMask from 'hh-shared/dist/utils/phoneMask';

const propTypes = {};

const defaultProps = {};

function NewDriverForm() {
  return (
    <>
      <FoxInputTextField
        label={lang.labels.FirstName()}
        placeholder={lang.labels.FirstName()}
        name={fieldNames.newDriverInformationForm.firstName}
        required
      />

      <FoxInputTextField
        label={lang.labels.LastName()}
        placeholder={lang.labels.LastName()}
        name={fieldNames.newDriverInformationForm.lastName}
        required
      />

      <FoxMaskedInputTextField
        {...phoneMask}
        label={lang.labels.PhoneNumber()}
        placeholder={lang.labels.PhoneNumber()}
        name={fieldNames.newDriverInformationForm.phoneNumber}
      />
    </>
  );
}

NewDriverForm.propTypes = propTypes;
NewDriverForm.defaultProps = defaultProps;

export default NewDriverForm;
