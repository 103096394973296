import React from 'react';
import { PropTypes } from 'prop-types';
import FoxCustomDialog from 'fox-react/dist/components/FoxCustomDialog';
import lang from 'hh-shared/dist/language/services/languageService';
import SimpleAddUserForm from './SimpleAddUserForm';

const propTypes = {
  isOpen: PropTypes.bool,
  onCloseClick: PropTypes.func.isRequired,
  onSendInvitationConfirmed: PropTypes.func.isRequired,
};

const defaultProps = {
  isOpen: false,
};

const SendInvitationDialog = ({
  isOpen,
  onCloseClick,
  onSendInvitationConfirmed,
}) => {
  return (
    <FoxCustomDialog
      title={lang.labels.InvitationToPlatform()}
      isOpen={isOpen}
      onCloseClick={onCloseClick}
    >
      <SimpleAddUserForm onSubmit={onSendInvitationConfirmed} />
    </FoxCustomDialog>
  );
};

SendInvitationDialog.propTypes = propTypes;
SendInvitationDialog.defaultProps = defaultProps;

export default SendInvitationDialog;
