import axios from 'config/axios';

const prefix = 'pricesManagement';

export default class PricesManagementSrv {
  static getForGrid(dataState) {
    return axios
      .post(`${prefix}/getForGrid`, dataState)
      .then(result => result.data);
  }

  static import(model) {
    return axios.post(`${prefix}/import`, model, {
      headers: { 'content-type': 'multipart/form-data' },
    });
  }
}
