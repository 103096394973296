import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import ZipCodesManagementSrv from './ZipCodesManagementSrv';
import ZipCodeForm from './ZipCodeForm';
import ZipCodesManagementPreviewPage from './ZipCodesManagementPreviewPage';

const propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const defaultProps = {};

function ZipCodesManagementEditPage({ history, match }) {
  const [showSpinner, setShowSpinner] = useState(false);
  const [initialValues, setInitialValues] = useState(false);
  const onSubmit = model =>
    ZipCodesManagementSrv.update({ ...model, id: match.params.id });

  useEffect(() => {
    setShowSpinner(true);
    ZipCodesManagementSrv.get(match.params.id)
      .then(res => {
        setInitialValues(res);
      })
      .finally(() => setShowSpinner(false));
  }, [match.params.id]);

  return (
    <>
      <BreadcrumbsContainer
        links={[
          {
            routePath: '/',
            label: lang.labels.HomePage(), // lang.labels.MainPage(),
          },
          {
            routePath: '/zip-codes-management',
            label: lang.labels.ZipCodes(),
          },
          {
            routePath: '/zip-codes-management/edit',
            label: lang.labels.EditingZipCode(),
          },
        ]}
      />
      <FormPreviewSectionContainer>
        <FormCardWrapper>
          <div className={showSpinner ? 'fox_spinner' : undefined}>
            <ZipCodeForm
              onSubmit={onSubmit}
              history={history}
              initialValues={initialValues}
              zipCodeId={match.params.id}
              isEditForm
            />
          </div>
        </FormCardWrapper>
        <ZipCodesManagementPreviewPage />
      </FormPreviewSectionContainer>
    </>
  );
}

ZipCodesManagementEditPage.propTypes = propTypes;
ZipCodesManagementEditPage.defaultProps = defaultProps;

export default ZipCodesManagementEditPage;
