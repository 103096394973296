"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fieldChanged = fieldChanged;

var _get = _interopRequireDefault(require("lodash/get"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function fieldChanged(values, prevValues, fieldName) {
  return (0, _get.default)(values, fieldName) !== (0, _get.default)(prevValues, fieldName);
}