import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import Form from 'fox-react/dist/components/Form';
import FoxInputTextField from 'fox-react/dist/reduxFormFields/FoxInputTextField';
import FoxInputTextareaField from 'fox-react/dist/reduxFormFields/FoxInputTextareaField';
import FoxEditorTinyMCEField from 'fox-react/dist/reduxFormFields/FoxEditorTinyMCEField';
import FoxButton from 'fox-react/dist/components/FoxButton';
import goBackAfterSuccess from 'fox-react/dist/utils/goBackAfterSuccess';
import lang from 'hh-shared/dist/language/services/languageService';
import {
  scrollToTheFirstError,
  displayCompletionToasts,
} from 'hh-shared/dist/consts/reduxFormMethods';
import fieldNames from 'common/fieldNames';
import formNames from 'common/formNames';
import icons from 'hh-shared/dist/consts/icons';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';
import { isRequiredFieldValidator } from 'hh-shared/dist/utils/formValidators';
import { getApiBaseAddress } from 'config/apiBaseAddress';
import { uploadHtmlExternalContent } from './functions';

const propTypes = {
  editorType: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  editorType: undefined,
};

const documentBaseUrl = getApiBaseAddress();

const TemplateEditForm = ({ editorType, handleSubmit, submitting }) => {
  return (
    <Form handleSubmit={handleSubmit}>
      <fieldset>
        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.HtmlTemplateInformations()}
            titleIcon={icons.htmlDocument}
            fullContentWidth
          >
            <FoxInputTextField
              label={lang.labels.Name()}
              placeholder={lang.labels.Name()}
              name={fieldNames.htmlTemplateInformationForm.name}
              required
              validate={isRequiredFieldValidator}
            />
            <FoxInputTextareaField
              label={lang.labels.Description()}
              placeholder={lang.labels.Description()}
              name={fieldNames.htmlTemplateInformationForm.description}
              required
              validate={isRequiredFieldValidator}
            />
            {editorType === 'content' && (
              <FoxEditorTinyMCEField
                label={lang.labels.Content()}
                placeholder={lang.labels.Content()}
                name={fieldNames.htmlTemplateInformationForm.content}
                required
                validate={isRequiredFieldValidator}
              />
            )}
            {editorType === 'emailContent' && (
              <>
                <FoxInputTextField
                  label={lang.labels.EmailSubject()}
                  placeholder={lang.labels.EmailSubject()}
                  name={fieldNames.htmlTemplateInformationForm.emailSubject}
                  required
                  validate={isRequiredFieldValidator}
                />
                <FoxEditorTinyMCEField
                  label={lang.labels.Content()}
                  placeholder={lang.labels.Content()}
                  name={fieldNames.htmlTemplateInformationForm.emailContent}
                  required
                  validate={isRequiredFieldValidator}
                  onUpload={uploadHtmlExternalContent}
                  documentBaseUrl={documentBaseUrl}
                />
              </>
            )}
            {editorType === 'smsContent' && (
              <FoxInputTextField
                label={lang.labels.Content()}
                placeholder={lang.labels.Content()}
                name={fieldNames.htmlTemplateInformationForm.smsContent}
                required
                validate={isRequiredFieldValidator}
              />
            )}
          </FormSectionCard>
        </SectionWrapper>
      </fieldset>
      <FoxButton primary disabled={submitting}>
        {lang.buttons.Save()}
      </FoxButton>
    </Form>
  );
};

TemplateEditForm.propTypes = propTypes;
TemplateEditForm.defaultProps = defaultProps;

const onSubmitSuccess = (result, dispatch) =>
  displayCompletionToasts(
    dispatch,
    true,
    lang.labels.SuccessfullyModificatedHTMLTemplate(),
  );

export default reduxForm({
  form: formNames.smsTemplateInformationForm,
  enableReinitialize: true,
  onSubmitFail: scrollToTheFirstError,
  onSubmitSuccess,
})(goBackAfterSuccess(TemplateEditForm));
