"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useFormDialogLogic;

var _react = require("react");

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}
/**
 * returns {Object} [dialogProps: Object, open: fn(), setInitialValues: fn(Object) ]
 */


function useFormDialogLogic() {
  var _useState = (0, _react.useState)({}),
      _useState2 = _slicedToArray(_useState, 2),
      initialValues = _useState2[0],
      setInitialValues = _useState2[1];

  var _useState3 = (0, _react.useState)(false),
      _useState4 = _slicedToArray(_useState3, 2),
      isOpen = _useState4[0],
      setIsOpen = _useState4[1];

  var open = function open() {
    return setIsOpen(true);
  };

  var onCloseClick = function onCloseClick() {
    return setIsOpen(false);
  };

  var dialogProps = {
    initialValues: initialValues,
    isOpen: isOpen,
    onCloseClick: onCloseClick
  };
  return [dialogProps, open, setInitialValues];
}