"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _FontAwesomeIcon = _interopRequireDefault(require("../commons/FontAwesomeIcon/FontAwesomeIcon"));

var _ContentRevealer = _interopRequireDefault(require("hh-shared/dist/components/commons/ContentRevealer"));

var _languageService = _interopRequireDefault(require("hh-shared/dist/language/services/languageService"));

var _icons = _interopRequireDefault(require("hh-shared/dist/consts/icons"));

var _Row = _interopRequireDefault(require("layout/Row"));

var _AccordionItemModule = _interopRequireDefault(require("./AccordionItem.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var propTypes = {
  title: _propTypes.default.string.isRequired,
  onRemove: _propTypes.default.func,
  children: _propTypes.default.any.isRequired,
  whiteTitle: _propTypes.default.bool,
  withoutBottomOffset: _propTypes.default.bool,
  renderRightInformation: _propTypes.default.node,
  className: _propTypes.default.string,
  innerClassName: _propTypes.default.string
};
var defaultProps = {
  onRemove: undefined,
  whiteTitle: false,
  withoutBottomOffset: false,
  renderRightInformation: undefined,
  className: '',
  innerClassName: ''
};

var AccordionItem = function AccordionItem(_ref) {
  var title = _ref.title,
      onRemove = _ref.onRemove,
      children = _ref.children,
      whiteTitle = _ref.whiteTitle,
      withoutBottomOffset = _ref.withoutBottomOffset,
      renderRightInformation = _ref.renderRightInformation,
      className = _ref.className,
      innerClassName = _ref.innerClassName,
      others = _objectWithoutProperties(_ref, ["title", "onRemove", "children", "whiteTitle", "withoutBottomOffset", "renderRightInformation", "className", "innerClassName"]);

  var _useState = (0, _react.useState)(false),
      _useState2 = _slicedToArray(_useState, 2),
      isExpanded = _useState2[0],
      setIsExpanded = _useState2[1];

  var toggleExpanded = function toggleExpanded() {
    return setIsExpanded(!isExpanded);
  };

  var contentClassName = (0, _classnames.default)(_AccordionItemModule.default.content, _defineProperty({}, _AccordionItemModule.default.content_without_bottom_offset, withoutBottomOffset));
  var itemContainerClassName = (0, _classnames.default)(_AccordionItemModule.default.item_container, className);
  var iconContainerClassName = (0, _classnames.default)(_AccordionItemModule.default.icon_container, 'align-items-center');
  var headerClassName = (0, _classnames.default)('justify-content-space-between', 'align-items-center', _AccordionItemModule.default.header, _defineProperty({}, _AccordionItemModule.default.header_white, whiteTitle));
  return _react.default.createElement("div", {
    className: itemContainerClassName
  }, _react.default.createElement(_Row.default, {
    className: headerClassName,
    onClick: toggleExpanded
  }, _react.default.createElement("div", {
    className: _AccordionItemModule.default.title
  }, title), _react.default.createElement(_Row.default, {
    className: iconContainerClassName
  }, renderRightInformation, onRemove && _react.default.createElement(_FontAwesomeIcon.default, {
    icon: _icons.default.trash,
    title: _languageService.default.buttons.RemoveDriver(),
    className: "".concat(_AccordionItemModule.default.icon, " ").concat(_AccordionItemModule.default.icon_remove, " clickable-icon"),
    onClick: onRemove
  }), _react.default.createElement(_FontAwesomeIcon.default, {
    icon: _icons.default.chevronDown,
    title: isExpanded ? _languageService.default.labels.Fold() : _languageService.default.labels.Expand(),
    className: "".concat(_AccordionItemModule.default.icon, " ").concat(isExpanded ? _AccordionItemModule.default.icon_reversed : '')
  }))), _react.default.createElement(_ContentRevealer.default, _extends({}, others, {
    className: innerClassName,
    isVisible: isExpanded,
    visibleClassName: contentClassName
  }), children));
};

AccordionItem.propTypes = propTypes;
AccordionItem.defaultProps = defaultProps;
var _default = AccordionItem;
exports.default = _default;