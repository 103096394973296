import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, FormSection, formValueSelector, change } from 'redux-form';
import { useSelector } from 'react-redux';
import Form from 'fox-react/dist/components/Form';
import FoxInputTextField from 'fox-react/dist/reduxFormFields/FoxInputTextField';
import FoxInputEmailField from 'fox-react/dist/reduxFormFields/FoxInputEmailField';
import FoxMaskedInputTextField from 'fox-react/dist/reduxFormFields/FoxMaskedInputTextField';
import FoxComboBoxField from 'fox-react/dist/reduxFormFields/FoxComboBoxField';
import FoxDropDownList from 'fox-react/dist/components/FoxDropDownList';
import FoxButton from 'fox-react/dist/components/FoxButton';
import { isEmail, isRequiredArray } from 'fox-react/dist/utils/formValidators';
import goBackAfterSuccess from 'fox-react/dist/utils/goBackAfterSuccess';
import FoxSwitchField from 'fox-react/dist/reduxFormFields/FoxSwitchField';
import lang from 'hh-shared/dist/language/services/languageService';
import {
  scrollToTheFirstError,
  displayCompletionToast,
} from 'hh-shared/dist/consts/reduxFormMethods';
import { isRequiredFieldValidator } from 'hh-shared/dist/utils/formValidators';
import phoneMask from 'hh-shared/dist/utils/phoneMask';
import PermissionWrapper from 'commons/PermissionWrapper';
import permissions from 'common/permissions';
import fieldNames from 'common/fieldNames';
import formNames from 'common/formNames';
import icons from 'hh-shared/dist/consts/icons';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';
import ContentRevealer from 'hh-shared/dist/components/commons/ContentRevealer';

import UserPermissions from './UserPermissions';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  versionNumber: PropTypes.number,
  versionNumbers: PropTypes.array.isRequired,
  onChangeVersion: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  onSubmitAsNew: PropTypes.func.isRequired,
  facilities: PropTypes.array.isRequired,
  companies: PropTypes.array,
  departments: PropTypes.any.isRequired,
};

const defaultProps = {
  versionNumber: 1,
  companies: [],
};

const formSelector = formValueSelector(formNames.userInformationForm);

function EditUserInformationForm({
  handleSubmit,
  versionNumber,
  versionNumbers,
  onChangeVersion,
  submitting,
  onSubmitAsNew,
  facilities,
  companies,
  departments,
}) {
  const submitAsNew = () => {
    handleSubmit(onSubmitAsNew)();
  };

  const isDepAdminPermission = useSelector(
    state =>
      !!formSelector(
        state,
        `permissions.${fieldNames.userPermissions.DEP_ADMIN}`,
      ),
  );
  const isEmployeePermission = useSelector(
    state =>
      !!formSelector(
        state,
        `permissions.${fieldNames.userPermissions.EMPLOYEE}`,
      ),
  );

  const isLogisticianPermission = useSelector(
    state =>
      !!formSelector(
        state,
        `permissions.${fieldNames.userPermissions.LOGISTICIAN}`,
      ),
  );

  return (
    <Form handleSubmit={handleSubmit}>
      <fieldset>
        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.UserInformations()}
            titleIcon={icons.userData}
          >
            <FoxInputTextField
              label={lang.labels.Login()}
              name={fieldNames.userInformationForm.login}
              placeholder={lang.labels.Login()}
              required
            />

            <FoxInputEmailField
              label={lang.labels.Email()}
              placeholder={lang.labels.Email()}
              name={fieldNames.userInformationForm.email}
              required
            />

            <FoxSwitchField
              label={lang.labels.UserBanned()}
              name={fieldNames.userInformationForm.isBanned}
            />
          </FormSectionCard>
        </SectionWrapper>

        <FormSection name="permissions">
          <UserPermissions />
        </FormSection>

        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.AdditionalUserInformations()}
            titleIcon={icons.userData}
          >
            <p>{lang.labels.Version()}</p>

            <FoxDropDownList
              items={versionNumbers}
              value={versionNumber}
              onChange={onChangeVersion}
              dataItemKey=""
              textField=""
            />

            <FoxInputTextField
              label={lang.labels.FirstName()}
              placeholder={lang.labels.FirstName()}
              name={fieldNames.userInformationForm.firstName}
              required
            />

            <FoxInputTextField
              label={lang.labels.LastName()}
              placeholder={lang.labels.LastName()}
              name={fieldNames.userInformationForm.lastName}
              required
            />

            <FoxMaskedInputTextField
              {...phoneMask}
              label={lang.labels.PhoneNumber()}
              placeholder={lang.labels.PhoneNumber()}
              name={fieldNames.userInformationForm.phoneNumber}
            />

            <PermissionWrapper
              oneOfPermissions={[permissions.DEP_ADMIN, permissions.ADMIN]}
            >
              <ContentRevealer
                isVisible={isDepAdminPermission}
                visibleClassName="margin margin-bottom"
              >
                <FoxComboBoxField
                  label={lang.labels.Department()}
                  placeholder={lang.labels.Department()}
                  name={fieldNames.userInformationForm.department}
                  items={departments}
                  validate={
                    isDepAdminPermission ? isRequiredFieldValidator : undefined
                  }
                  required={isDepAdminPermission}
                />
              </ContentRevealer>
            </PermissionWrapper>

            <ContentRevealer
              isVisible={isEmployeePermission}
              visibleClassName="margin margin-bottom"
            >
              <FoxComboBoxField
                label={lang.labels.Facility()}
                placeholder={lang.labels.Facility()}
                name={fieldNames.userInformationForm.facility}
                items={facilities}
                validate={isEmployeePermission && isRequiredFieldValidator}
                required={isEmployeePermission}
              />
            </ContentRevealer>

            <ContentRevealer
              isVisible={isLogisticianPermission}
              visibleClassName="margin margin-bottom"
            >
              <FoxComboBoxField
                label={lang.labels.Company()}
                placeholder={lang.labels.Company()}
                name={fieldNames.userInformationForm.company}
                items={companies}
                validate={isLogisticianPermission && isRequiredFieldValidator}
                required={isLogisticianPermission}
              />
            </ContentRevealer>
          </FormSectionCard>
        </SectionWrapper>
      </fieldset>

      <FoxButton primary disabled={submitting}>
        {lang.buttons.Save()}
      </FoxButton>
      <FoxButton
        type="button"
        primary
        disabled={submitting}
        onClick={submitAsNew}
      >
        {lang.buttons.SaveAsNew()}
      </FoxButton>
    </Form>
  );
}

EditUserInformationForm.propTypes = propTypes;
EditUserInformationForm.defaultProps = defaultProps;

const validate = values => {
  const errors = {};

  isRequiredArray(
    errors,
    values,
    [
      fieldNames.userInformationForm.login,
      fieldNames.userInformationForm.firstName,
      fieldNames.userInformationForm.lastName,
      fieldNames.userInformationForm.email,
    ],
    lang.validationMessages.FieldRequired(),
  );

  isEmail(
    errors,
    values,
    fieldNames.userInformationForm.email,
    lang.validationMessages.FieldMustBeValidEmail(),
  );

  return errors;
};

function onChange(values, dispatch, props, prevValues) {
  if (!props.dirty) {
    return;
  }

  const { permissions: currentPermissions } = values;

  if (currentPermissions && currentPermissions !== prevValues.permissions) {
    const {
      ADMIN,
      DEP_ADMIN,
      EMPLOYEE,
      LOGISTICIAN,
      PRICES_MANAGEMENT,
    } = currentPermissions;

    if (
      (!ADMIN &&
        !DEP_ADMIN &&
        !EMPLOYEE &&
        !LOGISTICIAN &&
        !PRICES_MANAGEMENT) ||
      !prevValues ||
      !prevValues.permissions
    ) {
      return;
    }

    if (
      (ADMIN !== prevValues.permissions.ADMIN && ADMIN) ||
      (DEP_ADMIN !== prevValues.permissions.DEP_ADMIN && DEP_ADMIN) ||
      (EMPLOYEE !== prevValues.permissions.EMPLOYEE && EMPLOYEE) ||
      (PRICES_MANAGEMENT !== prevValues.permissions.PRICES_MANAGEMENT &&
        PRICES_MANAGEMENT)
    ) {
      dispatch(
        change(
          formNames.userInformationForm,
          `permissions.${fieldNames.userPermissions.LOGISTICIAN}`,
          false,
        ),
      );
    } else if (
      LOGISTICIAN !== prevValues.permissions.LOGISTICIAN &&
      LOGISTICIAN
    ) {
      dispatch(
        change(
          formNames.userInformationForm,
          `permissions.${fieldNames.userPermissions.ADMIN}`,
          false,
        ),
      );
      dispatch(
        change(
          formNames.userInformationForm,
          `permissions.${fieldNames.userPermissions.DEP_ADMIN}`,
          false,
        ),
      );
      dispatch(
        change(
          formNames.userInformationForm,
          `permissions.${fieldNames.userPermissions.EMPLOYEE}`,
          false,
        ),
      );
      dispatch(
        change(
          formNames.userInformationForm,
          `permissions.${fieldNames.userPermissions.PRICES_MANAGEMENT}`,
          false,
        ),
      );
    }
  }
}

const onSubmitSuccess = (result, dispatch) =>
  displayCompletionToast(dispatch, lang.labels.SuccessfullyModificatedUser());

export default reduxForm({
  form: formNames.userInformationForm,
  validate,
  enableReinitialize: true,
  onChange,
  onSubmitFail: scrollToTheFirstError,
  onSubmitSuccess,
})(goBackAfterSuccess(EditUserInformationForm));
