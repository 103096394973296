import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import createFoxGridContainer from 'fox-react/dist/components/FoxGrid/createFoxGridContainer';
import FoxButton from 'fox-react/dist/components/FoxButton';
import FoxGridColumn from 'fox-react/dist/components/FoxGrid/FoxGridColumn';

import lang from 'hh-shared/dist/language/services/languageService';
import gridNames from 'common/gridNames';
import columnNames from 'common/columnNames';
import NumericCell from 'hh-shared/dist/components/grid/NumericCell';
import InnerContentWrapper from 'hh-shared/dist/components/layout/InnerContentWrapper';
import actionsColumnGridDefaults from 'hh-shared/dist/consts/actionsColumnGridDefaults';

const propTypes = {
  onLoadData: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const defaultProps = {};

const FoxGridContainer = createFoxGridContainer(
  gridNames.defaultPricesManagement,
);

class DefaultPricesManagementPage extends PureComponent {
  render() {
    const { onLoadData, match } = this.props;

    return (
      <InnerContentWrapper fullWidth>
        <FoxGridContainer
          onLoadData={onLoadData}
          defaultSortField={columnNames.defaultPricesManagement.flatRate}
          renderButtons={
            <Link to={`${match.url}/add`}>
              <FoxButton icon="add" primary>
                {lang.buttons.Add()}
              </FoxButton>
            </Link>
          }
        >
          <FoxGridColumn
            {...actionsColumnGridDefaults}
            cell={({ dataItem }) => (
              <td>
                <Link to={`${match.url}/edit/${dataItem.id}`}>
                  <FoxButton icon="edit">{lang.buttons.Edit()}</FoxButton>
                </Link>
              </td>
            )}
          />
          <FoxGridColumn
            field={columnNames.defaultPricesManagement.flatRate}
            title={lang.labels.DefaultPricesManagement_Columns_FlatRate()}
            filter="numeric"
            cell={({ dataItem }) => <NumericCell value={dataItem.flatRate} />}
          />
          <FoxGridColumn
            field={columnNames.defaultPricesManagement.minDistance}
            title={lang.labels.DefaultPricesManagement_Columns_MinDistance()}
            filter="numeric"
            cell={({ dataItem }) => (
              <NumericCell value={dataItem.minDistance} />
            )}
          />
          <FoxGridColumn
            field={columnNames.defaultPricesManagement.maxDistance}
            title={lang.labels.DefaultPricesManagement_Columns_MaxDistance()}
            filter="numeric"
            cell={({ dataItem }) => (
              <NumericCell value={dataItem.maxDistance} />
            )}
          />
          <FoxGridColumn
            field={columnNames.defaultPricesManagement.facilityVersionName}
            title={lang.labels.Facility()}
            filter="text"
          />
        </FoxGridContainer>
      </InnerContentWrapper>
    );
  }
}

DefaultPricesManagementPage.propTypes = propTypes;
DefaultPricesManagementPage.defaultProps = defaultProps;

export default DefaultPricesManagementPage;
