import React from 'react';
import PropTypes from 'prop-types';
import formNames from 'common/formNames';
import Form from 'fox-react/dist/components/Form';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import lang from 'hh-shared/dist/language/services/languageService';
import {
  scrollToTheFirstError,
  displayCompletionToasts,
} from 'hh-shared/dist/consts/reduxFormMethods';
import icons from 'hh-shared/dist/consts/icons';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';
import FoxInputTextField from 'fox-react/dist/reduxFormFields/FoxInputTextField';
import fieldNames from 'common/fieldNames';
import { FieldArray, reduxForm } from 'redux-form';
import FoxButton from 'fox-react/dist/components/FoxButton';
import goBackAfterSuccess from 'fox-react/dist/utils/goBackAfterSuccess';
import FoxConfirmationButton from 'fox-react/dist/components/FoxConfirmationButton';
import ZipCodeAutocompleteField from 'commons/ZipCodeAutocompleteField';
import { isRequiredFieldValidator } from 'hh-shared/dist/utils/formValidators';
import ZipCodesManagementSrv from './ZipCodesManagementSrv';
import StreetList from './StreetList';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  zipCodeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isEditForm: PropTypes.bool,
};

const defaultProps = {
  isEditForm: false,
  zipCodeId: undefined,
};

function ZipCodeForm({
  handleSubmit,
  submitting,
  history,
  zipCodeId,
  isEditForm,
}) {
  const remove = () => {
    ZipCodesManagementSrv.delete(zipCodeId).then(() => history.goBack());
  };

  return (
    <Form handleSubmit={handleSubmit}>
      <fieldset>
        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.ZipCodes()}
            titleIcon={icons.zipCode}
          >
            <ZipCodeAutocompleteField
              required
              validate={isRequiredFieldValidator}
            />
            <FoxInputTextField
              label={lang.labels.City()}
              placeholder={lang.labels.City()}
              name={fieldNames.zipCodeForm.city}
              required
              validate={isRequiredFieldValidator}
            />
            <FieldArray name="streets" component={StreetList} />
          </FormSectionCard>
        </SectionWrapper>
      </fieldset>

      <FoxButton primary disabled={submitting}>
        {lang.buttons.Save()}
      </FoxButton>

      {isEditForm && (
        <FoxConfirmationButton
          buttonText={lang.buttons.Delete()}
          onYesClick={remove}
          className="button button_error"
        />
      )}
    </Form>
  );
}

ZipCodeForm.propTypes = propTypes;
ZipCodeForm.defaultProps = defaultProps;

const onSubmitSuccess = (result, dispatch, props) =>
  displayCompletionToasts(
    dispatch,
    !!props.isEditForm,
    lang.labels.SuccessfullyModificatedZipCode(),
    lang.labels.SuccessfullyCreatedZipCode(),
  );

export default reduxForm({
  form: formNames.zipCodeForm,
  onSubmitFail: scrollToTheFirstError,
  onSubmitSuccess,
})(goBackAfterSuccess(ZipCodeForm));
