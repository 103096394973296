import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import get from 'lodash/get';

import FormPreviewPane from 'hh-shared/dist/components/layout/FormPreview/FormPreviewPaneContainer';
import FormPreviewSection from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSection';
import FormPreviewItem from 'hh-shared/dist/components/layout/FormPreview/FormPreviewItem';
import FormPreviewItemList from 'hh-shared/dist/components/layout/FormPreview/FormPreviewItemList';
import ContentRevealer from 'hh-shared/dist/components/commons/ContentRevealer';
import icons from 'hh-shared/dist/consts/icons';
import lang from 'hh-shared/dist/language/services/languageService';
import textFormating from 'common/textFormating';
import numberFormating from 'hh-shared/dist/consts/numberFormating';
import previewGetters, { constructFixDate } from 'common/previewGetters';
import formNames from 'common/formNames';
import { phoneMaskedDisplay } from 'hh-shared/dist/utils/phoneMask';

const propTypes = {
  loadingMethods: PropTypes.array.isRequired,
  unloadingMethods: PropTypes.array.isRequired,
  auctionTypes: PropTypes.array.isRequired,
};

const defaultProps = {};

/* eslint-disable indent */

const AuctionsManagementPagePreview = ({
  loadingMethods,
  unloadingMethods,
  auctionTypes,
}) => {
  const { toDataFormat } = textFormating;
  const { insertThousandsSeparator, toMoneyFormat } = numberFormating;
  const { getCurrentAddress } = previewGetters;
  const formValues = useSelector(
    state => getFormValues(formNames.auctionInformationForm)(state) || {},
  );

  const getAuctionType = () => {
    if (formValues.auctionType) {
      const currentAuctionType = auctionTypes.filter(
        auctionType => auctionType.id === formValues.auctionType,
      )[0];
      return (currentAuctionType && currentAuctionType.name) || undefined;
    }
    return undefined;
  };

  const getCurrency = (defaultReturnValue = undefined) =>
    (formValues.currency && formValues.currency.name) || defaultReturnValue;

  const getCurrentMethodName = (loading = true) => {
    if (!loadingMethods || !unloadingMethods) return undefined;
    const currentMethodArr = loading ? loadingMethods : unloadingMethods;
    const currentMethodName = loading ? 'loadingMethod' : 'unloadingMethod';

    return (
      (formValues[currentMethodName] &&
        (() => {
          const currentMethod = currentMethodArr.filter(
            method => method.id === formValues[currentMethodName],
          )[0];
          return (currentMethod && currentMethod.name) || undefined;
        })()) ||
      undefined
    );
  };

  return (
    <FormPreviewPane>
      <FormPreviewSection
        title={`${lang.labels.AuctionInformation()} ${formValues.number || ''}`}
        icon={icons.auction}
      >
        <FormPreviewItem
          label={lang.labels.AuctionType()}
          value={getAuctionType()}
        />

        {/* TODO podpięcie niestatycznego tekstu, by sprawdzić czy aukcja jest "publiczna" */}
        {getAuctionType() !== 'Publiczna' && (
          <FormPreviewItemList
            label={lang.labels.Subjects()}
            values={
              formValues.subjects
                ? formValues.subjects.map(subject => subject.name)
                : []
            }
          />
        )}

        <FormPreviewItem
          label={lang.labels.StartDateTime()}
          value={
            formValues.startTime
              ? toDataFormat(formValues.startTime)
              : undefined
          }
        />

        <FormPreviewItem
          label={lang.labels.EndDateTime()}
          value={
            formValues.endTime ? toDataFormat(formValues.endTime) : undefined
          }
        />

        <FormPreviewItem
          label={lang.labels.PrincipalPerson()}
          value={get(formValues, 'principalPerson.name') || undefined}
        />

        <FormPreviewItem
          label={lang.labels.Facility()}
          value={get(formValues, 'facility.name') || undefined}
        />
      </FormPreviewSection>
      <FormPreviewSection
        title={lang.labels.LoadingData()}
        icon={icons.loadingVehicle}
      >
        <FormPreviewItem
          label={lang.labels.LoadingDateTime()}
          value={constructFixDate(
            formValues.loadingTimeFix,
            formValues.loadingTimeFrom,
            formValues.loadingTimeTo,
          )}
        />

        <FormPreviewItem
          label={lang.labels.Address()}
          value={getCurrentAddress(
            formValues,
            'isNewLoadingAddress',
            'newLoadingAddress',
            'loadingAddress',
          )}
          isNew={formValues.isNewLoadingAddress}
        />
      </FormPreviewSection>
      <FormPreviewSection
        title={lang.labels.UnloadingData()}
        icon={icons.loadingVehicle}
      >
        <FormPreviewItem
          label={lang.labels.UnloadingDateTime()}
          value={constructFixDate(
            formValues.unloadingTimeFix,
            formValues.unloadingTimeFrom,
            formValues.unloadingTimeTo,
          )}
        />

        <FormPreviewItem
          label={lang.labels.Address()}
          value={getCurrentAddress(
            formValues,
            'isNewUnloadingAddress',
            'newUnloadingAddress',
            'unloadingAddress',
          )}
          isNew={formValues.isNewUnloadingAddress}
        />

        <FormPreviewItem
          label={lang.labels.AuthorizedPickupPerson()}
          value={formValues.authorizedPickupPerson || undefined}
        />

        <ContentRevealer
          isVisible={!!formValues.authorizedPickupPerson}
          mutableValues={[formValues.authorizedPickupPersonPhoneNumber]}
        >
          <FormPreviewItem
            label={lang.labels.AuthorizedPickupPersonPhoneNumber()}
            value={
              phoneMaskedDisplay(
                formValues.authorizedPickupPersonPhoneNumber,
              ) || undefined
            }
          />
        </ContentRevealer>
      </FormPreviewSection>
      <FormPreviewSection title={lang.labels.Expense()} icon={icons.money}>
        <FormPreviewItem
          label={lang.labels.Distance()}
          value={
            formValues.distance
              ? `${insertThousandsSeparator(formValues.distance)} km`
              : undefined
          }
        />

        <FormPreviewItem
          label={lang.labels.PriceForKilemeter()}
          value={
            formValues.pricePerKilometer
              ? `${toMoneyFormat(formValues.pricePerKilometer)} ${getCurrency(
                  '',
                )}`
              : undefined
          }
        />

        <FormPreviewItem
          label={lang.labels.StartPrice()}
          value={
            formValues.startPrice
              ? `${toMoneyFormat(formValues.startPrice)} ${getCurrency('')}`
              : undefined
          }
        />

        <FormPreviewItem label={lang.labels.Currency()} value={getCurrency()} />
      </FormPreviewSection>
      <FormPreviewSection title={lang.labels.Transport()} icon={icons.truck}>
        <FormPreviewItem
          label={lang.labels.Capacity()}
          value={
            formValues.capacity
              ? `${insertThousandsSeparator(formValues.capacity)} kg`
              : undefined
          }
        />

        <FormPreviewItem
          label={lang.labels.LoadingMethod()}
          value={getCurrentMethodName()}
        />

        <FormPreviewItem
          label={lang.labels.UnloadingMethod()}
          value={getCurrentMethodName(false)}
        />

        <FormPreviewItem
          label={lang.labels.AdditionalRequirements()}
          value={formValues.additionalRequirements || undefined}
        />
      </FormPreviewSection>
    </FormPreviewPane>
  );
};

AuctionsManagementPagePreview.propTypes = propTypes;
AuctionsManagementPagePreview.defaultProps = defaultProps;

export default AuctionsManagementPagePreview;
