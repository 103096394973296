import React from 'react';
import PropTypes from 'prop-types';
import { FormSection } from 'redux-form';
import { base64toBlob, downloadFile } from 'hh-shared/dist/utils/filesHelper';
import Attachment from 'hh-shared/dist/components/commons/Attachment';

const propTypes = {
  fields: PropTypes.object.isRequired,
  companyAttachments: PropTypes.array.isRequired,
};

function CompanyAttachment({ fields, companyAttachments }) {
  return (
    <div>
      {fields.map((member, index) => (
        <FormSection name={member} key={index}>
          <Attachment
            key={companyAttachments[index].id}
            fileName={companyAttachments[index].name}
            onRemove={() => fields.remove(index)}
            onDownload={() => {
              const content = companyAttachments[index].isNew
                ? companyAttachments[index].content
                : base64toBlob(companyAttachments[index].content);
              return downloadFile(content, companyAttachments[index].name);
            }}
          />
        </FormSection>
      ))}
    </div>
  );
}

CompanyAttachment.propTypes = propTypes;

export default CompanyAttachment;
