import { connect } from 'react-redux';
import CarTypesManagementPage from './CarTypesManagementPage';
import { getForGrid } from './actions';

const mapDispatchToProps = dispatch => {
  return {
    onLoadData: dataState => dispatch(getForGrid(dataState)),
  };
};

const CarTypesManagementPageContainer = connect(
  undefined,
  mapDispatchToProps,
)(CarTypesManagementPage);

export default CarTypesManagementPageContainer;
