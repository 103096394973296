import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, FormSection, formValueSelector } from 'redux-form';
import { useSelector } from 'react-redux';
import Form from 'fox-react/dist/components/Form';
import FoxComboBoxField from 'fox-react/dist/reduxFormFields/FoxComboBoxField';
import FoxButton from 'fox-react/dist/components/FoxButton';
import {
  isRequiredArray,
  isRequired,
} from 'fox-react/dist/utils/formValidators';
import goBackAfterSuccess from 'fox-react/dist/utils/goBackAfterSuccess';
import FoxSwitchField from 'fox-react/dist/reduxFormFields/FoxSwitchField';
import lang from 'hh-shared/dist/language/services/languageService';
import fieldNames from 'common/fieldNames';
import formNames from 'common/formNames';
import icons from 'hh-shared/dist/consts/icons';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';
import ContentRevealer from 'hh-shared/dist/components/commons/ContentRevealer';
import { calculateCity } from 'common/zipCodeDistanceCalculator';
import { fieldChanged } from 'fox-react/dist/utils/formHelpers';
import FoxInputNumberDecimalField from 'fox-react/dist/reduxFormFields/FoxInputNumberDecimalField';
import { displayCompletionToasts } from 'hh-shared/dist/consts/reduxFormMethods';
import NewZipCodeForm from './NewZipCodeForm';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  zipCodes: PropTypes.array.isRequired,
};

const defaultProps = {};

const formSelector = formValueSelector(formNames.routeInformationForm);

function RouteInformationForm({ handleSubmit, submitting, zipCodes }) {
  const isNewZipCodeFrom = useSelector(
    state =>
      !!formSelector(state, fieldNames.routeInformationForm.isNewZipCodeFrom),
  );

  const isNewZipCodeTo = useSelector(
    state =>
      !!formSelector(state, fieldNames.routeInformationForm.isNewZipCodeTo),
  );

  return (
    <Form handleSubmit={handleSubmit}>
      <fieldset>
        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.RouteInformations()}
            titleIcon={icons.route}
          >
            <ContentRevealer isVisible={!isNewZipCodeFrom}>
              <FoxComboBoxField
                label={lang.labels.ZipCodeFrom()}
                name={fieldNames.routeInformationForm.zipCodeFrom}
                items={zipCodes}
                required
              />
            </ContentRevealer>

            <FoxSwitchField
              label={lang.labels.NewZipCode()}
              placeholder={lang.labels.NewZipCode()}
              name={fieldNames.routeInformationForm.isNewZipCodeFrom}
            />

            <ContentRevealer
              isVisible={isNewZipCodeFrom}
              visibleClassName="margin margin-bottom"
            >
              <FormSection name="newZipCodeFrom">
                <NewZipCodeForm />
              </FormSection>
            </ContentRevealer>

            <ContentRevealer isVisible={!isNewZipCodeTo}>
              <FoxComboBoxField
                label={lang.labels.ZipCodeTo()}
                name={fieldNames.routeInformationForm.zipCodeTo}
                items={zipCodes}
                required
              />
            </ContentRevealer>

            <FoxSwitchField
              label={lang.labels.NewZipCode()}
              placeholder={lang.labels.NewZipCode()}
              name={fieldNames.routeInformationForm.isNewZipCodeTo}
            />

            <ContentRevealer isVisible={isNewZipCodeTo}>
              <FormSection name="newZipCodeTo">
                <NewZipCodeForm />
              </FormSection>
            </ContentRevealer>

            <FoxInputNumberDecimalField
              label={lang.labels.Distance()}
              placeholder={lang.labels.Distance()}
              name={fieldNames.routeInformationForm.distance}
              required
            />
          </FormSectionCard>
        </SectionWrapper>
      </fieldset>
      <FoxButton primary disabled={submitting}>
        {lang.buttons.Save()}
      </FoxButton>
    </Form>
  );
}

RouteInformationForm.propTypes = propTypes;
RouteInformationForm.defaultProps = defaultProps;

const validate = values => {
  const errors = {};
  isRequiredArray(
    errors,
    values,
    [fieldNames.routeInformationForm.distance],
    lang.validationMessages.FieldRequired(),
  );

  if (!values.isNewZipCodeFrom && !values.isNewZipCodeTo) {
    if (
      values[fieldNames.routeInformationForm.zipCodeFrom] ===
      values[fieldNames.routeInformationForm.zipCodeTo]
    ) {
      errors[
        fieldNames.routeInformationForm.zipCodeFrom
      ] = lang.validationMessages.ZipCodeFromCannotBeEqualToZipCodeTo();
      errors[
        fieldNames.routeInformationForm.zipCodeTo
      ] = lang.validationMessages.ZipCodeFromCannotBeEqualToZipCodeTo();
    }
  }

  if (!values.isNewZipCodeFrom) {
    isRequired(
      errors,
      values,
      fieldNames.routeInformationForm.zipCodeFrom,
      lang.validationMessages.FieldRequired(),
    );
  } else {
    isRequiredArray(
      errors,
      values,
      ['newZipCodeFrom.zipCode', 'newZipCodeFrom.city'],
      lang.validationMessages.FieldRequired(),
    );
  }

  if (!values.isNewZipCodeTo) {
    isRequired(
      errors,
      values,
      fieldNames.routeInformationForm.zipCodeTo,
      lang.validationMessages.FieldRequired(),
    );
  } else {
    isRequiredArray(
      errors,
      values,
      ['newZipCodeTo.zipCode', 'newZipCodeTo.city'],
      lang.validationMessages.FieldRequired(),
    );
  }

  return errors;
};

function onChange(values, dispatch, props, prevValues) {
  if (!props.dirty) {
    return;
  }

  if (values.isNewZipCodeFrom && values.newZipCodeFrom) {
    if (fieldChanged(values, prevValues, 'newZipCodeFrom.zipCode')) {
      calculateCity(
        values,
        'newZipCodeFrom',
        props.zipCodes,
        formNames.routeInformationForm,
        dispatch,
      );
    }
  }

  if (values.isNewZipCodeTo && values.newZipCodeTo) {
    if (fieldChanged(values, prevValues, 'newZipCodeTo.zipCode')) {
      calculateCity(
        values,
        'newZipCodeTo',
        props.zipCodes,
        formNames.routeInformationForm,
        dispatch,
      );
    }
  }
}

const onSubmitSuccess = (result, dispatch, props) =>
  displayCompletionToasts(
    dispatch,
    !!props.isEditForm,
    lang.labels.SuccessfullyModificatedRoute(),
    lang.labels.SuccessfullyCreatedRoute(),
  );

export default reduxForm({
  form: formNames.routeInformationForm,
  validate,
  enableReinitialize: true,
  onChange,
  onSubmitSuccess,
})(goBackAfterSuccess(RouteInformationForm));
