import React from 'react';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import formNames from 'common/formNames';
import FormPreviewPane from 'hh-shared/dist/components/layout/FormPreview/FormPreviewPaneContainer';
import FormPreviewSection from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSection';
import FormPreviewItem from 'hh-shared/dist/components/layout/FormPreview/FormPreviewItem';

const propTypes = {};

const defaultProps = {};

const ZipCodesManagementPreviewPage = () => {
  const formValues = useSelector(
    state => getFormValues(formNames.zipCodeForm)(state) || {},
  );

  return (
    <FormPreviewPane>
      <FormPreviewSection title={lang.labels.ZipCodes()} icon={icons.zipCode}>
        <FormPreviewItem
          label={lang.labels.ZipCode()}
          value={formValues.zipCode}
        />
        <FormPreviewItem label={lang.labels.City()} value={formValues.city} />
      </FormPreviewSection>
    </FormPreviewPane>
  );
};

ZipCodesManagementPreviewPage.propTypes = propTypes;
ZipCodesManagementPreviewPage.defaultProps = defaultProps;

export default ZipCodesManagementPreviewPage;
