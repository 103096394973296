import moment from 'moment';
import { dateTimeFormat } from 'hh-shared/dist/consts/formats';

export const getTwoDigitRepresentation = number =>
  `${`${number}`.length < 2 ? '0' : ''}${number}`;

export const toDataFormat = data => moment(data).format(dateTimeFormat);

const textFormating = {
  getTwoDigitRepresentation,
  toDataFormat,
};

export default textFormating;
