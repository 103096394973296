import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, FormSection, change, getFormValues } from 'redux-form';
import Form from 'fox-react/dist/components/Form';
import FoxComboBoxField from 'fox-react/dist/reduxFormFields/FoxComboBoxField';
import FoxInputTextField from 'fox-react/dist/reduxFormFields/FoxInputTextField';
import FoxInputNumberField from 'fox-react/dist/reduxFormFields/FoxInputNumberField';
import FoxInputNumberDecimalField from 'fox-react/dist/reduxFormFields/FoxInputNumberDecimalField';
import FoxSwitchField from 'fox-react/dist/reduxFormFields/FoxSwitchField';
import FoxRadioButtonsField from 'fox-react/dist/reduxFormFields/FoxRadioButtonsField';
import FoxEditorTinyMCEField from 'fox-react/dist/reduxFormFields/FoxEditorTinyMCEField';
import FoxButton from 'fox-react/dist/components/FoxButton';
import { fieldChanged } from 'fox-react/dist/utils/formHelpers';
import lang from 'hh-shared/dist/language/services/languageService';
import NewDriverForm from 'commons/NewDriverForm';
import NewCarForm from 'commons/NewCarForm';
import NewAddressForm from 'commons/NewAddressForm';
import {
  scrollToTheFirstError,
  displayCompletionToasts,
} from 'hh-shared/dist/consts/reduxFormMethods';
import fieldNames from 'common/fieldNames';
import formNames from 'common/formNames';
import icons from 'hh-shared/dist/consts/icons';
import PreviewButton from 'hh-shared/dist/components/commons/PreviewButton';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import ContentRevealer from 'hh-shared/dist/components/commons/ContentRevealer';
import transportOrderStatusesIds from 'hh-shared/dist/consts/transportOrderStatusesIds';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';
import CurrencyPriceInputWrapper from 'layout/CurrencyPriceInputWrapper';
import FontAwesomeIcon from 'hh-shared/dist/components/commons/FontAwesomeIcon/FontAwesomeIcon';
import { useSelector } from 'react-redux';
import { onChangeZipCodeFactory } from 'common/zipCodeDistanceCalculator';
import FoxInputTextareaField from 'fox-react/dist/reduxFormFields/FoxInputTextareaField';
import { maxLenght200 } from 'fox-react/dist/utils/formFieldNormalizers';
import FixDateTimePickerSubForm from 'commons/FixDateTimePickerSubForm';
import moment from 'moment';
import CancelDialog from 'transport-orders-management/common/CancelDialog/CancelDialog';
import phoneMask from 'hh-shared/dist/utils/phoneMask';
import FoxMaskedInputTextField from 'fox-react/dist/reduxFormFields/FoxMaskedInputTextField';

import { validate } from './validate';
import useTransportCodesDictionary from 'common/useTransportCodesDictionary';

const fnames = fieldNames.transportOrderInformationForm;

let drivers = [];
let cars = [];

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  facilities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      drivers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          phoneNumber: PropTypes.string,
        }),
      ),
      cars: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  carTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  loadingMethods: PropTypes.array.isRequired,
  unloadingMethods: PropTypes.array.isRequired,
  showNewDriver: PropTypes.bool.isRequired,
  showNewCar: PropTypes.bool.isRequired,
  showNewLoadingAddress: PropTypes.bool.isRequired,
  showNewUnloadingAddress: PropTypes.bool.isRequired,
  currencies: PropTypes.array.isRequired,
  onDistanceChange: PropTypes.func.isRequired,
  onPricePerKilometerChange: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onResetPrices: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  isEditForm: PropTypes.bool,
  initialValues: PropTypes.shape({
    statusId: PropTypes.string,
  }),
};

const defaultProps = {
  onCancel: undefined,
  isEditForm: false,
  initialValues: {},
};

function TransportOrderInformationForm({
  handleSubmit,
  submitting,
  facilities,
  companies,
  addresses,
  carTypes,
  loadingMethods,
  unloadingMethods,
  showNewDriver,
  showNewCar,
  showNewLoadingAddress,
  showNewUnloadingAddress,
  currencies,
  onDistanceChange,
  onPricePerKilometerChange,
  onValueChange,
  onResetPrices,
  isEditForm,
  onCancel,
  initialValues: { statusId },
}) {
  const transportCodes = useTransportCodesDictionary();
  const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);
  const { authorizedPickupPerson } = useSelector(state =>
    getFormValues(formNames.transportOrderInformationForm)(state),
  );

  return (
    <Form handleSubmit={handleSubmit}>
      <fieldset>
        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.TransportOrderInformations()}
            titleIcon={icons.order}
          >
            <FoxInputTextField
              label={lang.labels.OrderSuffix()}
              placeholder={lang.labels.OrderSuffix()}
              name={fnames.additionalNumber}
            />
            <FoxComboBoxField
              label={lang.labels.Facility()}
              placeholder={lang.labels.Facility()}
              name={fnames.facility}
              items={facilities}
              required
            />

            <FoxComboBoxField
              label={lang.labels.Company()}
              placeholder={lang.labels.Company()}
              name={fnames.company}
              items={companies}
              required
            />

            <FoxInputTextField
              label={lang.labels.OrderNavNumber()}
              placeholder={lang.labels.OrderNavNumber()}
              name={fnames.navNumber}
            />

            <FoxInputTextareaField
              label={lang.labels.InternalNote()}
              placeholder={lang.labels.WriteInternalNote()}
              name={fnames.comment}
            />
          </FormSectionCard>
        </SectionWrapper>

        <SectionWrapper columnOnBreak>
          <FormSectionCard
            title={lang.labels.LoadingData()}
            titleIcon={icons.loadingVehicle}
            lessPaddingRight
          >
            <FixDateTimePickerSubForm
              label={lang.labels.LoadingDateTime()}
              placeholder={lang.labels.LoadingDateTime()}
              timeFromName={fnames.loadingTimeFrom}
              timeToName={fnames.loadingTimeTo}
              dataFixName={fnames.loadingTimeFix}
            />

            <ContentRevealer
              isVisible={!showNewLoadingAddress}
              visibleClassName="vertical-offset"
            >
              <FoxComboBoxField
                label={lang.labels.LoadingAddress()}
                name={fnames.loadingAddress}
                items={addresses}
                required
              />
            </ContentRevealer>

            <FoxSwitchField
              label={lang.labels.NewAddress()}
              placeholder={lang.labels.NewAddress()}
              name={fnames.isNewLoadingAddress}
            />

            <ContentRevealer isVisible={showNewLoadingAddress}>
              <FormSection name="newLoadingAddress">
                <NewAddressForm />
              </FormSection>
            </ContentRevealer>
          </FormSectionCard>

          <div className="column justify-content justify-content-center align-items align-items-center">
            <FontAwesomeIcon
              icon={icons.arrow}
              size="4x"
              className="triple-column-icon"
            />
          </div>

          <FormSectionCard
            title={lang.labels.UnloadingData()}
            titleIcon={icons.loadingVehicle}
            lessPaddingRight
          >
            <FixDateTimePickerSubForm
              label={lang.labels.UnloadingDateTime()}
              placeholder={lang.labels.LoadingDateTime()}
              timeFromName={fnames.unloadingTimeFrom}
              timeToName={fnames.unloadingTimeTo}
              dataFixName={fnames.unloadingTimeFix}
            />

            <ContentRevealer
              isVisible={!showNewUnloadingAddress}
              visibleClassName="vertical-offset"
            >
              <FoxComboBoxField
                label={lang.labels.UnloadingAddress()}
                name={fnames.unloadingAddress}
                items={addresses}
                required
              />
            </ContentRevealer>

            <FoxSwitchField
              label={lang.labels.NewAddress()}
              placeholder={lang.labels.NewAddress()}
              name={fnames.isNewUnloadingAddress}
            />

            <ContentRevealer isVisible={showNewUnloadingAddress}>
              <FormSection name="newUnloadingAddress">
                <NewAddressForm />
              </FormSection>
            </ContentRevealer>

            <FoxInputTextareaField
              label={lang.labels.AuthorizedPickupPerson()}
              placeholder={lang.labels.AuthorizedPickupPerson()}
              name={fnames.authorizedPickupPerson}
              className="not-extendable"
              normalize={maxLenght200}
            />

            <ContentRevealer isVisible={!!authorizedPickupPerson}>
              <FoxMaskedInputTextField
                {...phoneMask}
                label={lang.labels.AuthorizedPickupPersonPhoneNumber()}
                placeholder={lang.labels.AuthorizedPickupPersonPhoneNumberPlaceholder()}
                name={fnames.authorizedPickupPersonPhoneNumber}
              />
            </ContentRevealer>
          </FormSectionCard>
        </SectionWrapper>

        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.Expense()}
            titleIcon={icons.money}
          >
            <FoxInputNumberDecimalField
              label={`${lang.labels.Distance()} (km)`}
              placeholder={lang.labels.Distance()}
              name={fnames.distance}
              min={0}
              required
              onChange={onDistanceChange}
            />

            <FoxInputNumberDecimalField
              label={lang.labels.PricePerKilometer()}
              placeholder={lang.labels.PricePerKilometer()}
              name={fnames.pricePerKilometer}
              required
              onChange={onPricePerKilometerChange}
            />

            <CurrencyPriceInputWrapper>
              <FoxInputNumberDecimalField
                label={lang.labels.Value()}
                placeholder={lang.labels.Value()}
                name={fnames.value}
                required
                onChange={onValueChange}
              />

              <FoxComboBoxField
                label={lang.labels.Currency()}
                name={fnames.currency}
                items={currencies}
                required
              />
            </CurrencyPriceInputWrapper>

            <FoxButton
              primary
              type="button"
              onClick={onResetPrices}
              disabled={submitting}
            >
              {lang.buttons.Reset()}
            </FoxButton>
          </FormSectionCard>
        </SectionWrapper>

        <SectionWrapper>
          <FormSectionCard title="Transport" titleIcon={icons.truck}>
            <ContentRevealer isVisible={!showNewDriver}>
              <FoxComboBoxField
                label={lang.labels.Driver()}
                placeholder={lang.labels.Driver()}
                name={fnames.driver}
                items={drivers}
              />
            </ContentRevealer>

            <FoxSwitchField
              label={lang.labels.NewDriver()}
              placeholder={lang.labels.NewDriver()}
              name={fnames.isNewDriver}
            />

            <ContentRevealer
              isVisible={showNewDriver}
              visibleClassName="margin margin-bottom"
            >
              <FormSection name="newDriver">
                <NewDriverForm />
              </FormSection>
            </ContentRevealer>

            <ContentRevealer isVisible={!showNewCar}>
              <FoxComboBoxField
                label={lang.labels.Car()}
                placeholder={lang.labels.Car()}
                name={fnames.car}
                items={cars}
              />
            </ContentRevealer>

            <FoxSwitchField
              label={lang.labels.NewCar()}
              placeholder={lang.labels.NewCar()}
              name={fnames.isNewCar}
            />

            <ContentRevealer isVisible={showNewCar}>
              <FormSection name="newCar">
                <NewCarForm carTypes={carTypes} />
              </FormSection>
            </ContentRevealer>

            <FoxInputNumberField
              label={`${lang.labels.Capacity()} (kg)`}
              placeholder={lang.labels.Capacity()}
              name={fnames.capacity}
              required
            />

            <FoxRadioButtonsField
              label={lang.labels.LoadingMethod()}
              name={fnames.loadingMethodId}
              items={loadingMethods}
              required
            />

            <FoxRadioButtonsField
              label={lang.labels.UnloadingMethod()}
              name={fnames.unloadingMethodId}
              items={unloadingMethods}
              required
            />

            <FoxComboBoxField
              label={lang.labels.TransportCode()}
              name={fnames.transportCode}
              items={transportCodes}
              required
            />

            <FoxEditorTinyMCEField
              label={lang.labels.AdditionalRequirements()}
              placeholder={lang.labels.AdditionalRequirements()}
              name={fnames.additionalRequirements}
            />
          </FormSectionCard>
        </SectionWrapper>
      </fieldset>
      <FoxButton primary disabled={submitting}>
        {lang.buttons.Save()}
      </FoxButton>

      {isEditForm && statusId !== transportOrderStatusesIds.canceled && (
        <PreviewButton
          label={lang.buttons.CancelOrder()}
          icon={icons.order}
          onClick={() => setCancelDialogIsOpen(true)}
        />
      )}
      {isEditForm && statusId !== transportOrderStatusesIds.canceled && (
        <CancelDialog
          isOpen={cancelDialogIsOpen}
          onCancelConfirmed={onCancel}
          onCloseClick={() => setCancelDialogIsOpen(false)}
        />
      )}
    </Form>
  );
}

TransportOrderInformationForm.propTypes = propTypes;
TransportOrderInformationForm.defaultProps = defaultProps;

const onChangeZipCode = onChangeZipCodeFactory(
  formNames.transportOrderInformationForm,
);

const onCompanyChange = (values, dispatch, props, previousValues) => {
  if (!values.company) {
    dispatch(
      change(formNames.transportOrderInformationForm, fnames.driver, null),
    );
    dispatch(change(formNames.transportOrderInformationForm, fnames.car, null));
    return;
  }
  drivers = values.company.drivers;
  cars = values.company.cars;
  if (fieldChanged(values, previousValues, 'company')) {
    props.onAfterCompanyChange(values.company);
  }
};

function onChange(values, dispatch, props, previousValues) {
  if (!props.dirty) {
    return;
  }
  onCompanyChange(values, dispatch, props, previousValues);
  onChangeZipCode(values, previousValues, props, dispatch);
  if (fieldChanged(values, previousValues, 'distance')) {
    props.onDistanceChange(values.distance);
  }

  if (fieldChanged(values, previousValues, 'loadingTimeFrom')) {
    dispatch(
      props.change(
        fnames.loadingTimeTo,
        moment(values.loadingTimeFrom).add(2, 'days'),
      ),
    );
  }

  if (fieldChanged(values, previousValues, 'unloadingTimeFrom')) {
    dispatch(
      props.change(
        fnames.unloadingTimeTo,
        moment(values.unloadingTimeFrom).add(2, 'days'),
      ),
    );
  }
}

const onSubmitSuccess = (result, dispatch, props) =>
  displayCompletionToasts(
    dispatch,
    !!props.isEditForm,
    lang.labels.SuccessfullyModificatedOrder(),
    lang.labels.SuccessfullyCreatedOrder(),
  );

export default reduxForm({
  form: formNames.transportOrderInformationForm,
  validate,
  enableReinitialize: true,
  onChange,
  onSubmitFail: scrollToTheFirstError,
  onSubmitSuccess,
})(TransportOrderInformationForm);
