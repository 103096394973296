import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import createFoxGridContainer from 'fox-react/dist/components/FoxGrid/createFoxGridContainer';
import FoxButton from 'fox-react/dist/components/FoxButton';
import FoxGridColumn from 'fox-react/dist/components/FoxGrid/FoxGridColumn';
import columnNames from 'common/columnNames';
import gridNames from 'common/gridNames';
import lang from 'hh-shared/dist/language/services/languageService';
import InnerContentWrapper from 'hh-shared/dist/components/layout/InnerContentWrapper';
import actionsColumnGridDefaults from 'hh-shared/dist/consts/actionsColumnGridDefaults';

const propTypes = {
  onLoadData: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const defaultProps = {};

const FoxGridContainer = createFoxGridContainer(gridNames.carsManagement);

class CarsManagementPage extends PureComponent {
  render() {
    const { onLoadData, match } = this.props;

    return (
      <InnerContentWrapper fullWidth>
        <FoxGridContainer
          onLoadData={onLoadData}
          defaultSortField={columnNames.carsManagement.registerNumber}
          renderButtons={
            <Link to={`${match.url}/add`}>
              <FoxButton icon="add" primary>
                {lang.buttons.Add()}
              </FoxButton>
            </Link>
          }
        >
          <FoxGridColumn
            {...actionsColumnGridDefaults}
            cell={({ dataItem }) => (
              <td>
                <Link to={`${match.url}/edit/${dataItem.instanceId}`}>
                  <FoxButton icon="edit">{lang.buttons.Edit()}</FoxButton>
                </Link>
              </td>
            )}
          />
          <FoxGridColumn
            field={columnNames.carsManagement.registerNumber}
            title={lang.labels.RegisterNumber()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.carsManagement.comment}
            title={lang.labels.Comment()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.carsManagement.carType}
            title={lang.labels.CarType()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.carsManagement.company}
            title={lang.labels.Company()}
            filter="text"
          />
        </FoxGridContainer>
      </InnerContentWrapper>
    );
  }
}

CarsManagementPage.propTypes = propTypes;
CarsManagementPage.defaultProps = defaultProps;

export default CarsManagementPage;
