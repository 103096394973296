import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import createFoxGridContainer from 'fox-react/dist/components/FoxGrid/createFoxGridContainer';
import FoxButton from 'fox-react/dist/components/FoxButton';
import FoxGridColumn from 'fox-react/dist/components/FoxGrid/FoxGridColumn';

import lang from 'hh-shared/dist/language/services/languageService';
import columnNames from 'common/columnNames';
import gridNames from 'common/gridNames';
import NumericCell from 'hh-shared/dist/components/grid/NumericCell';
import AddressCell from 'hh-shared/dist/components/grid/AddressCell';
import InnerContentWrapper from 'hh-shared/dist/components/layout/InnerContentWrapper';

import { getForGrid } from './actions';

const propTypes = {
  match: PropTypes.object.isRequired,
};

const defaultProps = {};

const FoxGridContainer = createFoxGridContainer(gridNames.routesManagement);

function RoutesManagementPage({ match }) {
  const dispatch = useDispatch();
  const onLoadData = dataState => dispatch(getForGrid(dataState));

  return (
    <InnerContentWrapper fullWidth>
      <FoxGridContainer
        onLoadData={onLoadData}
        defaultSortField={columnNames.routesManagement.zipCodeFrom}
        renderButtons={
          <Link to={`${match.url}/add`}>
            <FoxButton icon="add" primary>
              {lang.buttons.Add()}
            </FoxButton>
          </Link>
        }
      >
        <FoxGridColumn
          filterable={false}
          groupable={false}
          cell={({ dataItem }) => (
            <td>
              <Link to={`${match.url}/edit/${dataItem.instanceId}`}>
                <FoxButton icon="edit">{lang.buttons.Edit()}</FoxButton>
              </Link>
            </td>
          )}
        />
        <FoxGridColumn
          field={columnNames.routesManagement.zipCodeFrom}
          title={lang.labels.ZipCodeFrom()}
          filter="text"
          cell={AddressCell}
        />

        <FoxGridColumn
          field={columnNames.routesManagement.zipCodeTo}
          title={lang.labels.ZipCodeTo()}
          filter="text"
          cell={AddressCell}
        />
        <FoxGridColumn
          field={columnNames.routesManagement.distance}
          title={lang.labels.Distance()}
          filter="numeric"
          cell={({ dataItem }) => <NumericCell value={dataItem.distance} />}
        />
      </FoxGridContainer>
    </InnerContentWrapper>
  );
}

RoutesManagementPage.propTypes = propTypes;
RoutesManagementPage.defaultProps = defaultProps;

export default RoutesManagementPage;
