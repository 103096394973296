import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import FontAwesomeIcon from 'hh-shared/dist/components/commons/FontAwesomeIcon/FontAwesomeIcon';

import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import Column from 'hh-shared/dist/components/layout/Column';
import Row from 'hh-shared/dist/components/layout/Row';
import auctionActionTypes from 'auctions-management/actionTypes';
import { getForGrid as getForGridAuctionsManagement } from 'auctions-management/actions';
import { auctionOngoingFilter } from 'auctions-management/filters';
import DashboardButton from './DashboardButton';

import GreyTitle from './GreyTitle';
import AuctionCardBoxes from './AuctionCardBoxes';
import styles from './DashboardCard.module.scss';

const propTypes = {
  auctionsData: PropTypes.shape({
    liveCount: PropTypes.number,
    canceledCount: PropTypes.number,
    observedCount: PropTypes.number,
    endedSuccessfulCount: PropTypes.number,
    endedWithoutSuccessCount: PropTypes.number,
    endingTodayCount: PropTypes.number,
  }),
  hasDepAdminEmployee: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  auctionsData: undefined,
  hasDepAdminEmployee: false,
  className: '',
};

const AuctionDashboardCard = ({
  auctionsData,
  hasDepAdminEmployee,
  className,
}) => {
  const cardContainerClassName = cx(
    styles.card_container,
    styles.card_container_smaller,
    className,
  );
  const endingTodayCount = (auctionsData && auctionsData.endingTodayCount) || 0;

  const dispatch = useDispatch();
  const dataState = useSelector(state => state.auctionsManagement.dataState);

  const changeFilter = filter => {
    dispatch(
      getForGridAuctionsManagement({
        ...dataState,
        filter,
        skip: 0,
      }),
    );
    dispatch({
      type: auctionActionTypes.AUCTIONS_MANAGEMENT_SET_FILTER,
      payload: filter,
    });
  };

  const dispatchOngoing = () => changeFilter(auctionOngoingFilter);

  return (
    <Column className={cardContainerClassName}>
      <Row className="align-items-center justify-content-space-between">
        <Row className="align-items-center width-fit-content">
          <FontAwesomeIcon
            icon={icons.auction}
            title={lang.labels.Auctions()}
            className={styles.icon}
          />
          <div className={styles.title}>{lang.labels.Auctions()}</div>
        </Row>
        {hasDepAdminEmployee && (
          <DashboardButton
            tag={Link}
            title={lang.labels.AddNewAuction()}
            to="/auctions-management/add"
            icon={icons.plus}
            withIconAnimation
          >
            {lang.labels.AddNew_Feminine()}
          </DashboardButton>
        )}
      </Row>
      <Column className={styles.event}>
        <GreyTitle className={styles.event_title}>
          {lang.labels.TodayAreEnding()}:
        </GreyTitle>
        <Link
          title={lang.labels.GoToTheEvent()}
          className={styles.event_quantity}
          onClick={dispatchOngoing}
          to="/auctions-management"
        >
          {`${endingTodayCount} ${
            endingTodayCount !== 1
              ? lang.labels.DashboardActions()
              : lang.labels.Auction()
          }`}
          <FontAwesomeIcon
            icon={icons.arrowRight}
            title={lang.labels.GoToTheEvent()}
            className={styles.event_link_arrow}
          />
        </Link>
      </Column>
      <Column>
        <GreyTitle>{lang.labels.CurrentlyYouHave()}:</GreyTitle>
        <AuctionCardBoxes {...auctionsData} />
      </Column>
    </Column>
  );
};

AuctionDashboardCard.propTypes = propTypes;
AuctionDashboardCard.defaultProps = defaultProps;

export default AuctionDashboardCard;
