import React from 'react';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import formNames from 'common/formNames';
import FormPreviewPane from 'hh-shared/dist/components/layout/FormPreview/FormPreviewPaneContainer';
import FormPreviewSection from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSection';
import FormPreviewItem from 'hh-shared/dist/components/layout/FormPreview/FormPreviewItem';

const propTypes = {};

const defaultProps = {};

const CurrenciesManagementPagePreview = () => {
  const formValues = useSelector(
    state => getFormValues(formNames.currencyInformationForm)(state) || {},
  );

  return (
    <FormPreviewPane>
      <FormPreviewSection
        title={lang.labels.CurrencyInformations()}
        icon={icons.currencies}
      >
        <FormPreviewItem label={lang.labels.Name()} value={formValues.name} />
        <FormPreviewItem
          label={lang.labels.IsDefault()}
          value={formValues.isDefault ? lang.labels.Yes() : lang.labels.No()}
        />
      </FormPreviewSection>
    </FormPreviewPane>
  );
};

CurrenciesManagementPagePreview.propTypes = propTypes;
CurrenciesManagementPagePreview.defaultProps = defaultProps;

export default CurrenciesManagementPagePreview;
