import { useSelector } from 'react-redux';
import get from 'lodash/get';
import intersection from 'lodash/intersection';

export function useCurrentUser() {
  const userPermissions = useSelector(
    state => get(state, 'auth.currentUser.permissions') || [],
  );

  const hasOneOf = per => {
    return !!intersection(per, userPermissions).length;
  };

  return { hasOneOf };
}
