import { connect } from 'react-redux';
import lang from 'hh-shared/dist/language/services/languageService';
import { displayCompletionToast } from 'hh-shared/dist/consts/reduxFormMethods';
import { getTransportOrderPreviewLink } from 'common/links';
import TransportOrdersManagementEditPage from './TransportOrdersManagementEditPage';
import { updateAsync } from './actions';
import { getDefaultPrices } from '../auctions-management/actions';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: model =>
      updateAsync({ ...model, id: ownProps.match.params.id }).then(() =>
        ownProps.history.push(
          getTransportOrderPreviewLink(ownProps.match.params.id),
        ),
      ),
    onGetDefaultPrices: () => dispatch(getDefaultPrices()),
    dispatchCancelSuccessToast: () =>
      displayCompletionToast(
        dispatch,
        lang.validationMessages.TransportOrderCanceled(),
      ),
  };
};

const TransportOrdersManagementEditPageContainer = connect(
  undefined,
  mapDispatchToProps,
)(TransportOrdersManagementEditPage);

export default TransportOrdersManagementEditPageContainer;
