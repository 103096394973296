import React from 'react';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import formNames from 'common/formNames';
import FormPreviewPane from 'hh-shared/dist/components/layout/FormPreview/FormPreviewPaneContainer';
import FormPreviewSection from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSection';
import FormPreviewItem from 'hh-shared/dist/components/layout/FormPreview/FormPreviewItem';

const propTypes = {};

const defaultProps = {};

const CarTypesManagementPagePreview = () => {
  const formValues = useSelector(
    state => getFormValues(formNames.carTypeInformationForm)(state) || {},
  );

  return (
    <FormPreviewPane>
      <FormPreviewSection
        title={lang.labels.CarTypeInformations()}
        icon={icons.vehicle}
      >
        <FormPreviewItem
          label={lang.labels.Name()}
          value={formValues.name || undefined}
        />
      </FormPreviewSection>
    </FormPreviewPane>
  );
};

CarTypesManagementPagePreview.propTypes = propTypes;
CarTypesManagementPagePreview.defaultProps = defaultProps;

export default CarTypesManagementPagePreview;
