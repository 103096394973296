import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import get from 'lodash/get';

import formNames from 'common/formNames';
import { getCurrentAddress, constructFixDate } from 'common/previewGetters';
import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import {
  insertThousandsSeparator,
  toMoneyFormat,
} from 'hh-shared/dist/consts/numberFormating';
import FormPreviewPane from 'hh-shared/dist/components/layout/FormPreview/FormPreviewPaneContainer';
import FormPreviewSection from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSection';
import FormPreviewItem from 'hh-shared/dist/components/layout/FormPreview/FormPreviewItem';
import ContentRevealer from 'hh-shared/dist/components/commons/ContentRevealer';
import { phoneMaskedDisplay } from 'hh-shared/dist/utils/phoneMask';

const propTypes = {
  loadingMethods: PropTypes.array.isRequired,
  unloadingMethods: PropTypes.array.isRequired,
};

const defaultProps = {};

/* eslint-disable indent */

const TransportOrdersManagementPagePreview = ({
  loadingMethods,
  unloadingMethods,
}) => {
  const formValues = useSelector(
    state =>
      getFormValues(formNames.transportOrderInformationForm)(state) || {},
  );

  // TODO vorbert -> popracować nad optymalizacją xD
  const getCurrentDriverName = () => {
    if (formValues) {
      if (formValues.isNewDriver) {
        if (formValues.newDriver) {
          const { newDriver } = formValues;
          const firstName = newDriver.firstName || '';
          const lastName = newDriver.lastName || '';
          const fullName = `${firstName} ${lastName}`;
          return fullName.length > 1 ? fullName : undefined;
        }
        return undefined;
      }
      if (!formValues.isNewDriver && formValues.driver) {
        return formValues.driver.name ? formValues.driver.name : undefined;
      }
    }
    return undefined;
  };

  const getCurrency = (defaultReturnValue = undefined) =>
    (formValues.currency && formValues.currency.name) || defaultReturnValue;

  const getCurrentMethodName = (loading = true) => {
    if (!loadingMethods || !unloadingMethods) return undefined;
    const currentMethodArr = loading ? loadingMethods : unloadingMethods;
    const currentMethodName = loading ? 'loadingMethodId' : 'unloadingMethodId';
    return (
      (formValues[currentMethodName] &&
        (() => {
          const currentMethod = currentMethodArr.filter(
            method => method.id === formValues[currentMethodName],
          )[0];
          return (currentMethod && currentMethod.name) || undefined;
        })()) ||
      undefined
    );
  };

  return (
    <FormPreviewPane>
      <FormPreviewSection
        title={lang.labels.TransportOrderInformations()}
        icon={icons.order}
      >
        <FormPreviewItem
          label={lang.labels.OrderSuffix()}
          value={formValues.additionalNumber}
        />

        <FormPreviewItem
          label={lang.labels.Facility()}
          value={get(formValues, 'facility.name')}
        />

        <FormPreviewItem
          label={lang.labels.Company()}
          value={get(formValues, 'company.name')}
        />

        <FormPreviewItem
          label={lang.labels.OrderNavNumber()}
          value={formValues.navNumber}
        />

        <FormPreviewItem
          label={lang.labels.InternalNote()}
          value={formValues.comment}
        />
      </FormPreviewSection>
      <FormPreviewSection
        title={lang.labels.LoadingData()}
        icon={icons.loadingVehicle}
      >
        <FormPreviewItem
          label={lang.labels.LoadingDateTime()}
          value={constructFixDate(
            formValues.loadingTimeFix,
            formValues.loadingTimeFrom,
            formValues.loadingTimeTo,
          )}
        />

        <FormPreviewItem
          label={lang.labels.Address()}
          value={getCurrentAddress(
            formValues,
            'isNewLoadingAddress',
            'newLoadingAddress',
            'loadingAddress',
          )}
          isNew={formValues.isNewLoadingAddress}
        />
      </FormPreviewSection>
      <FormPreviewSection
        title={lang.labels.UnloadingData()}
        icon={icons.loadingVehicle}
      >
        <FormPreviewItem
          label={lang.labels.UnloadingDateTime()}
          value={constructFixDate(
            formValues.unloadingTimeFix,
            formValues.unloadingTimeFrom,
            formValues.unloadingTimeTo,
          )}
        />

        <FormPreviewItem
          label={lang.labels.Address()}
          value={getCurrentAddress(
            formValues,
            'isNewUnloadingAddress',
            'newUnloadingAddress',
            'unloadingAddress',
          )}
          isNew={formValues.isNewUnloadingAddress}
        />

        <FormPreviewItem
          label={lang.labels.AuthorizedPickupPerson()}
          value={formValues.authorizedPickupPerson}
        />

        <ContentRevealer
          isVisible={!!formValues.authorizedPickupPerson}
          mutableValues={[formValues.authorizedPickupPersonPhoneNumber]}
        >
          <FormPreviewItem
            label={lang.labels.AuthorizedPickupPersonPhoneNumber()}
            value={phoneMaskedDisplay(
              formValues.authorizedPickupPersonPhoneNumber,
            )}
          />
        </ContentRevealer>
      </FormPreviewSection>
      <FormPreviewSection title={lang.labels.Expense()} icon={icons.money}>
        <FormPreviewItem
          label={lang.labels.Distance()}
          value={
            formValues.distance
              ? `${insertThousandsSeparator(formValues.distance)} km`
              : undefined
          }
        />
        <FormPreviewItem
          label={lang.labels.PriceForKilemeter()}
          value={
            formValues.pricePerKilometer
              ? `${toMoneyFormat(formValues.pricePerKilometer)} ${getCurrency(
                  '',
                )}`
              : undefined
          }
        />
        <FormPreviewItem
          label={lang.labels.Value()}
          value={
            formValues.value
              ? `${toMoneyFormat(formValues.value)} ${(formValues.currency &&
                  formValues.currency.name) ||
                  ''}`
              : undefined
          }
        />
        <FormPreviewItem label={lang.labels.Currency()} value={getCurrency()} />
      </FormPreviewSection>
      <FormPreviewSection title={lang.labels.Transport()} icon={icons.truck}>
        <FormPreviewItem
          label={lang.labels.Driver()}
          value={getCurrentDriverName()}
          isNew={formValues.isNewDriver}
        />
        <ContentRevealer
          isVisible={formValues.isNewDriver}
          mutableValues={get(formValues, 'newDriver.phoneNumber')}
        >
          <FormPreviewItem
            label={lang.labels.DriverPhoneNumber()}
            value={phoneMaskedDisplay(get(formValues, 'newDriver.phoneNumber'))}
            isNew={formValues.isNewDriver}
          />
        </ContentRevealer>

        <FormPreviewItem
          label={lang.labels.Car()}
          value={formValues.car && formValues.car.name.toUpperCase()}
          isNewValue={formValues.isNewCar}
        />

        <ContentRevealer
          isVisible={formValues.isNewCar}
          mutableValues={[
            get(formValues, 'newCar.registerNumber'),
            get(formValues, 'newCar.comment'),
            get(formValues, 'newCar.carType.name'),
          ]}
        >
          <FormPreviewItem
            label={lang.labels.RegisterNumber()}
            value={
              (get(formValues, 'newCar.registerNumber') &&
                formValues.newCar.registerNumber.toUpperCase()) ||
              undefined
            }
            isNew
          />
          <FormPreviewItem
            label={lang.labels.Comment()}
            value={get(formValues, 'newCar.comment')}
            isNew
            withoutMarginRestriction
          />
          <FormPreviewItem
            label={lang.labels.CarType()}
            value={get(formValues, 'newCar.carType.name')}
            isNew
          />
        </ContentRevealer>

        <FormPreviewItem
          label={lang.labels.Capacity()}
          value={
            formValues.capacity
              ? `${insertThousandsSeparator(formValues.capacity)} kg`
              : undefined
          }
        />

        <FormPreviewItem
          label={lang.labels.LoadingMethod()}
          value={getCurrentMethodName()}
        />

        <FormPreviewItem
          label={lang.labels.UnloadingMethod()}
          value={getCurrentMethodName(false)}
        />

        <FormPreviewItem
          label={lang.labels.AdditionalRequirements()}
          value={
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={
                {
                  __html: formValues.additionalRequirements,
                } || undefined
              }
            />
          }
        />
      </FormPreviewSection>
    </FormPreviewPane>
  );
};

TransportOrdersManagementPagePreview.propTypes = propTypes;
TransportOrdersManagementPagePreview.defaultProps = defaultProps;

export default TransportOrdersManagementPagePreview;
