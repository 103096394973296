import React, { useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import transportOrderStatusesIds from 'hh-shared/dist/consts/transportOrderStatusesIds';
import { getForGrid as getForGridTransportOrder } from 'transport-orders-management/actions';
import transporOrderActionTypes from 'transport-orders-management/actionTypes';
import {
  transportOrderCanceledFilter,
  transportOrderAcceptedFilter,
  transportOrderLoadedFilter,
  transportOrderCompletedFilter,
  transportOrderPlacedFilter,
} from 'transport-orders-management/filters';
import PropTypes from 'prop-types';
import OrderStatusCell from './OrdersStatusCell';

const propTypes = {
  statusId: PropTypes.string.isRequired,
};
const defaultProps = {};

const OrderStatusClickableCell = ({ statusId, ...others }) => {
  const dispatch = useDispatch();

  const dataState = useSelector(
    state => state.transportOrdersManagement.dataState,
  );

  const filterChange = filter => {
    dispatch(
      getForGridTransportOrder({
        ...dataState,
        filter,
        skip: 0,
      }),
    );
    dispatch({
      type: transporOrderActionTypes.TRANSPORT_ORDER_MANAGEMENT_SET_FILTER,
      payload: filter,
    });
  };

  const dispatchCanceled = () => filterChange(transportOrderCanceledFilter);
  const dispatchAccepted = () => filterChange(transportOrderAcceptedFilter);
  const dispatchLoaded = () => filterChange(transportOrderLoadedFilter);
  const dispatchCompleted = () => filterChange(transportOrderCompletedFilter);
  const dispatchPlaced = () => filterChange(transportOrderPlacedFilter);

  const getBadgeFilter = () => {
    switch (statusId) {
      case transportOrderStatusesIds.placed:
        return dispatchPlaced;
      case transportOrderStatusesIds.accepted:
        return dispatchAccepted;
      case transportOrderStatusesIds.loaded:
        return dispatchLoaded;
      case transportOrderStatusesIds.realized:
        return dispatchCompleted;
      case transportOrderStatusesIds.canceled:
        return dispatchCanceled;
      default:
        return undefined;
    }
  };

  const [currentFilter, setCurrentFilter] = useState(() => getBadgeFilter());

  useEffect(() => {
    setCurrentFilter(() => getBadgeFilter());
    // eslint-disable-next-line
  }, [statusId]);

  return (
    <OrderStatusCell onClick={currentFilter} statusId={statusId} {...others} />
  );
};

OrderStatusClickableCell.propTypes = propTypes;
OrderStatusClickableCell.defaultProps = defaultProps;

export default memo(OrderStatusClickableCell);
