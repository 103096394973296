import { connect } from 'react-redux';
import formNames from 'common/formNames';
import { formValueSelector } from 'redux-form';
import fieldNames from 'common/fieldNames';
import UsersManagementEditPage from './UsersManagementEditPage';
import { updateAsync } from './actions';

const formSelector = formValueSelector(formNames.userInformationForm);

const mapStateToProps = state => ({
  currentFormValues: formSelector(
    state,
    fieldNames.userInformationForm.login,
    fieldNames.userInformationForm.email,
    fieldNames.userInformationForm.isBanned,
    fieldNames.userInformationForm.permissions,
  ),
});

const mapDispatchToProps = () => {
  return {
    onSubmit: model => {
      if (model.permissions) {
        if (model.permissions.EMPLOYEE) {
          model.company = null;
        }
        if (model.permissions.LOGISTICIAN) {
          model.department = null;
          model.facility = null;
        }
      }
      return updateAsync({ ...model });
    },
  };
};

const UsersManagementEditPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsersManagementEditPage);

export default UsersManagementEditPageContainer;
