import { connect } from 'react-redux';
import CompanyManagementPage from './CompanyManagementPage';
import { getForGrid } from './actions';

const mapDispatchToProps = dispatch => {
  return {
    onLoadData: dataState => dispatch(getForGrid(dataState)),
  };
};

const CompanyManagementPageContainer = connect(
  undefined,
  mapDispatchToProps,
)(CompanyManagementPage);

export default CompanyManagementPageContainer;
