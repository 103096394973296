import { gridStoreResults } from 'fox-react/dist/components/FoxGrid/actions';
import gridNames from 'common/gridNames';
import GusSrv from 'common/GusSrv';
import { change } from 'redux-form';
import formNames from 'common/formNames';
import fieldNames from 'common/fieldNames';
import { displayToastr } from 'hh-shared/dist/toastr/toastrActions';
import ToastrTypes from 'hh-shared/dist/toastr/ToastrTypes';
import lang from 'hh-shared/dist/language/services/languageService';
import { prepareFormData } from 'hh-shared/dist/utils/filesHelper';
import CompanyManagementSrv from './CompanyManagementSrv';

export function storeResults(results) {
  return gridStoreResults(gridNames.companiesManagement)(results);
}

export function getForGrid(dataState) {
  return function(dispatch) {
    return CompanyManagementSrv.getForGrid(dataState).then(results => {
      dispatch(storeResults(results));
    });
  };
}

export function addAsync(model) {
  return function() {
    return CompanyManagementSrv.add(model);
  };
}

export function updateAsync(model) {
  model.companyAttachmentIds = model.companyAttachments.map(x => x.id);
  return CompanyManagementSrv.update(model);
}

function populateResponse(dispatch, res) {
  const formName = formNames.companyInformationForm;
  const ff = fieldNames.companyInformationForm;
  dispatch(change(formName, ff.name, res.name));
  dispatch(change(formName, ff.nip, res.nip));
  dispatch(change(formName, ff.regon, res.regon));
  dispatch(change(formName, ff.phoneNumber, res.phoneNumber.substring(0, 9)));
  dispatch(change(formName, ff.city, res.city));
  dispatch(change(formName, ff.address, res.address));
  dispatch(change(formName, ff.zipCode, res.zipCode));
}

export function getFromGusByRegonAsync(regon) {
  return function(dispatch) {
    return GusSrv.getByRegon(regon).then(res => {
      populateResponse(dispatch, res);
      dispatch(
        displayToastr(
          `${lang.validationMessages.GusDataLoaded()}`,
          ToastrTypes.success,
        ),
      );
    });
  };
}

export function getFromGusByNipAsync(nip) {
  return function(dispatch) {
    return GusSrv.getByNip(nip).then(res => {
      populateResponse(dispatch, res);
      dispatch(
        displayToastr(
          `${lang.validationMessages.GusDataLoaded()}`,
          ToastrTypes.success,
        ),
      );
    });
  };
}

export function uploadAttachments(files) {
  return CompanyManagementSrv.uploadAttachments(prepareFormData(files));
}
