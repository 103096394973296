"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.maxLenght200 = exports.maxLength = void 0;

var maxLength = function maxLength(length) {
  return function (value) {
    return value && value.toString().substring(0, length);
  };
};

exports.maxLength = maxLength;
var maxLenght200 = maxLength(200);
exports.maxLenght200 = maxLenght200;