import React from 'react';
import PropTypes from 'prop-types';
import { FormSection } from 'redux-form';
import FoxInputTextField from 'fox-react/dist/reduxFormFields/FoxInputTextField';
import FoxButton from 'fox-react/dist/components/FoxButton';
import FoxComboBoxField from 'fox-react/dist/reduxFormFields/FoxComboBoxField';
import { toUpper } from 'fox-react/dist/utils/reduxFormUtils';
import { isRequiredFieldValidator } from 'hh-shared/dist/utils/formValidators';
import lang from 'hh-shared/dist/language/services/languageService';
import fieldNames from 'common/fieldNames';
import CarAccordionItem from './CarAccordionItem';
import styles from './CompanyInformationFormDriver.module.scss';

const propTypes = {
  fields: PropTypes.object.isRequired,
  carTypes: PropTypes.array.isRequired,
};

function CompanyInformationFormCar({ fields, carTypes }) {
  return (
    <>
      {fields.map((member, index) => (
        <CarAccordionItem
          key={index}
          carIndex={index}
          onRemove={() => fields.remove(index)}
        >
          <FormSection name={member} key={index}>
            <FoxInputTextField
              label={lang.labels.RegisterNumber()}
              placeholder={lang.labels.RegisterNumber()}
              name={fieldNames.newCarInformationForm.registerNumber}
              format={toUpper}
              required
              validate={[isRequiredFieldValidator]}
            />

            <FoxComboBoxField
              label={lang.labels.CarType()}
              name={fieldNames.newCarInformationForm.carType}
              items={carTypes}
              required
              validate={[isRequiredFieldValidator]}
            />

            <FoxInputTextField
              label={lang.labels.Comment()}
              placeholder={lang.labels.Comment()}
              name={fieldNames.newCarInformationForm.comment}
            />
          </FormSection>
        </CarAccordionItem>
      ))}
      <FoxButton
        type="button"
        onClick={() => fields.push({})}
        className={`k-button k-primary ${styles.accordion_button}`}
      >
        {lang.buttons.AddNewCar()}
      </FoxButton>
    </>
  );
}

CompanyInformationFormCar.propTypes = propTypes;

export default CompanyInformationFormCar;
