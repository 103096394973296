import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import lang from 'hh-shared/dist/language/services/languageService';
import TransportOrderInformationFormContainer from './TransportOrderInformationForm/TransportOrderInformationFormContainer';
import TransportOrdersManagementSrv from './TransportOrdersManagementSrv';
import TransportOrdersManagementPagePreviewContainer from './TransportOrdersManagementPagePreviewContainer';

const propTypes = {
  match: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

class TransportOrdersManagementAddBasedOnAuctionPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false,
      facilities: [],
      companies: [],
      users: [],
      cars: [],
      addresses: [],
      carTypes: [],
      initialValues: {},
      currencies: [],
    };
  }

  componentDidMount() {
    this.setState({ showSpinner: true });

    Promise.all([
      TransportOrdersManagementSrv.getForAddBasedOnAuction(
        this.props.match.params.auctionId,
      ),
      TransportOrdersManagementSrv.getFacilitiesAsSelectItems(),
      TransportOrdersManagementSrv.getCompaniesAsSelectItems(),
      TransportOrdersManagementSrv.getAddressesAsSelectItem(),
      TransportOrdersManagementSrv.getCarTypesAsSelectItems(),
      TransportOrdersManagementSrv.getCurrenciesAsSelectItems(),
    ])
      .then(
        ([
          initialValues,
          facilities,
          companies,
          addresses,
          carTypes,
          currencies,
        ]) => {
          this.setState({
            initialValues,
            facilities,
            companies,
            addresses,
            carTypes,
            currencies,
          });
        },
      )
      .finally(() => this.setState({ showSpinner: false }));
  }

  render() {
    const { onSubmit, history } = this.props;
    const {
      initialValues,
      showSpinner,
      facilities,
      companies,
      users,
      cars,
      addresses,
      carTypes,
      currencies,
    } = this.state;

    return (
      <>
        <BreadcrumbsContainer
          links={[
            {
              routePath: '/',
              label: lang.labels.MainPage(),
            },
            {
              routePath: '/transport-orders-management',
              label: lang.labels.TransportOrders(),
            },
            {
              routePath: '/transport-orders-management/add',
              label: `${lang.labels.AddingNewTransportOrderBasedOnAuction()}: ${initialValues &&
                initialValues.auctionNumber}`,
            },
          ]}
        />
        <FormPreviewSectionContainer>
          <FormCardWrapper>
            <div className={showSpinner ? 'fox_spinner' : undefined}>
              <TransportOrderInformationFormContainer
                onSubmit={onSubmit}
                initialValues={initialValues}
                history={history}
                facilities={facilities}
                companies={companies}
                users={users}
                cars={cars}
                addresses={addresses}
                carTypes={carTypes}
                currencies={currencies}
              />
            </div>
          </FormCardWrapper>
          <TransportOrdersManagementPagePreviewContainer />
        </FormPreviewSectionContainer>
      </>
    );
  }
}

TransportOrdersManagementAddBasedOnAuctionPage.propTypes = propTypes;
TransportOrdersManagementAddBasedOnAuctionPage.defaultProps = defaultProps;

export default TransportOrdersManagementAddBasedOnAuctionPage;
