import React, { memo } from 'react';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import FoxCustomDialog from 'fox-react/dist/components/FoxCustomDialog';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { validateDriverInput } from 'hh-shared/dist/components/transport-order/DriverInput';
import { validateCarInput } from 'hh-shared/dist/components/transport-order/CarInput';
import { isRequired } from 'fox-react/dist/utils/formValidators';
import DialogForm from 'hh-shared/dist/components/transport-order/DialogForm';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  drivers: PropTypes.array.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  cars: PropTypes.array.isRequired,
  carTypes: PropTypes.array.isRequired,
  transportOrderData: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  transportOrderData: {},
};

function ImplementationDialog({
  isOpen,
  drivers,
  onCloseClick,
  cars,
  carTypes,
  transportOrderData,
  handleSubmit,
}) {
  return (
    <FoxCustomDialog
      title={lang.labels.LoadingDetails()}
      isOpen={isOpen}
      onCloseClick={onCloseClick}
      style={{ minWidth: '650px' }}
      noLabel={lang.labels.Cancel()}
      yesLabel={lang.buttons.Loaded()}
      onConfirmClick={handleSubmit}
      bigger
    >
      <DialogForm
        handleSubmit={handleSubmit}
        transportOrderData={transportOrderData}
        drivers={drivers}
        cars={cars}
        carTypes={carTypes}
      />
    </FoxCustomDialog>
  );
}

ImplementationDialog.propTypes = propTypes;
ImplementationDialog.defaultProps = defaultProps;

const validate = values => {
  const errors = {};
  isRequired(
    errors,
    values,
    'loadingTimeTo',
    lang.validationMessages.FieldRequired(),
  );

  validateCarInput(errors, values);
  validateDriverInput(errors, values);

  return errors;
};

export default compose(
  reduxForm({
    form: 'loadedDialog',
    enableReinitialize: true,
    validate,
  }),
  memo,
)(ImplementationDialog);
