import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import DepartmentsSrv from './DepartmentsManagementSrv';
import EditDepartmentInformationForm from './EditDepartmentInformationForm';
import { updateAsync } from './actions';
import DepartmentsManagementPagePreview from './DepartmentsManagementPagePreview';

const propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

function DepartmentsManagementEditPage({ match, history }) {
  const [initialValues, setInitialValues] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = model =>
    dispatch(
      updateAsync({
        ...model,
        id: match.params.id,
      }),
    );

  useEffect(() => {
    setShowSpinner(true);

    DepartmentsSrv.get(match.params.id)
      .then(results => {
        setInitialValues(results);
      })
      .finally(() => setShowSpinner(false));
  }, [match.params.id]);

  return (
    <>
      <BreadcrumbsContainer
        links={[
          {
            routePath: '/',
            label: lang.labels.HomePage(),
          },
          {
            routePath: '/departments',
            label: lang.labels.Departments(),
          },
          {
            routePath: '/departments/edit',
            label: lang.labels.EditingDepartment(),
          },
        ]}
      />
      <FormPreviewSectionContainer>
        <FormCardWrapper>
          <div className={showSpinner ? 'fox_spinner' : undefined}>
            <EditDepartmentInformationForm
              onSubmit={onSubmit}
              initialValues={initialValues}
              history={history}
            />
          </div>
        </FormCardWrapper>
        <DepartmentsManagementPagePreview />
      </FormPreviewSectionContainer>
    </>
  );
}

DepartmentsManagementEditPage.propTypes = propTypes;
DepartmentsManagementEditPage.defaultProps = defaultProps;

export default DepartmentsManagementEditPage;
