import React, { memo } from 'react';
import moment from 'moment';
import lang from 'hh-shared/dist/language/services/languageService';
import ConfigurableDiagram from './ConfigurableDiagram/ConfigurableDiagram';
import YearsCallendarContainer from './YearsCallendarContainer';
import ChartHeader from 'dashboard/ChartHeader';
import styles from './RaportDiagrams.module.scss';

const propTypes = {};

const defaultProps = {};

const getCallendarDateRangeText = () => {
  const currentYear = moment().year();
  const previousYear = currentYear - 1;
  return `${previousYear} - ${currentYear}`;
};

function RaportDiagrams() {
  const callendarDateRangeText = getCallendarDateRangeText();

  return (
    <>
      <section className={styles.chartWrapper}>
        <ConfigurableDiagram />
      </section>
      <section className={styles.chartWrapper}>
        <ChartHeader>{`${lang.labels.RealizedInYears()} ${callendarDateRangeText}`}</ChartHeader>
        <YearsCallendarContainer />
      </section>
    </>
  );
}

RaportDiagrams.propTypes = propTypes;
RaportDiagrams.defaultProps = defaultProps;

export default memo(RaportDiagrams);
