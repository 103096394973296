import React, { memo } from 'react';
import PropTypes from 'prop-types';
import FoxDateTimePickerField from 'fox-react/dist/reduxFormFields/FoxDateTimePickerField';
import lang from 'hh-shared/dist/language/services/languageService';
import { isNotPastDateField } from 'fox-react/dist/utils/formValidators';
import ContentRevealer from 'hh-shared/dist/components/commons/ContentRevealer';
import FoxSwitchField from 'fox-react/dist/reduxFormFields/FoxSwitchField';
import { formValues } from 'redux-form';

const propTypes = {
  timeFromName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  timeToName: PropTypes.string.isRequired,
  dataFixName: PropTypes.string.isRequired,
  dataFixValue: PropTypes.bool,
};

const defaultProps = {
  dataFixValue: false,
};

const isNotPastValidator = isNotPastDateField(
  lang.validationMessages.CannotBePastDate(),
);

function FixDateTimePickerSubForm({
  timeFromName,
  label,
  timeToName,
  dataFixName,
  dataFixValue,
}) {
  return (
    <>
      <FoxSwitchField label={lang.labels.DataFix()} name={dataFixName} />

      <ContentRevealer isVisible={dataFixValue}>
        <FoxDateTimePickerField
          label={label}
          name={timeToName}
          validate={isNotPastValidator}
          required
        />
      </ContentRevealer>

      <ContentRevealer isVisible={!dataFixValue}>
        <FoxDateTimePickerField
          label={`${lang.labels.From()}: ${label}`}
          name={timeFromName}
          validate={isNotPastValidator}
          required
        />
        <FoxDateTimePickerField
          label={`${lang.labels.To()}: ${label}`}
          name={timeToName}
          validate={isNotPastValidator}
          required
        />
      </ContentRevealer>
    </>
  );
}

FixDateTimePickerSubForm.propTypes = propTypes;
FixDateTimePickerSubForm.defaultProps = defaultProps;

export default formValues(props => ({ dataFixValue: props.dataFixName }))(
  memo(FixDateTimePickerSubForm),
);
