import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import createFoxGridContainer from 'fox-react/dist/components/FoxGrid/createFoxGridContainer';
import FoxButton from 'fox-react/dist/components/FoxButton';
import FoxGridColumn from 'fox-react/dist/components/FoxGrid/FoxGridColumn';
import { gridStoreResults } from 'fox-react/dist/components/FoxGrid/actions';
import InnerContentWrapper from 'hh-shared/dist/components/layout/InnerContentWrapper';

import lang from 'hh-shared/dist/language/services/languageService';
import gridNames from 'common/gridNames';
import columnNames from 'common/columnNames';
import actionsColumnGridDefaults from 'hh-shared/dist/consts/actionsColumnGridDefaults';
import HtmlTemplatesManagementSrv from './HtmlTemplatesManagementSrv';
import {
  isEditContentButtonVisible,
  isEditEmailContentButtonVisible,
  isEditSmsContentButtonVisible,
} from './editContentSettings';

const propTypes = {
  match: PropTypes.object.isRequired,
};

const FoxGridContainer = createFoxGridContainer(
  gridNames.htmlTemplatesManagement,
);

const HtmlTemplatesManagementPage = props => {
  const { match } = props;
  const dispatch = useDispatch();

  const onLoadData = async dataState => {
    const results = await HtmlTemplatesManagementSrv.getForGrid(dataState);
    dispatch(gridStoreResults(gridNames.htmlTemplatesManagement)(results));
  };

  return (
    <InnerContentWrapper fullWidth>
      <FoxGridContainer
        onLoadData={onLoadData}
        defaultSortField={columnNames.htmlTemplatesManagement.name}
      >
        <FoxGridColumn
          {...actionsColumnGridDefaults}
          cell={({ dataItem }) => (
            <td>
              {isEditContentButtonVisible(dataItem.id) && (
                <Link to={`${match.url}/edit-content/${dataItem.id}`}>
                  <FoxButton icon="edit">
                    {lang.buttons.EditContent()}
                  </FoxButton>
                </Link>
              )}

              {isEditEmailContentButtonVisible(dataItem.id) && (
                <Link to={`${match.url}/edit-emailContent/${dataItem.id}`}>
                  <FoxButton icon="edit">
                    {lang.buttons.EditEmailContent()}
                  </FoxButton>
                </Link>
              )}

              {isEditSmsContentButtonVisible(dataItem.id) && (
                <Link to={`${match.url}/edit-smsContent/${dataItem.id}`}>
                  <FoxButton icon="edit">
                    {lang.buttons.EditSmsContent()}
                  </FoxButton>
                </Link>
              )}
            </td>
          )}
        />
        <FoxGridColumn
          field={columnNames.htmlTemplatesManagement.name}
          title={lang.labels.HtmlTemplatesManagement_Columns_Name()}
          filter="text"
        />
        <FoxGridColumn
          field={columnNames.htmlTemplatesManagement.description}
          title={lang.labels.HtmlTemplatesManagement_Columns_Description()}
          filter="text"
        />
        <FoxGridColumn
          field={columnNames.htmlTemplatesManagement.area}
          title={lang.labels.HtmlTemplatesManagement_Columns_Area()}
          filter="text"
        />
      </FoxGridContainer>
    </InnerContentWrapper>
  );
};

HtmlTemplatesManagementPage.propTypes = propTypes;

export default HtmlTemplatesManagementPage;
