import React, { useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './SideMenu.module.scss';
import NavigationMenu from './NavigationMenu/NavigationMenu';
import SideMenuSrv from './SideMenuSrv';
import actionTypes from './sideMenuActionTypes';

const propTypes = {
  isOpened: PropTypes.bool,
  open: PropTypes.func.isRequired,
};

const defaultProps = {
  isOpened: true,
};

/* eslint-disable indent */

// TODO vorbert -> ulepszyć 'znikanie' ikonek rozsunięcia/zsunięcia menu
const SideMenu = ({ isOpened, open }) => {
  const defaultTopOffset = 90;
  const defaultBottomOffset = 64;
  const [topOffset, setTopOffset] = useState(defaultTopOffset);
  const [bottomOffset, setBottomOffset] = useState(0);
  const dispatch = useDispatch();
  const { countersAreLoading } = useSelector(state => state.sideMenu);
  const sideMenuClassName = cx(styles.side_menu_container, {
    [styles.toggled]: !isOpened,
  });

  const getElementHeight = element =>
    element ? element.getBoundingClientRect().height : undefined;

  const getDefaultTopOffset = () => {
    const headerContainer = document.querySelector('.header');
    const contentWrapper = document.querySelector('.content-wrapper');

    const headerHeight = getElementHeight(headerContainer) || 0;
    const contentOffset = contentWrapper
      ? parseFloat(
          window
            .getComputedStyle(contentWrapper)
            .getPropertyValue('padding-top'),
        )
      : 0;
    return headerHeight + contentOffset;
  };

  useEffect(() => {
    if (countersAreLoading) {
      SideMenuSrv.get().then(res =>
        dispatch({ type: actionTypes.SET_SIDEBAR_COUNTERS, payload: res }),
      );
    }
  }, [dispatch, countersAreLoading]);

  const handleScroll = () => {
    const currentTopBorder = getDefaultTopOffset() - 18;

    const currentTopOffset =
      window.pageYOffset || document.documentElement.scrollTop;

    const currentBottomOffset =
      document.body.offsetHeight - window.pageYOffset - window.innerHeight;

    setTopOffset(
      currentTopOffset >= currentTopBorder
        ? 8
        : currentTopBorder - currentTopOffset,
    );

    setBottomOffset(
      currentBottomOffset > defaultBottomOffset
        ? 0
        : defaultBottomOffset - currentBottomOffset,
    );
  };

  useEffect(() => {
    SideMenuSrv.get().then(res =>
      dispatch({ type: actionTypes.SET_SIDEBAR_COUNTERS, payload: res }),
    );
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // TODO ogarnąć co trzeba zrobić, by react-hooks/exhaustive deps nie krzyczało
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      handleScroll();
    }, 300);
    // TODO poprawic
    // eslint-disable-next-line
  }, [isOpened]);

  return (
    <div
      className={sideMenuClassName}
      style={{
        top: `${topOffset}px`,
        maxHeight: `calc(100vh - ${topOffset + bottomOffset}px)`,
      }}
    >
      <NavigationMenu isOpened={isOpened} openMenu={open} />
    </div>
  );
};

SideMenu.propTypes = propTypes;
SideMenu.defaultProps = defaultProps;

export default memo(SideMenu);
