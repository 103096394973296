import React from 'react';
import { useSelector } from 'react-redux';

import lang from 'hh-shared/dist/language/services/languageService';
import IconArea from 'hh-shared/dist/components/commons/IconArea';
import numberFormating from 'hh-shared/dist/consts/numberFormating';
import icons from 'hh-shared/dist/consts/icons';
import Row from 'layout/Row';
import Column from 'layout/Column';

import DataFixTimes from 'hh-shared/dist/components/commons/DataFixTimes/DataFixTimes';
import styles from './TransportInfo.module.scss';

const TransportInfo = () => {
  const auctionState = useSelector(state => state.auction);

  const { auctionMetric } = auctionState;
  const { loadingAddress, unloadingAddress } = auctionMetric;
  const { insertThousandsSeparator } = numberFormating;

  const translateKgToTon = kg => `${parseInt(kg, 10) / 1000}`;

  return (
    <div className={styles.content}>
      <Column>
        <Row>
          <Row>
            <Column className="content_column">
              <IconArea icon={icons.plusMark} iconTitle={lang.labels.Loading()}>
                <Column>
                  <span>{lang.labels.Loading()}</span>
                  <div className={styles.header__label}>
                    {loadingAddress.zipCode} {loadingAddress.city}
                  </div>
                  <div className={styles.header__label}>
                    {loadingAddress.street}
                  </div>
                  <div className={styles['header__label--small']}>
                    <DataFixTimes
                      from={auctionMetric.loadingTimeFrom}
                      to={auctionMetric.loadingTimeTo}
                    />
                  </div>
                </Column>
              </IconArea>
              <IconArea
                icon={icons.loadingVehicle}
                iconTitle={lang.labels.LoadingData()}
              >
                <Column>
                  <span>{lang.labels.LoadingData()}</span>{' '}
                  <div>
                    {insertThousandsSeparator(
                      translateKgToTon(auctionMetric.capacity),
                    )}
                    t / {auctionMetric.loadingMethod}
                  </div>
                </Column>
              </IconArea>
            </Column>
          </Row>
          <Row>
            <Column className="content_column">
              <IconArea icon={icons.checkMark} iconTitle={lang.labels.Supply()}>
                <Column>
                  <span>{lang.labels.Supply()}</span>
                  <div className={styles.header__label}>
                    {unloadingAddress.zipCode} {unloadingAddress.city}
                  </div>
                  <div className={styles.header__label}>
                    {unloadingAddress.street}
                  </div>
                  <div className={styles['header__label--small']}>
                    <DataFixTimes
                      from={auctionMetric.unloadingTimeFrom}
                      to={auctionMetric.unloadingTimeTo}
                    />
                  </div>
                </Column>
              </IconArea>
              <IconArea
                icon={icons.loadingVehicle}
                iconTitle={lang.labels.UnloadingMethod()}
              >
                <Column>
                  <span>{lang.labels.UnloadingMethod()}</span>
                  <div>{auctionMetric.unloadingMethod}</div>
                </Column>
              </IconArea>
              <IconArea
                icon={icons.userData}
                iconTitle={lang.labels.AuthorizedPickupPersonInformations()}
              >
                <Column>
                  <span>
                    {lang.labels.AuthorizedPickupPersonInformations()}
                  </span>
                  <div>{auctionMetric.authorizedPickupPerson}</div>
                </Column>
              </IconArea>
            </Column>
          </Row>
        </Row>
      </Column>
      <Row>
        <IconArea
          icon={icons.information}
          iconTitle={lang.labels.AdditionalInformations()}
        >
          <Column>
            <span>{lang.labels.AdditionalInformations()}</span>
            {auctionMetric.additionalRequirements && (
              <div>{auctionMetric.additionalRequirements}</div>
            )}
            {!auctionMetric.additionalRequirements && (
              <div>{lang.labels.NoAdditionalInformations()}</div>
            )}
          </Column>
        </IconArea>
      </Row>
    </div>
  );
};

export default TransportInfo;
