import { connect } from 'react-redux';
import CarsManagementAddPage from './CarsManagementAddPage';
import { addAsync } from './actions';

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: model => dispatch(addAsync(model)),
  };
};

const CarsManagementAddPageContainer = connect(
  undefined,
  mapDispatchToProps,
)(CarsManagementAddPage);

export default CarsManagementAddPageContainer;
