import { decodeJwt, isExpired } from 'fox-react/dist/utils/jwt';
import axios from 'config/axios';
import { logout, loginSuccess } from './actions';

export default function checkLoginValidity(store) {
  const jwtToken = localStorage.getItem('accessToken');
  if (!jwtToken) {
    if (store.getState().auth.currentUser) {
      store.dispatch(logout());
    }
    return;
  }
  const decodedToken = decodeJwt(jwtToken);
  if (isExpired(decodedToken.exp)) {
    axios
      .post('/auth/refreshToken', {
        token: localStorage.getItem('refreshToken'),
      })
      .then(res => {
        store.dispatch(loginSuccess(res.data));
      })
      .catch(() => {
        store.dispatch(logout());
      });
  }
}
