"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _asReduxFormField = _interopRequireDefault(require("./asReduxFormField"));

var _wrapFormField = _interopRequireDefault(require("./wrapFormField"));

var _FoxRadioButtons = _interopRequireDefault(require("../components/FoxRadioButtons"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var _default = (0, _wrapFormField.default)((0, _asReduxFormField.default)(_FoxRadioButtons.default));

exports.default = _default;