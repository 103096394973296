"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _orderBy = _interopRequireDefault(require("lodash/orderBy"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  label: _propTypes.default.string,
  value: _propTypes.default.string.isRequired,
  onChange: _propTypes.default.func.isRequired,
  name: _propTypes.default.string.isRequired,
  items: _propTypes.default.array,
  invalid: _propTypes.default.any.isRequired
};
var defaultProps = {
  items: []
};

function FoxRadioButtons(_ref) {
  var items = _ref.items,
      name = _ref.name,
      value = _ref.value,
      onChange = _ref.onChange,
      invalid = _ref.invalid;
  var invalidClassName = (0, _classnames.default)({
    'k-state-invalid': invalid
  });
  var inputClassName = (0, _classnames.default)('k-radio', invalidClassName);
  var labelClassName = (0, _classnames.default)('k-radio-label', invalidClassName);
  return items ? (0, _orderBy.default)(items, 'order').map(function (item) {
    return _react.default.createElement(_react.default.Fragment, {
      key: item.id
    }, _react.default.createElement("input", {
      type: "radio",
      className: inputClassName,
      name: name,
      value: item.id,
      key: item.id,
      id: item.id,
      checked: item.id === value,
      onChange: onChange
    }), _react.default.createElement("label", {
      htmlFor: item.id,
      className: labelClassName
    }, item.name));
  }) : null;
}

FoxRadioButtons.propTypes = propTypes;
FoxRadioButtons.defaultProps = defaultProps;
var _default = FoxRadioButtons;
exports.default = _default;