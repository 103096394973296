import React from 'react';
import PropTypes from 'prop-types';
import FoxInputTextField from 'fox-react/dist/reduxFormFields/FoxInputTextField';
import FoxComboBoxField from 'fox-react/dist/reduxFormFields/FoxComboBoxField';
import fieldNames from 'common/fieldNames';
import lang from 'hh-shared/dist/language/services/languageService';
import { toUpper } from 'fox-react/dist/utils/reduxFormUtils';

const propTypes = {
  carTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const defaultProps = {};

function NewCarForm({ carTypes }) {
  return (
    <>
      <FoxInputTextField
        label={lang.labels.RegisterNumber()}
        placeholder={lang.labels.RegisterNumber()}
        name={fieldNames.newCarInformationForm.registerNumber}
        format={toUpper}
        required
      />

      <FoxInputTextField
        label={lang.labels.Comment()}
        placeholder={lang.labels.Comment()}
        name={fieldNames.carInformationForm.comment}
      />

      <FoxComboBoxField
        label={lang.labels.CarType()}
        name={fieldNames.carInformationForm.carType}
        items={carTypes}
        required
      />
    </>
  );
}

NewCarForm.propTypes = propTypes;
NewCarForm.defaultProps = defaultProps;

export default NewCarForm;
