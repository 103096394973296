import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import auctionTypes from 'hh-shared/dist/consts/auctionTypes';
import styles from './BidsSection.module.scss';
import BidsTable from './BidsTable';
import CurrentPriceSection from './CurrentPriceSection';
import BidsParticipantsTable from './BidsParticipantsTable';

const propTypes = {
  auctionId: PropTypes.string.isRequired,
};

const BidsSection = props => {
  const { auction } = useSelector(state => state);
  const { bids } = auction;
  const sectionRef = useRef();
  const { auctionMetric } = auction;

  // TODO -> otrzymać dane dotyczące osób zaproszonych do udziału w licytacji oraz flagi, czy już chociaz raz złożyli ofertę licytacji w aktualnej aukcji.
  // TODO Vorbert -> po otrzymaniu danych zaimplementuj je w tabeli

  const getElementWidth = element =>
    element.getBoundingClientRect().width -
    parseFloat(
      window.getComputedStyle(element).getPropertyValue('padding-right'),
    );

  const adjustFirstColumnWidth = () => {
    const { current: sectionContainer } = sectionRef;
    let firstColWidth = 0;
    sectionRef &&
      (() => {
        const firstColumns = [
          ...sectionContainer.querySelectorAll(
            'table > thead > tr:first-of-type > th:first-of-type',
          ),
        ];
        firstColumns.forEach((firstColumn, firstColumnIndex) => {
          if (firstColumnIndex === 0) {
            firstColWidth = getElementWidth(firstColumn);
          } else {
            firstColumn.style.width = `${firstColWidth}px`;
          }
        });
      })();
  };

  useEffect(() => {
    if (!props.auctionId) return;
    adjustFirstColumnWidth();
    // TODO TK Poprawic i usunac ignore'a
    // eslint-disable-next-line
  }, [props.auctionId]);

  return (
    <div className={styles.bids} ref={sectionRef}>
      <CurrentPriceSection />
      <BidsTable bids={bids} priceStep={auctionMetric.priceStep} />
      {auctionTypes.nonPublic === auctionMetric.auctionTypeId && (
        <BidsParticipantsTable />
      )}
    </div>
  );
};

BidsSection.propTypes = propTypes;
export default BidsSection;
