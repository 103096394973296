import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import FormPreviewPane from 'hh-shared/dist/components/layout/FormPreview/FormPreviewPaneContainer';
import FormPreviewSection from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSection';
import FormPreviewItem from 'hh-shared/dist/components/layout/FormPreview/FormPreviewItem';
import permissions from 'common/permissions';
import formNames from 'common/formNames';
import { phoneMaskedDisplay } from 'hh-shared/dist/utils/phoneMask';

const propTypes = {
  editForm: PropTypes.bool,
};

const defaultProps = {
  editForm: false,
};

const CarsManagementPagePreview = ({ editForm }) => {
  const formValues = useSelector(
    state => getFormValues(formNames.companyInformationForm)(state) || {},
  );

  const userPermissions = useSelector(
    state => get(state, 'auth.currentUser.permissions') || [],
  );

  const hasOneOf = per => {
    return !!intersection(per, userPermissions).length;
  };

  const canActivateCompany = hasOneOf([permissions.ACTIVATE_COMPANY]);

  return (
    <FormPreviewPane>
      {editForm && (
        <FormPreviewSection
          title={lang.labels.Version()}
          icon={icons.editedDocument}
        >
          <FormPreviewItem
            label={lang.labels.Version()}
            value={formValues.versionNumber || undefined}
          />
        </FormPreviewSection>
      )}
      <FormPreviewSection
        title={lang.labels.CompanyInformations()}
        icon={icons.company}
      >
        <FormPreviewItem
          label={lang.labels.Nip()}
          value={formValues.nip || undefined}
        />
        <FormPreviewItem
          label={lang.labels.Regon()}
          value={formValues.regon || undefined}
        />

        <FormPreviewItem
          label={lang.labels.Name()}
          value={formValues.name || undefined}
        />

        <FormPreviewItem
          label={lang.labels.Addresses()}
          value={formValues.address || undefined}
        />

        <FormPreviewItem
          label={lang.labels.ZipCode()}
          value={formValues.zipCode || undefined}
        />

        <FormPreviewItem
          label={lang.labels.City()}
          value={formValues.city || undefined}
        />

        <FormPreviewItem
          label={lang.labels.PhoneNumber()}
          value={phoneMaskedDisplay(formValues.phoneNumber) || undefined}
        />
      </FormPreviewSection>
      <FormPreviewSection
        title={`${lang.labels.Drivers()} ( ${(formValues.drivers &&
          formValues.drivers.length) ||
          0} )`}
        icon={icons.driver}
      >
        {formValues &&
          formValues.drivers &&
          formValues.drivers.map((driver, driverIndex) => (
            <React.Fragment key={driverIndex}>
              <FormPreviewItem
                label={`${lang.labels.Driver()} nr`}
                value={driverIndex + 1}
              />
              <FormPreviewItem
                label={lang.labels.Name()}
                value={driver.firstName || undefined}
              />
              <FormPreviewItem
                label={lang.labels.LastName()}
                value={driver.lastName || undefined}
              />
              <FormPreviewItem
                label={lang.labels.PhoneNumber()}
                value={driver.phoneNumber || undefined}
              />
            </React.Fragment>
          ))}
      </FormPreviewSection>

      <FormPreviewSection
        title={`${lang.labels.Cars()} ( ${(formValues.cars &&
          formValues.cars.length) ||
          0} )`}
        icon={icons.vehicle}
      >
        {formValues &&
          formValues.cars &&
          formValues.cars.map((car, carIndex) => (
            <React.Fragment key={carIndex}>
              <FormPreviewItem
                label={`${lang.labels.Car()} nr`}
                value={carIndex + 1}
              />
              <FormPreviewItem
                label={lang.labels.RegisterNumber()}
                value={car.registerNumber || undefined}
              />
              <FormPreviewItem
                label={lang.labels.CarType()}
                value={car.carType ? car.carType.name : undefined}
              />
              <FormPreviewItem
                label={lang.labels.Comment()}
                value={car.comment || undefined}
              />
            </React.Fragment>
          ))}
      </FormPreviewSection>
      <FormPreviewSection
        title={lang.labels.BlockingCompany()}
        icon={icons.password}
      >
        <FormPreviewItem
          label={lang.labels.Blocked()}
          value={formValues.isBlocked ? lang.labels.Yes() : lang.labels.No()}
        />
      </FormPreviewSection>
      <FormPreviewSection
        title={lang.labels.BidingByHHAllowed()}
        icon={icons.approval}
      >
        <FormPreviewItem
          label={lang.labels.Blocked()}
          value={
            formValues.bidingByHHAllowed ? lang.labels.Yes() : lang.labels.No()
          }
        />
      </FormPreviewSection>
      {canActivateCompany && (
        <FormPreviewSection title={lang.labels.IsActive()} icon={icons.company}>
          <FormPreviewItem
            label={lang.labels.IsActive()}
            value={formValues.isActive ? lang.labels.Yes() : lang.labels.No()}
          />
        </FormPreviewSection>
      )}
    </FormPreviewPane>
  );
};

CarsManagementPagePreview.propTypes = propTypes;
CarsManagementPagePreview.defaultProps = defaultProps;

export default CarsManagementPagePreview;
