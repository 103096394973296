import { connect } from 'react-redux';
import { setNewPasswordAsync } from 'auth/actions';
import SetNewPasswordPage from './SetNewPasswordPage';

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: model => dispatch(setNewPasswordAsync(model)),
  };
};

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
  return {
    onSubmit: model =>
      propsFromDispatch.onSubmit({
        email: model.email,
        newPassword: model.newPassword,
        token: ownProps.match.params.token,
      }),
  };
};

const SetNewPasswordPageContainer = connect(
  undefined,
  mapDispatchToProps,
  mergeProps,
)(SetNewPasswordPage);

export default SetNewPasswordPageContainer;
