import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import lang from 'hh-shared/dist/language/services/languageService';
import Badge from 'hh-shared/dist/components/commons/Badge';
import AccordionItem from 'hh-shared/dist/components/layout/AccordionItem';
import { insertThousandsSeparator } from 'hh-shared/dist/consts/numberFormating';

import styles from './BidsParticipantsTable.module.scss';

const propTypes = {};

const defaultProps = {};

const BidsTable = () => {
  const invitedCompanies = useSelector(state => state.auction.invitedCompanies);
  const currency = useSelector(state => state.auction.auctionMetric.currency);
  const bids = useSelector(state => state.auction.bids);
  const [winningOffer, setWinningOffer] = useState();

  const getOfferBadgeColor = offerValue =>
    offerValue === winningOffer ? { customGreen: true } : { blue: true };

  const getCompanyOffer = company => {
    const lastCompanyBid = bids.find(
      x => x.companyInstanceId === company.instanceId,
    );

    if (!lastCompanyBid) {
      return 0;
    }

    return lastCompanyBid.price;
  };

  const getNumberOfParticipantsThatBidded = () =>
    invitedCompanies.filter(participant => getCompanyOffer(participant) > 0)
      .length;

  const getMaxOfferValue = () =>
    !isEmpty(invitedCompanies) &&
    Math.min(
      ...invitedCompanies
        .map(participant => getCompanyOffer(participant))
        .filter(offer => offer > 0),
    );

  useEffect(() => {
    setWinningOffer(() => getMaxOfferValue());
    // eslint-disable-next-line
  }, [bids]);

  return (
    <AccordionItem
      title={lang.labels.InvitedSuppliers()}
      whiteTitle
      withoutBottomOffset
      dontRemoveChildrenOnHide
      renderRightInformation={
        <div className={styles.bids_table_quantity_description}>
          {`${lang.labels.Bidded()} `}
          <span className={styles.bids_table_quantity}>
            {getNumberOfParticipantsThatBidded()}/{invitedCompanies.length}
          </span>
        </div>
      }
      className={styles.bids_table_container}
      innerClassName={styles.bids_table_revealer}
    >
      <table className={styles.bids_table}>
        <thead>
          <tr>
            <th className={styles.header_index}>
              {lang.labels.OrdinalNumber()}
            </th>
            <th className={styles.header_suppliers}>
              {lang.labels.InvitedSuppliers()}
            </th>
            <th className={styles.header_badge} />
          </tr>
        </thead>
        <tbody>
          {invitedCompanies.map((company, companyindex) => (
            <tr key={company.companyVersionId}>
              <td
                data-table-header={lang.labels.OrdinalNumber()}
                className={styles.cell_index}
              >
                <span className={styles.index_cell}>{companyindex + 1}</span>
              </td>
              <td
                data-table-header={lang.labels.InvitedSuppliers()}
                className={styles.cell_company_name}
              >
                <span>{company.name}</span>
              </td>
              <td className={styles.cell_label}>
                {getCompanyOffer(company) <= 0 && ( // TODO ppolak: przy wonym czasie zrefactoryzowac ten kodzik ( za duzo wywołań i spaggetti)
                  <Badge
                    label={lang.labels.NoOffer()}
                    darkBlue
                    className={styles.badge}
                  />
                )}
                {getCompanyOffer(company) > 0 && (
                  <Badge
                    label={`${insertThousandsSeparator(
                      getCompanyOffer(company),
                    )} ${currency}`}
                    {...getOfferBadgeColor(getCompanyOffer(company))}
                    className={styles.badge}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </AccordionItem>
  );
};

BidsTable.propTypes = propTypes;
BidsTable.defaultProps = defaultProps;

export default BidsTable;
