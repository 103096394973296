import React, { memo, useRef, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import styles from './Bar.module.scss';

const propTypes = {
  data: PropTypes.array.isRequired,
  axisBottom: PropTypes.object,
  keys: PropTypes.array.isRequired,
};

const defaultProps = {
  axisBottom: {},
};

const tooltip = ({ value, id }) => (
  <span>
    {id}: <b>{value}</b>
  </span>
);

const hasLegendTooltip = legendElement => {
  if (!legendElement) return false;

  const tooltipElement = legendElement.parentElement.querySelector('title');

  return !!tooltipElement;
};

const addTitleToLegend = (legendElement, legendLabel) => {
  if (hasLegendTooltip(legendElement) || !legendLabel) return;

  const tooltipElement = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'title',
  );
  tooltipElement.innerHTML = legendLabel;
  legendElement.parentElement.insertBefore(tooltipElement, legendElement);
};

const handleLegendMouseEnter = ({ label: legendLabel }, event) => {
  const legendElement = event.target;
  addTitleToLegend(legendElement, legendLabel);
};

const itemOpacity = 0.85;

const trimLabel = (label, maxCharCount = 15) => {
  const labelIsToLarge = label && label.length > maxCharCount;
  return labelIsToLarge ? `${label.substring(0, maxCharCount - 3)}...` : label;
};

const trimLegendLabels = chartElement => {
  if (!chartElement) return;

  setTimeout(() => {
    const legendElements = chartElement.querySelectorAll(
      `[style="opacity: ${itemOpacity};"] text`,
    );
    legendElements.forEach(legendElement => {
      const elementText = legendElement.textContent;
      legendElement.innerHTML = trimLabel(elementText);
    });
  }, 50);
};

function Bar({ data, axisBottom, keys }) {
  const chartRef = useRef(null);

  useEffect(() => {
    trimLegendLabels(chartRef.current);
  }, [chartRef]);

  return (
    <div className={styles.chartWrapper} ref={chartRef}>
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy="day"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        indexScale={{ type: 'band', round: true }}
        colors={[
          'hsl(0, 100%, 55%)',
          '#FD6A02',
          '#FFC300',
          'hsl(60, 80%, 50%)',
          'hsl(100, 100%, 32.5%)',
          'hsl(160, 100%, 40%)',
          'hsl(190, 90%, 50%)',
          'hsl(210, 100%, 50%)',
          'hsl(240, 100%, 65%)',
          'hsl(270, 100%, 60%)',
          'hsl(300, 55%, 57.5%)',
          'hsl(320, 65%, 35.5%)',
          'hsl(0, 56%, 22%)',
          '#ad2323',
          'hsl(210, 56%, 25%)',
          'hsl(200, 20%, 45%)',
        ]}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#38bcb2',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: 'middle',
          legendOffset: 32,
          ...axisBottom,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'zrealizowane',
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor="#fff"
        animate
        motionStiffness={90}
        motionDamping={15}
        tooltip={tooltip}
        legends={[
          {
            dataFrom: 'keys',
            data: keys.map(id => {
              const newLabel = {
                id: `${id}`,
                label: 'teeee3st',
                color: '#232323',
                fill: undefined,
              };

              return newLabel;
            }),
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity,
            symbolSize: 20,
            onMouseEnter: handleLegendMouseEnter,
          },
        ]}
      />
    </div>
  );
}

Bar.propTypes = propTypes;
Bar.defaultProps = defaultProps;

export default memo(Bar);
