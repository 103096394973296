import { connect } from 'react-redux';
import UsersManagementPage from './UsersManagementPage';
import { getForGrid, sendInvitation } from './actions';

const mapDispatchToProps = dispatch => {
  return {
    onLoadData: dataState => dispatch(getForGrid(dataState)),
    onSendInvitation: values => sendInvitation(values),
  };
};

const UsersManagementPageContainer = connect(
  undefined,
  mapDispatchToProps,
)(UsersManagementPage);

export default UsersManagementPageContainer;
