import React from 'react';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';

import formNames from 'common/formNames';
import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import FormPreviewPane from 'hh-shared/dist/components/layout/FormPreview/FormPreviewPaneContainer';
import FormPreviewSection from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSection';
import FormPreviewItem from 'hh-shared/dist/components/layout/FormPreview/FormPreviewItem';

const propTypes = {};

const defaultProps = {};

const DepartmentsManagementPagePreview = () => {
  const formValues = useSelector(
    state => getFormValues(formNames.departmentInformationForm)(state) || {},
  );

  return (
    <FormPreviewPane>
      <FormPreviewSection
        title={lang.labels.DepartmentInformations()}
        icon={icons.department}
      >
        <FormPreviewItem label={lang.labels.Name()} value={formValues.name} />
        <FormPreviewItem
          label={lang.labels.AuctionEnumerator()}
          value={formValues.auctionEnumerator}
        />
        <FormPreviewItem
          label={lang.labels.AuctionDraftEnumerator()}
          value={formValues.auctionDraftEnumerator}
        />
        <FormPreviewItem
          label={lang.labels.TransportOrderEnumerator()}
          value={formValues.transportOrderEnumerator}
        />
      </FormPreviewSection>

      <FormPreviewSection
        title={lang.labels.DataForTransportOrder()}
        icon={icons.editedDocument}
      >
        <FormPreviewItem
          label={lang.labels.FullName()}
          value={formValues.fullName}
        />

        <FormPreviewItem label={lang.labels.Nip()} value={formValues.nip} />

        <FormPreviewItem
          label={lang.labels.ZipCode()}
          value={formValues.zipCode}
        />

        <FormPreviewItem label={lang.labels.City()} value={formValues.city} />

        <FormPreviewItem
          label={lang.labels.Street()}
          value={formValues.street}
        />
      </FormPreviewSection>

      <FormPreviewSection
        title={lang.labels.DataForInvoiceTransportOrder()}
        icon={icons.documentWithData}
      >
        <FormPreviewItem
          label={lang.labels.FullName()}
          value={formValues.invoiceDestinationName}
        />

        <FormPreviewItem
          label={lang.labels.ZipCode()}
          value={formValues.invoiceDestinationZipCode}
        />

        <FormPreviewItem
          label={lang.labels.City()}
          value={formValues.invoiceDestinationCity}
        />

        <FormPreviewItem
          label={lang.labels.Street()}
          value={formValues.invoiceDestinationStreet}
        />

        <FormPreviewItem
          label={lang.labels.InvoiceEmailAddress()}
          value={formValues.emailInvoiceAddress}
        />
      </FormPreviewSection>
    </FormPreviewPane>
  );
};

DepartmentsManagementPagePreview.propTypes = propTypes;
DepartmentsManagementPagePreview.defaultProps = defaultProps;

export default DepartmentsManagementPagePreview;
