import { decodeJwt } from 'fox-react/dist/utils/jwt';
import { isArray } from 'util';
import actionTypes from './actionTypes';

const initialState = {
  currentUser: undefined,
};

function extractDataFromToken(token) {
  const decodedToken = decodeJwt(token);
  return {
    ...decodedToken,
    permissions: isArray(decodedToken.roles)
      ? [...decodedToken.roles]
      : [decodedToken.roles],
  };
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...extractDataFromToken(action.data.accessToken),
          ...action.data,
          accessToken: undefined,
        },
      };
    case actionTypes.AUTH_LOGOUT:
      localStorage.removeItem('accessToken');
      return {
        ...state,
        currentUser: undefined,
      };
    case actionTypes.AUTH_STORE_USER_INFORMATIONS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.data,
        },
      };
    default:
      return state;
  }
}
