import { gridStoreResults } from 'fox-react/dist/components/FoxGrid/actions';
import gridNames from 'common/gridNames';
import DepartmentsSrv from './DepartmentsManagementSrv';

export function storeResults(results) {
  return gridStoreResults(gridNames.departmentsManagement)(results);
}

export function getForGrid(dataState) {
  return function(dispatch) {
    return DepartmentsSrv.getForGrid(dataState).then(results => {
      dispatch(storeResults(results));
    });
  };
}

export function addAsync(model) {
  return function() {
    return DepartmentsSrv.add(model);
  };
}

export function updateAsync(model) {
  return function() {
    return DepartmentsSrv.update(model);
  };
}
