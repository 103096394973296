import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import CurrencyInformationForm from './CurrencyInformationForm';
import CurrenciesManagementPagePreview from './CurrenciesManagementPagePreview';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

class CurrenciesManagementAddPage extends PureComponent {
  render() {
    const { onSubmit, history } = this.props;

    return (
      <>
        <BreadcrumbsContainer
          links={[
            {
              routePath: '/',
              label: lang.labels.HomePage(),
            },
            {
              routePath: '/currencies-management',
              label: lang.labels.Currencies(),
            },
            {
              routePath: '/currencies-management/add',
              label: lang.labels.AddingNewCurrency(),
            },
          ]}
        />
        <FormPreviewSectionContainer>
          <FormCardWrapper>
            <CurrencyInformationForm
              onSubmit={onSubmit}
              history={history}
              initialValues={{}}
            />
          </FormCardWrapper>
          <CurrenciesManagementPagePreview />
        </FormPreviewSectionContainer>
      </>
    );
  }
}

CurrenciesManagementAddPage.propTypes = propTypes;
CurrenciesManagementAddPage.defaultProps = defaultProps;

export default CurrenciesManagementAddPage;
