import { gridStoreResults } from 'fox-react/dist/components/FoxGrid/actions';
import gridNames from 'common/gridNames';
import AuctionsManagementSrv from './AuctionsManagementSrv';
import actionTypes from './actionTypes';

export function storeResults(results) {
  return gridStoreResults(gridNames.auctionsManagement)(results);
}

export function getForGrid(dataState) {
  return function(dispatch) {
    return AuctionsManagementSrv.getForGrid(dataState).then(results => {
      dispatch(storeResults(results));
    });
  };
}

export function addAndPublishAsync(model) {
  return AuctionsManagementSrv.addAndPublish(model);
}

export function addAsync(model) {
  return AuctionsManagementSrv.add(model);
}

export function updateAsync(model) {
  return function() {
    return AuctionsManagementSrv.update(model);
  };
}

export function getDefaultPrices() {
  return function(dispatch) {
    return AuctionsManagementSrv.getDefaultPrices().then(results => {
      dispatch({
        type: actionTypes.STORE_DEFAULT_PRICES,
        data: results,
      });
    });
  };
}

export function isObservedChange(id, isObserved) {
  return function(dispatch) {
    dispatch({
      type: actionTypes.CHANGE_OBSERVED_AUCTION,
      data: { id, isObserved },
    });
    return AuctionsManagementSrv.isObservedChange(id, isObserved);
  };
}
