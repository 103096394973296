"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _kendoReactDialogs = require("@progress/kendo-react-dialogs");

var _FoxDialog = _interopRequireDefault(require("./FoxDialog"));

var _FoxButton = _interopRequireDefault(require("./FoxButton"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var propTypes = {
  title: _propTypes.default.string,
  body: _propTypes.default.string,
  noLabel: _propTypes.default.string,
  yesLabel: _propTypes.default.string,
  onCloseClick: _propTypes.default.func,
  onNoClick: _propTypes.default.func,
  onYesClick: _propTypes.default.func
};
var defaultProps = {
  title: 'Potwierdź',
  body: 'Jesteś pewien?',
  noLabel: 'Nie',
  yesLabel: 'Tak',
  onCloseClick: undefined,
  onNoClick: undefined,
  onYesClick: undefined
};

function FoxConfirmationDialog(_ref) {
  var title = _ref.title,
      body = _ref.body,
      noLabel = _ref.noLabel,
      yesLabel = _ref.yesLabel,
      onCloseClick = _ref.onCloseClick,
      onNoClick = _ref.onNoClick,
      onYesClick = _ref.onYesClick;

  var handleBackgroundClick = function handleBackgroundClick(event) {
    var dialogHasBeenClicked = event.path.find(function (element) {
      return element.classList && element.classList.contains('k-dialog');
    });
    !dialogHasBeenClicked && onNoClick();
  };

  (0, _react.useEffect)(function () {
    window.addEventListener('click', handleBackgroundClick);
    return function () {
      return window.removeEventListener('click', handleBackgroundClick);
    };
  }, []);
  return _react.default.createElement(_FoxDialog.default, {
    title: title,
    body: body,
    onCloseClick: onCloseClick
  }, _react.default.createElement(_kendoReactDialogs.DialogActionsBar, null, _react.default.createElement(_FoxButton.default, {
    type: "button",
    onClick: onNoClick
  }, noLabel), _react.default.createElement(_FoxButton.default, {
    primary: true,
    type: "button",
    onClick: onYesClick
  }, yesLabel)));
}

FoxConfirmationDialog.propTypes = propTypes;
FoxConfirmationDialog.defaultProps = defaultProps;
var _default = FoxConfirmationDialog;
exports.default = _default;