import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import FontAwesomeIcon from 'hh-shared/dist/components/commons/FontAwesomeIcon/FontAwesomeIcon';

import styles from './DashboardButton.module.scss';

const propTypes = {
  tag: PropTypes.any,
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  withIconAnimation: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

const defaultProps = {
  tag: 'div',
  withIconAnimation: false,
  className: undefined,
};

const DashboardButton = ({
  tag: Tag,
  className,
  icon,
  withIconAnimation,
  children,
  ...others
}) => {
  const buttonClassName = cx(styles.button, className);
  const iconClassName = cx(styles.button_icon, {
    [styles.button_icon_withAnimation]: withIconAnimation,
  });

  return (
    <Tag {...others} className={buttonClassName} tabIndex={0}>
      <FontAwesomeIcon icon={icon} className={iconClassName} />
      {children}
    </Tag>
  );
};

DashboardButton.propTypes = propTypes;
DashboardButton.defaultProps = defaultProps;

export default memo(DashboardButton);
