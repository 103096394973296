import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import cx from 'classnames';
import get from 'lodash/get';

import PersonDataBadge from 'hh-shared/dist/components/commons/PersonDataBadge';
import lang from 'hh-shared/dist/language/services/languageService';
import auctionStatusIds from 'hh-shared/dist/utils/auctionStatusesIds';
import ProgressBar from 'hh-shared/dist/components/commons/ProgressBar/ProgressBar';
import StarToggle from 'hh-shared/dist/components/commons/StarToggle';
import IconArea from 'hh-shared/dist/components/commons/IconArea';
import Badge from 'hh-shared/dist/components/commons/Badge';
import icons from 'hh-shared/dist/consts/icons';
import numberFormating from 'hh-shared/dist/consts/numberFormating';
import customHooks from 'hh-shared/dist/consts/customHooks';
import AuctionStatusLabel from 'commons/AuctionStatusLabel';
import Row from 'layout/Row';
import Column from 'layout/Column';

import styles from './MainInfo.module.scss';
import auctionActionTypes from '../actionTypes';

/* eslint-disable indent */

const propTypes = {
  isObservedChange: PropTypes.func.isRequired,
  reloadPage: PropTypes.func.isRequired,
};

const defaultProps = {};

const MainInfo = ({ isObservedChange, reloadPage }) => {
  const auctionState = useSelector(state => state.auction);
  const [timeLeft, setTimeLeft] = useState('');
  const [percentage, setPercentage] = useState(0);
  const [canceledStatus, setCanceledStatus] = useState(false);
  const [closedStatus, setClosedStatus] = useState(false);
  const { auctionMetric, isAuctionStarted } = auctionState;
  const dispatch = useDispatch();
  const { useInterval } = customHooks;
  const {
    toMoneyFormat,
    insertThousandsSeparator,
    getTwoDigitRepresentation,
  } = numberFormating;
  const hoursLeftToWarningLabel = 2;

  const hoursClassName = cx('bold_semi-strong', 'display-inline');
  const progressBarClassName = cx('align-self-start', styles.progress_bar);

  const timeHeaderClassName = auctionHasEnded =>
    cx(styles.header_label, 'label', 'label_blue', 'label_bold', {
      label_grey: auctionHasEnded,
    });

  const hoursInformationClassName = (auctionHasEnded, auctionIsSuccessful) =>
    cx(hoursClassName, 'text_info', {
      text_lighten_green: auctionHasEnded && auctionIsSuccessful,
      text_lighten_error: auctionHasEnded && !auctionIsSuccessful,
    });

  const canceledDateClassName = cx(
    styles.header_label_small,
    styles.canceled_time,
  );

  const canceledHoursClassName = cx(hoursClassName, 'text_warning');

  const translateTime = time => {
    const seconds = Math.floor(time / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    return {
      seconds: seconds % 60,
      minutes: minutes % 60,
      hours: hours % 24,
      days,
    };
  };

  const getRemainingAuctionTime = () => {
    const currentTime = moment.now();
    const auctionStartTime = moment(auctionMetric.startTime)._d.getTime();
    const auctionEndTime = moment(auctionMetric.endTime)._d.getTime();
    let currentTimeDiff;

    if (currentTime >= auctionEndTime) {
      setPercentage(100);
      return null;
    } else if (currentTime < auctionStartTime) {
      setPercentage(0);
      isAuctionStarted &&
        dispatch({
          type: auctionActionTypes.AUCTION_SET_AUCTION_IS_STARTED,
          payload: false,
        });
      currentTimeDiff = auctionStartTime - currentTime;
    } else {
      !isAuctionStarted &&
        dispatch({
          type: auctionActionTypes.AUCTION_SET_AUCTION_IS_STARTED,
          payload: true,
        });
      currentTimeDiff = auctionEndTime - currentTime;
    }

    const currentTimePercentage = Math.floor(
      ((currentTime - auctionStartTime) / (auctionEndTime - auctionStartTime)) *
        100,
    );

    setPercentage(currentTimePercentage);

    return translateTime(currentTimeDiff);
  };

  const getTimeTo = time => {
    const { days, hours, minutes, seconds } = time;
    return (
      <>
        {moment.now() < moment(auctionMetric.startTime) && '-'}
        {days}{' '}
        <i>
          {days !== 1
            ? lang.labels.Days().toLowerCase()
            : lang.labels.Day().toLowerCase()}{' '}
        </i>
        {` ${hours} `}
        <i>h</i>
        {` ${getTwoDigitRepresentation(minutes)} `}
        <i>min</i>
        {` ${getTwoDigitRepresentation(seconds)} `}
        <i>s</i>
      </>
    );
  };

  const updateTimeLeft = () => {
    setTimeLeft(getRemainingAuctionTime());
  };

  const isAuctionOver = () =>
    get(auctionMetric, 'status.id') &&
    auctionMetric.status.id === auctionStatusIds.closed;

  const isAuctionSuccessful = () => auctionState.bids.length > 0;

  const displayDangerText = () =>
    !timeLeft ||
    (timeLeft &&
      timeLeft.hours <= hoursLeftToWarningLabel &&
      timeLeft.days === 0);

  const getAuctionDescriptionText = () => {
    const auctionHasEnded = isAuctionOver();
    if (isAuctionStarted && !auctionHasEnded) {
      return `${lang.labels.ToTheEndOfTheAuctionRemained()}:`;
    }

    return auctionHasEnded
      ? `${lang.labels.AuctionHasBeen()}:`
      : `${lang.labels.AuctionWillTakePlaceIn()}:`;
  };

  const getAuctionTimeHeaderText = () => {
    if (timeLeft && !isAuctionOver()) {
      return getTimeTo(timeLeft);
    }

    return typeof timeLeft === 'undefined'
      ? lang.labels.AuctionHasNotBeenStarted()
      : lang.labels.Ended();
  };

  const getTimeOffsetFromNow = destinationTime =>
    moment(destinationTime).diff(moment.now());

  const checkForDataReload = () => {
    const timeDiffReloadRange = 20000;
    const { startTime, endTime } = auctionMetric;
    const timeOffsetFromStartTime = getTimeOffsetFromNow(startTime);
    const timeOffsetFromEndTime = getTimeOffsetFromNow(endTime);

    if (
      (timeOffsetFromStartTime <= 0 &&
        Math.abs(timeOffsetFromStartTime) <= timeDiffReloadRange) ||
      (timeOffsetFromEndTime <= 0 &&
        Math.abs(timeOffsetFromEndTime) <= timeDiffReloadRange)
    )
      reloadPage();
  };

  const getProgressBarColor = () => {
    if (isAuctionOver() && isAuctionSuccessful()) {
      return 'green';
    } else if (canceledStatus) {
      return 'orange';
    } else if (displayDangerText()) {
      return 'red';
    } else {
      return 'blue';
    }
  };

  const getCanceledPercentage = () => {
    const canceledTime = moment(canceledStatus.time)._d.getTime();
    const auctionStartTime = moment(auctionMetric.startTime)._d.getTime();
    const auctionEndTime = moment(auctionMetric.endTime)._d.getTime();

    const canceledPercentage = Math.floor(
      ((canceledTime - auctionStartTime) /
        (auctionEndTime - auctionStartTime)) *
        100,
    );

    return canceledPercentage;
  };

  const getFillnessPercentage = () => {
    if (closedStatus) {
      return 100;
    }

    return !canceledStatus ? percentage : getCanceledPercentage();
  };

  useEffect(() => {
    setCanceledStatus(
      get(auctionMetric, 'statusHistory') &&
        auctionMetric.statusHistory.filter(
          x => x.statusId === auctionStatusIds.canceled,
        )[0],
    );

    setClosedStatus(
      get(auctionMetric, 'status.id') === auctionStatusIds.closed,
    );
  }, [auctionMetric]);

  useEffect(() => {
    updateTimeLeft();
    // TODO poprawic
    // eslint-disable-next-line
  }, []);

  useInterval(
    () => {
      updateTimeLeft();
      checkForDataReload();
    },
    1000,
    canceledStatus || closedStatus,
  );

  return (
    <div className={styles.header}>
      <Column className="content_column">
        <Row className="justify-content-space-between">
          <Row className="justify-content-start horizontal_spacing">
            <IconArea
              icon={icons.auction}
              iconTitle={lang.labels.AuctionNumber()}
            >
              <span>{lang.labels.AuctionNumber()}:</span>
              <div className={styles.header_label_medium}>
                {auctionMetric.number}
              </div>
            </IconArea>
            <Badge label={auctionMetric.auctionType} />
          </Row>
          <Row className="justify-content-space-between">
            <AuctionStatusLabel
              statusId={auctionMetric.status.id}
              label={auctionMetric.status.name}
              smaller={false}
              offsetTop={false}
              currentPriceWithoutOffer={!isAuctionSuccessful()}
              className={styles.status_label}
            />
            <StarToggle
              value={auctionMetric.isObserved}
              onChange={() => isObservedChange(!auctionMetric.isObserved)}
            />
          </Row>
        </Row>
        <Row>
          <Row>
            <IconArea icon={icons.route} iconTitle={lang.labels.Distance()}>
              <span>{lang.labels.Distance()}:</span>
              <div className={styles.header_label_medium}>
                {insertThousandsSeparator(auctionMetric.distance)} km
              </div>
            </IconArea>
          </Row>
          <Row>
            {!canceledStatus ? (
              <IconArea
                icon={icons.clock}
                iconTitle={lang.labels.AuctionTimeDuration()}
                className="full-width"
              >
                <span>{getAuctionDescriptionText()}</span>
                <div className={timeHeaderClassName(isAuctionOver())}>
                  {getAuctionTimeHeaderText()}
                </div>
              </IconArea>
            ) : (
              <IconArea
                icon={icons.information}
                iconTitle={lang.labels.InformationsAboutTheAuction()}
                className="full-width"
              >
                <span>{lang.labels.AuctionHasBeenCanceledBy()}:</span>
                <Row className={styles.canceled_row}>
                  <PersonDataBadge
                    fullName={canceledStatus.userFullName}
                    phoneNumber={canceledStatus.userPhone}
                    avatar={canceledStatus.userAvatar}
                  />
                  <span className={canceledDateClassName}>
                    {moment(canceledStatus.time).format('DD.MM.YYYY')}{' '}
                    <span className={canceledHoursClassName}>
                      {moment(canceledStatus.time).format('HH:mm')}
                    </span>
                  </span>
                </Row>
              </IconArea>
            )}
          </Row>
        </Row>
        <Row className="content_row">
          <Row>
            <IconArea
              icon={icons.money}
              iconTitle={lang.labels.StartingPrice()}
            >
              <span>{lang.labels.StartingPrice()}:</span>
              <div className={styles.header_label_medium}>
                {toMoneyFormat(auctionMetric.startPrice)}{' '}
                {auctionMetric.currency}
              </div>
            </IconArea>
          </Row>
          <Row>
            <Column className={styles.time_section}>
              <Row className="justify-content-space-between">
                <span className={styles.header_label_small}>
                  {moment(auctionMetric.startTime).format('DD.MM.YYYY')}{' '}
                  <span
                    className={hoursInformationClassName(
                      isAuctionOver(),
                      isAuctionSuccessful(),
                    )}
                  >
                    {moment(auctionMetric.startTime).format('HH:mm')}
                  </span>
                </span>
                <span className={styles.header_label_small}>
                  {moment(auctionMetric.endTime).format('DD.MM.YYYY')}{' '}
                  <span
                    className={hoursInformationClassName(
                      isAuctionOver(),
                      isAuctionSuccessful(),
                    )}
                  >
                    {moment(auctionMetric.endTime).format('HH:mm')}
                  </span>
                </span>
              </Row>
              <ProgressBar
                fillnessPercentage={getFillnessPercentage()}
                color={getProgressBarColor()}
                className={progressBarClassName}
              />
            </Column>
          </Row>
        </Row>
      </Column>
    </div>
  );
};

MainInfo.propTypes = propTypes;
MainInfo.defaultProps = defaultProps;

export default MainInfo;
