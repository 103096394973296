import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DefaultLayout from 'layout/DefaultLayout';
import get from 'lodash/get';
import intersection from 'lodash/intersection';

const propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.any,
  oneOfPermissions: PropTypes.array,
};

const defaultProps = {
  location: undefined,
  oneOfPermissions: undefined,
};

function ProtectedRoute({ component: Component, oneOfPermissions, ...others }) {
  const isLogged = useSelector(state => state.auth.currentUser);

  const userPermissions = useSelector(
    state => get(state, 'auth.currentUser.permissions') || [],
  );

  let allow = isLogged;
  if (oneOfPermissions) {
    allow = allow && !!intersection(oneOfPermissions, userPermissions).length;
  }

  return (
    <Route
      {...others}
      render={props =>
        allow ? (
          <DefaultLayout>
            <Component {...props} />
          </DefaultLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

ProtectedRoute.propTypes = propTypes;
ProtectedRoute.defaultProps = defaultProps;

export default ProtectedRoute;
