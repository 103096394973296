const auctionActionTypes = {
  HUB_CONNECTION_ASSIGN_TO_AUCTION_PAGE:
    'HUB_CONNECTION_ASSIGN_TO_AUCTION_PAGE',
  AUCTION_SET_BIDS_PARTICIPANTS: 'AUCTION_SET_BIDS_PARTICIPANTS',
  AUCTION_CHANGE_IS_OBSERVED: 'AUCTION_CHANGE_IS_OBSERVED',
  AUCTION_SET_AUCTION_IS_STARTED: 'AUCTION_SET_AUCTION_IS_STARTED',
  CLEAR_AUCTION_DATA: 'CLEAR_AUCTION_DATA',
  ACTION_ADD_ACTION_BID: 'ACTION_ADD_ACTION_BID',
  ACTION_SET_ACTION_DATA: 'ACTION_SET_ACTION_DATA',
  SET_LOADING_USER_PARTICIPANT: 'SET_LOADING_USER_PARTICIPANT',
  SET_USER_PARTICIPANT_DATA: 'SET_USER_PARTICIPANT_DATA',
  SET_LOADING_COMPANY_PARTICIPANT: 'SET_LOADING_COMPANY_PARTICIPANT',
  SET_COMPANY_PARTICIPANT_DATA: 'SET_COMPANY_PARTICIPANT_DATA',
};

export default auctionActionTypes;
