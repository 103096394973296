import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import icons from 'hh-shared/dist/consts/icons';
import lang from 'hh-shared/dist/language/services/languageService';
import { getForGrid as getForGridAuctionsManagement } from 'auctions-management/actions';
import {
  auctionObservedFilter,
  auctionOngoingFilter,
  auctionAllFilter,
  auctionCanceledFilter,
  auctionWithoutSuccessFilter,
  auctionWithSuccessFilter,
  auctionWithoutOfferFilter,
  auctionDraftFilter,
} from 'auctions-management/filters';
import auctionActionTypes from 'auctions-management/actionTypes';

import MenuLink from '../MenuLink';
import MenuSublinkGridFilter from '../MenuSublinkGridFilter';

const propTypes = {
  auctionMenuIsOpen: PropTypes.bool.isRequired,
  setAuctionMenuIsOpen: PropTypes.func.isRequired,
  handleMenuOpen: PropTypes.func.isRequired,
};

const defaultProps = {};

const commonProps = {
  routePath: '/auctions-management',
  stateFilterPath: 'auctionsManagement.dataState.filter',
};

function AuctionsMenuPosition({
  auctionMenuIsOpen,
  setAuctionMenuIsOpen,
  handleMenuOpen,
}) {
  const dispatch = useDispatch();
  const dataState = useSelector(state => state.auctionsManagement.dataState);
  const { onGoingAuctions } = useSelector(state => state.sideMenu);

  const toggleAuctionMenu = () => setAuctionMenuIsOpen(!auctionMenuIsOpen);

  const changeFilter = filter => {
    dispatch(
      getForGridAuctionsManagement({
        ...dataState,
        filter,
        skip: 0,
      }),
    );
    dispatch({
      type: auctionActionTypes.AUCTIONS_MANAGEMENT_SET_FILTER,
      payload: filter,
    });
  };

  const dispatchObserved = () => changeFilter(auctionObservedFilter);
  const dispatchDraft = () => changeFilter(auctionDraftFilter);
  const dispatchOngoing = () => changeFilter(auctionOngoingFilter);
  const dispatchWithoutOffer = () => changeFilter(auctionWithoutOfferFilter);
  const dispatchAll = () => changeFilter(auctionAllFilter);
  const dispatchCanceled = () => changeFilter(auctionCanceledFilter);
  const dispatchWithSuccess = () => changeFilter(auctionWithSuccessFilter);
  const dispatchWithoutSuccess = () =>
    changeFilter(auctionWithoutSuccessFilter);

  return (
    <MenuLink
      icon={icons.auction}
      label={lang.labels.Auctions()}
      handleMenuOpen={handleMenuOpen}
      submenuIsOpen={auctionMenuIsOpen}
      handleMenuItemToggle={toggleAuctionMenu}
    >
      <MenuSublinkGridFilter
        label={lang.labels.All()}
        onClick={dispatchAll}
        filter={auctionAllFilter}
        color="darkGreen"
        {...commonProps}
      />

      <MenuSublinkGridFilter
        label={lang.labels.Ongoing()}
        onClick={dispatchOngoing}
        filter={auctionOngoingFilter}
        count={onGoingAuctions}
        color="blue"
        {...commonProps}
      />

      <MenuSublinkGridFilter
        label={lang.labels.Draft()}
        onClick={dispatchDraft}
        filter={auctionDraftFilter}
        color="grey"
        {...commonProps}
      />

      <MenuSublinkGridFilter
        label={lang.labels.WithoutOffer()}
        onClick={dispatchWithoutOffer}
        filter={auctionWithoutOfferFilter}
        color="orange"
        {...commonProps}
      />

      <MenuSublinkGridFilter
        label={lang.labels.Canceled()}
        onClick={dispatchCanceled}
        filter={auctionCanceledFilter}
        color="orange"
        {...commonProps}
      />

      <MenuSublinkGridFilter
        label={lang.labels.Observed()}
        onClick={dispatchObserved}
        filter={auctionObservedFilter}
        color="yellow"
        {...commonProps}
      />

      <MenuSublinkGridFilter
        label={lang.labels.WithSuccess()}
        onClick={dispatchWithSuccess}
        filter={auctionWithSuccessFilter}
        color="customGreen"
        {...commonProps}
      />

      <MenuSublinkGridFilter
        label={lang.labels.WithoutSuccess()}
        onClick={dispatchWithoutSuccess}
        filter={auctionWithoutSuccessFilter}
        color="red"
        {...commonProps}
      />
    </MenuLink>
  );
}

AuctionsMenuPosition.propTypes = propTypes;
AuctionsMenuPosition.defaultProps = defaultProps;

export default memo(AuctionsMenuPosition);
