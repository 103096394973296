"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var auctionTypes = {
  public: '18965528-8421-42b6-bba3-e1bdf68f9335',
  nonPublic: 'c44474ab-a646-4dc9-be5e-512c65c8da68'
};
var _default = auctionTypes;
exports.default = _default;