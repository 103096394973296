import get from 'lodash/get';

export const diagramData = state =>
  get(state, 'dashboard.diagram.realizedDays');

export const diagramFullYear = state =>
  get(state, 'dashboard.diagram.fullDays');

export const diagramYearWeeks = state =>
  get(state, 'dashboard.diagram.yearWeeks');

export const diagramYearMonths = state =>
  get(state, 'dashboard.diagram.yearWeeks');

export const diagramYearMonthDays = state =>
  get(state, 'dashboard.diagram.yearWeeks');

export const diagramSelector = state => get(state, 'dashboard.diagram');

export const keysSelector = state => get(state, 'dashboard.diagram.keys');
