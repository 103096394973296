import axios from 'config/axios';

const prefix = 'externalContent';

export default class ExternalContentSrv {
  static upload(file, fileName) {
    const form = new FormData();
    form.append('file', file, fileName);

    return axios.post(prefix, form);
  }
}
