import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { isRequired } from 'fox-react/dist/utils/formValidators';
import Form from 'fox-react/dist/components/Form';
import FoxInputTextField from 'fox-react/dist/reduxFormFields/FoxInputTextField';
import FoxButton from 'fox-react/dist/components/FoxButton';
import goBackAfterSuccess from 'fox-react/dist/utils/goBackAfterSuccess';
import lang from 'hh-shared/dist/language/services/languageService';
import {
  scrollToTheFirstError,
  displayCompletionToasts,
} from 'hh-shared/dist/consts/reduxFormMethods';
import fieldNames from 'common/fieldNames';
import formNames from 'common/formNames';
import icons from 'hh-shared/dist/consts/icons';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const defaultProps = {};

function CarTypeInformationForm({ handleSubmit, submitting }) {
  return (
    <Form handleSubmit={handleSubmit}>
      <fieldset>
        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.CarTypeInformations()}
            titleIcon={icons.vehicle}
          >
            <FoxInputTextField
              label={lang.labels.Name()}
              placeholder={lang.labels.Name()}
              name={fieldNames.carTypeInformationForm.name}
              required
            />
          </FormSectionCard>
        </SectionWrapper>
      </fieldset>
      <FoxButton primary disabled={submitting}>
        {lang.buttons.Save()}
      </FoxButton>
    </Form>
  );
}

CarTypeInformationForm.propTypes = propTypes;
CarTypeInformationForm.defaultProps = defaultProps;

const validate = values => {
  const errors = {};

  isRequired(
    errors,
    values,
    fieldNames.carTypeInformationForm.name,
    lang.validationMessages.FieldRequired(),
  );

  return errors;
};

const onSubmitSuccess = (result, dispatch, props) =>
  displayCompletionToasts(
    dispatch,
    !!props.isEditForm,
    lang.labels.SuccessfullyModificatedCarType(),
    lang.labels.SuccessfullyCreatedCarType(),
  );

export default reduxForm({
  form: formNames.carTypeInformationForm,
  validate,
  enableReinitialize: true,
  onSubmitFail: scrollToTheFirstError,
  onSubmitSuccess,
})(goBackAfterSuccess(CarTypeInformationForm));
