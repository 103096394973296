import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import get from 'lodash/get';

import formNames from 'common/formNames';
import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import FormPreviewPane from 'hh-shared/dist/components/layout/FormPreview/FormPreviewPaneContainer';
import FormPreviewSection from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSection';
import FormPreviewItem from 'hh-shared/dist/components/layout/FormPreview/FormPreviewItem';

const propTypes = {
  editForm: PropTypes.bool,
};

const defaultProps = {
  editForm: false,
};

const CarsManagementPagePreview = ({ editForm }) => {
  const formValues = useSelector(
    state => getFormValues(formNames.carInformationForm)(state) || {},
  );

  return (
    <FormPreviewPane>
      {editForm && (
        <FormPreviewSection
          title={lang.labels.Version()}
          icon={icons.editedDocument}
        >
          <FormPreviewItem
            label={lang.labels.Version()}
            value={formValues.versionNumber}
          />
        </FormPreviewSection>
      )}
      <FormPreviewSection
        title={lang.labels.CarInformations()}
        icon={icons.vehicle}
      >
        <FormPreviewItem
          label={lang.labels.RegisterNumber()}
          value={formValues.registerNumber}
        />

        <FormPreviewItem
          label={lang.labels.CarType()}
          value={get(formValues, 'carType.name')}
        />

        <FormPreviewItem
          label={lang.labels.Comment()}
          value={formValues.comment}
        />

        <FormPreviewItem
          label={lang.labels.Company()}
          value={get(formValues, 'company.name')}
        />
      </FormPreviewSection>
    </FormPreviewPane>
  );
};

CarsManagementPagePreview.propTypes = propTypes;
CarsManagementPagePreview.defaultProps = defaultProps;

export default CarsManagementPagePreview;
