import { connect } from 'react-redux';
import CarTypesManagementAddPage from './CarTypesManagementAddPage';
import { addAsync } from './actions';

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: model => dispatch(addAsync(model)),
  };
};

const CarTypesManagementAddPageContainer = connect(
  undefined,
  mapDispatchToProps,
)(CarTypesManagementAddPage);

export default CarTypesManagementAddPageContainer;
