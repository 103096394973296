"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertObjOfBoolsToArray = convertObjOfBoolsToArray;

var _keys = _interopRequireDefault(require("lodash/keys"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function convertObjOfBoolsToArray(obj) {
  var ret = [];
  (0, _keys.default)(obj).forEach(function (key) {
    if (obj[key]) {
      ret.push(key);
    }
  });
  return ret;
}