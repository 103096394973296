import React, { memo } from 'react';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import FoxCustomDialog from 'fox-react/dist/components/FoxCustomDialog';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { validateDriverInput } from 'hh-shared/dist/components/transport-order/DriverInput';
import { validateCarInput } from 'hh-shared/dist/components/transport-order/CarInput';
import { isRequired } from 'fox-react/dist/utils/formValidators';
import Form from 'fox-react/dist/components/Form';
import IconArea from 'hh-shared/dist/components/commons/IconArea';
import icons from 'hh-shared/dist/consts/icons';
import FoxDateTimePickerField from 'fox-react/dist/reduxFormFields/FoxDateTimePickerField';
import ColoredTime from 'hh-shared/dist/components/commons/DataFixTimes/ColoredTime';
import styles from './RealizedDialog.module.scss';
import FoxInputTextareaField from 'fox-react/dist/reduxFormFields/FoxInputTextareaField';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  transportOrderData: PropTypes.object,
};

const defaultProps = {
  transportOrderData: {},
};

function RealizedDialog({
  isOpen,
  onCloseClick,
  handleSubmit,
  transportOrderData,
}) {
  const isFixDate = !transportOrderData.unloadingTimeFrom;

  return (
    <FoxCustomDialog
      title={lang.labels.RealizedDetails()}
      isOpen={isOpen}
      onCloseClick={onCloseClick}
      style={{ minWidth: '650px' }}
      noLabel={lang.labels.Cancel()}
      yesLabel={lang.buttons.Realized()}
      onConfirmClick={handleSubmit}
      bigger
    >
      <Form handleSubmit={handleSubmit}>
        <div className={styles.row}>
          <IconArea
            icon={icons.unfilledClock}
            alignTop
            iconClassName={styles.icon}
          >
            {!isFixDate && (
              <div className={styles.row_small}>
                <div>{lang.labels.DeclaredUnloadingRangeDateTime()}</div>
                <div>
                  {`${lang.labels.From()}: `}
                  <ColoredTime time={transportOrderData.unloadingTimeFrom} />
                </div>
                <div>
                  {`${lang.labels.To()}: `}
                  <ColoredTime time={transportOrderData.unloadingTimeTo} />
                </div>
              </div>
            )}

            <div className={styles.row_small}>
              <FoxDateTimePickerField
                label={lang.labels.UnloadingDateTime()}
                name="unloadingTimeTo"
                required
              />
            </div>
          </IconArea>
        </div>
        <div className={styles.row}>
          <IconArea
            icon={icons.comment}
            iconTitle={lang.labels.Comment()}
            iconClassName={styles.icon}
            alignTop
          >
            <FoxInputTextareaField
              name="message"
              label={lang.labels.Comment()}
            />
          </IconArea>
        </div>
      </Form>
    </FoxCustomDialog>
  );
}

RealizedDialog.propTypes = propTypes;
RealizedDialog.defaultProps = defaultProps;

const validate = values => {
  const errors = {};
  isRequired(
    errors,
    values,
    'loadingTimeTo',
    lang.validationMessages.FieldRequired(),
  );

  validateCarInput(errors, values);
  validateDriverInput(errors, values);

  return errors;
};

export default compose(
  reduxForm({
    form: 'realizedDialog',
    enableReinitialize: true,
    validate,
  }),
  memo,
)(RealizedDialog);
