import userPreferencesActionTypes from './actionTypes';

const initialState = {
  onlyMyFacility: true,
};

export default function userPreferencesReducer(state = initialState, action) {
  switch (action.type) {
    case userPreferencesActionTypes.USER_PREFERENCES_SET_VALUE:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
