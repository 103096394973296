import React, { memo } from 'react';
import FoxInputTextField from 'fox-react/dist/reduxFormFields/FoxInputTextField';
import lang from 'hh-shared/dist/language/services/languageService';
import fieldNames from 'common/fieldNames';
import { isRequiredFieldValidator } from 'hh-shared/dist/utils/formValidators';
import ZipCodeAutocompleteField from '../ZipCodeAutocompleteField';
import StreetAutocompleteField from './StreetAutocompleteField';

const propTypes = {};

const defaultProps = {};

function NewAddressForm() {
  return (
    <>
      <ZipCodeAutocompleteField required validate={isRequiredFieldValidator} />
      <FoxInputTextField
        label={lang.labels.City()}
        placeholder={lang.labels.City()}
        name={fieldNames.newAddressInformationForm.city}
        required
        validate={isRequiredFieldValidator}
      />
      <StreetAutocompleteField />
    </>
  );
}

NewAddressForm.propTypes = propTypes;
NewAddressForm.defaultProps = defaultProps;

export default memo(NewAddressForm);
