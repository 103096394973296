import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, FormSection, formValues } from 'redux-form';
import Form from 'fox-react/dist/components/Form';
import FoxInputTextField from 'fox-react/dist/reduxFormFields/FoxInputTextField';
import FoxInputEmailField from 'fox-react/dist/reduxFormFields/FoxInputEmailField';
import FoxConfirmationButton from 'fox-react/dist/components/FoxConfirmationButton';
import FoxComboBoxField from 'fox-react/dist/reduxFormFields/FoxComboBoxField';
import FoxButton from 'fox-react/dist/components/FoxButton';
import goBackAfterSuccess from 'fox-react/dist/utils/goBackAfterSuccess';
import FoxSwitchField from 'fox-react/dist/reduxFormFields/FoxSwitchField';
import lang from 'hh-shared/dist/language/services/languageService';
import {
  scrollToTheFirstError,
  displayCompletionToasts,
} from 'hh-shared/dist/consts/reduxFormMethods';
import NewAddressForm from 'commons/NewAddressForm';
import fieldNames from 'common/fieldNames';
import formNames from 'common/formNames';
import icons from 'hh-shared/dist/consts/icons';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';
import ContentRevealer from 'hh-shared/dist/components/commons/ContentRevealer';
import {
  isRequiredFieldValidator,
  isEmailFieldValidator,
} from 'hh-shared/dist/utils/formValidators';
import phoneMask from 'hh-shared/dist/utils/phoneMask';
import FoxMaskedInputTextField from 'fox-react/dist/reduxFormFields/FoxMaskedInputTextField';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  onSubmitAsNew: PropTypes.func,
  departments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  addresses: PropTypes.array.isRequired,
  handleRemove: PropTypes.func,
  isNewAddress: PropTypes.bool,
};

const defaultProps = {
  onSubmitAsNew: undefined,
  handleRemove: undefined,
  isNewAddress: false,
};

function FacilityInformationForm({
  handleSubmit,
  submitting,
  onSubmitAsNew,
  departments,
  addresses,
  handleRemove,
  isNewAddress,
}) {
  const submitAsNew = () => {
    handleSubmit(onSubmitAsNew)();
  };

  return (
    <Form handleSubmit={handleSubmit}>
      <fieldset>
        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.FacilityInformations()}
            titleIcon={icons.facility}
          >
            <FoxInputTextField
              label={lang.labels.Name()}
              placeholder={lang.labels.Name()}
              name={fieldNames.facilityInformationForm.name}
              required
              validate={isRequiredFieldValidator}
            />

            <FoxInputTextField
              label={lang.labels.Code()}
              placeholder={lang.labels.Code()}
              name={fieldNames.facilityInformationForm.code}
            />

            <ContentRevealer isVisible={!isNewAddress}>
              <FoxComboBoxField
                label={lang.labels.Address()}
                name={fieldNames.facilityInformationForm.address}
                items={addresses}
                required
                validate={isRequiredFieldValidator}
              />
            </ContentRevealer>

            <FoxSwitchField
              label={lang.labels.NewAddress()}
              placeholder={lang.labels.NewAddress()}
              name={fieldNames.facilityInformationForm.isNewAddress}
            />

            <ContentRevealer isVisible={isNewAddress}>
              <FormSection name="newAddress">
                <NewAddressForm />
              </FormSection>
            </ContentRevealer>

            <FoxInputEmailField
              label={lang.labels.Email()}
              placeholder={lang.labels.Email()}
              name={fieldNames.facilityInformationForm.email}
              required
              validate={[isRequiredFieldValidator, isEmailFieldValidator]}
            />

            <FoxMaskedInputTextField
              {...phoneMask}
              label={lang.labels.PhoneNumber()}
              placeholder={lang.labels.PhoneNumber()}
              name={fieldNames.facilityInformationForm.phoneNumber}
              required
              validate={isRequiredFieldValidator}
            />

            <FoxComboBoxField
              label={lang.labels.Department()}
              name={fieldNames.facilityInformationForm.department}
              items={departments}
              required
              validate={isRequiredFieldValidator}
            />
          </FormSectionCard>
        </SectionWrapper>
      </fieldset>
      <FoxButton primary disabled={submitting}>
        {lang.buttons.Save()}
      </FoxButton>

      {onSubmitAsNew && (
        <FoxButton
          type="button"
          primary
          disabled={submitting}
          onClick={submitAsNew}
        >
          {lang.buttons.SaveAsNew()}
        </FoxButton>
      )}

      {handleRemove && (
        <FoxConfirmationButton
          buttonText={lang.buttons.Delete()}
          onYesClick={() => handleRemove()}
          className="button button_error"
        />
      )}
    </Form>
  );
}

FacilityInformationForm.propTypes = propTypes;
FacilityInformationForm.defaultProps = defaultProps;

const onSubmitSuccess = (result, dispatch, props) =>
  displayCompletionToasts(
    dispatch,
    !!props.isEditForm,
    lang.labels.SuccessfullyModificatedFacility(),
    lang.labels.SuccessfullyCreatedFacility(),
  );

export default reduxForm({
  form: formNames.facilityInformationForm,
  enableReinitialize: true,
  onSubmitFail: scrollToTheFirstError,
  onSubmitSuccess,
})(goBackAfterSuccess(formValues('isNewAddress')(FacilityInformationForm)));
