import { change, formValueSelector } from 'redux-form';
import get from 'lodash/get';

import formNames from 'common/formNames';
import fieldNames from 'common/fieldNames';
import {
  getPricePerKilometer,
  getStartPrice,
  getPricesByDistance,
} from 'common/pricesCalucator';

const formSelector = formValueSelector(formNames.transportOrderInformationForm);

function clearPrices(dispatch) {
  dispatch(
    change(
      formNames.transportOrderInformationForm,
      fieldNames.transportOrderInformationForm.pricePerKilometer,
      null,
    ),
  );
  dispatch(
    change(
      formNames.transportOrderInformationForm,
      fieldNames.transportOrderInformationForm.value,
      null,
    ),
  );
}

function calculatePrices(prices, distance, zipCode, dispatch) {
  const pricePerKilometer = getPricePerKilometer(prices, distance, zipCode);
  const startPrice = getStartPrice(prices, distance, zipCode);

  dispatch(
    change(
      formNames.transportOrderInformationForm,
      fieldNames.transportOrderInformationForm.pricePerKilometer,
      pricePerKilometer,
    ),
  );
  dispatch(
    change(
      formNames.transportOrderInformationForm,
      fieldNames.transportOrderInformationForm.value,
      startPrice,
    ),
  );
}

export function changePrices(
  company,
  distance,
  defaultPrices,
  zipCode,
  dispatch,
) {
  if (!distance) {
    clearPrices(dispatch);
  } else if (
    company &&
    company.prices &&
    company.prices.length &&
    getPricesByDistance(company.prices, distance).length
  ) {
    calculatePrices(company.prices, distance, zipCode, dispatch);
  } else {
    calculatePrices(defaultPrices, distance, zipCode, dispatch);
  }
}

export const commonMapStateToProps = state => {
  return {
    loadingMethods: state.dictionary.static.loadingMethods,
    unloadingMethods: state.dictionary.static.unloadingMethods,
    showNewDriver: !!formSelector(
      state,
      fieldNames.transportOrderInformationForm.isNewDriver,
    ),
    showNewCar: !!formSelector(
      state,
      fieldNames.transportOrderInformationForm.isNewCar,
    ),
    showNewLoadingAddress: !!formSelector(
      state,
      fieldNames.transportOrderInformationForm.isNewLoadingAddress,
    ),
    showNewUnloadingAddress: !!formSelector(
      state,
      fieldNames.transportOrderInformationForm.isNewUnloadingAddress,
    ),
    company: formSelector(
      state,
      fieldNames.transportOrderInformationForm.company,
    ),
    distance: formSelector(
      state,
      fieldNames.transportOrderInformationForm.distance,
    ),
    value: formSelector(state, fieldNames.transportOrderInformationForm.value),
    loadingAddressZipCode: get(
      state,
      'form.transportOrderInformationForm.values.loadingAddress.zipCode',
    ),
    newLoadingAddressZipCode: get(
      state,
      'form.transportOrderInformationForm.values.newLoadingAddress.zipCode',
    ),
    isNewLoadingAddress: formSelector(
      state,
      fieldNames.transportOrderInformationForm.isNewLoadingAddress,
    ),
    defaultPrices: state.defaultPrices,
  };
};

export const commonMapDispatchToProps = dispatch => {
  return {
    onAfterCompanyChange: (company, distance, defaultPrices, zipCode) => {
      changePrices(company, distance, defaultPrices, zipCode, dispatch);
    },
    onDistanceChange: (distance, company, defaultPrices, zipCode) => {
      changePrices(company, distance, defaultPrices, zipCode, dispatch);
    },
    onPricePerKilometerChange: (pricePerKilometer, distance) => {
      if (pricePerKilometer && distance) {
        dispatch(
          change(
            formNames.transportOrderInformationForm,
            fieldNames.transportOrderInformationForm.value,
            distance * pricePerKilometer,
          ),
        );
      }
    },
    onValueChange: (value, distance) => {
      if (value && distance) {
        dispatch(
          change(
            formNames.transportOrderInformationForm,
            fieldNames.transportOrderInformationForm.pricePerKilometer,
            value / distance,
          ),
        );
      }
    },
    onResetPrices: (company, distance, defaultPrices, zipCode) => {
      changePrices(company, distance, defaultPrices, zipCode, dispatch);
    },
  };
};

export const commonMergeProps = (
  propsFromState,
  propsFromDispatch,
  ownProps,
) => {
  return {
    ...propsFromState,
    ...propsFromDispatch,
    onAfterCompanyChange: company =>
      propsFromDispatch.onAfterCompanyChange(
        company,
        propsFromState.distance,
        propsFromState.defaultPrices,
        propsFromState.isNewLoadingAddress
          ? propsFromState.newLoadingAddressZipCode
          : propsFromState.loadingAddressZipCode,
      ),
    onDistanceChange: distance =>
      propsFromDispatch.onDistanceChange(
        distance,
        propsFromState.company,
        propsFromState.defaultPrices,
        propsFromState.isNewLoadingAddress
          ? propsFromState.newLoadingAddressZipCode
          : propsFromState.loadingAddressZipCode,
      ),
    onPricePerKilometerChange: pricePerKilometer =>
      propsFromDispatch.onPricePerKilometerChange(
        pricePerKilometer,
        propsFromState.distance,
      ),
    onValueChange: value =>
      propsFromDispatch.onValueChange(value, propsFromState.distance),
    onResetPrices: () =>
      propsFromDispatch.onResetPrices(
        propsFromState.company,
        propsFromState.distance,
        propsFromState.defaultPrices,
        propsFromState.isNewLoadingAddress
          ? propsFromState.newLoadingAddressZipCode
          : propsFromState.loadingAddressZipCode,
      ),
    ...ownProps,
  };
};
