import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import RouteInformationForm from './RouteInformationForm';
import RoutesManagementPagePreview from './RoutesManagementPagePreview';
import { addAsync } from './actions';

const propTypes = {
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

function RoutesManagementAddPage({ history }) {
  const dispatch = useDispatch();
  const onSubmit = model => dispatch(addAsync(model));

  const zipCodes = useSelector(state => state.dictionary.static.zipCodes);

  return (
    <>
      <BreadcrumbsContainer
        links={[
          {
            routePath: '/',
            label: lang.labels.HomePage(),
          },
          {
            routePath: '/routes-management',
            label: lang.labels.Routes(),
          },
          {
            routePath: '/routes-management/add',
            label: lang.labels.AddingNewRoute(),
          },
        ]}
      />
      <FormPreviewSectionContainer>
        <FormCardWrapper>
          <div>
            <RouteInformationForm
              onSubmit={onSubmit}
              history={history}
              zipCodes={zipCodes}
            />
          </div>
        </FormCardWrapper>
        <RoutesManagementPagePreview />
      </FormPreviewSectionContainer>
    </>
  );
}

RoutesManagementAddPage.propTypes = propTypes;
RoutesManagementAddPage.defaultProps = defaultProps;

export default RoutesManagementAddPage;
