import React from 'react';
import PropTypes from 'prop-types';
import styles from './CurrencyPriceInputWrapper.module.scss';
import Row from './Row';

const propTypes = {
  children: PropTypes.any.isRequired,
};
const defaultProps = {};

const CurrencyPriceInputWrapper = ({ children }) => {
  return <Row className={styles.input_wrapper}>{children}</Row>;
};

CurrencyPriceInputWrapper.propTypes = propTypes;
CurrencyPriceInputWrapper.defaultProps = defaultProps;

export default CurrencyPriceInputWrapper;
