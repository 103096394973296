import { connect } from 'react-redux';
import PricesManagementPage from './PricesManagementPage';
import { getForGrid, importPrices } from './actions';

const mapDispatchToProps = dispatch => {
  return {
    onLoadData: dataState => dispatch(getForGrid(dataState)),
    onImport: files => dispatch(importPrices(files)),
  };
};

const PricesManagementPageContainer = connect(
  undefined,
  mapDispatchToProps,
)(PricesManagementPage);

export default PricesManagementPageContainer;
