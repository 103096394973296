import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import get from 'lodash/get';

import formNames from 'common/formNames';
import permissions from 'common/permissions';
import PermissionWrapper from 'commons/PermissionWrapper';
import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import FormPreviewPane from 'hh-shared/dist/components/layout/FormPreview/FormPreviewPaneContainer';
import FormPreviewSection from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSection';
import FormPreviewItem from 'hh-shared/dist/components/layout/FormPreview/FormPreviewItem';
import { phoneMaskedDisplay } from 'hh-shared/dist/utils/phoneMask';

const propTypes = {
  editForm: PropTypes.bool,
};

const defaultProps = {
  editForm: false,
};

const UsersManagementPagePreview = ({ editForm }) => {
  const formValues = useSelector(
    state => getFormValues(formNames.userInformationForm)(state) || {},
  );

  const hasPermission = permissionName =>
    formValues &&
    formValues.permissions &&
    formValues.permissions[permissionName]
      ? lang.labels.Yes()
      : lang.labels.No();

  return (
    <FormPreviewPane>
      <FormPreviewSection
        title={lang.labels.UserInformations()}
        icon={icons.userData}
      >
        <FormPreviewItem label={lang.labels.Login()} value={formValues.login} />

        <FormPreviewItem label={lang.labels.Email()} value={formValues.email} />

        <FormPreviewItem
          label={lang.labels.UserBanned()}
          value={formValues.isBanned ? lang.labels.Yes() : lang.labels.No()}
        />
      </FormPreviewSection>
      <FormPreviewSection
        title={lang.labels.UserPermissions()}
        icon={icons.permissions}
      >
        <PermissionWrapper oneOfPermissions={[permissions.ADMIN]}>
          <FormPreviewItem
            label={lang.labels.MainAdmin()}
            value={hasPermission(permissions.ADMIN)}
          />
        </PermissionWrapper>

        <PermissionWrapper
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.ADMIN]}
        >
          <FormPreviewItem
            label={lang.labels.DepAdmin()}
            value={hasPermission(permissions.DEP_ADMIN)}
          />
          <FormPreviewItem
            label={lang.labels.ActivateCompany()}
            value={hasPermission(permissions.ACTIVATE_COMPANY)}
          />
          <FormPreviewItem
            label={lang.labels.Employee()}
            value={hasPermission(permissions.EMPLOYEE)}
          />
        </PermissionWrapper>
        <FormPreviewItem
          label={lang.labels.Logistician()}
          value={hasPermission(permissions.LOGISTICIAN)}
        />
        <FormPreviewItem
          label={lang.labels.PricesManagement()}
          value={hasPermission(permissions.PRICES_MANAGEMENT)}
        />
        {/* Odkomentować, gdy będzie możliwa zmiana uprawnień użytkownika dotyczących bycia kierowcom. */}
        {/* <FormPreviewItem
          label={lang.labels.Driver()}
          value={hasPermission(permissions.DRIVER)}
        /> */}
      </FormPreviewSection>
      <FormPreviewSection
        title={lang.labels.AdditionalUserInformations()}
        icon={icons.userData}
      >
        {editForm && (
          <FormPreviewItem
            label={lang.labels.Version()}
            value={formValues.versionNumber}
          />
        )}

        <FormPreviewItem
          label={lang.labels.FirstName()}
          value={formValues.firstName}
        />

        <FormPreviewItem
          label={lang.labels.LastName()}
          value={formValues.lastName}
        />

        <FormPreviewItem
          label={lang.labels.PhoneNumber()}
          value={phoneMaskedDisplay(formValues.phoneNumber)}
        />

        <FormPreviewItem
          label={lang.labels.Department()}
          value={get(formValues, 'department.name')}
        />

        <FormPreviewItem
          label={lang.labels.Facility()}
          value={get(formValues, 'facility.name')}
        />

        <FormPreviewItem
          label={lang.labels.Company()}
          value={get(formValues, 'company.name')}
        />
      </FormPreviewSection>
    </FormPreviewPane>
  );
};

UsersManagementPagePreview.propTypes = propTypes;
UsersManagementPagePreview.defaultProps = defaultProps;

export default UsersManagementPagePreview;
