import React, { useEffect, useState } from 'react';
import { getFormValues } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import FormPreviewPane from 'hh-shared/dist/components/layout/FormPreview/FormPreviewPaneContainer';
import FormPreviewSection from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSection';
import FormPreviewItem from 'hh-shared/dist/components/layout/FormPreview/FormPreviewItem';
import icons from 'hh-shared/dist/consts/icons';
import formNames from 'common/formNames';
import lang from 'hh-shared/dist/language/services/languageService';
import copyToClipboard from 'hh-shared/dist/utils/copyToClipboard';
import { displayToastr } from 'hh-shared/dist/toastr/toastrActions';
import ToastrTypes from 'hh-shared/dist/toastr/ToastrTypes';
import PropTypes from 'prop-types';
import EditPagePreviewButton from './EditPagePreviewButton';
import HtmlTemplatesManagementSrv from '../HtmlTemplatesManagementSrv';
import styles from './EditPagePreview.module.scss';

const propTypes = {
  smsPage: PropTypes.bool,
};

const defaultProps = {
  smsPage: false,
};

const EditPagePreview = ({ smsPage }) => {
  const [placeholders, setPlaceholders] = useState([]);
  const dispatch = useDispatch();
  const formValues = useSelector(
    state => getFormValues(formNames.smsTemplateInformationForm)(state) || {},
  );

  useEffect(() => {
    formValues.id &&
      HtmlTemplatesManagementSrv.getPlaceholders(formValues.id).then(results =>
        setPlaceholders(results),
      );
  }, [formValues.id]);

  const onPlaceholderClick = placeholder => {
    copyToClipboard(`[[${placeholder}]]`);
    dispatch(
      displayToastr(
        `${lang.labels.InsideYourClipboardIsLocatedPlaceholder()} "${placeholder}". ${lang.labels.PasteItIntoAppropriatePlaceInsideTheTemplate()}`,
        ToastrTypes.info,
      ),
    );
  };

  return (
    <FormPreviewPane>
      {placeholders.length > 0 && (
        <FormPreviewSection
          title={lang.labels.AvailablePlaceholders()}
          icon={icons.placeholder}
        >
          <div className={styles.placeholder_list}>
            {placeholders.map((placeholder, placeholderIndex) => (
              <EditPagePreviewButton
                label={placeholder}
                key={placeholderIndex}
                onClick={() => onPlaceholderClick(placeholder)}
              />
            ))}
          </div>
        </FormPreviewSection>
      )}
      <FormPreviewSection
        title={lang.labels.HtmlTemplateInformations()}
        icon={icons.htmlDocument}
      >
        <FormPreviewItem label={lang.labels.Name()} value={formValues.name} />

        <FormPreviewItem
          label={lang.labels.Description()}
          value={formValues.description}
        />

        {smsPage && (
          <FormPreviewItem
            label={lang.labels.Content()}
            value={formValues.smsContent}
          />
        )}
      </FormPreviewSection>
    </FormPreviewPane>
  );
};

EditPagePreview.propTypes = propTypes;
EditPagePreview.defaultProps = defaultProps;

export default EditPagePreview;
