import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import createFoxGridContainer from 'fox-react/dist/components/FoxGrid/createFoxGridContainer';
import FoxButton from 'fox-react/dist/components/FoxButton';
import FoxGridColumn from 'fox-react/dist/components/FoxGrid/FoxGridColumn';

import gridNames from 'common/gridNames';
import columnNames from 'common/columnNames';
import InnerContentWrapper from 'hh-shared/dist/components/layout/InnerContentWrapper';
import lang from 'hh-shared/dist/language/services/languageService';
import actionsColumnGridDefaults from 'hh-shared/dist/consts/actionsColumnGridDefaults';

const propTypes = {
  onLoadData: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const defaultProps = {};

const FoxGridContainer = createFoxGridContainer(gridNames.currenciesManagement);

class CurrenciesManagementPage extends PureComponent {
  render() {
    const { onLoadData, match } = this.props;

    return (
      <InnerContentWrapper fullWidth>
        <FoxGridContainer
          onLoadData={onLoadData}
          defaultSortField={columnNames.currenciesManagement.name}
          renderButtons={
            <Link to={`${match.url}/add`}>
              <FoxButton icon="add" primary>
                {lang.buttons.Add()}
              </FoxButton>
            </Link>
          }
        >
          <FoxGridColumn
            {...actionsColumnGridDefaults}
            cell={({ dataItem }) => (
              <td>
                <Link to={`${match.url}/edit/${dataItem.id}`}>
                  <FoxButton icon="edit">{lang.buttons.Edit()}</FoxButton>
                </Link>
              </td>
            )}
          />
          <FoxGridColumn
            field={columnNames.currenciesManagement.name}
            title={lang.labels.CurrenciesManagement_Columns_Name()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.currenciesManagement.isDefault}
            title={lang.labels.CurrenciesManagement_Columns_IsDefault()}
            filter="boolean"
            columnMenu={false}
            cell={({ dataItem }) => (
              <td colSpan="1">
                {dataItem.isDefault ? lang.labels.Yes() : lang.labels.No()}
              </td>
            )}
          />
        </FoxGridContainer>
      </InnerContentWrapper>
    );
  }
}

CurrenciesManagementPage.propTypes = propTypes;
CurrenciesManagementPage.defaultProps = defaultProps;

export default CurrenciesManagementPage;
