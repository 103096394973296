import { useSelector } from 'react-redux';
import permissions from './permissions';
import transportCodeIds from 'hh-shared/src/lib/utils/transportCodeIds';
import { useCurrentUser } from './useCurrentUser';

export default function useTransportCodesDictionary() {
  const transportCodes = useSelector(
    state => state.dictionary.static.transportCodes,
  );
  const { hasOneOf } = useCurrentUser();

  if (hasOneOf([permissions.EXPORT_NAV])) {
    return transportCodes;
  }

  return transportCodes.filter(x => x.id !== transportCodeIds.different);
}
