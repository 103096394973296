import React, { memo, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { usePrevious } from 'hh-shared/dist/consts/customHooks';
import lang from 'hh-shared/dist/language/services/languageService';
import ConditionalCell from 'hh-shared/dist/components/grid/ConditionalCell';

const propTypes = {
  auctionId: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultProps = { value: undefined };

const CurrentPriceCell = ({ auctionId, value, ...others }) => {
  const labelRef = useRef(null);
  const [labelRow, setLabelRow] = useState(null);
  const successClassName = 'highlight-green';
  const prevAuctionId = usePrevious(auctionId);

  const updateClassName = () =>
    labelRow && labelRow.classList.add(successClassName);

  const removeCurrentClassName = () =>
    labelRow && labelRow.classList.remove(successClassName);

  useEffect(() => {
    labelRef.current &&
      (() => {
        const { current: labelElement } = labelRef;
        setLabelRow(labelElement.closest('tr'));
      })();
  }, [labelRef]);

  useEffect(() => {
    if (prevAuctionId === auctionId || !prevAuctionId) {
      updateClassName();
      setTimeout(() => {
        removeCurrentClassName();
      }, 1000);
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <td colSpan="1" ref={labelRef}>
      <ConditionalCell
        {...others}
        alternateValue={value || lang.labels.WithoutOffer()}
        displayWithoutTd
        numericValue
      />
    </td>
  );
};

CurrentPriceCell.propTypes = propTypes;
CurrentPriceCell.defaultProps = defaultProps;

export default memo(CurrentPriceCell);
