import React from 'react';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';

import formNames from 'common/formNames';
import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import numberFormating from 'hh-shared/dist/consts/numberFormating';
import FormPreviewPane from 'hh-shared/dist/components/layout/FormPreview/FormPreviewPaneContainer';
import FormPreviewSection from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSection';
import FormPreviewItem from 'hh-shared/dist/components/layout/FormPreview/FormPreviewItem';

const propTypes = {};

const defaultProps = {};

const DefaultPriceManagementPagePreview = () => {
  const { insertThousandsSeparator, toMoneyFormat } = numberFormating;
  const formValues = useSelector(
    state => getFormValues(formNames.defaultPriceInformationForm)(state) || {},
  );

  return (
    <FormPreviewPane>
      <FormPreviewSection
        title={lang.labels.DefaultPriceInformations()}
        icon={icons.currencies}
      >
        <FormPreviewItem
          label={lang.labels.FlatRate()}
          value={
            formValues.flatRate ? toMoneyFormat(formValues.flatRate) : undefined
          }
        />
        <FormPreviewItem
          label={lang.labels.MinDistance()}
          value={
            formValues.minDistance
              ? `${insertThousandsSeparator(formValues.minDistance)} km`
              : undefined
          }
        />
        <FormPreviewItem
          label={lang.labels.MaxDistance()}
          value={
            formValues.maxDistance
              ? `${insertThousandsSeparator(formValues.maxDistance)} km`
              : undefined
          }
        />
      </FormPreviewSection>
    </FormPreviewPane>
  );
};

DefaultPriceManagementPagePreview.propTypes = propTypes;
DefaultPriceManagementPagePreview.defaultProps = defaultProps;

export default DefaultPriceManagementPagePreview;
