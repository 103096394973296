import { connect } from 'react-redux';
import TransportOrdersManagementPagePreview from './TransportOrdersManagementPagePreview';

const mapStateToProps = state => {
  return {
    loadingMethods: state.dictionary.static.loadingMethods,
    unloadingMethods: state.dictionary.static.unloadingMethods,
  };
};

const TransportOrdersManagementPagePreviewContainer = connect(
  mapStateToProps,
  null,
)(TransportOrdersManagementPagePreview);

export default TransportOrdersManagementPagePreviewContainer;
