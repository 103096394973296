import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import FontAwesomeIcon from 'hh-shared/dist/components/commons/FontAwesomeIcon/FontAwesomeIcon';

import icons from 'hh-shared/dist/consts/icons';

import styles from './MenuLink.module.scss';
import Column from '../Column';
import Row from '../Row';

const propTypes = {
  routePath: PropTypes.string,
  icon: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  handleMenuItemToggle: PropTypes.func,
  handleMenuOpen: PropTypes.func,
  checkExactPath: PropTypes.bool,
  submenuIsOpen: PropTypes.bool,
  children: PropTypes.any,
};

const defaultProps = {
  routePath: '',
  handleMenuItemToggle: () => {},
  handleMenuOpen: () => {},
  checkExactPath: false,
  submenuIsOpen: false,
  children: undefined,
};

/* eslint-disable indent */

const MenuLink = ({
  routePath,
  icon,
  label,
  location,
  handleMenuItemToggle,
  handleMenuOpen,
  checkExactPath,
  submenuIsOpen,
  children,
}) => {
  const menuIsOpened = useSelector(state => state.sideMenu.isOpened);

  const getCurrentRoutePath = () => location.pathname;
  const currentPathIncludesRoute = route => {
    const currentRoute = getCurrentRoutePath();
    return !checkExactPath
      ? currentRoute.includes(route)
      : currentRoute === route;
  };

  const getChildrenProps = () => children && children.map(child => child.props);

  const getChildrenRoutes = () =>
    (children &&
      children.length > 0 &&
      getChildrenProps().map(
        childProps => childProps && childProps.routePath,
      )) ||
    (children.props ? [children.props.routePath] : []);

  const isCurrentRoute = () =>
    routePath
      ? currentPathIncludesRoute(routePath)
      : getChildrenRoutes().filter(childRoute =>
          getCurrentRoutePath().includes(childRoute),
        ).length > 0;

  const onClick = event => {
    !routePath &&
      (() => {
        event.preventDefault();
        handleMenuItemToggle();
        !menuIsOpened && handleMenuOpen();
      })();
  };

  // Poniżej jest sprawdzanie dotyczące tablicy boolean-ów, bo gdy robi się
  // warunek && komponent, to trafia do komponentu tablica false-ów, gdy warunek
  // nie zostaje spełniony xD
  const userCanViewLink = () =>
    routePath ||
    !!children.props ||
    (children.length > 0 &&
      !children.every(child => typeof child === 'boolean'));

  const linkClassName = (currentRoute, openedMenu) =>
    cx(styles.link, {
      [`${styles.active} active`]: currentRoute,
      [styles.link_hidden]: !openedMenu,
    });

  const sublinkContainerClassName = openedSubmenu =>
    cx(styles.sublink_container, {
      [styles.sublink_container_closed]: !openedSubmenu,
    });

  return (
    <>
      {userCanViewLink() && (
        <Column>
          <Link
            to={routePath}
            onClick={onClick}
            className={linkClassName(isCurrentRoute(), menuIsOpened)}
            title={label}
          >
            <Row className="align-items-center">
              <span>
                <FontAwesomeIcon
                  className={styles.link_icon}
                  icon={icon}
                  title={label}
                />
              </span>
              <span>{label}</span>
              {children && (
                <FontAwesomeIcon
                  icon={submenuIsOpen ? icons.chevronUp : icons.chevronDown}
                  className={styles.arrow_icon}
                />
              )}
            </Row>
          </Link>
          {children && (
            <Column className={sublinkContainerClassName(submenuIsOpen)}>
              {children}
            </Column>
          )}
        </Column>
      )}
    </>
  );
};

MenuLink.propTypes = propTypes;
MenuLink.defaultProps = defaultProps;

export default withRouter(memo(MenuLink));
