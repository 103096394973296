import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import CarInformationForm from './CarInformationForm';
import CarsManagementSrv from './CarsManagementSrv';
import CarsManagementPagePreview from './CarsManagementPagePreview';

const propTypes = {
  onSubmit: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

class CarsManagementAddPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false,
      carTypes: [],
      companies: [],
    };
  }

  componentDidMount() {
    this.setState({ showSpinner: true });

    Promise.all([
      CarsManagementSrv.getCarTypesAsSelectItems(),
      CarsManagementSrv.getCompaniesAsSelectItems(),
    ])
      .then(results => {
        this.setState({
          carTypes: results[0],
          companies: results[1],
        });
      })
      .finally(() => this.setState({ showSpinner: false }));
  }

  render() {
    const { onSubmit, history } = this.props;
    const { showSpinner, carTypes, companies } = this.state;

    return (
      <>
        <BreadcrumbsContainer
          links={[
            {
              routePath: '/',
              label: lang.labels.HomePage(),
            },
            {
              routePath: '/cars-management',
              label: lang.labels.Cars(),
            },
            {
              routePath: '/cars-management/add',
              label: lang.labels.AddingNewCar(),
            },
          ]}
        />
        <FormPreviewSectionContainer>
          <FormCardWrapper>
            <div className={showSpinner ? 'fox_spinner' : undefined}>
              <CarInformationForm
                onSubmit={onSubmit}
                history={history}
                carTypes={carTypes}
                companies={companies}
              />
            </div>
          </FormCardWrapper>
          <CarsManagementPagePreview />
        </FormPreviewSectionContainer>
      </>
    );
  }
}

CarsManagementAddPage.propTypes = propTypes;
CarsManagementAddPage.defaultProps = defaultProps;

export default CarsManagementAddPage;
