import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import DefaultPriceInformationForm from './DefaultPriceInformationForm';
import DefaultPricesManagementSrv from './DefaultPricesManagementSrv';
import DefaultPriceManagementPagePreview from './DefaultPriceManagementPagePreview';

const propTypes = {
  match: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

const DefaultPricesManagementEditPage = ({ match, onSubmit, history }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [facilities, setFacilities] = useState([]);

  useEffect(() => {
    setShowSpinner(true);

    Promise.all([
      DefaultPricesManagementSrv.get(match.params.id),
      DefaultPricesManagementSrv.getFacilitiesAsSelectItem(),
    ])
      .then(([resDataToEdit, resFacilities]) => {
        setInitialValues(resDataToEdit);
        setFacilities(resFacilities);
      })
      .finally(() => setShowSpinner(false));
  }, [match.params.id]);

  return (
    <>
      <BreadcrumbsContainer
        links={[
          {
            routePath: '/',
            label: lang.labels.HomePage(),
          },
          {
            routePath: '/default-prices-management',
            label: lang.labels.DefaultPrices(),
          },
          {
            label: lang.labels.EditingDefaultPrice(),
          },
        ]}
      />
      <FormPreviewSectionContainer>
        <FormCardWrapper>
          <div className={showSpinner ? 'fox_spinner' : undefined}>
            <DefaultPriceInformationForm
              onSubmit={onSubmit}
              initialValues={initialValues}
              history={history}
              isEditForm
              facilities={facilities}
            />
          </div>
        </FormCardWrapper>
        <DefaultPriceManagementPagePreview />
      </FormPreviewSectionContainer>
    </>
  );
};

DefaultPricesManagementEditPage.propTypes = propTypes;
DefaultPricesManagementEditPage.defaultProps = defaultProps;

export default DefaultPricesManagementEditPage;
