import { connect } from 'react-redux';
import { updateAsync } from './actions';
import AddressesManagementEditPage from './AddressesManagementEditPage';

const mapStateToProps = state => ({
  zipCodes: state.dictionary.static.zipCodes,
});

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: model => dispatch(updateAsync(model)),
  };
};

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
  // TODO: sprawdzić czy tak działa, jeśli tak to usunąć z editPage i ujednolicić w całej aplikacji
  return {
    onSubmit: model =>
      propsFromDispatch.onSubmit({
        ...model,
        instanceId: ownProps.match.params.instanceId,
      }),
    ...ownProps,
    ...propsFromState,
  };
};

const AddressesManagementEditPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(AddressesManagementEditPage);

export default AddressesManagementEditPageContainer;
