import { connect } from 'react-redux';
import { getForGrid } from './actions';
import AddressesManagementPage from './AddressesManagementPage';

const mapDispatchToProps = dispatch => {
  return {
    onLoadData: dataState => dispatch(getForGrid(dataState)),
  };
};

const AddressesManagementPageContainer = connect(
  undefined,
  mapDispatchToProps,
)(AddressesManagementPage);

export default AddressesManagementPageContainer;
