import axios from 'config/axios';

const prefix = 'auctions';

export default class AuctionsSrv {
  static get(id) {
    return axios.get(`${prefix}/${id}`).then(result => result.data);
  }

  static createBid(price, auctionId, companyId) {
    return axios.post(`${prefix}/${auctionId}/bids`, { price, companyId });
  }

  static cancel(auctionId, reason) {
    return axios.put(`${prefix}/${auctionId}/cancel`, { reason });
  }

  static delete(auctionId) {
    return axios.delete(`${prefix}/${auctionId}/delete`);
  }

  static getUserParticipantData(id) {
    return axios
      .get(`${prefix}/${id}/getUserParticipantData`)
      .then(result => result.data);
  }

  static getCompanyParticipantData(id) {
    return axios
      .get(`${prefix}/${id}/getCompanyParticipantData`)
      .then(result => result.data);
  }

  static getAuctioneers(id) {
    return axios.get(`${prefix}/${id}/auctioneers`).then(result => result.data);
  }
}
