import React from 'react';
import PropTypes from 'prop-types';
import InnerContentWrapper from 'hh-shared/dist/components/layout/InnerContentWrapper';
import createFoxGridContainer from 'fox-react/dist/components/FoxGrid/createFoxGridContainer';
import gridNames from 'common/gridNames';
import { Link } from 'react-router-dom';
import FoxButton from 'fox-react/dist/components/FoxButton';
import lang from 'hh-shared/dist/language/services/languageService';
import columnNames from 'common/columnNames';
import FoxGridColumn from 'fox-react/dist/components/FoxGrid/FoxGridColumn';
import { useDispatch } from 'react-redux';
import { getForGrid } from './actions';

const propTypes = {
  match: PropTypes.object.isRequired,
};

const defaultProps = {};

const FoxGridContainer = createFoxGridContainer(gridNames.zipCodesManagement);

function ZipCodesManagementPage({ match }) {
  const dispatch = useDispatch();

  const onLoadData = dataState => dispatch(getForGrid(dataState));

  return (
    <InnerContentWrapper fullWidth>
      <FoxGridContainer
        onLoadData={onLoadData}
        defaultSortField={columnNames.zipCodesManagement.zipCode}
        renderButtons={
          <Link to={`${match.url}/add`}>
            <FoxButton icon="add" primary>
              {lang.buttons.Add()}
            </FoxButton>
          </Link>
        }
      >
        <FoxGridColumn
          filterable={false}
          groupable={false}
          cell={({ dataItem }) => (
            <Link to={`${match.url}/edit/${dataItem.id}`}>
              <FoxButton icon="edit">{lang.buttons.Edit()}</FoxButton>
            </Link>
          )}
        />
        <FoxGridColumn
          field={columnNames.zipCodesManagement.zipCode}
          title={lang.labels.ZipCode()}
          filter="text"
        />
        <FoxGridColumn
          field={columnNames.zipCodesManagement.city}
          title={lang.labels.City()}
          filter="text"
        />
      </FoxGridContainer>
    </InnerContentWrapper>
  );
}

ZipCodesManagementPage.propTypes = propTypes;
ZipCodesManagementPage.defaultProps = defaultProps;

export default ZipCodesManagementPage;
