const formNames = {
  loginForm: 'loginForm',
  resetPasswordForm: 'resetPasswordForm',
  setNewPasswordForm: 'setNewPasswordForm',
  userInformationForm: 'userInformationForm',
  myProfileForm: 'myProfileForm',
  userPasswordChangeForm: 'userPasswordChangeForm',
  htmlTemplateInformationForm: 'htmlTemplateInformationForm',
  smsTemplateInformationForm: 'smsTemplateInformationForm',
  departmentInformationForm: 'departmentInformationForm',
  companyInformationForm: 'companyInformationForm',
  carTypeInformationForm: 'carTypeInformationForm',
  carInformationForm: 'carInformationForm',
  facilityInformationForm: 'facilityInformationForm',
  routeInformationForm: 'routeInformationForm',
  currencyInformationForm: 'currencyInformationForm',
  auctionInformationForm: 'auctionInformationForm',
  transportOrderInformationForm: 'transportOrderInformationForm',
  addressInformationForm: 'addressInformationForm',
  defaultPriceInformationForm: 'defaultPriceInformationForm',
  zipCodeForm: 'zipCodeForm',
  simpleAddUserForm: 'simpleAddUserForm',
};

export default formNames;
