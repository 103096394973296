import {
  HubConnectionBuilder,
  LogLevel,
  HubConnectionState,
} from '@aspnet/signalr';

import { getAuctionData, addAuctionBid } from 'auction/actions';
import get from 'lodash/get';
import store from 'store/store';
import config from '../config/config';
import events from './events';
import auctionsManagementTypes from '../auctions-management/actionTypes';
import transportOrderActionTypes from '../transport-orders-management/TransportOrderPreviewPage/actionTypes';
import sideMenuActionTypes from '../layout/Menu/sideMenuActionTypes';
import actionTypes from './actionTypes';

/* eslint-disable consistent-return */
const onUpdateSidebarCounters = next => {
  next({
    type: sideMenuActionTypes.UPDATE_SIDEBAR_COUNTERS,
  });
};

const onUpdateTransportOrderStatusHistory = (res, next) => {
  next({
    type: transportOrderActionTypes.SET_TRANSPORT_ORDER_STATUS_HISTORY,
    payload: res,
  });
};

const onUpdateAuctionBids = (res, next) => {
  next({
    type: auctionsManagementTypes.AUCTIONS_LIVE_AUCTIONS_UPDATE,
    payload: res,
  });

  const auctionId = get(store.getState(), 'auction.auctionMetric.id');
  if (auctionId === res.auctionId) {
    if (res.auctionClose) {
      store.dispatch(getAuctionData(auctionId));
    }
    next(addAuctionBid(res));
  }
};

const startSignalRConnection = connection => connection.start().then();

const signalRMiddleware = () => next => async action => {
  if (action.type === actionTypes.CONNECT_TO_SIGNALR) {
    const connectionHub = `${config.clientApiUrl}${config.liveHub}`;
    const options = {
      logger: LogLevel.Warning,
      accessTokenFactory: () => `Bearer ${localStorage.getItem('accessToken')}`,
    };

    const connection = new HubConnectionBuilder()
      .withUrl(connectionHub, options)
      .build();

    connection.on(events.UpdateAuctionBids, res =>
      onUpdateAuctionBids(res, next),
    );

    connection.on(events.UpdateTransportOrderStatusHistory, res =>
      onUpdateTransportOrderStatusHistory(res, next),
    );

    connection.on(events.UpdateSidebarCounters, () =>
      onUpdateSidebarCounters(next),
    );

    connection.onclose(() =>
      setTimeout(startSignalRConnection(connection), 5000),
    );

    if (connection.state === HubConnectionState.Disconnected) {
      startSignalRConnection(connection);
    }
  }

  if (action.type) return next(action);
};

export default signalRMiddleware;
