import React, { PureComponent } from 'react';
import lang from 'hh-shared/dist/language/services/languageService';
import { PropTypes } from 'prop-types';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import UserInformationForm from './AddUserInformationForm';
import UsersManagementSrv from './UsersManagementSrv';
import UsersManagementPagePreview from './UsersManagementPagePreview';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

class UsersManagementAddPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false,
      facilities: [],
      departments: [],
    };
    this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
  }

  componentDidMount() {
    this.setState({ showSpinner: true });

    Promise.all([
      UsersManagementSrv.getFacilitiesAsSelectItem(),
      UsersManagementSrv.getCompaniesAsSelectItems(),
      UsersManagementSrv.getDepartmentsAsSelectItems(),
    ])
      .then(([facilities, companies, departments]) => {
        this.setState({
          facilities,
          companies,
          departments,
        });
      })
      .finally(() => this.setState({ showSpinner: false }));
  }

  onSubmitSuccess() {
    this.props.history.goBack();
  }

  render() {
    const { onSubmit } = this.props;
    const { showSpinner, facilities, companies, departments } = this.state;

    return (
      <>
        <BreadcrumbsContainer
          links={[
            {
              routePath: '/',
              label: lang.labels.HomePage(),
            },
            {
              routePath: '/users-management',
              label: lang.labels.Users(),
            },
            {
              routePath: '/users-management/add',
              label: lang.labels.AddingNewUser(),
            },
          ]}
        />
        <FormPreviewSectionContainer>
          <FormCardWrapper>
            <div className={showSpinner ? 'fox_spinner' : ''}>
              <UserInformationForm
                onSubmit={onSubmit}
                onSubmitSuccess={this.onSubmitSuccess}
                facilities={facilities}
                companies={companies}
                departments={departments}
              />
            </div>
          </FormCardWrapper>
          <UsersManagementPagePreview />
        </FormPreviewSectionContainer>
      </>
    );
  }
}

UsersManagementAddPage.propTypes = propTypes;
UsersManagementAddPage.defaultProps = defaultProps;

export default UsersManagementAddPage;
