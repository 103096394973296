import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './ChartHeader.module.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: undefined,
};

const ChartHeader = ({ children, className }) => {
  const headerClassName = cx(styles.header, className);

  return <h2 className={headerClassName}>{children}</h2>;
};

ChartHeader.propTypes = propTypes;
ChartHeader.defaultProps = defaultProps;

export default memo(ChartHeader);
