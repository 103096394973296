import axios from 'config/axios';

const prefix = 'carTypesManagement';

export default class CarTypesManagementSrv {
  static getForGrid(dataState) {
    return axios
      .post(`${prefix}/getForGrid`, dataState)
      .then(result => result.data);
  }

  static get(id) {
    return axios.get(`${prefix}/${id}`).then(result => result.data);
  }

  static add(model) {
    return axios.post(prefix, model);
  }

  static update(model) {
    return axios.put(prefix, model);
  }
}
