import { connect } from 'react-redux';
import get from 'lodash/get';
import TransportOrdersManagementPage from './TransportOrdersManagementPage';
import { getForGrid } from './actions';
import gridNames from '../common/gridNames';

const mapStateToProps = state => {
  return {
    userHasFacilityDefined: !!get(state, 'auth.currentUser.facilityInstanceId'),
    dataState: state[gridNames.transportOrdersManagement].dataState,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoadData: dataState => dispatch(getForGrid(dataState)),
  };
};

const TransportOrdersManagementPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransportOrdersManagementPage);

export default TransportOrdersManagementPageContainer;
