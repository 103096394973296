import axios from 'config/axios';

const prefix = 'zipCodesManagement';

export default class ZipCodesManagementSrv {
  static getForGrid(dataState) {
    return axios
      .post(`${prefix}/getForGrid`, dataState)
      .then(result => result.data);
  }

  static get(id) {
    return axios.get(`${prefix}/${id}`).then(result => result.data);
  }

  static add(model) {
    return axios.post(prefix, model);
  }

  static update(model) {
    return axios.put(prefix, model);
  }

  static delete(id) {
    return axios.delete(`${prefix}/${id}`);
  }
}
