import ExternalContentSrv from '../ExternalContentSrv';

export function calculateEditType(path) {
  if (path === '/html-templates-management/edit-content/:id') {
    return 'content';
  }

  if (path === '/html-templates-management/edit-emailContent/:id') {
    return 'emailContent';
  }

  if (path === '/html-templates-management/edit-smsContent/:id') {
    return 'smsContent';
  }

  return undefined;
}

export function uploadHtmlExternalContent(blobInfo, success, fail) {
  const blob = blobInfo.blob();
  const fileName = blobInfo.filename();
  ExternalContentSrv.upload(blob, fileName)
    .then(res => {
      const location = `externalContent/${res.data}`;
      success(location);
    })
    .catch(() => fail());
}
