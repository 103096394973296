import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import FoxSwitch from 'fox-react/dist/components/FoxSwitch';
import langs from 'hh-shared/dist/language/services/languageService';

import styles from './OnlyMyFacilitySwitch.module.scss';

const propTypes = {
  onlyMyFacility: PropTypes.bool.isRequired,
  toggleOnlyMyFacility: PropTypes.func.isRequired,
  onChangeCallback: PropTypes.func,
};

const defaultProps = {
  onChangeCallback: () => {},
};

function OnlyMyFacilitySwitch({
  onlyMyFacility,
  toggleOnlyMyFacility,
  onChangeCallback,
}) {
  useEffect(() => {
    onChangeCallback();
    // we don't want to change on every onChangeCallback
    // eslint-disable-next-line
  }, [onlyMyFacility]);

  return (
    <span className={styles.switch_container}>
      <span className={styles.switch_description}>
        {langs.labels.OnlyMyFacility()}
      </span>
      <FoxSwitch value={onlyMyFacility} onChange={toggleOnlyMyFacility} />
    </span>
  );
}

OnlyMyFacilitySwitch.propTypes = propTypes;
OnlyMyFacilitySwitch.defaultProps = defaultProps;

export default memo(OnlyMyFacilitySwitch);
