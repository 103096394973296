import DictionarySrv from './DictionarySrv';
import dictionaryActionTypes from './actionTypes';

export function getStaticDictionaries() {
  return function(dispatch) {
    return DictionarySrv.getStaticDictionaries().then(results => {
      dispatch({
        type: dictionaryActionTypes.STORE_STATIC_DICTIONARIES,
        data: results,
      });
    });
  };
}

export function getBigStaticDictionaries() {
  return function(dispatch) {
    return DictionarySrv.getBigStaticDictionaries().then(results => {
      dispatch({
        type: dictionaryActionTypes.STORE_BIG_STATIC_DICTIONARIES,
        data: results,
      });
    });
  };
}
