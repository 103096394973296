import notificationTemplateAction from './actionTypes';

const initialState = {
  isLoading: true,
  template: {
    emailContent: '<!DOCTYPE html>\n<html>\n<body>\n\n</body>\n</html>',
  },
};

const editTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case notificationTemplateAction.NOTIFICATION_EDIT_SET_TEMPLATE:
      return {
        ...state,
        isLoading: false,
        template: {
          ...action.payload,
          emailContent:
            action.payload.emailContent || state.template.emailContent,
        },
      };
    default:
      return state;
  }
};

export default editTemplateReducer;
