import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveCalendar } from '@nivo/calendar';
import { months } from 'common';
import moment from 'moment';
import styles from './YearsCallendar.module.scss';

const propTypes = {
  data: PropTypes.array.isRequired,
  diagramProps: PropTypes.object.isRequired,
};

const defaultProps = {};

const monthLegend = (_year, month) => months[month];

const getYearsDiff = (from, to) => moment(to).diff(from, 'year') + 1;
const getCallendarHeight = yearsQuantity => {
  const cssVariableName = '--callendar-height';
  return `calc(${yearsQuantity} * var(${cssVariableName}))`;
};

function YearsCallendar({ data, diagramProps }) {
  const { from, to } = diagramProps;
  const yearsDiff = getYearsDiff(from, to);
  const chartHeight = getCallendarHeight(yearsDiff);

  return (
    <div style={{ height: chartHeight }} className={styles.callendarWrapper}>
      <ResponsiveCalendar
        data={data}
        emptyColor="#eeeeee"
        colors={['#00ffd7', '#00d6b5', '#00af94', '#008a74', '#006656']}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        yearSpacing={40}
        monthBorderColor="#ffffff"
        dayBorderWidth={2}
        monthSpacing={22}
        dayBorderColor="#ffffff"
        monthLegend={monthLegend}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'row',
            translateY: 36,
            itemCount: 4,
            itemWidth: 42,
            itemHeight: 36,
            itemsSpacing: 14,
            itemDirection: 'right-to-left',
          },
        ]}
        {...diagramProps}
      />
    </div>
  );
}

YearsCallendar.propTypes = propTypes;
YearsCallendar.defaultProps = defaultProps;

export default memo(YearsCallendar);
