import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import CarTypeInformationForm from './CarTypeInformationForm';
import CarTypesManagementPagePreview from './CarTypesManagementPagePreview';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

class CarTypesManagementAddPage extends PureComponent {
  render() {
    const { onSubmit, history } = this.props;

    return (
      <>
        <BreadcrumbsContainer
          links={[
            {
              routePath: '/',
              label: lang.labels.HomePage(),
            },
            {
              routePath: '/car-types-management',
              label: lang.labels.CarTypes(),
            },
            {
              routePath: '/car-types-management/add',
              label: lang.labels.AddingNewCarType(),
            },
          ]}
        />
        <FormPreviewSectionContainer>
          <FormCardWrapper>
            <CarTypeInformationForm onSubmit={onSubmit} history={history} />
          </FormCardWrapper>
          <CarTypesManagementPagePreview />
        </FormPreviewSectionContainer>
      </>
    );
  }
}

CarTypesManagementAddPage.propTypes = propTypes;
CarTypesManagementAddPage.defaultProps = defaultProps;

export default CarTypesManagementAddPage;
