import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { diagramData } from 'dashboard/selectors';
import YearsCallendar from './YearsCallendar';
import { diagramYearFrom } from 'dashboard/config';

const propTypes = {};

const defaultProps = {};

const from = moment([diagramYearFrom])
  .startOf('year')
  .format('yyyy-MM-DD');

const to = moment()
  .endOf('year')
  .format('yyyy-MM-DD');

function YearsCallendarContainer() {
  const realizedDays = useSelector(diagramData) || [];

  const diagramProps = { from, to };

  return <YearsCallendar data={realizedDays} diagramProps={diagramProps} />;
}

YearsCallendarContainer.propTypes = propTypes;
YearsCallendarContainer.defaultProps = defaultProps;

export default memo(YearsCallendarContainer);
