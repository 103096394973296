import { gridStoreResults } from 'fox-react/dist/components/FoxGrid/actions';
import gridNames from 'common/gridNames';
import AddressesManagementSrv from './AddressesManagementSrv';

export function storeResults(results) {
  return gridStoreResults(gridNames.addressesManagement)(results);
}

export function getForGrid(dataState) {
  return function(dispatch) {
    return AddressesManagementSrv.getForGrid(dataState).then(results => {
      dispatch(storeResults(results));
    });
  };
}

export function addAsync(model) {
  return function() {
    return AddressesManagementSrv.add(model);
  };
}

export function updateAsync(model) {
  return function() {
    return AddressesManagementSrv.update(model);
  };
}
