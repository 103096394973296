import { connect } from 'react-redux';
import FacilitiesManagementPage from './FacilitiesManagementPage';
import { getForGrid } from './actions';

const mapDispatchToProps = dispatch => {
  return {
    onLoadData: dataState => dispatch(getForGrid(dataState)),
  };
};

const FacilitiesManagementPageContainer = connect(
  undefined,
  mapDispatchToProps,
)(FacilitiesManagementPage);

export default FacilitiesManagementPageContainer;
