import { connect } from 'react-redux';
import UsersManagementAddPage from './UsersManagementAddPage';
import { addAsync } from './actions';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = () => {
  return {
    onSubmit: model => {
      if (model.permissions) {
        if (model.permissions.EMPLOYEE) {
          model.company = null;
        }
        if (model.permissions.LOGISTICIAN) {
          model.department = null;
          model.facility = null;
        }
      }
      return addAsync({ ...model });
    },
  };
};

const UsersManagementAddPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsersManagementAddPage);

export default UsersManagementAddPageContainer;
