import { connect } from 'react-redux';
import CurrenciesManagementAddPage from './CurrenciesManagementAddPage';
import { addAsync } from './actions';

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: model => dispatch(addAsync(model)),
  };
};

const CurrenciesManagementAddPageContainer = connect(
  undefined,
  mapDispatchToProps,
)(CurrenciesManagementAddPage);

export default CurrenciesManagementAddPageContainer;
