import htmlTemplateIds from 'hh-shared/dist/consts/htmlTemplateIds';

const editContentHtmls = [
  htmlTemplateIds.TransportOrderAdditionalRequirements,
  htmlTemplateIds.TransportOrder,
  htmlTemplateIds.CompanyInformation,
];

const editEmailHtmls = [
  htmlTemplateIds.ResetPassword,
  htmlTemplateIds.SetNewPassword,
  htmlTemplateIds.AuctionTookToRealization,
  htmlTemplateIds.AuctionBidOfferSet,
  htmlTemplateIds.AuctionPublished,
  htmlTemplateIds.AuctionBiddedByAnotherCompany,
  htmlTemplateIds.AuctionCloseToClose,
  htmlTemplateIds.AuctionCancelled,
  htmlTemplateIds.AuctionClosed,
  htmlTemplateIds.TransportOrderChangeOnAcceptForImplementation,
  htmlTemplateIds.TransportOrderCreated,
  htmlTemplateIds.UserAccountBanned,
  htmlTemplateIds.TransportOrderCanceled,
  htmlTemplateIds.TransportOrderRealized,
  htmlTemplateIds.TransportOrderLoaded,
  htmlTemplateIds.InviteToPlatform,
  htmlTemplateIds.NewCompanyRequest,
  htmlTemplateIds.CompanyActivated,
];

const editSmsContentHtmls = [
  htmlTemplateIds.AuctionTookToRealization,
  htmlTemplateIds.AuctionBidOfferSet,
  htmlTemplateIds.AuctionPublished,
  htmlTemplateIds.AuctionBiddedByAnotherCompany,
  htmlTemplateIds.AuctionCloseToClose,
  htmlTemplateIds.AuctionCancelled,
  htmlTemplateIds.AuctionClosed,
  htmlTemplateIds.TransportOrderCreated,
  htmlTemplateIds.UserAccountBanned,
  htmlTemplateIds.TransportOrderCanceled,
  htmlTemplateIds.TransportOrderRealized,
  htmlTemplateIds.TransportOrderLoaded,
];

export const isEditContentButtonVisible = id => editContentHtmls.includes(id);
export const isEditEmailContentButtonVisible = id =>
  editEmailHtmls.includes(id);
export const isEditSmsContentButtonVisible = id =>
  editSmsContentHtmls.includes(id);
