import { connect } from 'react-redux';
import FacilitiesManagementEditPage from './FacilitiesManagementEditPage';
import { updateAsync } from './actions';

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: model => dispatch(updateAsync(model)),
  };
};

const FacilitiesManagementEditPageContainer = connect(
  undefined,
  mapDispatchToProps,
)(FacilitiesManagementEditPage);

export default FacilitiesManagementEditPageContainer;
