import { connect } from 'react-redux';
import CurrenciesManagementPage from './CurrenciesManagementPage';
import { getForGrid } from './actions';

const mapDispatchToProps = dispatch => {
  return {
    onLoadData: dataState => dispatch(getForGrid(dataState)),
  };
};

const CurrenciesManagementPageContainer = connect(
  undefined,
  mapDispatchToProps,
)(CurrenciesManagementPage);

export default CurrenciesManagementPageContainer;
