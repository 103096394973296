import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import { addAsync } from './actions';
import AddDepartmentInformationForm from './AddDepartmentInformationForm';
import DepartmentsManagementPagePreview from './DepartmentsManagementPagePreview';

const propTypes = {
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

function DepartmentsManagementAddPage({ history }) {
  const dispatch = useDispatch();
  const onSubmit = model => dispatch(addAsync(model));
  const zipCodes = useSelector(state => state.dictionary.static.zipCodes);

  return (
    <>
      <BreadcrumbsContainer
        links={[
          {
            routePath: '/',
            label: lang.labels.HomePage(),
          },
          {
            routePath: '/departments',
            label: lang.labels.Departments(),
          },
          {
            routePath: '/departments/add',
            label: lang.labels.AddingNewDepartment(),
          },
        ]}
      />
      <FormPreviewSectionContainer>
        <FormCardWrapper>
          <AddDepartmentInformationForm
            onSubmit={onSubmit}
            history={history}
            zipCodes={zipCodes}
          />
        </FormCardWrapper>
        <DepartmentsManagementPagePreview />
      </FormPreviewSectionContainer>
    </>
  );
}

DepartmentsManagementAddPage.propTypes = propTypes;
DepartmentsManagementAddPage.defaultProps = defaultProps;

export default DepartmentsManagementAddPage;
