import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import DefaultPriceInformationForm from './DefaultPriceInformationForm';
import DefaultPricesManagementSrv from './DefaultPricesManagementSrv';
import DefaultPriceManagementPagePreview from './DefaultPriceManagementPagePreview';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

const DefaultPricesManagementAddPage = ({ onSubmit, history }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [facilities, setFacilities] = useState([]);

  useEffect(() => {
    setShowSpinner(true);

    DefaultPricesManagementSrv.getFacilitiesAsSelectItem()
      .then(results => setFacilities(results))
      .finally(() => setShowSpinner(false));
  }, []);

  return (
    <>
      <BreadcrumbsContainer
        links={[
          {
            routePath: '/',
            label: lang.labels.HomePage(),
          },
          {
            routePath: '/default-prices-management',
            label: lang.labels.DefaultPrices(),
          },
          {
            routePath: '/default-prices-management/add',
            label: lang.labels.AddingNewDefaultPrice(),
          },
        ]}
      />
      <FormPreviewSectionContainer>
        <FormCardWrapper>
          <div className={showSpinner ? 'fox_spinner' : ''}>
            <DefaultPriceInformationForm
              onSubmit={onSubmit}
              history={history}
              facilities={facilities}
            />
          </div>
        </FormCardWrapper>
        <DefaultPriceManagementPagePreview />
      </FormPreviewSectionContainer>
    </>
  );
};

DefaultPricesManagementAddPage.propTypes = propTypes;
DefaultPricesManagementAddPage.defaultProps = defaultProps;

export default DefaultPricesManagementAddPage;
