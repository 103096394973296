import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, change } from 'redux-form';
import lang from 'hh-shared/dist/language/services/languageService';
import {
  scrollToTheFirstError,
  displayCompletionToasts,
} from 'hh-shared/dist/consts/reduxFormMethods';
import fieldNames from 'common/fieldNames';
import formNames from 'common/formNames';
import icons from 'hh-shared/dist/consts/icons';
import NewAddressForm from 'commons/NewAddressForm';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';
import Form from 'fox-react/dist/components/Form';
import FoxButton from 'fox-react/dist/components/FoxButton';
import { isRequiredArray } from 'fox-react/dist/utils/formValidators';
import goBackAfterSuccess from 'fox-react/dist/utils/goBackAfterSuccess';
import { fieldChanged } from 'fox-react/dist/utils/formHelpers';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  onSubmitAsNew: PropTypes.func,
};

const defaultProps = {
  onSubmitAsNew: undefined,
};

function AddressInformationForm({ handleSubmit, submitting, onSubmitAsNew }) {
  const submitAsNew = () => {
    handleSubmit(onSubmitAsNew)();
  };

  return (
    <div className="form-container">
      <Form handleSubmit={handleSubmit}>
        <fieldset>
          <SectionWrapper>
            <FormSectionCard
              title={lang.labels.AddressInformations()}
              titleIcon={icons.address}
            >
              <NewAddressForm />
            </FormSectionCard>
          </SectionWrapper>
        </fieldset>
        <FoxButton primary disabled={submitting}>
          {lang.buttons.Save()}
        </FoxButton>
        {onSubmitAsNew && (
          <FoxButton
            type="button"
            primary
            disabled={submitting}
            onClick={submitAsNew}
          >
            {lang.buttons.SaveAsNew()}
          </FoxButton>
        )}
      </Form>
    </div>
  );
}

AddressInformationForm.propTypes = propTypes;
AddressInformationForm.defaultProps = defaultProps;

const validate = values => {
  const errors = {};

  isRequiredArray(
    errors,
    values,
    [
      fieldNames.newAddressInformationForm.zipCode,
      fieldNames.newAddressInformationForm.city,
      fieldNames.newAddressInformationForm.street,
    ],
    lang.validationMessages.FieldRequired(),
  );

  return errors;
};

function onChange(values, dispatch, props, previousValues) {
  if (!props.dirty) {
    return;
  }

  if (fieldChanged(values, previousValues, 'zipCode')) {
    const zipCode = props.zipCodes.find(x => x.code === values.zipCode);
    if (zipCode) {
      dispatch(change(formNames.addressInformationForm, 'city', zipCode.city));
    }
  }
}

const onSubmitSuccess = (result, dispatch, props) =>
  displayCompletionToasts(
    dispatch,
    !!props.isEditForm,
    lang.labels.SuccessfullyModificatedAddress(),
    lang.labels.SuccessfullyCreatedAddress(),
  );

export default reduxForm({
  form: formNames.addressInformationForm,
  validate,
  enableReinitialize: true,
  onSubmitFail: scrollToTheFirstError,
  onChange,
  onSubmitSuccess,
})(goBackAfterSuccess(AddressInformationForm));
