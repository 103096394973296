import React from 'react';
import lang from 'hh-shared/dist/language/services/languageService';
import Badge from 'hh-shared/dist/components/commons/Badge';
import auctionStatusesIds from 'hh-shared/dist/utils/auctionStatusesIds';
import PropTypes from 'prop-types';

const propTypes = {
  label: PropTypes.string.isRequired,
  statusId: PropTypes.string.isRequired,
  currentPriceWithoutOffer: PropTypes.bool.isRequired,
};

const defaultProps = {};

const AuctionStatusLabel = ({
  label,
  statusId,
  currentPriceWithoutOffer,
  ...others
}) => {
  const { draft, live, forPublication, closed, canceled } = auctionStatusesIds;

  const getCurrentBadgeText = () => {
    if (statusId === closed) {
      return !currentPriceWithoutOffer
        ? lang.labels.WithSuccess()
        : lang.labels.WithoutSuccess();
    } else if (statusId === canceled) {
      return lang.labels.Canceled();
    }
    return label;
  };

  const getBadgeColor = () => {
    switch (statusId) {
      case draft:
        return { grey: true };
      case live:
        return { blue: true };
      case forPublication:
        return { grey: true };
      case closed:
        return !currentPriceWithoutOffer
          ? { customGreen: true }
          : { red: true };
      case canceled:
        return { orange: true };
      default:
        return { grey: true };
    }
  };

  return (
    <Badge
      label={getCurrentBadgeText()}
      {...getBadgeColor()}
      className="margin-auto"
      opaque
      smaller
      offsetTop
      {...others}
    />
  );
};

AuctionStatusLabel.propTypes = propTypes;
AuctionStatusLabel.defaultProps = defaultProps;

export default AuctionStatusLabel;
