import React from 'react';
import { PropTypes } from 'prop-types';
import { reduxForm } from 'redux-form';
import Form from 'fox-react/dist/components/Form';
import FoxInputTextField from 'fox-react/dist/reduxFormFields/FoxInputTextField';
import FoxInputEmailField from 'fox-react/dist/reduxFormFields/FoxInputEmailField';
import FoxButton from 'fox-react/dist/components/FoxButton';
import lang from 'hh-shared/dist/language/services/languageService';
import {
  isRequiredFieldValidator,
  isEmailFieldValidator,
} from 'hh-shared/dist/utils/formValidators';
import {
  scrollToTheFirstError,
  displayCompletionToast,
} from 'hh-shared/dist/consts/reduxFormMethods';
import fieldNames from 'common/fieldNames';
import formNames from 'common/formNames';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const defaultProps = {};

const SimpleAddUserForm = ({ handleSubmit, submitting }) => {
  return (
    <Form handleSubmit={handleSubmit}>
      <fieldset>
        <FoxInputEmailField
          label={lang.labels.Email()}
          placeholder={lang.labels.Email()}
          name={fieldNames.simpleAddUserForm.email}
          validate={[isRequiredFieldValidator, isEmailFieldValidator]}
        />
        <FoxInputTextField
          label={lang.labels.FirstName()}
          placeholder={lang.labels.FirstName()}
          name={fieldNames.simpleAddUserForm.firstName}
          validate={isRequiredFieldValidator}
        />
        <FoxInputTextField
          label={lang.labels.LastName()}
          placeholder={lang.labels.LastName()}
          name={fieldNames.simpleAddUserForm.lastName}
          validate={isRequiredFieldValidator}
        />
      </fieldset>
      <FoxButton primary disabled={submitting}>
        {lang.buttons.Send()}
      </FoxButton>
    </Form>
  );
};

SimpleAddUserForm.propTypes = propTypes;
SimpleAddUserForm.defaultProps = defaultProps;

const onSubmitSuccess = (_result, dispatch) =>
  displayCompletionToast(
    dispatch,
    lang.labels.SuccessfullySendInvitationToPlatform(),
  );

export default reduxForm({
  form: formNames.simpleAddUserForm,
  onSubmitFail: scrollToTheFirstError,
  onSubmitSuccess,
})(SimpleAddUserForm);
