import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { isRequired } from 'fox-react/dist/utils/formValidators';
import Form from 'fox-react/dist/components/Form';
import FoxInputTextField from 'fox-react/dist/reduxFormFields/FoxInputTextField';
import FoxButton from 'fox-react/dist/components/FoxButton';
import FoxAvatarEditField from 'fox-react/dist/reduxFormFields/FoxAvatarEditField';
import FormField from 'fox-react/dist/components/FormField';
import lang from 'hh-shared/dist/language/services/languageService';
import { scrollToTheFirstError } from 'hh-shared/dist/consts/reduxFormMethods';
import AvatarPlaceholder from 'hh-shared/dist/components/commons/AvatarPlaceholder';
import icons from 'hh-shared/dist/consts/icons';
import fieldNames from 'common/fieldNames';
import formNames from 'common/formNames';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';
import styles from './UserInformationForm.module.scss';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  avatar: PropTypes.string,
};

const defaultProps = {
  avatar: undefined,
};

function UserInformationForm({ handleSubmit, submitting, firstName, avatar }) {
  const [editAvatar, setEditAvatar] = useState(false);

  return (
    <Form handleSubmit={handleSubmit}>
      <fieldset>
        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.UserInformations()}
            titleIcon={icons.userData}
            fullContentWidth
          >
            <FormField label={lang.labels.Avatar()} />
            {editAvatar ? (
              <FoxAvatarEditField
                name={fieldNames.myProfileUserInformationForm.avatar}
                placeholder={lang.labels.Avatar()}
                onClose={() => setEditAvatar(false)}
              />
            ) : (
              <>
                <div className={`${styles.avatar_container} avatar_container`}>
                  {avatar ? (
                    <img src={avatar} alt="avatar" />
                  ) : (
                    <AvatarPlaceholder label={firstName.charAt(0)} />
                  )}
                  <div
                    className={styles.avatar_edit_button}
                    onClick={() => setEditAvatar(true)}
                    data-label={lang.buttons.Edit()}
                  />
                </div>
              </>
            )}

            <FoxInputTextField
              name={fieldNames.myProfileUserInformationForm.login}
              label={lang.labels.Login()}
              placeholder={lang.labels.Login()}
            />
            <FoxButton disabled={submitting} primary>
              {lang.buttons.Update()}
            </FoxButton>
          </FormSectionCard>
        </SectionWrapper>
      </fieldset>
    </Form>
  );
}

UserInformationForm.propTypes = propTypes;
UserInformationForm.defaultProps = defaultProps;

const validate = values => {
  const errors = {};

  isRequired(
    errors,
    values,
    fieldNames.myProfileUserInformationForm.login,
    lang.validationMessages.FieldRequired(),
  );

  return errors;
};

export default reduxForm({
  form: formNames.myProfileForm,
  validate,
  enableReinitialize: true,
  onSubmitFail: scrollToTheFirstError,
})(UserInformationForm);
