import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import auctionStatusIds from 'hh-shared/dist/utils/auctionStatusesIds';
import { getForGrid as getForGridAuctionsManagement } from 'auctions-management/actions';
import auctionActionTypes from 'auctions-management/actionTypes';
import {
  auctionOngoingFilter,
  auctionAllFilter,
  auctionCanceledFilter,
  auctionWithoutSuccessFilter,
  auctionWithSuccessFilter,
  auctionDraftFilter,
} from 'auctions-management/filters';
import PropTypes from 'prop-types';
import AuctionStatusCell from './AuctionStatusCell';

const propTypes = {
  statusId: PropTypes.string.isRequired,
  currentPriceWithoutOffer: PropTypes.bool.isRequired,
};

const defaultProps = {};

const AuctionStatusClickableCell = ({
  statusId,
  currentPriceWithoutOffer,
  ...others
}) => {
  const { draft, live, forPublication, closed, canceled } = auctionStatusIds;
  const dispatch = useDispatch();

  const auctionDataState = useSelector(
    state => state.auctionsManagement.dataState,
  );

  const changeFilter = filter => {
    dispatch(
      getForGridAuctionsManagement({
        ...auctionDataState,
        filter,
        skip: 0,
      }),
    );
    dispatch({
      type: auctionActionTypes.AUCTIONS_MANAGEMENT_SET_FILTER,
      payload: filter,
    });
  };

  const dispatchOngoing = () => changeFilter(auctionOngoingFilter);

  const dispatchDraft = () => changeFilter(auctionDraftFilter);

  const dispatchAll = () => changeFilter(auctionAllFilter);

  const dispatchCanceled = () => changeFilter(auctionCanceledFilter);

  const dispatchWithSuccess = () => changeFilter(auctionWithSuccessFilter);

  const dispatchWithoutSuccess = () =>
    changeFilter(auctionWithoutSuccessFilter);

  const getBadgeFilter = () => {
    switch (statusId) {
      case draft:
        return dispatchDraft;
      case live:
        return dispatchOngoing;
      case forPublication:
        return dispatchDraft;
      case closed:
        return !currentPriceWithoutOffer
          ? dispatchWithSuccess
          : dispatchWithoutSuccess;
      case canceled:
        return dispatchCanceled;
      default:
        return dispatchAll;
    }
  };

  const [currentFilter, setCurrentFilter] = useState(() => getBadgeFilter());

  useEffect(() => {
    setCurrentFilter(() => getBadgeFilter());
    // eslint-disable-next-line
  }, [statusId]);

  return (
    <AuctionStatusCell
      onClick={currentFilter}
      {...{ statusId, currentPriceWithoutOffer }}
      {...others}
    />
  );
};

AuctionStatusClickableCell.propTypes = propTypes;
AuctionStatusClickableCell.defaultProps = defaultProps;

export default AuctionStatusClickableCell;
