import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import CompanyInformationForm from './CompanyInformationForm';
import CompanyManagementPagePreview from './CompanyManagementPagePreview';
import { addAsync } from './actions';
import CarsManagementSrv from '../cars-management/CarsManagementSrv';

const propTypes = {
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

function CompanyManagementAddPage({ history }) {
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(true);
  const [carTypes, setCarTypes] = useState([]);

  useEffect(() => {
    setShowSpinner(true);

    CarsManagementSrv.getCarTypesAsSelectItems()
      .then(result => setCarTypes(result))
      .finally(() => setShowSpinner(false));
  }, []);

  const onSubmit = model => dispatch(addAsync(model));

  return (
    <>
      <BreadcrumbsContainer
        links={[
          {
            routePath: '/',
            label: lang.labels.HomePage(),
          },
          {
            routePath: '/companies-management',
            label: lang.labels.Company(),
          },
          {
            routePath: '/companies-management/add',
            label: lang.labels.AddingNewCompany(),
          },
        ]}
      />
      <FormPreviewSectionContainer>
        <FormCardWrapper>
          <div className={cx({ fox_spinner: showSpinner })}>
            <CompanyInformationForm
              onSubmit={onSubmit}
              history={history}
              carTypes={carTypes}
            />
          </div>
        </FormCardWrapper>
        <CompanyManagementPagePreview />
      </FormPreviewSectionContainer>
    </>
  );
}

CompanyManagementAddPage.propTypes = propTypes;
CompanyManagementAddPage.defaultProps = defaultProps;

export default CompanyManagementAddPage;
