import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

import AuctionStatusClickableCell from 'commons/AuctionStatusClickableCell';

import lang from 'hh-shared/dist/language/services/languageService';
import { useImmediateInterval } from 'hh-shared/dist/consts/customHooks';
import { getTwoDigitRepresentation } from 'hh-shared/dist/consts/numberFormating';
import { calculateAuctionTime } from 'hh-shared/dist/utils/auctionTimeCalculator';

const propTypes = {
  dataItem: PropTypes.object.isRequired,
};

const defaultProps = {};

const getTimeTo = time => {
  const { days, hours, minutes, seconds } = time;
  return `${days} ${
    days !== 1
      ? lang.labels.Days().toLowerCase()
      : lang.labels.Day().toLowerCase()
  } ${hours} h ${getTwoDigitRepresentation(
    minutes,
  )} min ${getTwoDigitRepresentation(seconds)} s`;
};

function AuctionStatusCell({ dataItem }) {
  const [timeLeft, setTimeLeft] = useState(null);

  const updateAuctionTimeLeft = () => {
    const auctionTime = calculateAuctionTime(
      dataItem.startTime,
      dataItem.endTime,
    );

    const tl = auctionTime
      ? `${auctionTime.sign}${getTimeTo(auctionTime)}`
      : lang.labels.Ended();

    setTimeLeft(tl);
  };

  useImmediateInterval(() => {
    updateAuctionTimeLeft();
  }, 1000);

  return (
    <AuctionStatusClickableCell
      text={timeLeft}
      badgeText={dataItem.status}
      statusId={dataItem.statusId}
      currentPriceWithoutOffer={dataItem.currentPriceWithoutOffer}
    />
  );
}

AuctionStatusCell.propTypes = propTypes;
AuctionStatusCell.defaultProps = defaultProps;

export default memo(AuctionStatusCell);
