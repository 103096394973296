import axios from 'config/axios';
import get from 'lodash/get';
import store from 'store/store';

const prefix = 'transportOrdersManagement';

export default class TransportOrdersManagementSrv {
  static getForGrid(dataState) {
    return axios
      .post(`${prefix}/getForGrid`, {
        ...dataState,
        userPreferences: get(store.getState(), 'userPreferences'),
      })
      .then(result => result.data);
  }

  static get(id) {
    return axios.get(`${prefix}/${id}`).then(result => result.data);
  }

  static getForAddBasedOnAuction(auctionId) {
    return axios
      .get(`${prefix}/getForAddBasedOnAuction/${auctionId}`)
      .then(result => result.data);
  }

  static add(model) {
    return axios.post(prefix, model);
  }

  static update(model) {
    return axios.put(prefix, model);
  }

  static cancel(id, reason) {
    return axios.put(`${prefix}/cancel/${id}`, { reason });
  }

  static sendMessage(id, message) {
    return axios.post(`${prefix}/${id}/message`, { message });
  }

  static getFacilitiesAsSelectItems() {
    return axios
      .get(`${prefix}/getFacilitiesAsSelectItems`)
      .then(result => result.data);
  }

  static getCompaniesAsSelectItems() {
    return axios
      .get(`${prefix}/getCompaniesAsSelectItems`)
      .then(result => result.data);
  }

  static getAddressesAsSelectItem() {
    return axios
      .get(`${prefix}/getAddressesAsSelectItem`)
      .then(result => result.data);
  }

  static getCarTypesAsSelectItems() {
    return axios
      .get(`${prefix}/getCarTypesAsSelectItems`)
      .then(result => result.data);
  }

  static getCurrenciesAsSelectItems() {
    return axios
      .get(`${prefix}/getCurrenciesAsSelectItems`)
      .then(result => result.data);
  }

  static getCompletedTransportOrderPdf(id) {
    return axios
      .get(`${prefix}/getCompletedTransportOrderPdf/${id}`, {
        responseType: 'blob',
        timeout: 30000,
      })
      .then(result => result.data);
  }

  static getAdditionalRequirementsInitValue() {
    return axios
      .get(`${prefix}/getAdditionalRequirementsInitValue`)
      .then(result => result.data);
  }

  static getPreview(transportOrderId) {
    return axios
      .get(`${prefix}/getPreview/${transportOrderId}`)
      .then(result => result.data);
  }

  static getCars(transportOrderId) {
    return axios
      .get(`${prefix}/getCars/${transportOrderId}`)
      .then(result => result.data);
  }

  static getDrivers(transportOrderId) {
    return axios
      .get(`${prefix}/getDrivers/${transportOrderId}`)
      .then(result => result.data);
  }

  static acceptForImplementation(model) {
    return axios
      .post(`${prefix}/acceptForImplementation`, model)
      .then(result => result.data);
  }

  static loaded(model) {
    return axios.post(`${prefix}/loaded`, model).then(result => result.data);
  }

  static realized(model) {
    return axios.post(`${prefix}/realized`, model).then(result => result.data);
  }

  static updateComment(values, transportOrderId) {
    return axios
      .post(`${prefix}/${transportOrderId}/updateComment`, values)
      .then(result => result.data);
  }
}
