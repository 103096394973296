import React from 'react';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import { useSelector, useDispatch } from 'react-redux';
import {
  transportOrderAcceptedFilter,
  transportOrderLoadedFilter,
  transportOrderCompletedFilter,
  transportOrderPlacedFilter,
} from 'transport-orders-management/filters';
import transportOrderActionTypes from 'transport-orders-management/actionTypes';
import { getForGrid as getForGridTransportOrderManagement } from 'transport-orders-management/actions';
import Row from 'layout/Row';
import DashboardCardBox from './DashboardCardBox';
import styles from './DashboardCardBoxes.module.scss';

const propTypes = {
  placedCount: PropTypes.number,
  acceptedCount: PropTypes.number,
  loadedCount: PropTypes.number,
  realizedCount: PropTypes.number,
};

const defaultProps = {
  placedCount: 0,
  acceptedCount: 0,
  loadedCount: 0,
  realizedCount: 0,
};

const OrdersCardBoxes = ({
  placedCount,
  acceptedCount,
  loadedCount,
  realizedCount,
}) => {
  const dispatch = useDispatch();
  const transportOrdersDataState = useSelector(
    state => state.transportOrdersManagement.dataState,
  );

  const dispatchTransportOrderChangeFilter = filter => {
    dispatch(
      getForGridTransportOrderManagement({
        ...transportOrdersDataState,
        filter,
        skip: 0,
      }),
    );
    dispatch({
      type: transportOrderActionTypes.TRANSPORT_ORDER_MANAGEMENT_SET_FILTER,
      payload: filter,
    });
  };

  const dispatchTransportOrderPlaced = () =>
    dispatchTransportOrderChangeFilter(transportOrderPlacedFilter);

  const dispatchTransportOrderAccepted = () =>
    dispatchTransportOrderChangeFilter(transportOrderAcceptedFilter);

  const dispatchTransportOrderLoaded = () =>
    dispatchTransportOrderChangeFilter(transportOrderLoadedFilter);

  const dispatchTransportOrderCompleted = () =>
    dispatchTransportOrderChangeFilter(transportOrderCompletedFilter);

  return (
    <Row className={styles.box_container}>
      <DashboardCardBox
        quantity={placedCount}
        label={lang.labels.DashboardPlacedTransportOrderStatus()}
        link="/transport-orders-management"
        onClick={dispatchTransportOrderPlaced}
        color="red"
      />
      <DashboardCardBox
        quantity={acceptedCount}
        label={lang.labels.DashboardAcceptedTransportOrderStatus()}
        link="/transport-orders-management"
        onClick={dispatchTransportOrderAccepted}
      />
      <DashboardCardBox
        quantity={loadedCount}
        label={lang.labels.DashboardLoadedTransportOrderStatus()}
        link="/transport-orders-management"
        onClick={dispatchTransportOrderLoaded}
      />
      <DashboardCardBox
        quantity={realizedCount}
        label={lang.labels.DashboardCompletedTransportOrderStatus()}
        link="/transport-orders-management"
        onClick={dispatchTransportOrderCompleted}
      />
    </Row>
  );
};

OrdersCardBoxes.propTypes = propTypes;
OrdersCardBoxes.defaultProps = defaultProps;

export default OrdersCardBoxes;
