import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { isRequiredArray } from 'fox-react/dist/utils/formValidators';
import Form from 'fox-react/dist/components/Form';
import FoxInputNumberDecimalField from 'fox-react/dist/reduxFormFields/FoxInputNumberDecimalField';
import FoxComboBoxField from 'fox-react/dist/reduxFormFields/FoxComboBoxField';
import FoxButton from 'fox-react/dist/components/FoxButton';
import goBackAfterSuccess from 'fox-react/dist/utils/goBackAfterSuccess';
import lang from 'hh-shared/dist/language/services/languageService';
import {
  scrollToTheFirstError,
  displayCompletionToasts,
} from 'hh-shared/dist/consts/reduxFormMethods';
import fieldNames from 'common/fieldNames';
import formNames from 'common/formNames';
import icons from 'hh-shared/dist/consts/icons';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  facilities: PropTypes.array.isRequired,
};

const defaultProps = {};

function DefaultPriceInformationForm({ handleSubmit, submitting, facilities }) {
  return (
    <Form handleSubmit={handleSubmit}>
      <fieldset>
        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.DefaultPriceInformations()}
            titleIcon={icons.currencies}
          >
            <FoxInputNumberDecimalField
              label={lang.labels.FlatRate()}
              placeholder={lang.labels.FlatRate()}
              name={fieldNames.defaultPriceInformationForm.flatRate}
              required
            />
            <FoxInputNumberDecimalField
              label={lang.labels.MinDistance()}
              placeholder={lang.labels.MinDistance()}
              name={fieldNames.defaultPriceInformationForm.minDistance}
              required
            />
            <FoxInputNumberDecimalField
              label={lang.labels.MaxDistance()}
              placeholder={lang.labels.MaxDistance()}
              name={fieldNames.defaultPriceInformationForm.maxDistance}
              required
            />
            <FoxComboBoxField
              label={lang.labels.Facility()}
              placeholder={lang.labels.Facility()}
              name={fieldNames.defaultPriceInformationForm.facility}
              items={facilities}
            />
          </FormSectionCard>
        </SectionWrapper>
      </fieldset>
      <FoxButton primary disabled={submitting}>
        {lang.buttons.Save()}
      </FoxButton>
    </Form>
  );
}

DefaultPriceInformationForm.propTypes = propTypes;
DefaultPriceInformationForm.defaultProps = defaultProps;

const validate = values => {
  const errors = {};

  isRequiredArray(
    errors,
    values,
    [
      fieldNames.defaultPriceInformationForm.flatRate,
      fieldNames.defaultPriceInformationForm.minDistance,
      fieldNames.defaultPriceInformationForm.maxDistance,
    ],
    lang.validationMessages.FieldRequired(),
  );

  return errors;
};

const onSubmitSuccess = (result, dispatch, props) =>
  displayCompletionToasts(
    dispatch,
    !!props.isEditForm,
    lang.labels.SuccessfullyModificatedDefaultPrice(),
    lang.labels.SuccessfullyCreatedDefaultPrice(),
  );

export default reduxForm({
  form: formNames.defaultPriceInformationForm,
  validate,
  enableReinitialize: true,
  onSubmitFail: scrollToTheFirstError,
  onSubmitSuccess,
})(goBackAfterSuccess(DefaultPriceInformationForm));
