import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import RouteInformationForm from './RouteInformationForm';
import RoutesManagementSrv from './RoutesManagementSrv';
import RoutesManagementPagePreview from './RoutesManagementPagePreview';
import { updateAsync } from './actions';

const propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

function RoutesManagementEditPage({ match, history }) {
  const [initialValues, setInitialValues] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = model => dispatch(updateAsync(model));

  const zipCodes = useSelector(state => state.dictionary.static.zipCodes);

  useEffect(() => {
    setShowSpinner(true);
    Promise.all([RoutesManagementSrv.get(match.params.id)])
      .then(([routeData]) => {
        setInitialValues(routeData);
      })
      .finally(() => setShowSpinner(false));
  }, [match.params.id]);

  const handleSubmit = model => {
    onSubmit({ ...model, id: match.params.id });
  };

  return (
    <>
      <BreadcrumbsContainer
        links={[
          {
            routePath: '/',
            label: lang.labels.HomePage(),
          },
          {
            routePath: '/routes-management',
            label: lang.labels.Routes(),
          },
          {
            routePath: '/routes-management/edit',
            label: lang.labels.EditingRoute(),
          },
        ]}
      />
      <FormPreviewSectionContainer>
        <FormCardWrapper>
          <div className={showSpinner ? 'fox_spinner' : undefined}>
            <RouteInformationForm
              onSubmit={handleSubmit}
              initialValues={initialValues}
              history={history}
              zipCodes={zipCodes}
              isEditForm
            />
          </div>
        </FormCardWrapper>
        <RoutesManagementPagePreview />
      </FormPreviewSectionContainer>
    </>
  );
}

RoutesManagementEditPage.propTypes = propTypes;
RoutesManagementEditPage.defaultProps = defaultProps;

export default RoutesManagementEditPage;
